import React, { useEffect, useState } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { Price, Purchase, OwnerShipStep, Chips } from 'components';
import { Divider } from '@mui/material';
import { Renderer } from '../Renderer';

// render basket (multiple nfts)
const basketRenderer = (total: number, thumbnails: string[]) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Chips
        thumbnails={thumbnails}
        onClick={() => undefined}
        maxChips={3}
        sx={{ left: 0, border: (theme) => theme.border, cursor: 'default' }}
      />
      {total > 0 && (
        <Stack spacing={1}>
          <Typography variant="subtitle2" color="text.secondary">
            Total amount
          </Typography>
          <Price value={total} justifyContent="flex-end" variant="h3" />
        </Stack>
      )}
    </Box>
  );
};

const CheckoutBasket = () => {
  const basket = useSelector((global: GlobalState) => global.state.basket);
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  let total = 0;
  basket.forEach((nft, _) => {
    const { sale } = nft;
    if (sale) total += sale?.unitary_price_float * Number(sale.quantity);
  });

  useEffect(() => {
    setCurrentStep(1);
    const chips = [...basket.values()].map((nft) => nft.thumbnail);
    setThumbnails(chips);
  }, []);

  return (
    <Renderer title="Buy Items">
      {basketRenderer(total, thumbnails)}
      <Divider sx={{ my: 3 }} variant="middle" />
      {/** Steps */}
      <Stack spacing={2}>
        <OwnerShipStep step={1} currentStep={currentStep} complete={() => setCurrentStep(2)} />
        <Purchase step={2} currentStep={currentStep} complete={() => undefined} basket={basket} />
      </Stack>
    </Renderer>
  );
};

export default CheckoutBasket;
