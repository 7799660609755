import React from 'react';
import { Box, Link, Stack, styled, Typography, useTheme} from '@mui/material';
import { ReactComponent as VisionDark } from '../../../assets/vision/logo-vision-dark.svg';
import { ReactComponent as VisionLight } from '../../../assets/vision/logo-vision-light.svg';

import { VisionDownloadApp } from 'components';

export const GalleriesBanner = () => {
  const theme = useTheme();
  return (
    <Stack spacing={2} sx={{ mt: { xs: 3, lg: 5 }, mb: 5 }}>
      <Box textAlign="center">
        {theme.palette.mode == 'dark' ? <VisionDark /> : <VisionLight />}
      </Box>
      <Typography variant="subtitle1" color="text.secondary" textAlign="center">
        Explore amazing galleries created by the community or Create yours to showcase your NFTs collection.<br />
        Install now Kalao Vision and enjoy an outstanding NFT virtual experience.
      </Typography>
      <VisionDownloadApp />
    </Stack>
  );
};