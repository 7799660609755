// 3rd party libs
import React, { useEffect } from 'react';
import { Box, Stack, styled, Typography, useMediaQuery } from '@mui/material';

// Componenets
import { TopSectionContainer } from 'helpers/topSection';
import { FiltersProvider, useAuth, useFilters, useResize } from 'providers';
import { VirtuosoGrid } from 'react-virtuoso';
import { VContainer, VList } from 'helpers/virtuosoHelpers';
import { useWeb3React } from '@web3-react/core';
import { useNFTs } from 'hooks';
import { Collection, SearchNftSort, SearchTarget } from 'types/generated';
import { useNavigate } from 'react-router-dom';
import { goToNft, initNftWithSale, InnerItem } from 'helpers/nfts';
import { RightContainer } from 'helpers/rightContainer';
import { theme } from 'themes';
import { Filters, MobileFilters, Sorting, Container, Button } from 'components';
import { useLikedAssetsQuery, useLikeMutation, useOwnedNftsCollectionsQuery } from 'api';
import { goToCollection } from 'helpers/collections';
import { AppRoutes } from 'constants/routes';

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

const SIZE = 30;
const INIT_NFTS = [...Array(SIZE).keys()].map(() => initNftWithSale);

export const OwnedRenderer = () => {
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const { config } = useResize();
  const { account } = useWeb3React();
  const { loggedIn } = useAuth();
  const { data: likedAssets } = useLikedAssetsQuery({}, { skip: !loggedIn });
  const [like] = useLikeMutation();
  const { data: ownedCollections } = useOwnedNftsCollectionsQuery(
    { userAddress: account },
    { skip: !account }
  );
  const { sortBy, salesType, keywords, collection, setCollections } = useFilters();
  const { nfts, error, isLoading, loadMore, updateLikes } = useNFTs({
    keywords,
    collectionAddress: collection?.address,
    sortBy,
    target: SearchTarget.Collectibles,
    salesType,
    owner: account,
    skip: !account,
    size: SIZE
  });

  useEffect(() => {
    if (ownedCollections) setCollections(ownedCollections.ownedNFTsCollections as Collection[]);
  }, [ownedCollections]);

  const onLike = (assetId: string, likes: number) => {
    updateLikes(assetId, likedAssets?.[assetId] ? --likes : ++likes);
    like({ assetId, value: !likedAssets?.[assetId] }).unwrap();
  };

  const itemContent = (index: number) => {
    const nft = isLoading ? INIT_NFTS[index] : nfts[index];
    return (
      nft && (
        <InnerItem
          nft={nft}
          owned={true}
          goTo={() => navigate(goToNft(nft.asset_id))}
          goToCollection={() => navigate(goToCollection(nft.collection.address))}
          liked={likedAssets?.[nft.asset_id]}
          onLike={() => onLike(nft.asset_id, nft.likes)}
          isLoading={isLoading}
          showBalance={true}
        />
      )
    );
  };

  const filters = matches ? (
    <Filters showOwnCollections={true} showKeywords={true} />
  ) : (
    <MobileFilters activeFilters={['ownedCollections']} search={true} sorting={<Sorting sortType={'sortCollection'} defaultKey={'price_asc'} />} />
  );

  return (
    <Root>
      <TopSectionContainer>
        {filters}
        {matches && (
          <RightContainer>
            <Button ctx="primary" onClick={() => navigate(`/${AppRoutes.sell}`)}>
              <Typography variant="button">Bulk Listing</Typography>
            </Button>
            <Button ctx="primary" onClick={() => navigate(`/${AppRoutes.transfer}`)}>
              <Typography variant="button">Bulk Transfer</Typography>
            </Button>
            <Sorting defaultKey={'recently_received'} sortType={'sortOwnedNfts'} />
          </RightContainer>
        )}
        {!matches && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{my: 1}}>
            <Button ctx="primary" onClick={() => navigate(`/${AppRoutes.sell}`)} size="small" sx={{mr: 1}}>
              <Typography variant="button">Bulk Listing</Typography>
            </Button>
            <Button ctx="primary" onClick={() => navigate(`/${AppRoutes.transfer}`)} size="small">
              <Typography variant="button">Bulk Transfer</Typography>
            </Button>
          </Stack>
        )}
      </TopSectionContainer>
      <Container>
        <VirtuosoGrid
          useWindowScroll={true}
          totalCount={isLoading ? SIZE : nfts.length}
          endReached={() => loadMore()}
          overscan={500}
          components={{
            Item: VContainer(config),
            List: VList
          }}
          itemContent={itemContent}
        />
      </Container>
    </Root>
  );
};

export const Owned = () => (
  <FiltersProvider sortBy={SearchNftSort.RecentlyReceived}>
    <OwnedRenderer />
  </FiltersProvider>
);
