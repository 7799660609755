/* eslint-disable react/display-name */
import { VirtuosoGrid } from 'react-virtuoso';
import { theme } from 'themes';
import React, { useMemo, useState } from 'react';
import { EnhancedActivity, SortClickHandler, SortState } from 'types';
import { useMediaQuery, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useNetwork, useSort } from 'hooks';
import { EmptyPlaceHolder, initSortState } from './utils';
import {
  RenderActivity,
  RenderMobileActivity,
  skeletonRow,
  Headers,
  RenderNftMobileActivity
} from './rows';
import { goToNft } from 'helpers/nfts';
import { goToCollection } from 'helpers/collections';
import { goToArtist } from 'helpers/artists';

export type ActivityTableProps = {
  activities: EnhancedActivity[];
  loading: boolean;
  height?: number;
  minHeight?: number;
  isNftPage?: boolean;
  useWindowScroll: boolean;
  loadMore?: () => void;
};

const ListContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

const Scrollable = styled('div')(({ theme }) => ({
  scrollbarGutter: 'stable',
  '&::-webkit-scrollbar': {
    width: '0.3rem'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '1rem',
    backgroundColor: theme.palette.mode == 'dark' ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)'
  }
}));

const ActivityTable = ({
  activities,
  loading,
  loadMore,
  height,
  minHeight,
  useWindowScroll,
  isNftPage
}: ActivityTableProps): JSX.Element => {
  const { network } = useNetwork();
  const navigate = useNavigate();
  const [sortState, setSortState] = useState<Record<string, SortState>>(initSortState());
  const sortedActivities = useSort<EnhancedActivity>(sortState, activities);
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const renderer = (index: number) => {
    if (loading) return skeletonRow(!matches, isNftPage);

    const activity = sortedActivities[index];
    return matches ? (
      <RenderActivity
        activity={activity}
        network={network.blockExplorerUrls[0]}
        goTo={goTo}
        isNftPage={isNftPage}
      />
    ) : isNftPage ? (
      <RenderNftMobileActivity activity={activity} goTo={goTo} network="" />
    ) : (
      <RenderMobileActivity activity={activity} goTo={goTo} network="" />
    );
  };

  const sortHandler: SortClickHandler = (key, currentSortState) => {
    const newState = {
      ...sortState,
      [key]:
        currentSortState === SortState.ascending
          ? SortState.descending
          : currentSortState === SortState.descending
          ? SortState.unsorted
          : SortState.ascending
    };
    setSortState(newState);
  };

  const headers = useMemo(() => {
    return <Headers sort={sortState} click={sortHandler} hide={!matches} isNftPage={isNftPage} />;
  }, [matches, sortState, sortHandler]);

  const goTo = (page: 'nft' | 'collection' | 'user', address: string) => {
    if (page == 'nft') navigate(goToNft(address));
    else if (page == 'collection') navigate(goToCollection(address));
    else {
      navigate(goToArtist(address));
    }
  };

  return (
    <>
      {headers}
      {sortedActivities.length == 0 ? (
        <EmptyPlaceHolder>
          <Typography variant="body1" color="text.primary">
            No activities found !
          </Typography>
        </EmptyPlaceHolder>
      ) : (
        <VirtuosoGrid
          style={{
            height,
            minHeight
          }}
          totalCount={sortedActivities.length}
          useWindowScroll={useWindowScroll}
          endReached={() => loadMore?.()}
          components={{
            List: React.forwardRef((props, ref) => <ListContainer {...props} ref={ref} />),
            // eslint-disable-next-line react/prop-types
            Scroller: React.forwardRef(({ style, ...props }, ref) => {
              return <Scrollable style={{ ...style }} ref={ref} {...props} />;
            })
          }}
          itemContent={renderer}
        />
      )}
    </>
  );
};

export default ActivityTable;
