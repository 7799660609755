import React, { useEffect, useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { Approve, DropDownInput, ProcessBid, NumberInput, Price, Button } from 'components';
import { Bid, NumberOrString, PriceSymbol, Property } from 'types';
import { Renderer } from '../Renderer';
import { getTokenOptions } from 'constants/options';
import { useAssets } from 'hooks';
import { useLazyAttributesCountQuery } from 'api';
import { AttributesQuery } from 'types/generated';

type OfferProps = {
  confirmOffer: (price: number, qty: number) => void;
  max: number;
};

const validate = (price: NumberOrString | undefined, qty: NumberOrString | undefined) => {
  const err: Record<string, string> = {};
  if (!price) err['price'] = 'Enter price';
  if (!qty) err['quantity'] = 'Enter quantity';
  return { err };
};

const OfferForm = ({ confirmOffer, max }: OfferProps) => {
  const [price, setPrice] = useState<NumberOrString>('');
  const [quantity, setQuantity] = useState<NumberOrString>('1');
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const { wavax } = useAssets();

  const onConfirmOffer = () => {
    const { err } = validate(price, quantity);
    if (Object.keys(err).length !== 0) setErrors(err);
    else {
      confirmOffer(Number(price), Number(quantity));
    }
  };

  const total = price && quantity ? Number(price) * Number(quantity) : undefined;

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary" textAlign="start">
          Price
        </Typography>
        <DropDownInput
          placeholder="Enter price"
          value={price}
          numeric={true}
          onValueChange={(v) => {
            if (errors['price']) setErrors({ ...errors, ['price']: undefined });
            setPrice(v);
          }}
          defaultKey={'WAVAX'}
          onOptionChange={(v) => undefined}
          options={getTokenOptions(['WAVAX'])}
          error={errors['price'] !== undefined}
        />
        {errors['price'] && (
          <Typography variant="caption" color="red">
            {errors['price']}
          </Typography>
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="caption" color="text.secondary">
            Balance:
          </Typography>
          <Price value={wavax} symbol={PriceSymbol.WAVAX} size={10} />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary" textAlign="start">
          Quantity
        </Typography>
        <NumberInput
          sx={{ width: '100%', borderRadius: '0.5rem', height: '3.5rem' }}
          value={quantity}
          step={1}
          max={max}
          onChange={(value) => {
            if (errors['quantity']) setErrors({ ...errors, ['quantity']: undefined });
            setQuantity(value as number);
          }}
          error={errors['quantity'] !== undefined}
        />
        <Typography variant="caption" color="red">
          {errors['quantity']}
        </Typography>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h4" color="text.secondary">
            Total
          </Typography>
          {total && <Price value={total} symbol={PriceSymbol.WAVAX} />}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button ctx="primary" onClick={onConfirmOffer}>
            <Typography variant="button" color="text.primary">
              Place A bid
            </Typography>
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

const toAttributes = (properties?: Property[]): AttributesQuery[] => {
  const attrs: AttributesQuery[] = [];
  properties?.forEach((property) => {
    property.attrs.forEach((attr) => {
      attrs.push({ trait_type: property.name, value: attr.name });
    });
  });
  return attrs;
};

const AdvancedOffer = () => {
  const advancedOffer = useSelector((global: GlobalState) => global.state.advancedOffer);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [confirmOffer, setConfirmedOffer] = useState<Bid>();
  const [trigger, results] = useLazyAttributesCountQuery();

  useEffect(() => {
    setCurrentStep(1);
    trigger({
      attributes: toAttributes(advancedOffer?.properties),
      collectionAddress: advancedOffer?.collection.address
    });
  }, []);

  const onConfirmOffer = (price: number, quantity: number) => {
    if (advancedOffer) {
      const { properties, collection } = advancedOffer;
      if (properties) {
        const offer: Bid = {
          type: 'attributes',
          properties,
          price,
          quantity,
          collection
        };
        setConfirmedOffer(offer);
      } else {
        const offer: Bid = {
          type: 'collection',
          price,
          quantity,
          collection
        };
        setConfirmedOffer(offer);
      }
    }
  };

  const title =
    advancedOffer?.properties === undefined ? 'Make Collection Offer' : 'Make Properties Offer';

  const count = results?.data?.attributesCount || 0;

  return (
    <Renderer
      header={
        <Typography variant="h3" color="text.primary" textAlign="center" sx={{ mb: 3 }}>
          {title}
        </Typography>
      }
      advancedOffer={advancedOffer}
      selectedNftsCount={count}
    >
      {/** Steps */}
      {!confirmOffer ? (
        <OfferForm confirmOffer={onConfirmOffer} max={count} />
      ) : (
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Divider sx={{ my: 3 }} variant="middle" />
          <Approve
            bid={confirmOffer}
            token={PriceSymbol.WAVAX}
            step={1}
            currentStep={currentStep}
            complete={() => setCurrentStep(2)}
          />
          <ProcessBid
            step={2}
            currentStep={currentStep}
            complete={() => undefined}
            token={PriceSymbol.WAVAX}
            bid={confirmOffer}
          />
        </Stack>
      )}
    </Renderer>
  );
};

export default AdvancedOffer;
