import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ConnectorRenderer, Help, isMobile, openMetamaskDeepLink, StyledContainer } from '../utils';
import { ReactComponent as MetamaskIcon } from '../../../assets/wallet/metamask.svg';
import { ReactComponent as WalletconnectIcon } from '../../../assets/wallet/wallet-connect.svg';
import { ReactComponent as CoinbaseIcon } from '../../../assets/wallet/coinbase.svg';
import { ReactComponent as CorewalletIconDark } from '../../../assets/wallet/corewallet.svg';
import { ReactComponent as CorewalletIconLight } from '../../../assets/wallet/corewallet-black.svg';
import { metaMask, hooks as metaHooks } from 'connectors/metamask';
import { avalanche, hooks as avaxHooks } from 'connectors/avalanche';
import { walletConnect, hooks as walletHooks } from 'connectors/walletConnect';
import { coinbaseWallet, hooks as coinbaseHooks } from 'connectors/coinbase';
import { useLocalStorage } from 'hooks';
import { useNetwork } from 'hooks';
import { useTheme } from '@mui/material';

type Props = {
  help?: boolean;
};

const Web3Connectors = ({ help }: Props): JSX.Element => {
  const theme = useTheme();
  const [metaError, setMetamskError] = useState('');
  const [coreError, setCoreError] = useState('');
  const [walletError, setWalletError] = useState('');
  const [coinbaseError, setCoinbaseError] = useState('');
  const [_, setLastConnector] = useLocalStorage<string | undefined>('last-connector', undefined);
  const { hooks } = useWeb3React();
  const { supportedChainId } = useNetwork();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();
  const avaxError = avaxHooks.useError();
  const metaMaskError = metaHooks.useError();
  const cbError = coinbaseHooks.useError();
  const walletEroor = walletHooks.useError();

  useEffect(() => {
    if (metaMaskError) {
      setMetamskError(metaMaskError.message);
      setLastConnector('');
    }
    if (avaxError) {
      setCoreError(
        avaxError.message == 'Avalanche not installed'
          ? 'Core Wallet not installed'
          : avaxError.message
      );
      setLastConnector('');
    }
    if (walletEroor) {
      setWalletError(walletEroor.message);
      setLastConnector('');
    }
    if (cbError) {
      setCoinbaseError(cbError.message);
      setLastConnector('');
    }
  }, [metaMaskError, avaxError, walletEroor, cbError]);

  return (
    <StyledContainer spacing={1} sx={{ height: '100%' }}>
      <ConnectorRenderer
        title="Browser Wallet"
        subtitle="Connect to your browser wallet"
        error={metaError}
        icon={<MetamaskIcon />}
        connect={async () => {
          if (isMobile() && !window.ethereum) {
            openMetamaskDeepLink();
          } else {
            try {
              await provider?.send('wallet_requestPermissions', [{ eth_accounts: {} }]);
              await metaMask.activate(supportedChainId);
              setLastConnector('metamask');
            } catch (err: any) {
              if (err?.code) {
                setMetamskError(err.message);
              } else if (err instanceof Error) {
                setMetamskError(err.message);
              } else setMetamskError('Something went wrong !');
            }
          }
        }}
      />
      <ConnectorRenderer
        title="Core Wallet"
        subtitle="Connect to your core wallet"
        error={coreError}
        icon={theme.palette.mode == 'dark' ? <CorewalletIconDark /> : <CorewalletIconLight />}
        connect={async () => {
          try {
            await avalanche.activate(supportedChainId);
            setLastConnector('avalanche');
          } catch (err: any) {
            if (err?.code) {
              setCoreError(err.message);
            } else if (err instanceof Error) {
              setCoreError(err.message);
            } else setCoreError('Something went wrong !');
          }
        }}
      />
      <ConnectorRenderer
        title="WalletConnect"
        subtitle="Scan with WalletConnect to connect"
        error={walletError}
        icon={<WalletconnectIcon />}
        connect={async () => {
          try {
            await walletConnect.activate();
            setLastConnector('walletconnect');
          } catch (err: any) {
            if (err?.code) {
              setCoreError(err.message);
            } else if (err instanceof Error) {
              setCoreError(err.message);
            } else setCoreError('Something went wrong !');
          }
        }}
      />
      <ConnectorRenderer
        title="Coinbase Wallet"
        subtitle="Scan with Coinbase to connect"
        error={coinbaseError}
        icon={<CoinbaseIcon />}
        connect={async () => {
          try {
            await coinbaseWallet.activate();
            setLastConnector('coinbase');
          } catch (err: any) {
            if (err?.code) {
              setCoreError(err.message);
            } else if (err instanceof Error) {
              setCoreError(err.message);
            } else setCoreError('Something went wrong !');
          }
        }}
      />
      {help && <Help />}
    </StyledContainer>
  );
};

export default Web3Connectors;
