import React from 'react';
import { styled, Box } from '@mui/material';

// Components
import { Container, Footer } from 'components';
import { Summary } from './Summary';
import { Listings } from './Listings';
import { Offers } from './Offers';
import { UserDashboardStatistics } from 'types/generated';

type Props = {
  stats: UserDashboardStatistics;
};

// Styling
const ItemsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem'
});

export default function Dash({ stats }: Props) {
  return (
    <>
      <Container sx={{ paddingBottom: '5rem' }}>
        <ItemsContainer>
          <Summary stats={stats} />
          <Listings />
          <Offers />
        </ItemsContainer>
      </Container>
      <Footer />
    </>
  );
}
