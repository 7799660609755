import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { OwnerShipStep, ApproveAsset, CompleteSigning, Button, Price } from 'components';
import { Divider, Typography, Box, styled } from '@mui/material';
import { Renderer } from '../Renderer';
import { NFTOffer, PriceSymbol } from 'types';
import { OfferSide } from 'types/generated';
import { kalaoApi } from 'api';
import { useWeb3React } from '@web3-react/core';

const Fees = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});

type Props = {
  nftOffer?: NFTOffer;
  accept: () => void;
};

const AcceptOfferForm = ({ nftOffer, accept }: Props) => {
  if (!nftOffer) return null;
  const { offer } = nftOffer;
  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      <Stack>
        <Typography variant="body2" color="text.secondary" textAlign="start">
          From
        </Typography>
        <Typography variant="body2" color="text.primary" textAlign="start">
          {offer.buyer}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="body2" color="text.secondary" textAlign="start">
          Fees
        </Typography>
        <Fees>
          <Typography variant="subtitle2" color="text.primary">
            Service Fee
          </Typography>
          <Typography variant="subtitle2" color="text.primary">
            0%
          </Typography>
        </Fees>
      </Stack>
      <Divider sx={{ my: 3 }} variant="middle" />
      <Fees>
        <Typography variant="h5" color="text.secondary">
          Total earnings
        </Typography>
        <Price variant="h4" value={offer.unitary_price_float} symbol={PriceSymbol.WAVAX} />
      </Fees>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button ctx="primary" sx={{ width: '8rem' }} onClick={() => accept()}>
          <Typography variant="button">Accept</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

export const getAddresses = (offers: NFTOffer[]) => {
  const addresses = new Set<string>();

  offers.forEach((offer) => {
    const { collection } = offer;
    addresses.add(collection.address);
  });
  return [...addresses.values()];
};

const AcceptOffer = () => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const nftOffer = useSelector((global: GlobalState) => global.state.nftOffer);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [accept, setAccept] = useState(false);

  useEffect(() => {
    setCurrentStep(1);
  }, []);

  // Refetch list of offers
  const refetch = () => {
    dispatch(
      kalaoApi.endpoints.MyOffers.initiate(
        { address: account, kind: OfferSide.All },
        { subscribe: false, forceRefetch: true }
      )
    );
  };

  return (
    //todo: pass offer to renderer
    <Renderer title="Accept Offer" nftOffer={nftOffer}>
      {!accept && <AcceptOfferForm nftOffer={nftOffer} accept={() => setAccept(true)} />}
      {/** Steps */}
      {accept && nftOffer && (
        <>
          <Divider sx={{ my: 3 }} variant="middle" />
          <Stack spacing={2}>
            <OwnerShipStep step={1} currentStep={currentStep} complete={() => setCurrentStep(2)} />
            <ApproveAsset
              step={2}
              currentStep={currentStep}
              complete={() => setCurrentStep(3)}
              collections={getAddresses([nftOffer])}
            />
            <CompleteSigning
              step={3}
              nftOffer={nftOffer}
              currentStep={currentStep}
              complete={refetch}
              token={PriceSymbol.WAVAX}
              price={nftOffer.offer.unitary_price_float}
              offerMsg={
                nftOffer.offer.side == OfferSide.Received
                  ? 'Please sign the transaction in your wallet to accept the offer'
                  : 'Please sign the transaction in your wallet to create an offer'
              }
            />
          </Stack>
        </>
      )}
    </Renderer>
  );
};

export default AcceptOffer;
