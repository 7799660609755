// 3rd party libs
import React from 'react';
import { Box, styled, useMediaQuery } from '@mui/material';
import Collections from './TopCollections';
import { RankingTabs } from './Tabs';
import { matchPath, Route, Routes, useLocation } from 'react-router-dom';
import Users from './TopUsers';
import { FiltersProvider } from 'providers';
import { theme } from 'themes';
import { Banner, Filters, MobileFilters } from 'components';

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

// Compononent
const RankingPage = (): JSX.Element => {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const location = useLocation();
  const showCategories =
    !!matchPath('/stats/rankings/collections', location.pathname) ||
    !!matchPath('/stats/rankings', location.pathname);
  const filters = matches ? (
    <Filters
      showPeriod={true}
      showCategories={showCategories}
      width="15rem"
      sx={{ justifyContent: 'center' }}
    />
  ) : (
    <Box sx={{px:2, mb: 1}}>
      <MobileFilters activeFilters={showCategories ? ['period', 'categories'] : ['period']} />
    </Box>
  );

  return (
    <Root>
      <Banner
        title="Ranking"
        subTitle="Top Collections, Buyers and Sellers ranked by volume, floor price and more"
      />
      {filters}
      <RankingTabs />
      <Routes>
        <Route path="/" element={<Collections />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/users" element={<Users />} />
      </Routes>
    </Root>
  );
};

const Ranking = (): JSX.Element => {
  return (
    <FiltersProvider>
      <RankingPage />
    </FiltersProvider>
  );
};

export default Ranking;
