import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialog-paper': {
    background: theme.palette.background.paper,
    borderRadius: '1rem',
    minWidth: '600px'
  }
}));

const Title = styled(DialogTitle)({
  position: 'relative'
});

interface DialogProps {
  title?: JSX.Element;
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialog = ({ children, open, title, onClose }: DialogProps) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '90%', padding: '2rem' } }}
      BackdropProps={{ style: { opacity: 1 } }}
    >
      <Title>
        <Typography variant="h3" component={'span'}>
          {title}
        </Typography>
        <CloseIcon
          onClick={onClose}
          fontSize="small"
          sx={{
            position: 'absolute',
            cursor: 'pointer',
            top: 0,
            right: 0,
            borderRadius: '50%',
            fontWeight: 900,
            color: (theme) => theme.palette.text.secondary
          }}
        />
      </Title>
      {children}
    </BootstrapDialog>
  );
};

export default CustomDialog;
