import React from 'react';
import { Card, styled, Typography, Badge, Stack, Box } from '@mui/material';
import { Button, Price } from 'components';
import { NftSalePreview } from 'types/generated';
import { priceFormatter } from 'lib/format';
import { PriceSymbol } from 'types';

interface PurchasePros {
  sale: NftSalePreview;
  onBuy?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
}

interface OfferProps {
  bestOffer?: number | null;
  isOwner?: boolean;
  sale?: NftSalePreview | null;
  onMakeOffer?: () => void;
  onSale: () => void;
  onTransfer: () => void;
}

interface SaleOrTransferProps {
  sale: () => void;
  transfer: () => void;
}

type NFTCardActionItemType = {
  title: string;
  icon: boolean;
  price: JSX.Element;
};

type NFTCardActionType = {
  cardDetails: NFTCardActionItemType;
  background?: string;
  el?: JSX.Element;
};

const CardStyled = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '1.25rem',
  minWidth: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  boxShadow: 'none'
}));

const BadgeStyled = styled(Badge)({
  '.MuiBadge-badge': {
    top: '50%',
    left: '-1.1rem',
    right: 'inherit',
    textAlign: 'center'
  }
});

export const NFTCardAction = ({ cardDetails, background, el }: NFTCardActionType) => {
  const { title, price, icon } = cardDetails;
  return (
    <CardStyled
      sx={{
        background: (theme) => background || theme.input.background
      }}
    >
      <Stack alignItems="center" spacing={2}>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          align="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {icon ? (
            <BadgeStyled color="secondary" variant="dot">
              {title}
            </BadgeStyled>
          ) : (
            <>{title}</>
          )}
        </Typography>
        {price}
        {el}
      </Stack>
    </CardStyled>
  );
};

export const Purchase = ({ sale, onBuy }: PurchasePros) => {
  return (
    <NFTCardAction
      cardDetails={{
        title: 'On Sale For',
        price: <Price value={priceFormatter.format(sale.unitary_price_float)} variant="h3" />,
        icon: false
      }}
      el={
        <Button ctx="primary" onClick={onBuy} size="large" sx={{ width: '10rem' }}>
          <Typography variant="button">Buy Now</Typography>
        </Button>
      }
    />
  );
};

export const Edit = ({ sale, onEdit, onCancel }: PurchasePros) => {
  return (
    <Box>
      <NFTCardAction
        cardDetails={{
          title: 'On Sale For',
          price: <Price value={priceFormatter.format(sale.unitary_price_float)} variant="h3" />,
          icon: false
        }}
      />
      <Box sx={{ textAlign: 'center' }}>
        <Button ctx="primary" onClick={onEdit} size="small" sx={{ width: '10rem', mt: 2, mx: 1 }}>
          <Typography variant="button">Edit price</Typography>
        </Button>
        <Button
          ctx="secondary"
          onClick={onCancel}
          size="small"
          sx={{ width: '10rem', mt: 2, mx: 1 }}
        >
          <Typography variant="button">Cancel</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export const Offer = ({
  sale,
  bestOffer,
  isOwner,
  onMakeOffer,
  onSale,
  onTransfer
}: OfferProps) => {
  return (
    <Box>
      <NFTCardAction
        cardDetails={{
          title: bestOffer ? 'Best offer' : 'No offers yet',
          price: bestOffer ? (
            <Price
              value={priceFormatter.format(bestOffer || 0)}
              symbol={PriceSymbol.WAVAX}
              variant="h3"
            />
          ) : (
            <Typography variant="h3">-</Typography>
          ),
          icon: false
        }}
        background="transparent"
        el={
          !isOwner ? (
            <Button ctx="secondary" onClick={onMakeOffer} size="large" sx={{ width: '10rem' }}>
              <Typography variant="button">Make Offer</Typography>
            </Button>
          ) : (
            <></>
          )
        }
      />
      {!sale && isOwner && (
        <Box sx={{ textAlign: 'center' }}>
          <Button ctx="primary" onClick={onSale} size="small" sx={{ width: '10rem', mt: 2, mx: 1 }}>
            <Typography variant="button">List For Sale</Typography>
          </Button>
          <Button
            ctx="primary"
            onClick={onTransfer}
            size="small"
            sx={{ width: '10rem', mt: 2, mx: 1 }}
          >
            <Typography variant="button">Transfer</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const SaleOrTransfer = ({ sale, transfer }: SaleOrTransferProps) => {
  return (
    <NFTCardAction
      cardDetails={{
        title: 'No offers yet',
        price: <Typography variant="h3">-</Typography>,
        icon: false
      }}
      el={
        <Box sx={{ textAlign: 'center' }}>
          <Button ctx="primary" onClick={sale} size="large" sx={{ width: '10rem' }}>
            <Typography variant="button">List For Sale</Typography>
          </Button>
          <Button ctx="primary" onClick={transfer} size="large" sx={{ width: '10rem' }}>
            <Typography variant="button">Transfer</Typography>
          </Button>
        </Box>
      }
    />
  );
};
