import * as Redux from 'redux';

export type Action<TPayload> = Redux.Action & TPayload;

type Factory<TPayload> = {
  create: (payload: TPayload) => Action<TPayload>;
  test: (action: Redux.Action) => action is Action<TPayload>;
};

export function factory<TPayload>(type: string): Factory<TPayload> {
  return {
    // Creates an action of the specified type.
    create: (payload: TPayload): Action<TPayload> => {
      const p = <Action<TPayload>>payload;
      p.type = type;
      return p;
    },

    // Type-safe check if an action is of the corresponding type.
    test: (action: Redux.Action): action is Action<TPayload> => action.type === type
  };
}
