import React, { useEffect, useRef, useState } from 'react';
import { Box, Skeleton, styled } from '@mui/material';
import ReactPannellum from 'components/imagePanorama/panellum';

type Props = {
  src: string;
  isLoading?: boolean;
};

const Container = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  '.pnlm-container': {
    width: '100%'
  }
}));

const ImagePanorama = ({
  src,
  isLoading
}: Props): JSX.Element => {
  const [imgSrc, setImgSrc] = useState(src);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setImgSrc(src);
    setLoading(false);
  }, [src]);

  const configPannellum = {
    autoRotate: -1,
    hfov: 150,
    autoLoad: true
  };

  return (
    <Container>
      {(loading || isLoading) && <Skeleton variant="rectangular" className="skeleton" />}
      <ReactPannellum
        id="1"
        sceneId="firstScene"
        imageSource={imgSrc}
        config={configPannellum}
      />
    </Container>
  );
};

export default ImagePanorama;
