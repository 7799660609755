import React, { useEffect, useMemo, useState } from 'react';

import { Box, ClickAwayListener, debounce, Grow, Paper, Popper, styled } from '@mui/material';
import { useAutocompleteMutation } from 'api';
import { SearchTarget } from 'types/generated';
import { SearchInput } from './Search';
import { Chips } from './Chips';
import AutoCompleteRenderer from 'components/AutoCompleteRenderer';

export const PopperContainer = styled(Box)({
  background: 'transparent',
  paddingTop: '0.5rem'
});

export const InnerPopperContainer = styled(Paper)(({ theme }) => ({
  background:
    theme.palette.mode == 'dark' ? theme.input.background : theme.palette.background.paper,
  borderRadius: '0.9375rem',
  padding: theme.spacing(2),
  width: '100%'
}));

const AutoComplete = () => {
  const [open, setOpen] = React.useState(false);
  const [target, setTarget] = useState('');
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [autoComplete, { reset }] = useAutocompleteMutation({ fixedCacheKey: 'auto-complete' });

  useEffect(() => {
    return () => {
      debouncedSearchChange.clear();
    };
  }, []);

  const targets =
    target == 'NFTs'
      ? [SearchTarget.Sales, SearchTarget.Collectibles]
      : target == 'Collections'
      ? [SearchTarget.Collections]
      : target == 'Users'
      ? [SearchTarget.Artists]
      : [
          SearchTarget.Collectibles,
          SearchTarget.Collections,
          SearchTarget.Artists,
          SearchTarget.Sales
        ];

  function handleSearchChange(search: string) {
    if (search.length >= 3) {
      autoComplete({
        input: {
          word_begin: search,
          targets,
          certified: false,
          top: false
        }
      });
    }
  }

  const handleTargetChange = (search: string): void => {
    autoComplete({
      input: {
        word_begin: search,
        targets,
        certified: false,
        top: false
      }
    });
  };

  const debouncedSearchChange = useMemo(() => debounce(handleSearchChange, 300), [target]);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <SearchInput
        ref={anchorRef}
        target={target}
        onFocus={() => setOpen(true)}
        onDelete={() => setTarget('')}
        onReset={reset}
        onTargetFilter={handleTargetChange}
        inputChange={debouncedSearchChange}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal={true}
        style={{ width: anchorRef.current?.clientWidth, zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <PopperContainer>
              <ClickAwayListener onClickAway={handleClose}>
                <InnerPopperContainer id="composition-menu">
                  <Chips select={setTarget} />
                  <AutoCompleteRenderer close={() => setOpen(false)} />
                </InnerPopperContainer>
              </ClickAwayListener>
            </PopperContainer>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AutoComplete;
