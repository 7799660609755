import config from 'config';
import { convertOfferPriceInWei } from 'services/exchange';
import { addDays, getUnixTime } from 'date-fns';
import { OfferInput, AttributesQuery } from 'types/generated';
import { Bid } from 'types';

// handles offer on colleciton and offer on NFT
// we provide merkle_root of the tokenId if offer on NFT
// if offer on collection or attributes we provide an empty array
export const toBackendOffer = (
  price: number,
  quantity: number,
  buyer: string,
  address: string,
  nonce: number,
  chainId: number,
  expiration: Date,
  token_ids: string[] | undefined,
  proof: string | undefined
): OfferInput => {
  return {
    buyer,
    token_address: address,
    token_ids: token_ids || [],
    merkle_root: proof || config.null_merkleroot,
    payment_token: config.wavax,
    amount_to_buy: quantity.toString(),
    unitary_price: convertOfferPriceInWei(price),
    expiration_ts: getUnixTime(expiration),
    starting_ts: getUnixTime(new Date()),
    nonce: nonce,
    chain_id: chainId,
    buyer_signature: ''
  };
};

export const getOffer = (
  account: string,
  nonce: number,
  chainId: number,
  bid: Bid,
  attrsTokenIDs: string[],
  attributes: AttributesQuery[],
  // Applies to attributes and nft
  proof?: string | undefined
): OfferInput => {
  const { collection, price, quantity } = bid;
  const { address } = collection;
  let offer: OfferInput;

  if (bid.type == 'nft') {
    const { token_id, expirationDate } = bid;
    offer = toBackendOffer(
      price,
      quantity,
      account,
      address,
      nonce,
      chainId,
      expirationDate,
      [token_id],
      proof
    );
  } else if (bid.type == 'collection') {
    offer = toBackendOffer(
      price,
      quantity,
      account,
      address,
      nonce,
      chainId,
      addDays(new Date(), 10),
      undefined,
      undefined
    );
  } else {
    offer = toBackendOffer(
      price,
      quantity,
      account,
      address,
      nonce,
      chainId,
      addDays(new Date(), 10),
      attrsTokenIDs,
      proof
    );
    offer.attributes = JSON.stringify({ attributes: attributes });
  }

  return offer;
};
