import { formatAddress } from 'helpers/formatAddress';
import { CollectionRanking } from 'types';
import { Collection, CollectionRankingQuery, CollectionStats, GraphNode } from 'types/generated';

export const parseCollection = (collection?: Collection | null): Collection => {
  return {
    avatar: collection?.avatar || '',
    address: collection?.address || '',
    certified: collection?.certified || false,
    name:
      (collection?.name && collection?.name != 'anonymous'
        ? collection?.name
        : formatAddress(collection?.address)) || '',
    thumbnails: collection?.thumbnails || []
  };
};

export const parseCollectionStats = (
  stats?: Partial<CollectionStats> | null
): Pick<CollectionStats, 'volume' | 'average_price' | 'total_owners' | 'floor_price'> & {
  supply: number;
} => {
  return {
    average_price: stats?.average_price || 0,
    volume: stats?.volume || 0,
    total_owners: stats?.total_owners || 0,
    supply: stats?.total_minted ? Number(stats.total_minted) : 0,
    floor_price: stats?.floor_price || 0
  };
};

export const toSeries = (graph?: {
  nodes: Pick<GraphNode, 'x_coordinate' | 'average_price'>[];
}) => {
  const data: { x: number; y: number }[] = [];
  graph?.nodes.forEach((node) => {
    const { average_price: y, x_coordinate: x } = node;
    data.push({ y, x });
  });

  return data;
};

export const toCollectionsRanking = (data?: CollectionRankingQuery): CollectionRanking[] => {
  return (
    data?.ranking.items.map((item) => {
      return {
        rank: item.rank,
        ...parseCollection(item?.collection),
        ...parseCollectionStats(item?.collection_stats),
        series: toSeries(item?.graph)
      };
    }) || []
  );
};
