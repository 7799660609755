import React from 'react';
import { Grid, styled, Box, Typography } from '@mui/material';
import { KeyValuePair } from 'types';

type Props = {
  data: KeyValuePair<string, string>[];
  sm: number;
};

const Container = styled(Box)(({ theme }) => ({
  borderRadius: '0.9375rem',
  padding: theme.spacing(3, 1),
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.input.background,
  height: '100%'
}));

const Overview = ({ data, sm }: Props) => {
  /**
   *
   */
  const render = (data: KeyValuePair<string, string>) => {
    const { key, value } = data;
    return (
      <Grid key={data.key} item xs={6} sm={sm}>
        <Container>
          <Typography variant="body2" color="text.secondary">
            {key}
          </Typography>
          <Typography variant="h4" color="text.primary" fontWeight={500}>
            {value}
          </Typography>
        </Container>
      </Grid>
    );
  };

  return (
    <Grid container direction="row" spacing={2}>
      {data.map(render)}
    </Grid>
  );
};

export default Overview;
