// 3rd party libs
import React, { useState } from 'react';
import { Stack, Grid, useMediaQuery } from '@mui/material';
import { useUserRanking } from 'hooks';
import { toUsersRanking } from 'helpers/rankingHelpers';
import { NumberOrString, UserRanking } from 'types';
import { NameRenderer, PriceRenderer, RankedAvatar, StyledBox, StyledGrid } from '../utils';
import { DropDownSelect } from 'components';
import { DaysFilter } from 'types/generated';
import { days_filter_options } from 'constants/options';
import { useNavigate } from 'react-router-dom';
import { goToArtist } from 'helpers/artists';
import { formatAddress } from 'helpers/formatAddress';
import { theme } from 'themes';

type Props = {
  ranking: UserRanking;
  loading: boolean;
  goTo: () => void;
};

const initRankings: UserRanking[] = [...Array(12).keys()].map(() => {
  return {
    rank: 0,
    name: '',
    avatar: '',
    certified: false,
    description: '',
    address: '',
    banner: '',
    volume: 0,
    sold: 0,
    bought: 0
  };
});

const Renderer = ({ ranking, loading, goTo }: Props) => {
  const { rank, avatar, name, address, volume, sold, bought, certified } = ranking;
  const format_name = name && name != 'anonymous' ? name : formatAddress(address);
  return (
    <StyledBox onClick={goTo}>
      <RankedAvatar rank={rank} avatar={avatar} certified={certified} loading={loading} />
      <Stack sx={{ p: '1rem', minWidth: 0, flex: 3 }} justifyContent="center">
        <NameRenderer
          name={format_name}
          loading={loading}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        />
        <PriceRenderer caption="Volume" value={volume} loading={loading} />
      </Stack>
      <Stack sx={{ p: '1rem', flex: 1 }} alignItems="flex-end">
        <PriceRenderer caption="Sold" value={sold} loading={loading} />
        <PriceRenderer caption="Bought" value={bought} loading={loading} />
      </Stack>
    </StyledBox>
  );
};

const RendererMobile = ({ ranking, loading, goTo }: Props) => {
  const { rank, avatar, name, address, volume, sold, bought, certified } = ranking;
  const format_name = name && name != 'anonymous' ? name : formatAddress(address);
  return (
    <StyledBox onClick={goTo}>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Stack direction="row" alignItems="center">
          <RankedAvatar rank={rank} avatar={avatar} certified={certified} loading={loading} />
          <NameRenderer
            name={format_name}
            loading={loading}
            sx={{ ml: 1.5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          />
        </Stack>
        <Stack
          direction="row"
          sx={{ p: '1rem' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <PriceRenderer caption="Volume" value={volume} loading={loading} />
          <PriceRenderer caption="Sold" value={sold} loading={loading} />
          <PriceRenderer caption="Bought" value={bought} loading={loading} />
        </Stack>
      </Stack>
    </StyledBox>
  );
};

export const Users = (): JSX.Element => {
  const navigate = useNavigate();
  const [daysFilter, setDaysFilter] = useState<NumberOrString>('');
  const { data, isFetching } = useUserRanking({
    days_filter: (daysFilter as DaysFilter) || DaysFilter.All,
    page: 1,
    size: 12
  });

  const rankings = isFetching ? initRankings : toUsersRanking(data);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Stack spacing={1}>
      <DropDownSelect
        pairs={days_filter_options}
        defaultKey={daysFilter}
        empty="All time"
        onSelectChange={(v) => setDaysFilter(v as DaysFilter)}
        sx={{ width: '15rem', ml: 'auto' }}
        disablePortal={false}
      />
      <Grid container direction="row" spacing={1}>
        {rankings?.map((ranking, i) => {
          return (
            <StyledGrid key={i} item xs={12} sm={12} md={6} lg={4}>
              {matches && (
                <Renderer
                  ranking={ranking}
                  loading={isFetching}
                  goTo={() => navigate(goToArtist(ranking.address))}
                />
              )}
              {!matches && (
                <RendererMobile
                  ranking={ranking}
                  loading={isFetching}
                  goTo={() => navigate(goToArtist(ranking.address))}
                />
              )}
            </StyledGrid>
          );
        })}
      </Grid>
    </Stack>
  );
};
