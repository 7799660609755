import React from 'react';
import { Typography, Divider, Box } from '@mui/material';
import { GridItem, RenderKeyValue, PrimaryButton, SecondaryButton, StakingInput } from '../utils';
import { IconFactory } from 'components';

const KalaoVesting = () => {
  return (
    <GridItem sx={{ border: (theme) => theme.border }} spacing={2}>
      <div className="header">
        <Typography variant="h4" color="text.primary" fontWeight={500}>
          KLO VestingVault
        </Typography>
        <IconFactory icon="klo" />
      </div>
      <div className="container">
        <RenderKeyValue
          property="Staked tokens"
          value="2000"
          symbol="esKLO"
          secondaryValye="($ 200)"
        />
        <RenderKeyValue
          property="Reserved for Vesting"
          value="20000"
          symbol="klo"
          secondaryValye="($ 200)"
        />
        <RenderKeyValue property="Vesting Status" value="20" symbol="klo" secondaryValye="($ 20)" />
        <Divider variant="middle" sx={{ my: 2 }} />
        <RenderKeyValue property="Claimable" value="20000" symbol="klo" secondaryValye="($ 200)" />
      </div>
      <div className="buttons">
        <PrimaryButton caption="Stake" click={() => undefined} />
        <SecondaryButton caption="Unstake" click={() => undefined} />
      </div>
      <StakingInput
        caption="Deposit"
        value={''}
        balance="200"
        symbol="esKLO"
        fiat="($ 90)"
        onChange={() => undefined}
        onClick={() => undefined}
      />
      <div className="container">
        <RenderKeyValue property="Wallet" value="2000" symbol="esKLO" secondaryValye="($ 200)" />
        <RenderKeyValue
          property="Vault Capacity"
          value="20000"
          symbol="klo"
          secondaryValye="($ 200)"
        />
        <RenderKeyValue property="Reserve Amount" value="20" symbol="klo" secondaryValye="($ 20)" />
      </div>
    </GridItem>
  );
};

export default KalaoVesting;
