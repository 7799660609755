import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { StepStatus, TokenKind, TransferedBalance } from 'types';
import Step from '../Step';
import { useWeb3React } from '@web3-react/core';
import { batchTransfer1155, batchTransfer721 } from 'services/exchange';
import { parse1155Transfer, parse721Transfer } from './utils';
import { Done } from './Done';
import { useNetwork } from 'hooks';

type Props = {
  title: string;
  step: number;
  currentStep: number;
  userBalances: TransferedBalance[];
  tokenKind: TokenKind;
  sendTo: string;
  // callback, called once the step has been completed successfully
  complete: (txHash: string) => void;
  disable_redirect: boolean;
};

const Progress = () => {
  return (
    <Typography variant="body2" color="text.secondary">
      Please sign the transaction in your wallet to confirm the transfer
    </Typography>
  );
};

const TransferTokens = ({
  title,
  step,
  currentStep,
  userBalances,
  tokenKind,
  sendTo,
  complete,
  disable_redirect
}: Props) => {
  const { hooks } = useWeb3React();
  const [error, setError] = useState<string>();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [txHash, setTxHash] = useState('');
  const { network } = useNetwork();

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
      mint();
    }
  }, [currentStep]);

  const mint = async () => {
    setStatus(StepStatus.IN_PROGRESS);

    if (!provider) {
      throw new Error('Please connect your wallet');
    }
    try {
      if (tokenKind === TokenKind.erc721) {
        const { collections, to, tokenIds } = parse721Transfer(userBalances, sendTo);
        await batchTransfer721(collections, to, tokenIds, provider).then((tx) => {
          setStatus(StepStatus.DONE);
          setTxHash(tx.transactionHash);
          complete(tx.transactionHash);
        });
      } else {
        const { collections, to, tokenIds, amounts } = parse1155Transfer(userBalances, sendTo);
        await batchTransfer1155(collections, to, tokenIds, amounts, provider).then((tx) => {
          setStatus(StepStatus.DONE);
          setTxHash(tx.transactionHash);
          complete(tx.transactionHash);
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Transfer failed!');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={
        status == StepStatus.DONE ? (
          <Done title={title} explorer={network.blockExplorerUrls[0]} hash={txHash} disable_redirect={disable_redirect} />
        ) : (
          title
        )
      }
      step={step}
      currentStep={currentStep}
      error={error}
      status={status}
      el={status == StepStatus.IN_PROGRESS ? <Progress /> : undefined}
    />
  );
};

export default TransferTokens;
