import React from 'react';
import { Typography, styled, Box } from '@mui/material';
import { Miniature, MiniatureInfo, NumberInput } from 'components';

import { useSale } from '../context/saleContext';
import { Sale } from '../type';

const Container = styled('div')(({ theme }) => ({
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  border: theme.border,
  padding: theme.spacing(1)
}));

const StyledRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0.5rem'
});

const StyledCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0.5),
  height: '100%'
}));

const labelOrError = (label: string, error?: string) => {
  return error ? (
    <Typography variant="caption" color="red" textAlign="center">
      {error}
    </Typography>
  ) : (
    <Typography variant="caption" color="text.secondary" textAlign="center">
      {label}
    </Typography>
  );
};

const renderer = (nft: Sale, update: (nft: Sale) => void, select: () => void) => {
  const { error } = nft;
  return (
    <StyledRow key={nft.asset_id}>
      <StyledCell sx={{ flexDirection: 'row', flex: 1, minWidth: 0, gap: 1 }}>
        <Miniature thumbnail={nft.thumbnail} remove={select} removeLeft={true} />
        <MiniatureInfo nft={nft} />
      </StyledCell>
      <StyledCell>
        {labelOrError('Quantity', error.quantity)}
        <NumberInput
          sx={{ height: '3rem', width: '5rem' }}
          value={nft.quantity}
          step={1}
          max={Number(nft.qty)}
          error={error.quantity !== undefined}
          disableDecimal={true}
          onChange={(value) => {
            nft.quantity = value;
            nft.error.quantity = undefined;
            update({ ...nft });
          }}
        />
      </StyledCell>
      <StyledCell>
        {labelOrError('Unit Price', error.price)}
        <NumberInput
          sx={{ height: '3rem', width: '4.5rem' }}
          value={nft.price}
          error={error.price !== undefined}
          step={0.1}
          arrows={false}
          onChange={(value) => {
            nft.price = value;
            nft.error.price = undefined;
            update({ ...nft });
          }}
        />
      </StyledCell>
    </StyledRow>
  );
};

export const SelectedSales = () => {
  const { sales, unSelect, update } = useSale();
  const salesArr = [...sales.values()];

  if (salesArr.length == 0) return null;
  return (
    <Container>
      {salesArr.map((sale) => renderer(sale, update, () => unSelect(sale.asset_id)))}
    </Container>
  );
};
