// 3rd party libs
import React from 'react';
import { Box, styled } from '@mui/material';

// Componenets
import Container from 'components/Container';
import { ExploreCollectionCard } from 'components';
import { useResize } from 'providers';
import { VirtuosoGrid } from 'react-virtuoso';
import { VContainer, VList } from 'helpers/virtuosoHelpers';
import { useNavigate, useParams } from 'react-router-dom';
import { goToCollection, initCollection } from 'helpers/collections';
import { useCollections } from 'hooks';
import { AddressRouteParams } from 'types';

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

const SIZE = 30;
const COLLECTIONS = [...Array(SIZE).keys()].map(() => initCollection);

export const Collections = () => {
  const navigate = useNavigate();
  const { address } = useParams<AddressRouteParams>();
  const { config } = useResize();
  const { collections, error, isLoading, loadMore } = useCollections({
    owner: address,
    size: SIZE,
    skip: !address
  });

  return (
    <Root>
      <Container>
        <VirtuosoGrid
          useWindowScroll={true}
          totalCount={isLoading ? SIZE : collections.length}
          endReached={() => loadMore()}
          overscan={500}
          components={{
            Item: VContainer(config),
            List: VList
          }}
          itemContent={(index) => {
            const data = isLoading ? COLLECTIONS[index] : collections[index];
            return (
              data && (
                <ExploreCollectionCard
                  data={data}
                  goTo={() => navigate(goToCollection(data.collection.address))}
                  isLoading={isLoading}
                />
              )
            );
          }}
        />
      </Container>
    </Root>
  );
};
