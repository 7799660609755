import React from 'react';
import { Box, Link, Stack, styled, Typography} from '@mui/material';
import { ReactComponent as Apple } from '../../assets/logo/apple.svg';
import { ReactComponent as Windows } from '../../assets/logo/windows.svg';

const StyledLink = styled(Link)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: 'flex',
  alignItems: 'center',
  background: theme.input.background,
  marginTop: theme.spacing(1),
  borderRadius: '1.75rem',
  ':hover': {
    background: theme.palette.action.hover
  }
}));

const VisionDownloadApp = () => {
  return (
    <Box sx={{ mt: 2 }}>
      <Stack direction="row" justifyContent="center">
        <StyledLink href="#" underline="none" color="text.primary">
          <Apple width={25} height={25} />
          <Typography sx={{ ml: 1 }}>Apple</Typography>
        </StyledLink>
        <StyledLink href="#" underline="none" color="text.primary" sx={{ ml: 2 }}>
          <Windows width={25} height={25} />
          <Typography sx={{ ml: 1 }}>Windows</Typography>
        </StyledLink>
      </Stack>
    </Box>
  );
};

export default VisionDownloadApp;