// 3rd party libs
import React from 'react';

import { useAuth, useFilters, useResize } from 'providers';
import { VirtuosoGrid } from 'react-virtuoso';
import { VContainer, VList } from 'helpers/virtuosoHelpers';
import { useNavigate } from 'react-router-dom';
import { useNFTs } from 'hooks';
import { SearchTarget } from 'types/generated';
import { goToNft, initNftWithSale, InnerItem } from 'helpers/nfts';
import { goToCollection } from 'helpers/collections';
import { useLikedAssetsQuery, useLikeMutation } from 'api';

const SIZE = 30;
const INIT_NFTS = [...Array(SIZE).keys()].map(() => initNftWithSale);

export const Explorer = (): JSX.Element => {
  const navigate = useNavigate();
  const { config } = useResize();
  const { loggedIn } = useAuth();
  const { data: likedAssets } = useLikedAssetsQuery({}, { skip: !loggedIn });
  const [like] = useLikeMutation();
  const { categories, salesType, price, collection, sortBy } = useFilters();
  const { nfts, isLoading, loadMore, updateLikes } = useNFTs({
    target: SearchTarget.Sales,
    categories,
    salesType,
    sortBy,
    price,
    collectionAddress: collection?.address,
    certified: true,
    size: 20,
    skip: false
  });

  const onLike = (assetId: string, likes: number) => {
    updateLikes(assetId, likedAssets?.[assetId] ? --likes : ++likes);
    like({ assetId, value: !likedAssets?.[assetId] }).unwrap();
  };

  const itemContent = (index: number) => {
    const nft = isLoading ? INIT_NFTS[index] : nfts[index];
    return (
      nft && (
        <InnerItem
          nft={nft}
          goTo={() => navigate(goToNft(nft.asset_id))}
          goToCollection={() => navigate(goToCollection(nft.collection.address))}
          liked={likedAssets?.[nft.asset_id]}
          onLike={() => onLike(nft.asset_id, nft.likes)}
          isLoading={isLoading}
        />
      )
    );
  };

  return (
    <VirtuosoGrid
      useWindowScroll={true}
      totalCount={isLoading ? SIZE : nfts.length}
      endReached={() => loadMore()}
      overscan={500}
      components={{
        Item: VContainer(config),
        List: VList
      }}
      itemContent={itemContent}
    />
  );
};
