// 3rd party libs
import React from 'react';
import { Box, Stack, styled, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { ImageContainer } from 'components';
import { VirtuosoGrid } from 'react-virtuoso';
import { VList } from 'helpers/virtuosoHelpers';
import { ReactComponent as Approved } from '../../assets/certified.svg';
import { UserBalance } from 'types/generated';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { compact } from 'lib/format';
import { theme } from 'themes';

type Props<T> = {
  userBalances: UserBalance[];
  error?: unknown;
  selected: Map<string, T>;
  select: (userBalance: UserBalance) => void;
  loadMore: () => void;
  // Show balance or only available amount ( minus the one listed for sale)
  showBalance?: boolean;
};

const StyledGrid = styled('div')(({ theme }) => ({
  '& > .grid': {
    '&::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '0.5rem',
      backgroundColor: theme.palette.mode == 'dark' ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)'
    }
  }
}));

const StyledBox = styled('div')({
  position: 'relative',
  width: '100%',
  aspectRatio: '1 / 1',
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  overflow: 'hidden',
  border: '0.2rem solid rgba(0,0,0,0.1)',
  '&.selected': {
    border: '0.2rem solid #3D3AE9',
    '.checkmark': { display: 'block' }
  },
  '& > .checkmark': {
    padding: 1.5,
    width: 35,
    display: 'none',
    position: 'absolute',
    top: 5,
    left: 5,
    background: '#fff',
    borderRadius: '50%'
  },
  '& > .info': {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  '& > .box': {
    background: 'rgba(0, 0, 0, 0.5)'
  }
});

const ItemContainer = styled('div')(({ theme }) => ({
  width: '50%',
  padding: '0.5rem',

  [theme.breakpoints.up('sm')]: {
    width: '50%',
    padding: '0.5rem'
  },
  [theme.breakpoints.up('md')]: {
    width: '33.33%',
    padding: '0.5rem'
  }
}));

const ErrorContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const Gallery = <T extends unknown>({
  selected,
  select,
  loadMore,
  userBalances,
  error,
  showBalance = false
}: Props<T>) => {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const itemContent = (index: number) => {
    const { nft, available_amount, balance } = userBalances[index];
    const { thumbnail, asset_id, name, collection } = nft;
    const cls = selected.has(asset_id) ? 'selected' : undefined;
    const availabeAmount = showBalance ? Number(balance) : Number(available_amount);

    return (
      <StyledBox key={asset_id} className={cls} onClick={() => select(userBalances[index])}>
        <ImageContainer
          src={thumbnail}
          editions={collection.kind == 'erc1155' ? compact.format(availabeAmount) : ''}
        />
        <Check className="checkmark" />
        <div className="info box" />
        <Box className="info" sx={{ px: 1 }}>
          <Stack sx={{ color: 'white' }} spacing={0}>
            <Typography className="name" variant="body2" sx={{ mt: 0.5 }}>
              {name}
            </Typography>
            <Stack direction="row" spacing={0.5} alignItems={'center'}>
              <Typography
                className="name"
                variant="caption"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  gap: 0.5
                }}
              >
                {collection.name}
              </Typography>
              {collection.certified && (
                <SvgIcon sx={{ width: '1rem' }}>
                  <Approved />
                </SvgIcon>
              )}
            </Stack>
          </Stack>
        </Box>
      </StyledBox>
    );
  };

  return (
    <StyledGrid>
      {error ? (
        <ErrorContainer>
          <Typography variant="body1" color="red">
            {'Failed to load balances'}
          </Typography>
        </ErrorContainer>
      ) : (
        <VirtuosoGrid
          className="grid"
          style={{ height: matches ? 600 : 400 }}
          endReached={() => loadMore()}
          totalCount={userBalances.length}
          overscan={500}
          components={{
            Item: ItemContainer,
            List: VList
          }}
          itemContent={itemContent}
        />
      )}
    </StyledGrid>
  );
};

export default Gallery;
