import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { Web3ReactHooks } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { Avalanche } from '@avalabs/avalanche-connector';
import { WalletConnect } from '@web3-react/walletconnect';
import { hooks as coinbaseWalletHooks, coinbaseWallet } from './coinbase';
import { hooks as metaMaskHooks, metaMask } from './metamask';
import { hooks as walletConnectHooks, walletConnect } from './walletConnect';
import { hooks as avalancheHooks, avalanche } from './avalanche';

const connectors: (
  | [MetaMask, Web3ReactHooks]
  | [Avalanche, Web3ReactHooks]
  | [WalletConnect, Web3ReactHooks]
  | [CoinbaseWallet, Web3ReactHooks]
)[] = [
  [metaMask, metaMaskHooks],
  [avalanche, avalancheHooks],
  [walletConnect, walletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks]
];

export default connectors;
