import React, { ReactNode } from 'react';
import { Stack, styled, Typography, StackProps } from '@mui/material';
import { Bid, NFTOffer, NFTSale, AdvancedOffer } from 'types';
import { renderAdvancedOffer, renderBid, renderNFT, renderNFTOffer, renderNFTSale } from './utils';
import { NftPreview } from 'types/generated';

type Props = {
  title?: string;
  nftOffer?: NFTOffer;
  nftSale?: NFTSale;
  nft?: NftPreview;
  bid?: Bid;
  advancedOffer?: AdvancedOffer;
  selectedNftsCount?: number;
  // custom 'item' renderer
  customRenderer?: JSX.Element;
  children: ReactNode;
  // custom header
  header?: JSX.Element;
  quantity?: number;
} & StackProps;

const Container = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  background: 'transparent'
}));

const headerRenderer = (title?: string) => {
  return (
    <>
      <Typography variant="h3" color="text.primary" textAlign="center" sx={{ mb: 3 }}>
        {title}
      </Typography>
      <Typography variant="h6" color="text.secondary">
        Summary
      </Typography>
    </>
  );
};

export const Renderer = ({
  title,
  children,
  nftOffer,
  nftSale,
  nft,
  advancedOffer,
  selectedNftsCount,
  bid,
  header,
  customRenderer,
  quantity,
  ...rest
}: Props) => {
  return (
    <Container sx={{ paddingTop: '0' }} {...rest}>
      {header || headerRenderer(title)}
      {customRenderer}
      {nftOffer && renderNFTOffer(nftOffer)}
      {nftSale && renderNFTSale(nftSale)}
      {nft && renderNFT(nft, quantity)}
      {bid && renderBid(bid)}
      {advancedOffer && renderAdvancedOffer(advancedOffer, selectedNftsCount)}
      {children}
    </Container>
  );
};
