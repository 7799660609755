import React from 'react';
import { Typography, Box, Grid, Stack, Paper, Skeleton } from '@mui/material';
import { Button, Address, LinkExternal, Chips } from 'components';
import { Info } from '../utils/Info';
import { NFTDetailsItem } from './helpers';
import { Collection, NftBase, NftMetadatas, User } from 'types/generated';
import { Stats } from '../types';
import { compact } from 'lib/format';
import { useNavigate } from 'react-router-dom';
import { goToCollection } from 'helpers/collections';
import { goToArtist } from 'helpers/artists';
import { formatAddress } from 'helpers/formatAddress';

type Props = {
  base: NftBase;
  collection: Collection;
  stats: Stats;
  metadata: NftMetadatas;
  owner: User;
  creator: User;
  loading?: boolean;
};

const Owner = (props: {
  owner: User;
  totalOwners: number;
  avatars: string[];
  loading?: boolean;
}) => {
  const navigate = useNavigate();
  const { owner, totalOwners, avatars, loading } = props;
  return totalOwners > 1 ? (
    <Stack alignItems="center" spacing={1}>
      <Typography
        variant="body1"
        color="text.primary"
        textTransform="capitalize"
        fontWeight="600"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          mb: '0.05rem'
        }}
      >
        Owners {totalOwners}
      </Typography>
      <Chips
        thumbnails={avatars}
        maxChips={3}
        onClick={() => undefined}
        sx={{
          m: 0,
          position: 'static',
          left: 0,
          bottom: 0,
          background: 'transparent',
          '.MuiAvatarGroup-root': { padding: 0, paddingLeft: '1rem' }
        }}
      />
    </Stack>
  ) : (
    <Info
      info={owner.name && owner.name != 'anonymous' ? owner.name : formatAddress(owner.address)}
      subTitle={'Owner'}
      avatar={owner.avatar}
      certified={owner.certified}
      loading={loading}
      onClick={() => navigate(goToArtist(owner.address))}
      sx={{ cursor: 'pointer' }}
    />
  );
};

export const NFTInfo = ({ base, owner, creator, collection, stats, metadata, loading }: Props) => {
  const navigate = useNavigate();
  const { certified, address, name, description, avatar } = collection;
  const { total_minted, average_royalties_percent } = stats;
  const { token_id, address: contractAddress, kind } = base;
  const { external_url } = metadata;
  const goTo = () => navigate(goToCollection(address));

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            borderRadius: '1.25rem',
            background: 'transparent',
            p: 3,
            height: '100%'
          }}
          variant="outlined"
          elevation={0}
        >
          <Typography
            variant="h4"
            color="text.primary"
            textTransform="capitalize"
            fontWeight={500}
            sx={{ mb: 3 }}
          >
            Details
          </Typography>

          <Box sx={{ display: 'flex', gap: '2rem', mb: 3, alignItems: 'center' }}>
            <Owner
              owner={owner}
              totalOwners={Number(base.total_owners)}
              avatars={base.owners_avatars}
              loading={loading}
            />
            <Info
              info={
                creator.name && creator.name != 'anonymous'
                  ? creator.name
                  : formatAddress(creator.address)
              }
              subTitle={'Creator'}
              avatar={creator.avatar}
              certified={creator.certified}
              loading={loading}
              onClick={() => navigate(goToArtist(creator.address))}
              sx={{ cursor: 'pointer' }}
            />
          </Box>

          <NFTDetailsItem
            title="Contract address"
            subTitle={<Address address={contractAddress} copy={true} exploreAddress={true} />}
            loading={loading}
            sx={{ mb: 2 }}
          />
          <NFTDetailsItem
            title="Token ID"
            subTitle={<Address address={contractAddress} tokenId={token_id} exploreToken={true} />}
            loading={loading}
            sx={{ mb: 2 }}
          />
          <NFTDetailsItem
            title="Blockchain"
            subTitle="Avalanche"
            loading={loading}
            sx={{ mb: 2 }}
          />
          <NFTDetailsItem title="Token Standard" subTitle={kind} loading={loading} sx={{ mb: 2 }} />
          {external_url && (
            <NFTDetailsItem
              title="Token informations"
              subTitle={<LinkExternal url={external_url} />}
              loading={loading}
              sx={{ mb: 2 }}
            />
          )}
          <NFTDetailsItem
            title="Royalties Fee"
            subTitle={`${compact.format(average_royalties_percent)} %`}
            loading={loading}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            borderRadius: '1.25rem',
            background: 'transparent',
            p: 3
          }}
          variant="outlined"
          elevation={0}
        >
          <Typography
            variant="h4"
            color="text.primary"
            textTransform="capitalize"
            fontWeight={500}
            sx={{ mb: 3 }}
          >
            Collection
          </Typography>

          <Box sx={{ display: 'flex', mb: 3 }}>
            <Stack>
              <Info
                info={name && name != 'anonymous' ? name : formatAddress(address)}
                subTitle={`${compact.format(Number(total_minted))} Items`}
                avatar={avatar}
                certified={certified}
                loading={loading}
                sx={{ cursor: 'pointer' }}
                onClick={goTo}
              />
            </Stack>
          </Box>

          <Typography sx={{ mb: 2 }} variant="body2" color="text.secondary">
            {loading ? (
              <>
                <Skeleton animation="wave" height={10} width="90%" sx={{ mb: 2 }} />
                <Skeleton animation="wave" height={10} width="90%" sx={{ mb: 2 }} />
                <Skeleton animation="wave" height={10} width="80%" />
              </>
            ) : (
              description
            )}
          </Typography>

          <Button ctx="secondary" sx={{ border: 0 }} onClick={goTo}>
            <Typography variant="button">See Collection</Typography>
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};
