import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { SquareIconButton } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { openFilters } from 'store/actions';
import { GlobalState } from 'store';

type Props = {
  children?: React.ReactNode;
} & BoxProps;

// Styling
const SideMenuContainer = styled(Box)<{ w: number; open: boolean }>(({ w, open, theme }) => ({
  background: theme.palette.background.default,
  position: 'sticky',
  // Avoid overlapping with the navbar bottom border
  top: 'calc(5rem - 0.0625rem)',
  flex: `0 0 ${open ? w : 4}rem`,
  height: '91.5vh',
  overflowY: 'auto',
  border: theme.border,
  '&::-webkit-scrollbar': {
    width: '0.3rem'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.mode == 'dark' ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)'
  }
}));

const SideMenu = ({ children, ...rest }: Props) => {
  const dispatch = useDispatch();
  const open = useSelector((global: GlobalState) => global.state.openFilters);

  return (
    <SideMenuContainer w={18} open={open} {...rest}>
      {open ? (
        children
      ) : (
        <Box
          sx={{ height: '5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <SquareIconButton
            size="small"
            onClick={() => dispatch(openFilters.create({ open: true }))}
          >
            <ChevronRightIcon />
          </SquareIconButton>
        </Box>
      )}
    </SideMenuContainer>
  );
};

export default SideMenu;
