import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { goToNft } from 'helpers/nfts';
import { NftSalePreview } from 'types/generated';

type Props = {
  sale: NftSalePreview | null | undefined;
  asset_id?: string;
};

export const Done = ({ sale, asset_id }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onViewItem = (assetId: string) => {
    dispatch(openModal.create({}));
    navigate(goToNft(assetId));
  };

  const onContinue = () => {
    dispatch(openModal.create({}));
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Typography variant="h6" fontWeight="600" sx={{ color: (theme) => theme.colors.green, mr: 1 }}>
        Listing price has been successfully updated
      </Typography>
      <Stack sx={{ width: '100%' }}>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          {sale && (
            <Button ctx="primary" onClick={() => asset_id ? onViewItem(asset_id): onContinue()} sx={{ marginLeft: '-3.84rem' }}>
              <Typography variant="button">View Listing</Typography>
            </Button>
          )}
        </Box>
      </Stack>
    </Box>
  );
};