import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { NFTSale } from 'types';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import { Button, LinkExternal } from 'components';
import { useNavigate } from 'react-router-dom';
import { goToNft } from 'helpers/nfts';
import { AppRoutes } from 'constants/routes';

type Props = {
  explorer: string;
  hash: string;
  sale: NFTSale | null | undefined;
  basket: Map<string, NFTSale> | null | undefined;
};

export const Done = ({ explorer, hash, sale, basket }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onViewItem = (assetId: string) => {
    dispatch(openModal.create({}));
    navigate(goToNft(assetId));
  };

  const onViewOwned = () => {
    dispatch(openModal.create({}));
    navigate(`${AppRoutes.dashboard}/owned`);
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Typography variant="h6" fontWeight="600" sx={{ color: (theme) => theme.colors.green, mr: 1 }}>
        Purchase completed successfully
      </Typography>
      <Stack sx={{ width: '100%' }}>
        <Box>
          <LinkExternal
            url={`${explorer}/${hash}`}
            name="View on explorer"
          />
        </Box>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          {sale && (
            <Button ctx="primary" onClick={() => onViewItem(sale.asset_id)} sx={{ marginLeft: '-3.84rem' }}>
              <Typography variant="button">View item</Typography>
            </Button>
          )}
          {basket && (
            <Button ctx="primary" onClick={onViewOwned} sx={{ marginLeft: '-3.84rem' }}>
              <Typography variant="button">My collectibles</Typography>
            </Button>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
