import * as React from 'react';

import Comfy from '@mui/icons-material/GridViewSharp';
import Dense from '@mui/icons-material/ViewComfySharp';
import { Stack, StackProps } from '@mui/material';
import { SquareIconButton } from 'components';

type Props = {
  size: 'comfy' | 'dense';
  resize: (size: 'comfy' | 'dense') => void;
} & StackProps;

const ResizeToggle = ({ size, resize }: Props) => {
  const handleSize = (s: 'comfy' | 'dense') => {
    resize(s);
  };

  return (
    <Stack direction="row">
      <SquareIconButton
        disableRipple={true}
        onClick={() => handleSize('comfy')}
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          background: (theme) => (size == 'comfy' ? theme.input.background : undefined)
        }}
      >
        <Comfy />
      </SquareIconButton>
      <SquareIconButton
        disableRipple={true}
        onClick={() => handleSize('dense')}
        sx={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          background: (theme) => (size == 'dense' ? theme.input.background : undefined)
        }}
      >
        <Dense />
      </SquareIconButton>
    </Stack>
  );
};

export default ResizeToggle;
