import React from 'react';
import { InputBase, MenuItem, Select, SelectChangeEvent, SelectProps, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { KeyValuePair, NumberOrString } from 'types';

type Props = {
  onOptionChange: (key: NumberOrString) => void;
  options: KeyValuePair<NumberOrString, JSX.Element>[];
  defaultKey: NumberOrString;
  modal?: boolean;
} & SelectProps;

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    background: 'transparent',
    padding: '0.8rem 1rem',
    ':hover': {
      background: theme.palette.action.hover
    }
  }
}));

const DropDownBasic = ({ onOptionChange, options, defaultKey, ...rest }: Props) => {
  const [selected, setSelected] = React.useState<NumberOrString>(defaultKey || '');

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const key = event.target.value as NumberOrString;
    setSelected(key);
    onOptionChange(key);
  };

  return (
    <Select
      {...rest}
      value={selected}
      onChange={handleChange}
      IconComponent={KeyboardArrowDownIcon}
      renderValue={(selected) => {
        const pair = options.find((option) => option.key == selected);
        return pair?.value;
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            borderRadius: '0.5rem',
            background: (theme) => theme.palette.background.default
          }
        }
      }}
      input={<BootstrapInput />}
    >
      {options.map((option) => {
        const { key, value } = option;
        return (
          <MenuItem key={key} value={key} sx={{ px: 2 }}>
            {value}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default DropDownBasic;
