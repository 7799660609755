import React from 'react';
import { Typography, Stack, Link, styled, Box } from '@mui/material';
import { Button } from 'components';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store';

const Container = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  background: 'transparent'
}));

const TelegramNotifs = () => {
  // Global State
  const telegram_token = useSelector((global: GlobalState) => global.state.telegramToken);

  return (
    <Container sx={{ textAlign: 'center' }} spacing={2}>
      <Typography variant="body1" sx={{ fontSize: 20 }}>
        Connect Telegram
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Open Telegram and add the bot.
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Link
          href={`https://t.me/kalao_notifications_bot?start=${telegram_token}`}
          underline="none"
          target="_blank"
          rel="noopener"
          sx={{ display: 'flex', alignItems: 'center' }}
          variant="body2"
        >
          <Button disabled={!telegram_token} ctx="primary" sx={{ width: '8rem' }}>
            <Typography variant="button">Open</Typography>
          </Button>
        </Link>
      </Stack>
    </Container>
  );
};

export default TelegramNotifs;
