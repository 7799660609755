import React, { useEffect, useState } from 'react';
import { styled, Paper, InputBase, Chip, PaperProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  inputChange: (value: string) => void;
  onFocus: () => void;
  onDelete: () => void;
  onReset: () => void;
  onTargetFilter: (value: string) => void;
  target: string;
} & PaperProps;

const SearchContainer = styled(Paper)(({ theme }) => ({
  borderRadius: '5.625rem',
  backgroundColor: theme.input.background,
  padding: theme.spacing(2),
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  flexGrow: 1,
  ':hover': {
    backgroundColor: theme.palette.action.hover
  },
  width: '15rem'
}));

export const SearchInput = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const { inputChange, onFocus, onDelete, onReset, onTargetFilter, target, ...rest } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value;
    setValue(inputValue);
    inputChange(inputValue);
  };

  useEffect(() => {
    value && onTargetFilter(value);
  }, [target]);

  useEffect(() => {
    if (inputRef.current && target) {
      inputRef.current.focus();
    }
  }, [target]);

  useEffect(() => {
    if (!value) onReset();
  }, [value]);

  return (
    <SearchContainer ref={ref} elevation={0} {...rest}>
      <SearchIcon sx={{ color: (theme) => theme.colors.grey100 }} />
      {target && <Chip label={target} sx={{ ml: 1 }} onDelete={onDelete} />}
      <InputBase
        inputRef={inputRef}
        value={value}
        onFocus={onFocus}
        onChange={handleChange}
        sx={{ ml: 1, flexGrow: 1 }}
        onKeyDown={(e) => {
          if (e.key == 'Backspace' && !value && target) onDelete();
          e.stopPropagation();
        }}
        placeholder="Search"
        endAdornment={
          value && (
            <CloseIcon
              sx={{
                cursor: !value ? 'default' : 'pointer',
                color: (theme) => theme.colors.grey100,
                '&:hover': { color: (theme) => theme.palette.text.primary }
              }}
              onClick={() => {
                setValue('');
              }}
            />
          )
        }
      />
    </SearchContainer>
  );
});

SearchInput.displayName = 'SearchInput';
