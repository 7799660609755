// 3rd party libs
import React from 'react';
import { Box, styled } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import { VirtuosoGrid } from 'react-virtuoso';

// Componenets
import Container from 'components/Container';
import { ExploreCollectionCard } from 'components';
import { FiltersProvider, useFilters, useResize } from 'providers';
import { VContainer, VList } from 'helpers/virtuosoHelpers';
import { useNavigate } from 'react-router-dom';
import { useCollections } from 'hooks';
import { goToCollection, initCollection } from 'helpers/collections';

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

const SIZE = 30;
const COLLECTIONS = [...Array(SIZE).keys()].map(() => initCollection);

const CollectionsRenderer = () => {
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const { config } = useResize();
  const { keywords } = useFilters();
  const { collections, error, isLoading, loadMore } = useCollections({
    owner: account,
    size: SIZE,
    keywords,
    skip: !account
  });

  return (
    <Root>
      <Container>
        <VirtuosoGrid
          useWindowScroll={true}
          totalCount={isLoading ? SIZE : collections.length}
          endReached={() => loadMore()}
          overscan={500}
          components={{
            Item: VContainer(config),
            List: VList
          }}
          itemContent={(index) => {
            const data = isLoading ? COLLECTIONS[index] : collections[index];
            return (
              data && (
                <ExploreCollectionCard
                  data={data}
                  goTo={() => navigate(goToCollection(data.collection.address))}
                  isLoading={isLoading}
                />
              )
            );
          }}
        />
      </Container>
    </Root>
  );
};

export const Collections = () => {
  return (
    <FiltersProvider>
      <CollectionsRenderer />
    </FiltersProvider>
  );
};
