import React, { useEffect, useState } from 'react';
import { Stack, Typography, Grid, Divider, Box } from '@mui/material';
import { Button } from 'components';
import { Asset, Form, TokenKind } from 'types';
import NFTInfo from '../NFTInfo';
import CollectionSelector from '../CollectionSelector';
import { FileUpload } from 'types';
import Preview from '../Preview';
import Header from '../Header';
import FileUploader from '../FileUploader';
import { useDispatch } from 'react-redux';
import { Collection } from 'types/generated';
import { useWeb3React } from '@web3-react/core';
import { useNotistack } from 'hooks';
import { batch, createNFT, openModal } from 'store/actions';

type Props = {
  tokenKind: TokenKind;
  back: () => void;
  switchType: () => void;
};

const Main = ({ tokenKind, back, switchType }: Props) => {
  const { error } = useNotistack();
  const dispatch = useDispatch();
  const [collection, setCollection] = useState<Collection>();
  const [form, setForm] = useState<Form>({
    name: '',
    description: '',
    external_url: '',
    properties: [],
    copies: 1
  });
  const [file, setFile] = useState<FileUpload>();
  const [imageCover, setImageCover] = useState<FileUpload>();
  const { account, hooks } = useWeb3React();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      if (file) URL.revokeObjectURL(file.preview);
      if (imageCover) URL.revokeObjectURL(imageCover.preview);
    };
  }, []);

  const isVideoOrAudio =
    file?.type.includes('video') || file?.type.includes('audio') ? true : false;

  const create = async () => {
    if (!account || !provider) {
      error('Please connect your wallet');
      return;
    }

    if (!collection) {
      error('Select a collection');
      return;
    }

    const { name } = form;

    if (isVideoOrAudio) {
      if (!imageCover) {
        error('Image preview is required');
        return;
      } else if (!name) {
        error('Name is required');
        return;
      }
    }
    if (!file || !name) {
      error('Missing token required information (File and Name)');
      return;
    }

    const asset: Asset = {
      fileUrl: file.preview,
      form,
      tokenKind,
      collection,
      fileCoverUrl: imageCover?.preview
    };

    dispatch(
      batch.create({
        actions: [createNFT.create({ createNFT: asset }), openModal.create({ modal: 'CREATE NFT' })]
      })
    );
  };

  return (
    <Stack>
      <Header type={tokenKind} back={back} switchType={switchType} />
      <Grid container direction="row" spacing={{ xs: 2, sm: 2, md: 4, lg: 10 }}>
        <Grid item xs={12} sm={7} md={8} lg={8}>
          <Stack spacing={1} sx={{ mb: 3 }}>
            <Typography variant="h5" fontWeight={500}>
              Your file
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              Drag and drop or Browse to upload file
            </Typography>
          </Stack>
          <Grid
            container
            spacing={2}
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
              border: (theme) => theme.border,
              borderRadius: '0.9375rem',
              p: 2
            }}
          >
            <Box sx={{ width: { xs: '100%', md: isVideoOrAudio ? '60%' : '100%' } }}>
              <FileUploader type="all" file={file} setFile={setFile} />
            </Box>
            {isVideoOrAudio && (
              <Box
                sx={{
                  ml: { md: '1rem' },
                  mt: { xs: '1rem', md: '0' },
                  width: { xs: '100%', md: isVideoOrAudio ? 'calc(40% - 1rem)' : '100%' }
                }}
              >
                <FileUploader type="images" file={imageCover} setFile={setImageCover} />
              </Box>
            )}
          </Grid>
          <Divider sx={{ my: 4 }} />
          <NFTInfo form={form} type={tokenKind} onChange={(f) => setForm(f)} />
          <Divider sx={{ my: 5 }} />
          <CollectionSelector
            account={account}
            nftKind={tokenKind}
            collection={collection}
            onClick={(c) => setCollection(c)}
          />
          <Button
            ctx="primary"
            size="large"
            sx={{ mt: 4, display: { xs: 'none', sm: 'block' } }}
            onClick={create}
          >
            <Typography variant="button">Create item</Typography>
          </Button>
        </Grid>
        <Preview
          copies={Number(form.copies)}
          preview={file?.preview}
          previewMime={String(file?.type)}
          previewCover={imageCover?.preview}
          name={form?.name}
          avatar={collection?.avatar}
          create={create}
          description={form?.description}
          external_url={form?.external_url}
          properties={form?.properties}
        />
      </Grid>
    </Stack>
  );
};

export default Main;
