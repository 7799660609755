// 3rd
import { useEffect, useState, useRef } from 'react';
// Api
import { useLazyCollectionsQuery } from 'api';

// Types
import { CollectionDetails, SearchNftSort, SearchTarget } from 'types/generated';
import { NumberOrString } from 'types';

/**
 * Hook to query collections
 */

type Props = {
  // page size (paging offset)
  size: number;
  categories?: NumberOrString[];
  keywords?: string;
  owner?: string;
  creator?: string;
  token_kind?: string;
  sortBy?: SearchNftSort;
  certified?: boolean;
  // Skip if true
  skip: boolean;
};

type Response = {
  collections: CollectionDetails[];
  error?: string | unknown;
  isLoading: boolean;
  isFetching?: boolean;
  loadMore: (reset?: boolean) => void;
  refresh: () => void;
};

const useCollections = ({
  categories,
  keywords,
  creator,
  token_kind,
  sortBy,
  owner,
  certified,
  skip,
  size
}: Props): Response => {
  const hasMore = useRef(true);
  const shouldReset = useRef(true);
  const page = useRef(1);
  const [search, result] = useLazyCollectionsQuery();
  const [collections, setCollections] = useState<CollectionDetails[]>([]);

  const trigger = () =>
    search({
      input: {
        creator,
        owner,
        token_kind,
        category_tags: categories?.map((c) => c.toString()),
        keywords,
        sort: sortBy,
        target: SearchTarget.Collections,
        certified
      },
      pagination: {
        page: page.current++,
        size
      }
    });

  useEffect(() => {
    if (!skip) trigger();
  }, []);

  useEffect(() => {
    shouldReset.current = true;
    hasMore.current = true;
    page.current = 1;
    trigger();
  }, [categories, keywords, creator, token_kind, sortBy, owner]);

  useEffect(() => {
    if (!result.isSuccess) return;
    if (shouldReset.current) {
      shouldReset.current = false;
      hasMore.current = result.data.search.hasMore;
      setCollections(result.data.search.collections as CollectionDetails[]);
    } else {
      hasMore.current = result.data.search.hasMore;
      setCollections([...collections, ...(result.data.search.collections as CollectionDetails[])]);
    }
  }, [result.data]);

  const refresh = () => {
    shouldReset.current = true;
    hasMore.current = true;
    page.current = 1;
    trigger();
  };

  const loadMore = () => {
    if (hasMore.current) trigger();
  };

  return {
    collections,
    error: result.error && 'Failed to load data',
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    loadMore,
    refresh
  };
};

export default useCollections;
