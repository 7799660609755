import React from 'react';
import { styled, Stack, Typography, Skeleton, SvgIcon, Divider, Box } from '@mui/material';
import { useCollectionQuery, useUserQuery } from 'api';
import { Avatar, Price, SocialNetworks } from 'components';
import { processCollectionQueryResponse } from 'helpers/processQueryResponse';
import { ReactComponent as Approved } from '../../assets/certified.svg';
import { shortCompact, priceFormatter } from 'lib/format';
import { useNavigate } from 'react-router-dom';
import { goToArtist } from 'helpers/artists';
import { formatAddress } from 'helpers/formatAddress';

type Props = {
  address: string;
};

const Container = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.paper,
  width: '22rem',
  borderRadius: '0.9375rem'
}));

const Content = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  '.avatar': {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  '.description': {
    maxHeight: '5rem'
  },
  '.creator': {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center'
  }
}));

const renderKeyValue = (key: string, price: string) => {
  return (
    <Stack
      direction="row"
      justifyContent={'center'}
      alignItems="center"
      sx={{ flex: 1, minWidth: 0 }}
      spacing={1}
    >
      <Typography variant="body2" color="text.secondary">
        {key}
      </Typography>
      <Price value={price} fontSize={18} />
    </Stack>
  );
};

const CollectionOverview = ({ address }: Props) => {
  const navigate = useNavigate();
  const { data } = useCollectionQuery({ address });
  const { collection, stats } = processCollectionQueryResponse(data);
  const { creator_name, creator } = collection;
  const { data: user } = useUserQuery({ address: creator }, { skip: !creator });
  const { instagram, twitter, facebook, discord, website, telegram } = collection;
  const onCreator = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(goToArtist(user?.user.address));
  };
  return (
    <Container>
      <Content spacing={2}>
        <div className="avatar">
          <Avatar
            top={25}
            approved={collection.certified}
            src={collection.avatar}
            sx={{ width: 55, height: 55 }}
            iconStyle={{ fontSize: 20 }}
          />
          <Typography variant="h5" color="text.primary" fontWeight={600}>
            {collection.name && collection.name != 'anonymous'
              ? collection.name
              : formatAddress(collection.address)}
          </Typography>
        </div>
        <div className="description">
          {collection ? (
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3
              }}
            >
              {collection.description}
            </Typography>
          ) : (
            <>
              <Skeleton width={'100%'} height={10} />
              <Skeleton width={'100%'} height={10} />
              <Skeleton width={'80%'} height={10} />
            </>
          )}
        </div>
        <div className="creator">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={onCreator}
            sx={{ cursor: 'pointer' }}
          >
            <Avatar
              src={user?.user.avatar || ''}
              sx={{ width: 35, height: 35 }}
              iconStyle={{ fontSize: 20 }}
            />
            <Box>
              <Typography component="div" variant="caption" color="text.secondary">
                Creator
              </Typography>
              <Typography component="div" variant="body2" color="text.primary">
                {creator_name && creator_name != 'anonymous'
                  ? creator_name
                  : formatAddress(user?.user.address)}
                {user?.user.certified && (
                  <SvgIcon fontSize={'inherit'} sx={{ ml: 1 }}>
                    <Approved />
                  </SvgIcon>
                )}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="center" sx={{ ml: 'auto' }} spacing={1}>
            <SocialNetworks
              instagram={instagram}
              twitter={twitter}
              facebook={facebook}
              discord={discord}
              website={website}
              telegram={telegram}
            />
          </Stack>
        </div>
      </Content>
      <Divider variant="fullWidth" />
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" variant="middle" flexItem />}
        spacing={1}
        sx={{ height: '4rem', p: 2 }}
      >
        {renderKeyValue('Floor price', priceFormatter.format(stats.floor_price))}
        {renderKeyValue('Volume', shortCompact.format(stats.volume))}
      </Stack>
    </Container>
  );
};

export default CollectionOverview;
