import * as React from 'react';
import { BoxProps, ClickAwayListener, Grow, Popper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { InnerPopperContainer, PopperContainer, StyledDropDown } from '../utils';

type Props = {
  element: JSX.Element;
  valueRenderer: JSX.Element;
  disablePortal?: boolean;
} & BoxProps;

const DropDownElement = ({ element, valueRenderer, disablePortal = true, ...rest }: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <StyledDropDown
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        {...rest}
      >
        {valueRenderer}
        <KeyboardArrowDownIcon />
      </StyledDropDown>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal={disablePortal}
        style={{ zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <PopperContainer>
              <ClickAwayListener onClickAway={handleClose}>
                <InnerPopperContainer id="composition-menu">{element}</InnerPopperContainer>
              </ClickAwayListener>
            </PopperContainer>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropDownElement;
