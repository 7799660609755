import React from 'react';
import { Stack, Tab, Typography, Tabs } from '@mui/material';
import { Property } from 'types';

type Props = {
  properties?: Property[];
};

const PropertiesChip = ({ properties }: Props) => {
  const selectedProperty = (property: Property) => {
    return (
      <Stack
        sx={{
          px: 2,
          py: 1,
          maxWidth: '10rem',
          borderRadius: '5.625rem',
          border: (theme) => theme.border,
          background: (theme) => theme.input.background
        }}
      >
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {property.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {property.attrs.map((attr) => attr.name).join(', ')}
        </Typography>
      </Stack>
    );
  };

  return (
    <Tabs variant="scrollable" scrollButtons="auto">
      {properties?.map((p) => {
        return (
          <Tab
            key={p.name}
            label={selectedProperty(p)}
            disableRipple={true}
            sx={{ cursor: 'default' }}
          />
        );
      })}
    </Tabs>
  );
};

export default PropertiesChip;
