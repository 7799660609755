import React, { useEffect } from 'react';
import { Stack, Typography, styled, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Avatar } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'store/actions';
import { Collection, CollectionDetails, SearchNftSort } from 'types/generated';
import { TokenKind } from 'types';
import { batch, setDeployNFTKind } from 'store/actions';
import { useCollections } from 'hooks';
import { VirtuosoGrid } from 'react-virtuoso';
import { VList } from 'helpers/virtuosoHelpers';
import { GlobalState } from 'store';

type Props = {
  account?: string;
  nftKind: TokenKind;
  collection?: Collection;
  onClick: (c: Collection) => void;
};

const StyledGrid = styled('div')(({ theme }) => ({
  '& > .grid': {
    '&::-webkit-scrollbar': {
      width: '0.5rem'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '0.5rem',
      backgroundColor: theme.palette.mode == 'dark' ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)'
    }
  }
}));

const StyledBox = styled(Box)<{ hasBorder: boolean }>(({ theme, hasBorder }) => ({
  padding: theme.spacing(2),
  background: theme.input.background,
  width: '100%',
  aspectRatio: '1 / 1',
  borderRadius: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  border: hasBorder ? '0.125rem solid #3D3AE9' : undefined,
  ':hover': {
    background: theme.palette.action.hover
  }
}));

const Container = styled(Box)(({ theme }) => ({
  padding: '0.5rem',
  [theme.breakpoints.up('xs')]: {
    width: '50%'
  },
  [theme.breakpoints.up('sm')]: {
    width: '33%'
  },
  [theme.breakpoints.up('md')]: {
    width: '25%'
  }
}));

const NewCollection = (props: { nftKind: TokenKind }) => {
  return (
    <>
      <IconButton
        size="large"
        sx={{
          background: (theme) => theme.palette.background.default,
          alignSelf: 'flex-start',
          mb: 'auto'
        }}
      >
        <AddIcon fontSize="large" />
      </IconButton>
      <Typography variant="body1" color="text.primary" fontWeight={500}>
        Create collection {props.nftKind}
      </Typography>
    </>
  );
};

const renderCollection = (collection: Collection) => {
  return (
    <>
      <Avatar src={collection.avatar} sx={{ width: '3rem', height: '3rem' }} />
      <Typography variant="body1" color="text.primary" fontWeight={500} sx={{ mt: 'auto' }}>
        {collection.name}
      </Typography>
    </>
  );
};

const CollectionSelector = ({ account, nftKind, collection, onClick }: Props) => {
  const dispatch = useDispatch();
  const collectionAddress = useSelector((global: GlobalState) => global.state.collectionAddress);
  const { collections, isLoading, loadMore, refresh } = useCollections({
    owner: account,
    token_kind: nftKind,
    size: 20,
    skip: false,
    sortBy: SearchNftSort.RecentlyCreated
  });

  useEffect(() => {
    if (collectionAddress) refresh();
  }, [collectionAddress]);

  const newCollectionClick = () =>
    dispatch(
      batch.create({
        actions: [
          setDeployNFTKind.create({ deployNFTKind: nftKind }),
          openModal.create({ modal: 'CREATE COLLECTION' })
        ]
      })
    );

  const items = [<NewCollection key="create-colllection" nftKind={nftKind} />, ...collections];
  // Existing collection
  const itemContent = (index: number) => {
    const content =
      index == 0 ? items[index] : renderCollection((items[index] as CollectionDetails).collection);

    const border =
      index > 0 && (items[index] as CollectionDetails).collection.address == collection?.address;

    return (
      <StyledBox
        hasBorder={border}
        sx={{
          wordBreak: 'break-all',
          cursor: 'pointer'
        }}
        onClick={() =>
          index === 0
            ? newCollectionClick()
            : onClick((items[index] as CollectionDetails).collection)
        }
      >
        {content}
      </StyledBox>
    );
  };

  return (
    <Stack>
      <Typography variant="h5" color="text.primary" fontWeight={500} sx={{ mb: 1 }}>
        Choose collection
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Choose an existing {nftKind} collection or create a new one
      </Typography>
      <StyledGrid>
        <VirtuosoGrid
          className="grid"
          style={{ height: 500 }}
          totalCount={items.length}
          endReached={() => !isLoading && loadMore()}
          components={{
            Item: Container,
            List: VList
          }}
          itemContent={itemContent}
        />
      </StyledGrid>
    </Stack>
  );
};

export default CollectionSelector;
