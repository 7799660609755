import React, { ReactNode } from 'react';
import { Stack, Typography, InputBase, styled, InputBaseProps } from '@mui/material';
import { NumberOrString } from 'types';

type Props = {
  value: NumberOrString | undefined;
  label?: string | JSX.Element;
  err?: string;
  placeholder?: string;
  set: (value: NumberOrString) => void;
  numeric?: boolean;
  flex?: number;
  endAdornment?: ReactNode;
} & InputBaseProps;

const StyledInput = styled(InputBase)(({ theme }) => ({
  background: theme.input.background,
  ':hover': {
    background: theme.palette.action.hover
  },
  input: {
    '&::placeholder': {
      fontSize: 14
    }
  }
}));

const InputField = ({
  value,
  label,
  placeholder,
  set,
  numeric,
  err,
  flex,
  endAdornment,
  ...rest
}: Props) => {
  return (
    <Stack sx={{ flex }} spacing={1}>
      {typeof label == 'string' ? (
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
      ) : (
        label
      )}
      <StyledInput
        {...rest}
        value={value}
        onChange={(e) => {
          if (numeric) set(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'));
          else set(e.target.value);
        }}
        endAdornment={endAdornment}
        placeholder={placeholder}
      />
      {err && (
        <Typography variant="subtitle2" color="error">
          {err}
        </Typography>
      )}
    </Stack>
  );
};

export default InputField;
