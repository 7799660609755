import React from 'react';
import { Stack, Box, Typography, Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import { Button } from 'components';
import { NFTOffer } from 'types';

type Props = {
  offer: NFTOffer | null | undefined;
};

export const Done = ({ offer }: Props) => {
  const dispatch = useDispatch();

  const onContinue = () => {
    dispatch(openModal.create({}));
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Typography variant="h6" fontWeight="600" sx={{ color: (theme) => theme.colors.green, mr: 1 }}>
        Your offer has been successfully cancelled
      </Typography>
      <Stack sx={{ width: '100%' }}>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button ctx="primary" onClick={() => onContinue()} sx={{ marginLeft: '-3.84rem' }}>
            <Typography variant="button">Continue</Typography>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}; 