import React, { useMemo, useState } from 'react';
import { Container, UsersRanking } from 'components';
import { useUserRanking } from 'hooks';
import { Pagination } from '../Pagination';
import { SortState } from 'types';
import { toUsersRanking } from 'helpers/rankingHelpers';
import { useFilters } from 'providers';
import { DaysFilter } from 'types/generated';

const SIZE = 100;

export const initSortState = (): Record<string, SortState> => {
  return {
    name: SortState.unsorted,
    volume: SortState.unsorted,
    sold: SortState.unsorted,
    bought: SortState.unsorted
  };
};

export default function Users() {
  const [page, setPage] = useState(1);
  const { categories, period } = useFilters();
  const { data, isFetching, error, count } = useUserRanking({
    days_filter: (period as DaysFilter) || DaysFilter.All,
    category_tags: categories,
    page,
    size: SIZE
  });

  const rankings = useMemo(() => {
    return toUsersRanking(data);
  }, [data]);

  return (
    <Container>
      <UsersRanking
        loading={isFetching}
        rankings={rankings}
        skeletonCount={SIZE}
        footer={
          <Pagination
            count={count}
            size={rankings.length}
            page={page}
            onChange={(_, page) => setPage(page)}
          />
        }
      />
    </Container>
  );
}
