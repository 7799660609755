import React from 'react';
import { Typography, Divider, Box } from '@mui/material';
import { GridItem, RenderKeyValue, PrimaryButton } from '../utils';

const KalaoRewards = () => {
  return (
    <GridItem sx={{ border: (theme) => theme.border }} spacing={2}>
      <div className="header">
        <Typography variant="h4" color="text.primary" fontWeight={500}>
          Rewards
        </Typography>
      </div>
      <div className="container">
        <RenderKeyValue property="AVAX (WAVAX)" value="2" symbol="avax" secondaryValye="($ 200)" />
        <RenderKeyValue property="KLO" value="200" symbol="klo" secondaryValye="($ 200)" />
        <RenderKeyValue
          property="Escrowed KLO"
          value="300"
          symbol="veKLO"
          secondaryValye="($ 200)"
        />
        <RenderKeyValue
          property="veKLO Boost"
          value="3000"
          symbol="esKLO"
          secondaryValye="($ 200)"
        />
        <Divider variant="middle" sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="body2" color="text.primary" fontWeight={400}>
            Total
          </Typography>
          <Typography variant="body1" color="text.primary">
            $ 250
          </Typography>
        </Box>
      </div>
      <div className="buttons center">
        <PrimaryButton caption="Coumpound" click={() => undefined} />
        <PrimaryButton caption="Claim" click={() => undefined} />
      </div>
    </GridItem>
  );
};

export default KalaoRewards;
