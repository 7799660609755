import type { AddEthereumChainParameter } from '@web3-react/types';

/**
 * Currencies
 */

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18
};

const AVAX: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Avalanche',
  symbol: 'AVAX',
  decimals: 18
};

interface BasicChainInformation {
  urls: string[];
  name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency;
}

export function getAddChainParameters(chainId: number): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls
    };
  } else {
    return chainId;
  }
}

export const CHAINS: { [chainId: number]: BasicChainInformation | ExtendedChainInformation } = {
  1: {
    nativeCurrency: ETH,
    urls: ['https://cloudflare-eth.com'],
    name: 'Ethereum'
  },
  43114: {
    name: 'Avalanche Mainnet',
    urls: ['https://api.avax.network/ext/bc/C/rpc'],
    nativeCurrency: AVAX,
    blockExplorerUrls: ['https://snowtrace.io/']
  },
  43113: {
    name: 'Avalanche FUJI',
    urls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    nativeCurrency: AVAX,
    blockExplorerUrls: ['https://testnet.snowtrace.io/']
  }
};

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{
  [chainId: number]: string[];
}>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});
