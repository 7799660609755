import { useEffect, useMemo, useState } from 'react';

// Types
import { BaseType, SortState } from 'types';

export const hasOwnPropertyNumStrArr = <X extends Record<string, unknown>, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, number | string | Array<number | string>> => {
  return Object.prototype.hasOwnProperty.call(obj, prop);
};

export const getSortedData = <T extends Record<string, unknown>>(
  sortBy: Record<string, SortState>,
  data: T[]
): T[] => {
  const sortedData = [...data];

  Object.keys(sortBy).forEach((sortProp) => {
    sortedData.sort((a, b) => {
      if (hasOwnPropertyNumStrArr(a, sortProp) && hasOwnPropertyNumStrArr(b, sortProp)) {
        const aVal = a[sortProp] as number | string;
        const bVal = b[sortProp] as number | string;
        if (aVal !== undefined && bVal !== undefined) {
          return sortBy[sortProp] === SortState.ascending
            ? aVal < bVal
              ? -1
              : 1
            : sortBy[sortProp] === SortState.descending
            ? aVal < bVal
              ? 1
              : -1
            : 0;
        } else if (aVal) {
          // If only a has the property
          return sortBy[sortProp] === SortState.ascending ? -1 : 1;
        } else if (bVal) {
          // If only b has the property
          return sortBy[sortProp] === SortState.ascending ? 1 : -1;
        }
        return 0;
      } else {
        return 0;
      }
    });
  });

  return sortedData;
};

// sortBy object should provide properties in the desired order for sorting.
// e.g., { price: 100, stock: 4 } will sort by price, then by stock
const useSort = <T extends BaseType>(sortBy: Record<string, SortState>, data?: T[]): T[] => {
  const items = useMemo(() => {
    if (!data) return [];
    else return data;
  }, [data]);
  const [sortedVals, setSortedVals] = useState<T[]>(items);

  useEffect(() => {
    setSortedVals(getSortedData<T>(sortBy, items));
  }, [sortBy, items]);

  return sortedVals;
};

export default useSort;
