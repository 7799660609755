import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Background, Container, Footer, Sharecode } from 'components';
import { useDispatch } from 'react-redux';
import { GridSizeProvider, FiltersProvider, useAuth } from 'providers';
import { useCollectionQuery, useUploadAssetMutation } from 'api';
import { processCollectionQueryResponse, getProperties } from 'helpers/processQueryResponse';
import { Gallery, SharecodeRouteParams } from 'types';
import { useWeb3React } from '@web3-react/core';
import { useNotistack } from 'hooks';
import { Chip, Grid, Stack, Typography, useTheme, styled, Skeleton } from '@mui/material';
import { formatAddress } from 'helpers/formatAddress';
import Vision from './Vision';
import { AppRoutes } from 'constants/routes';
import ImagePanorama from 'components/imagePanorama';
import NFTsSwiper from './ExploreNFTs';

type Props = {
  isLoading?: boolean;
};

const initGallery: Gallery = {
  sharecode: '',
  title: '',
  description: '',
  total_items: 0,
  total_likes: 0,
  image_cover: '',
  image_360: '',
  creator: {
    address: '',
    name: '',
    avatar: '',
    certified: false
  },
  items: []
};


//TEMP
const galleries : any = {
  "XD546DD": {
    sharecode : "XD546DD",
    title : "Sample 1 Gallery Vision",
    description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend hendrerit orci in ullamcorper. Mauris ac massa ut nulla cursus suscipit non at nisl. Donec suscipit nec dolor et sagittis. Vivamus ante mauris, consectetur et suscipit in, commodo id nibh. Nam pharetra consequat tortor, ac condimentum odio tincidunt ac.",
    total_items : 7,
    total_likes : 37,
    image_cover : "https://storage.googleapis.com/beta-kalao/galleries/sample-test/cover.jpg",
    image_360 : "https://storage.kalao.io/vision/gallery-360/test-360.jpg",
    creator: {
      address: "0x0347f0571275e583299e2661c64770a25001739d",
      name: "Sinobired",
      avatar: "https://storage.googleapis.com/beta-kalao/users/0x0347f0571275e583299e2661c64770a25001739d/avatar",
      certified: true
    },
    items: [
      {
        "asset_id": "0x895794b884fe5952f63b75b39e4efbec69c1d15b_24",
        "token_id": "24",
        "name": " #4935",
        "collection": {
          "address": "0x895794b884fe5952f63b75b39e4efbec69c1d15b",
          "avatar": "https://storage.kalao.io/collections/avatars/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789.jpg",
          "name": "Firat BD",
          "certified": true,
          "display_theme": "default"
        },
        "thumbnail": "https://storage.googleapis.com/beta-kalao/nfts/0x895794b884fe5952f63b75b39e4efbec69c1d15b/0x895794b884fe5952f63b75b39e4efbec69c1d15b_24/thumbnail",
        "likes": 0,
        "sale": null
      },
      {
        "asset_id": "0x895794b884fe5952f63b75b39e4efbec69c1d15b_25",
        "token_id": "25",
        "name": " #2262",
        "collection": {
          "address": "0x895794b884fe5952f63b75b39e4efbec69c1d15b",
          "avatar": "https://storage.kalao.io/collections/avatars/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789.jpg",
          "name": "Firat BD",
          "certified": true,
          "display_theme": "default"
        },
        "thumbnail": "https://storage.googleapis.com/beta-kalao/nfts/0x895794b884fe5952f63b75b39e4efbec69c1d15b/0x895794b884fe5952f63b75b39e4efbec69c1d15b_25/thumbnail",
        "likes": 0,
        "sale": null
      },
      {
        "asset_id": "0x895794b884fe5952f63b75b39e4efbec69c1d15b_26",
        "token_id": "26",
        "name": " #66",
        "collection": {
          "address": "0x895794b884fe5952f63b75b39e4efbec69c1d15b",
          "avatar": "https://storage.kalao.io/collections/avatars/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789.jpg",
          "name": "Firat BD",
          "certified": true,
          "display_theme": "default"
        },
        "thumbnail": "https://storage.googleapis.com/beta-kalao/nfts/0x895794b884fe5952f63b75b39e4efbec69c1d15b/0x895794b884fe5952f63b75b39e4efbec69c1d15b_26/thumbnail",
        "likes": 0,
        "sale": null
      },
      {
        "asset_id": "0x895794b884fe5952f63b75b39e4efbec69c1d15b_27",
        "token_id": "27",
        "name": " #4496",
        "collection": {
          "address": "0x895794b884fe5952f63b75b39e4efbec69c1d15b",
          "avatar": "https://storage.kalao.io/collections/avatars/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789.jpg",
          "name": "Firat BD",
          "certified": true,
          "display_theme": "default"
        },
        "thumbnail": "https://storage.googleapis.com/beta-kalao/nfts/0x895794b884fe5952f63b75b39e4efbec69c1d15b/0x895794b884fe5952f63b75b39e4efbec69c1d15b_27/thumbnail",
        "likes": 0,
        "sale": null
      },
      {
        "asset_id": "0x895794b884fe5952f63b75b39e4efbec69c1d15b_29",
        "token_id": "29",
        "name": " #2290",
        "collection": {
          "address": "0x895794b884fe5952f63b75b39e4efbec69c1d15b",
          "avatar": "https://storage.kalao.io/collections/avatars/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789.jpg",
          "name": "Firat BD",
          "certified": true,
          "display_theme": "default"
        },
        "thumbnail": "https://storage.googleapis.com/beta-kalao/nfts/0x895794b884fe5952f63b75b39e4efbec69c1d15b/0x895794b884fe5952f63b75b39e4efbec69c1d15b_29/thumbnail",
        "likes": 0,
        "sale": null
      },
      {
        "asset_id": "0x895794b884fe5952f63b75b39e4efbec69c1d15b_30",
        "token_id": "30",
        "name": " #815",
        "collection": {
          "address": "0x895794b884fe5952f63b75b39e4efbec69c1d15b",
          "avatar": "https://storage.kalao.io/collections/avatars/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789.jpg",
          "name": "Firat BD",
          "certified": true,
          "display_theme": "default"
        },
        "thumbnail": "https://storage.googleapis.com/beta-kalao/nfts/0x895794b884fe5952f63b75b39e4efbec69c1d15b/0x895794b884fe5952f63b75b39e4efbec69c1d15b_30/thumbnail",
        "likes": 1,
        "sale": null
      },
      {
        "asset_id": "0x895794b884fe5952f63b75b39e4efbec69c1d15b_31",
        "token_id": "31",
        "name": " #1436",
        "collection": {
          "address": "0x895794b884fe5952f63b75b39e4efbec69c1d15b",
          "avatar": "https://storage.kalao.io/collections/avatars/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789.jpg",
          "name": "Firat BD",
          "certified": true,
          "display_theme": "default"
        },
        "thumbnail": "https://storage.googleapis.com/beta-kalao/nfts/0x895794b884fe5952f63b75b39e4efbec69c1d15b/0x895794b884fe5952f63b75b39e4efbec69c1d15b_31/thumbnail",
        "likes": 0,
        "sale": null
      }
    ]
  },
  "SP5DD89": {
    sharecode : "SP5DD89",
    title : "Sample 2 Gallery Vision",
    description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend hendrerit orci in ullamcorper. Mauris ac massa ut nulla cursus suscipit non at nisl. Donec suscipit nec dolor et sagittis. Vivamus ante mauris, consectetur et suscipit in, commodo id nibh. Nam pharetra consequat tortor, ac condimentum odio tincidunt ac.",
    total_items : 3,
    total_likes : 37,
    image_cover : "https://storage.googleapis.com/beta-kalao/galleries/sample-test/cover.jpg",
    image_360 : "https://storage.googleapis.com/beta-kalao/galleries/sample-test/360.jpg",
    creator: {
      address: "0xd5de5a673c2fafefbbe942b6a9cbd30599d65ec4",
      name: "Roger is Back",
      avatar: "https://storage.googleapis.com/beta-kalao/users/0xd5de5a673c2fafefbbe942b6a9cbd30599d65ec4/avatar",
      certified: true
    },
    items: [
      {
        "asset_id": "0x895794b884fe5952f63b75b39e4efbec69c1d15b_32",
        "token_id": "32",
        "name": " #3443",
        "collection": {
          "address": "0x895794b884fe5952f63b75b39e4efbec69c1d15b",
          "avatar": "https://storage.kalao.io/collections/avatars/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789.jpg",
          "name": "Firat BD",
          "certified": true,
          "display_theme": "default"
        },
        "thumbnail": "https://storage.googleapis.com/beta-kalao/nfts/0x895794b884fe5952f63b75b39e4efbec69c1d15b/0x895794b884fe5952f63b75b39e4efbec69c1d15b_32/thumbnail",
        "likes": 0,
        "sale": null
      },
      {
        "asset_id": "0x895794b884fe5952f63b75b39e4efbec69c1d15b_1",
        "token_id": "1",
        "name": " #4790",
        "collection": {
          "address": "0x895794b884fe5952f63b75b39e4efbec69c1d15b",
          "avatar": "https://storage.kalao.io/collections/avatars/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789.jpg",
          "name": "Firat BD",
          "certified": true,
          "display_theme": "default"
        },
        "thumbnail": "https://storage.googleapis.com/beta-kalao/nfts/0x895794b884fe5952f63b75b39e4efbec69c1d15b/0x895794b884fe5952f63b75b39e4efbec69c1d15b_1/thumbnail",
        "likes": 0,
        "sale": null
      },
      {
        "asset_id": "0x895794b884fe5952f63b75b39e4efbec69c1d15b_2",
        "token_id": "2",
        "name": " #3475",
        "collection": {
          "address": "0x895794b884fe5952f63b75b39e4efbec69c1d15b",
          "avatar": "https://storage.kalao.io/collections/avatars/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789.jpg",
          "name": "Firat BD",
          "certified": true,
          "display_theme": "default"
        },
        "thumbnail": "https://storage.googleapis.com/beta-kalao/nfts/0x895794b884fe5952f63b75b39e4efbec69c1d15b/0x895794b884fe5952f63b75b39e4efbec69c1d15b_2/thumbnail",
        "likes": 0,
        "sale": null
      }
    ]
  }
};

const renderTopSection = (gallery: Gallery, isLoading?: boolean) => {
  const { title, description, sharecode, total_items, image_cover, creator } = gallery;
  const { name, certified, avatar, address } = creator;
  const theme = useTheme();
  const goTo = (address: string, certified: boolean) => {
      let url;
      certified
        ? url = `/${AppRoutes.profile.replace(':address', address)}/created`
        : url = `/${AppRoutes.profile.replace(':address', address)}`;
      return url;
  };
  return (
    <Box>
      <Background
        src={image_cover}
        avatar={avatar}
        isApproved={certified}
        height="22rem"
      />
      <Stack spacing={1} alignItems="center" sx={{ pt:6, pb: 1 }}>
        <Typography variant="h2" color="text.primary" fontWeight="600">
          {isLoading ? (
            <Skeleton animation="wave" height={40} width="290px" />
          ) : (
            title
          )}
        </Typography>
        <Typography variant="h5" color="text.secondary" fontWeight="600">
          {isLoading ? (
            <Skeleton animation="wave" height={22} width="150px" />
          ) : (
            <Link to={goTo(address, certified)} style={{color: theme.palette.text.secondary, textDecoration: 'none' }}>
              @{(name && name != 'anonymous') ? name : formatAddress(address)}
            </Link>
          )}
        </Typography>
      </Stack>
    </Box>
  );
};

const renderContent = (gallery: Gallery, isLoading?: boolean) => {
  const { title, description, sharecode, total_items, image_360, creator } = gallery;
  const { name, certified, avatar, address } = creator;
  const theme = useTheme();
  return (
    <Box sx={{mt: 6, mb: 5}}>
      <Grid container>
        <Grid item xs={12} sm={6} lg={8}>
          <Box sx={{overflow: 'hidden', borderRadius: {sm: '0 1.25rem 1.25rem 0'}}}>
            <ImagePanorama src={image_360} />
          </Box>
        </Grid>
        <Grid item xs={12}sm={6} lg={4} >
          <Box sx={{px: {xs: '2rem',  lg: '3rem'}}}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{mb: 3, mt: {xs: 3, sm: 0}}}>
              <Typography component="div" variant="h4" color="text.primary">
                {isLoading ? (
                  <Skeleton animation="wave" height={25} width="110px" />
                ) : (
                  total_items + ' items'
                )}
              </Typography>
              <Typography component="div" variant="h4" color="text.primary">
                {isLoading ? (
                  <Skeleton animation="wave" height={25} width="110px" />
                ) : (
                  'Likes'
                )}
              </Typography>
            </Stack>
            <Typography variant="body1" color="text.secondary" textAlign="justify" sx={{ mb: 6 }}>
              {isLoading ? (
                <>
                  <Skeleton animation="wave" height={16} width="90%" sx={{ mb: 1 }} />
                  <Skeleton animation="wave" height={16} width="90%" sx={{ mb: 1 }} />
                  <Skeleton animation="wave" height={16} width="80%" />
                </>
              ) : (
                description
              )}
            </Typography>
            <Vision sharecode={sharecode} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const GalleryPage = ({ isLoading=true }: Props) => {
  const dispatch = useDispatch();
  const { success, error } = useNotistack();
  const { account } = useWeb3React();
  const { loggedIn } = useAuth();
  const { sharecode } = useParams<SharecodeRouteParams>();
  /*
  const { data } = useCollectionQuery({ address }, { skip: !address });
  const { collection, stats } = processCollectionQueryResponse(data);
  const { banner, avatar, certified, creator, address: collectionAddress } = collection;
  const isCreator = loggedIn && equalsIgnoringCase(creator, account);
  */

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const gallery = sharecode ? galleries[sharecode] : initGallery;
  isLoading = false;

  return (
    <Box>
      {renderTopSection(gallery, isLoading)}
      {renderContent(gallery, isLoading)}
      {gallery.items.length > 0 && (
        <Container>
          <NFTsSwiper nfts={gallery.items} />
        </Container>
        )}
      <Footer />
    </Box>
  );
};

export default GalleryPage;
