import React, { useEffect, useState } from 'react';
import { Stack, styled, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { OwnerShipStep, Button, Updating, DropDownInput, Price } from 'components';
import { NumberOrString, PriceSymbol } from 'types';
import { Divider } from '@mui/material';
import { openModal } from 'store/actions';
import { Renderer } from '../Renderer';
import { getTokenOptions } from 'constants/options';

type UpdateProps = {
  close: () => void;
  confirm: (price: number) => void;
};

const Fees = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});

const Update = ({ confirm, close }: UpdateProps) => {
  const [value, setValue] = useState<NumberOrString>();
  const [error, setError] = useState<string>();

  const onConfirm = () => {
    if (!value) setError('Enter a valid price');
    else confirm(Number(value));
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={1} sx={{ mt: 2 }}>
        <Typography variant="body2" color="text.secondary" textAlign="start">
          New price
        </Typography>
        <DropDownInput
          defaultKey={'AVAX'}
          value={value}
          numeric={true}
          onValueChange={(v) => {
            if (error) setError('');
            setValue(v);
          }}
          onOptionChange={(v) => undefined}
          options={getTokenOptions(['AVAX'])}
          error={error !== undefined}
        />
      </Stack>

      <Stack>
        <Typography variant="body2" color="text.secondary" textAlign="start">
          Fees
        </Typography>
        <Fees>
          <Typography variant="subtitle2" color="text.primary">
            Service Fee
          </Typography>
          <Typography variant="subtitle2" color="text.primary">
            0%
          </Typography>
        </Fees>
      </Stack>
      {value && (
        <>
          <Divider sx={{ my: 3 }} variant="middle" />
          <Fees>
            <Typography variant="h5" color="text.secondary">
              Total earnings
            </Typography>
            <Price variant="h4" value={value} symbol={PriceSymbol.AVAX} />
          </Fees>
        </>
      )}
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button ctx="primary" sx={{ width: '8rem' }} onClick={onConfirm}>
          <Typography variant="button">Confirm</Typography>
        </Button>
        <Button ctx="secondary" sx={{ width: '8rem' }} onClick={() => close()}>
          <Typography variant="button">Cancel</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

const UpdateSale = () => {
  const dispatch = useDispatch();
  const nftSale = useSelector((global: GlobalState) => global.state.nftSale);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [price, setPrice] = useState<number>();

  useEffect(() => {
    setCurrentStep(1);
  }, []);

  const newSale =
    price && nftSale
      ? { ...nftSale, sale: { ...nftSale.sale, unitary_price_float: price } }
      : nftSale;

  return (
    //todo: pass listing to renderer
    <Renderer title="Listing update" nftSale={newSale}>
      {!price && nftSale && (
        <Update confirm={setPrice} close={() => dispatch(openModal.create({}))} />
      )}
      {/** Steps */}
      {price && nftSale && (
        <>
          <Divider sx={{ my: 3 }} variant="middle" />
          <Stack spacing={2}>
            <OwnerShipStep step={1} currentStep={currentStep} complete={() => setCurrentStep(2)} />
            <Updating
              step={2}
              currentStep={currentStep}
              nftSale={{ ...nftSale, sale: { ...nftSale.sale, unitary_price_float: price } }}
              complete={() => undefined}
            />
          </Stack>
        </>
      )}
    </Renderer>
  );
};

export default UpdateSale;
