import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Avatar, Miniature, MiniatureInfo, Price, PropertiesChip } from 'components';
import { AdvancedOffer, Bid, NFTOffer, NFTSale, PriceSymbol } from 'types';
import { priceFormatter } from 'lib/format';
import { NftPreview, OfferKind } from 'types/generated';
import { formatDistance, fromUnixTime } from 'date-fns';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store';

export const renderNFTSale = (nftSale: NFTSale): JSX.Element => {
  const price = nftSale.sale.unitary_price_float;
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Miniature thumbnail={nftSale.thumbnail} />
      <MiniatureInfo nft={nftSale} />
      <Price value={priceFormatter.format(price)} symbol={PriceSymbol.AVAX} fontSize={26} />
    </Box>
  );
};

export const renderNFTOffer = (nftOffer: NFTOffer): JSX.Element => {
  const modal = useSelector((global: GlobalState) => global.state.modal);
  const price = Number(nftOffer.offer.unitary_price_float);
  const avatar =
    nftOffer.offer.kind == OfferKind.Collection ? nftOffer.collection.avatar : nftOffer.thumbnail;
  const expiryDate =
    // api sends dates in unix timestamp
    //
    modal == 'ACCEPT OFFER' || modal == 'CANCEL OFFER'
      ? fromUnixTime(nftOffer.offer.expiration_date)
      : nftOffer.offer.expiration_date;
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Miniature thumbnail={avatar} />
      <MiniatureInfo
        nft={nftOffer}
        el={
          <Typography variant="caption" sx={{ color: '#3D3AE9' }}>
            Expiration:
            {formatDistance(expiryDate, new Date(), {
              addSuffix: true
            })}
          </Typography>
        }
      />
      <Price value={priceFormatter.format(price)} symbol={PriceSymbol.WAVAX} fontSize={26} />
    </Box>
  );
};

export const renderNFT = (nft: NftPreview, quantity?: number): JSX.Element => {
  const { sale } = nft;
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Miniature thumbnail={nft.thumbnail} quantity={quantity} />
      <MiniatureInfo nft={nft} />
      {sale && (
        <Price
          value={priceFormatter.format(sale.unitary_price_float)}
          symbol={PriceSymbol.AVAX}
          fontSize={26}
        />
      )}
    </Box>
  );
};

export const renderBid = (bid: Bid): JSX.Element => {
  const { collection } = bid;
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Miniature thumbnail={collection.avatar} />
      <Typography>{collection.name}</Typography>
    </Box>
  );
};

export const renderAdvancedOffer = (advancedOffer: AdvancedOffer, selectedNftsCount?: number) => {
  const { collection, stats, properties } = advancedOffer;

  const count = selectedNftsCount || 0;

  return (
    <Stack alignItems="center" spacing={1}>
      <Avatar
        sx={{ width: '4rem', height: '4rem', mb: 2 }}
        top={35}
        src={collection.avatar}
        approved={collection.certified}
      />
      <Typography variant="body1" color="text.primary">
        {collection.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {stats.total_minted} items
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
        {properties && (
          <Typography variant="body2" color="text.primary">
            {count} Items selected
          </Typography>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="body2" color="text.secondary">
            Floor Price
          </Typography>
          <Price value={priceFormatter.format(stats.floor_price)} />
        </Box>
      </Box>
      <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
        <PropertiesChip properties={properties} />
      </Box>
    </Stack>
  );
};
