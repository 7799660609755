import React from 'react';
import { MenuItem, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDropDown = styled(Box)(({ theme }) => ({
  background: theme.input.background,
  borderRadius: '5.625rem',
  height: '3rem',
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ':hover': {
    background: theme.palette.action.hover
  }
}));

export const PopperContainer = styled(Box)({
  background: 'transparent',
  paddingTop: '0.5rem'
});

export const InnerPopperContainer = styled(Paper)(({ theme }) => ({
  background:
    theme.palette.mode == 'dark' ? theme.input.background : theme.palette.background.paper,
  borderRadius: '0.9375rem',
  padding: theme.spacing(1)
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  background: 'transparent',
  borderRadius: '0.5rem',
  width: '100%',
  paddingLeft: 0,
  height: '2.5rem',
  fontSize: 14,
  color: theme.palette.text.secondary,
  ':hover': {
    color: theme.palette.text.primary
  },
  '&.Mui-selected': {
    color: theme.palette.text.primary
  }
}));
