import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Asset, StepStatus, TokenKind } from 'types';
import Step from '../Step';
import { mintERC1155, mintERC721 } from 'services/tokenConfigurator';
import { useWeb3React } from '@web3-react/core';
import { Done } from './Done';

type Props = {
  step: number;
  currentStep: number;
  nft: Asset;
  ipfsUploadUrl: string;
  // callback, called once the step has been completed successfully
  complete: () => void;
};

const Progress = () => {
  return (
    <Typography variant="body2" color="text.secondary">
      Sending transaction to create your NFT
    </Typography>
  );
};

const Mint = ({ step, currentStep, nft, ipfsUploadUrl, complete }: Props) => {
  const { account, hooks } = useWeb3React();
  const [error, setError] = useState<string>();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const { tokenKind, collection, form } = nft;
  const { copies } = form;

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
      mint();
    }
  }, [currentStep]);

  const mint = async () => {
    setStatus(StepStatus.IN_PROGRESS);

    if (!provider || !account) {
      throw new Error('Please connect your wallet');
    }
    try {
      if (tokenKind === TokenKind.erc721) {
        await mintERC721(provider, {
          collectionAddress: collection.address,
          to: account,
          uri: ipfsUploadUrl
        }).then(() => {
          setStatus(StepStatus.DONE);
          complete();
        });
      } else {
        await mintERC1155(provider, {
          collectionAddress: collection.address,
          to: account,
          amount: Number(copies),
          uri: ipfsUploadUrl
        }).then(() => {
          setStatus(StepStatus.DONE);
          complete();
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Mint failed!');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={
        status == StepStatus.DONE ? (
          <Done nft={nft}  />
        ) : (
          'Mint'
        )
      }
      step={step}
      currentStep={currentStep}
      error={error}
      status={status}
      el={status == StepStatus.IN_PROGRESS ? <Progress /> : undefined}
    />
  );
};

export default Mint;
