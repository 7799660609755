// 3rd party libs
import React, { CSSProperties, useState } from 'react';
import Badge from '@mui/material/Badge';
import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { ReactComponent as Certified } from '../../assets/certified.svg';

type BadgeProps = {
  width?: number;
  top?: number;
  iconStyle?: CSSProperties;
};

type Props = {
  src: string;
  approved?: boolean;
} & AvatarProps;

const StyledBadge = styled(Badge)<BadgeProps>(({ width, top }) => ({
  '& .MuiBadge-badge': {
    width: width || 30,
    top: top || 15
  }
}));

const Avatar = ({ src, approved, width, top, style, iconStyle, ...rest }: Props & BadgeProps) => {
  const [loading, setLoading] = useState(true);
  const content = (
    <>
      {loading && (
        <Skeleton variant="circular">
          <MuiAvatar {...rest} />
        </Skeleton>
      )}
      <MuiAvatar
        src={src}
        {...rest}
        onLoad={() => setLoading(false)}
        style={{ ...style, display: loading ? 'none' : 'block' }}
      />
    </>
  );
  return approved ? (
    <StyledBadge
      width={width}
      top={top}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={!loading && <Certified style={iconStyle} />}
    >
      {content}
    </StyledBadge>
  ) : (
    content
  );
};

export default Avatar;
