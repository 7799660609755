import { NFTOffer, NFTSale } from 'types';
import {
  ArtistInfo,
  Collection,
  NftAttribute,
  NftBase,
  NftInteractions,
  NftMetadatas,
  NftDetails,
  NftSalePreview,
  NftPreview,
  LAndOResult,
  OfferSide,
  OfferStatus,
  OfferKind,
  NftMetadataAssets,
  SaleKind,
  SaleStatus
} from 'types/generated';
import { Details, User } from './types';

const toStr = (value?: string | null): string => value || '';

const toBase = (base?: NftBase): NftBase => {
  return {
    address: toStr(base?.address),
    asset_id: toStr(base?.asset_id),
    kind: toStr(base?.kind),
    owner: toStr(base?.owner),
    token_id: toStr(base?.token_id),
    editions: base?.editions || '1',
    total_owners: toStr(base?.total_owners),
    owners_avatars: base?.owners_avatars || []
  };
};

const toMetadata = (meta?: NftMetadatas): NftMetadatas => {
  return {
    background_color: toStr(meta?.background_color),
    description: toStr(meta?.description),
    external_url: toStr(meta?.external_url),
    extra: toStr(meta?.extra),
    name: toStr(meta?.name),
    thumbnail_mime: toStr(meta?.thumbnail_mime),
    thumbnail_url: toStr(meta?.thumbnail_url),
    youtube_url: toStr(meta?.youtube_url)
  };
};

const toCollection = (collection?: Collection): Collection => {
  return {
    address: toStr(collection?.address),
    avatar: toStr(collection?.avatar),
    certified: collection?.certified || false,
    name: toStr(collection?.name),
    description: toStr(collection?.description),
    thumbnails: collection?.thumbnails || [],
    kind: toStr(collection?.kind),
    display_theme: collection?.display_theme
  };
};

const toAttributes = (attr?: NftAttribute[]): NftAttribute[] => {
  const attrs: NftAttribute[] = [];
  attr?.forEach((attr) => attrs.push(attr));
  return attrs;
};

const toUser = (user?: ArtistInfo | null): User => {
  return {
    address: toStr(user?.address),
    avatar: toStr(user?.avatar),
    certified: user?.certified || false,
    name: toStr(user?.name)
  };
};

const toInteractions = (interactions?: NftInteractions | null): NftInteractions => {
  return {
    favorites: interactions?.favorites || 0,
    likes: interactions?.likes || 0,
    views: interactions?.views || 0
  };
};

const toAssets = (assets?: NftMetadataAssets): NftMetadataAssets => {
  return {
    hd_animation_mime: toStr(assets?.hd_animation_mime),
    hd_animation_url: toStr(assets?.hd_animation_url),
    hd_image_mime: toStr(assets?.hd_image_mime),
    hd_image_url: toStr(assets?.hd_image_url),
    raw_animation_mime: toStr(assets?.raw_animation_mime),
    raw_animation_url: toStr(assets?.raw_animation_url),
    raw_image_mime: toStr(assets?.raw_image_mime),
    raw_image_url: toStr(assets?.raw_image_url)
  };
};

export const toDetails = (r?: NftDetails | null): Details => {
  return {
    base: toBase(r?.base),
    metadata: toMetadata(r?.metadatas),
    attributes: toAttributes(r?.attributes),
    collection: toCollection(r?.collection),
    owner: toUser(r?.owner),
    creator: toUser(r?.creator),
    interactions: toInteractions(r?.interactions),
    stats: {
      total_minted: r?.stats.total_minted || '0 ',
      average_royalties_percent: r?.stats.average_royalties_percent || 0
    },
    moreNfts: r?.more_nfts || [],
    best_offer: r?.best_offer,
    sale: r?.sale,
    assets: toAssets(r?.assets)
  };
};

export const toNftSale = (
  base: NftBase,
  meta: NftMetadatas,
  collection: Collection,
  sale: NftSalePreview
): NFTSale => {
  return {
    asset_id: base.asset_id,
    collection,
    likes: 0, // Not important
    name: meta.name,
    thumbnail: meta.thumbnail_url,
    token_id: base.token_id,
    sale
  };
};

export const toNFtPreview = (
  base: NftBase,
  meta: NftMetadatas,
  collection: Collection,
  sale?: NftSalePreview | null
): NftPreview => {
  return {
    asset_id: base.asset_id,
    collection,
    likes: 0, // Not important
    name: meta.name,
    thumbnail: meta.thumbnail_url,
    token_id: base.token_id,
    sale
  };
};

export const toNFTSalePreview = (listing: LAndOResult): NftSalePreview => {
  return {
    expiration_date: 0,
    floor_diff: '',
    kind: SaleKind.All,
    quantity: listing.quantity.toString(),
    sale_id: listing.id,
    seller: listing.from_address,
    start_date: 0,
    status: SaleStatus.All,
    unitary_price: '',
    unitary_price_float: listing.price
  };
};

// TODO: Create a specific offer instead of 'NFTOffer' to pick only 'offer_id', 'buyer' and 'total_quantity'
export const toNftOffer = (
  base: NftBase,
  meta: NftMetadatas,
  collection: Collection,
  offer: LAndOResult
): NFTOffer => {
  const { expiration, floor_diff, from_address, id, price, quantity } = offer;
  return {
    asset_id: base.asset_id,
    collection,
    likes: 0, // Not important
    name: meta.name,
    thumbnail: meta.thumbnail_url,
    token_id: base.token_id,
    offer: {
      bought_quantity: quantity.toString(),
      buyer: from_address,
      expiration_date: expiration,
      floor_diff: floor_diff,
      kind: OfferKind.Nft,
      merkle_root: '',
      offer_id: id,
      payment_token: '',
      quantity: quantity.toString(),
      side: OfferSide.Received,
      start_date: 0,
      status: OfferStatus.Live,
      total_quantity: quantity.toString(),
      unitary_price: '',
      unitary_price_float: price
    }
  };
};
