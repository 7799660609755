import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Tabs } from 'components';
import NFTActivity from './Activity';
import { TabPanel } from './helpers';
import { NFTInfo } from './Info';
import { NFTProperties } from './Properties';
import { Collection, NftAttribute, NftBase, NftMetadatas } from 'types/generated';
import { Stats, User } from '../types';
import { ListingsAndOffers } from './Offers';

type Props = {
  base: NftBase;
  metadata: NftMetadatas;
  attributes: NftAttribute[];
  collection: Collection;
  stats: Stats;
  owner: User;
  creator: User;
  loading?: boolean;
};

const tabs: { label: string; value: string }[] = [
  { label: 'Properties', value: 'properties' },
  { label: 'Offers', value: 'offers' },
  { label: 'Activity', value: 'activity' },
  { label: 'Info', value: 'info' }
];

const getTabIndex = (newTab: string) => {
  return tabs.findIndex((tab) => tab.value == newTab);
};

const NFTDetails = ({
  base,
  metadata,
  attributes,
  collection,
  owner,
  creator,
  stats,
  loading
}: Props) => {
  const [currentTab, setCurrentTab] = useState('properties');
  return (
    <Box sx={{ mb: 4, minHeight: '200px' }}>
      <Box sx={{ borderBottom: (theme) => theme.border, mb: 4 }}>
        <Tabs tabs={tabs} onTabChange={(tab) => setCurrentTab(tab)} />
      </Box>
      {/* Properties Tab */}
      <TabPanel value={getTabIndex(currentTab)} index={0}>
        <NFTProperties attributes={attributes} loading={loading} />
      </TabPanel>

      {/* Offer Tab */}
      <TabPanel value={getTabIndex(currentTab)} index={1}>
        <ListingsAndOffers
          assetId={base.asset_id}
          base={base}
          metadata={metadata}
          collection={collection}
          owner={owner.address}
        />
      </TabPanel>

      {/* Activities Tab */}
      <TabPanel value={getTabIndex(currentTab)} index={2}>
        <NFTActivity assetId={base.asset_id} />
      </TabPanel>

      {/* Info Tab */}
      <TabPanel value={getTabIndex(currentTab)} index={3}>
        <NFTInfo
          base={base}
          metadata={metadata}
          stats={stats}
          owner={owner}
          creator={creator}
          collection={collection}
          loading={loading}
        />
      </TabPanel>
    </Box>
  );
};

export default NFTDetails;
