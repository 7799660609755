import React, { ReactNode, useState } from 'react';
import { SquareIconButton } from 'components';
import { Collapse, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type ItemContentType = {
  title: string;
  show: boolean;
  children: ReactNode;
};

export const MenuItemContent = ({ title, children, show }: ItemContentType) => {
  const [toggeled, setToggeled] = useState(true);
  return !show ? null : (
    <>
      <MenuItem sx={{ height: '5rem' }} onClick={() => setToggeled(!toggeled)} disableRipple={true}>
        <ListItemText>{title}</ListItemText>
        <ListItemIcon>
          <SquareIconButton size="small">
            <KeyboardArrowDownIcon sx={{ transform: toggeled ? 'rotate(180deg)' : 'inherit' }} />
          </SquareIconButton>
        </ListItemIcon>
      </MenuItem>
      <Collapse in={toggeled} timeout={100} unmountOnExit sx={{ p: 1 }}>
        {children}
      </Collapse>
    </>
  );
};
