import React from 'react';
import { Price } from 'components';
import { KeyValuePair, NumberOrString, PriceSymbol } from 'types';

export const categories_options: KeyValuePair<string, string>[] = [
  { key: 'art', value: 'Art' },
  { key: 'music', value: 'Music' },
  { key: 'collectibles', value: 'Collectibles' },
  { key: 'domain_name', value: 'Domain name' },
  { key: 'photography', value: 'Photography' },
  { key: 'sport', value: 'Sport' }
];

export const sales_options: KeyValuePair<string, string>[] = [
  //{ key: 'auction', value: 'Auction' },
  { key: 'direct', value: 'Direct Sale' }
];

export const days_filter_options: KeyValuePair<string, string>[] = [
  //{ key: 'daily', value: 'Last 24 hours' },
  { key: 'weekly', value: 'Last 7 days' }
  //{ key: 'monthly', value: 'Last 30 days' },
  //{ key: 'three_months', value: 'Last 3 months' },
  //{ key: 'yearly', value: 'Year' },
];

export const stats_range_options: KeyValuePair<string, string>[] = [
  { key: 'weekly', value: 'Last 7 days' },
  { key: 'monthly', value: 'Last 30 days' },
  { key: 'yearly', value: 'All' }
];

export const activity_options: KeyValuePair<string, string>[] = [
  { key: 'sale', value: 'Sale' },
  { key: 'listing', value: 'Listing' },
  { key: 'transfer', value: 'Transfer' },
  { key: 'mint', value: 'Mint' },
  { key: 'offer', value: 'Offer' },
  { key: 'collection_offer', value: 'Collection Offer' }
];

export const nftDetails_activity_options: KeyValuePair<string, string>[] = [
  { key: 'all', value: 'All types' },
  { key: 'sale', value: 'Sale' },
  { key: 'listing', value: 'Listing' },
  { key: 'transfer', value: 'Transfer' },
  { key: 'mint', value: 'Mint' },
  { key: 'offer', value: 'Offer' }
];

export const activity_listings_options: KeyValuePair<string, string>[] = [
  { key: 'all', value: 'All' },
  { key: 'live', value: 'Live' },
  { key: 'cancelled', value: 'Canceled' },
  { key: 'ended', value: 'Ended' },
  { key: 'sold_out', value: 'Sold out' }
];

export const activity_offers_options: KeyValuePair<string, string>[] = [
  { key: 'all', value: 'All' },
  { key: 'live', value: 'Live' },
  { key: 'filled', value: 'Filled' },
  { key: 'cancelled', value: 'Cancelled' },
  { key: 'expired', value: 'Expired' },
  { key: 'inactive', value: 'Inactive' }
];

export const getTokenOptions = (tokens: ('AVAX' | 'WAVAX')[], size = 14) => {
  const opts: { key: NumberOrString; value: JSX.Element }[] = [];
  tokens.forEach((token) => {
    opts.push({
      key: token,
      value: <Price value={token} symbol={PriceSymbol[token]} size={size} />
    });
  });

  return opts;
};

export const sort_explore_nfts: KeyValuePair<string, string>[] = [
  { key: 'recently_listed', value: 'Recently Listed' },
  { key: 'price_asc', value: 'Price: Low to High' },
  { key: 'price_desc', value: 'Price: High to Low' },
  { key: 'ending_soon', value: 'Ending Soon' },
  { key: 'most_liked', value: 'Most Liked' }
];

export const sort_explore_collection: KeyValuePair<string, string>[] = [
  { key: 'top', value: 'Top volume' },
  { key: 'trending', value: 'Trending' },
  { key: 'recently_created', value: 'Recently created' }
];

export const sort_collection: KeyValuePair<string, string>[] = [
  { key: 'recently_listed', value: 'Recently Listed' },
  { key: 'price_asc', value: 'Price: Low to High' },
  { key: 'price_desc', value: 'Price: High to Low' },
  { key: 'token_id_asc', value: 'TokenId: Low to High' },
  { key: 'rarity_asc', value: 'Rarity: High to Low' },
  { key: 'ending_soon', value: 'Ending Soon' },
  { key: 'most_liked', value: 'Most Liked' }
];

export const sort_owned_nfts: KeyValuePair<string, string>[] = [
  { key: 'recently_received', value: 'Recently Received' },
  { key: 'price_asc', value: 'Price: Low to High' },
  { key: 'price_desc', value: 'Price: High to Low' },
  { key: 'ending_soon', value: 'Ending Soon' },
  { key: 'most_liked', value: 'Most Liked' }
];

export const sort_sell_nfts: KeyValuePair<string, string>[] = [
  { key: 'recently_received', value: 'Recently Received' },
  { key: 'most_liked', value: 'Most Liked' },
  { key: 'token_id_asc', value: 'TokenId: Low to High' }
];

export const sort_created_nfts: KeyValuePair<string, string>[] = [
  { key: 'recently_created', value: 'Recently Created' },
  { key: 'price_asc', value: 'Price: Low to High' },
  { key: 'price_desc', value: 'Price: High to Low' },
  { key: 'ending_soon', value: 'Ending Soon' },
  { key: 'most_liked', value: 'Most Liked' }
];
