import React, { useMemo } from 'react';
import { useFilters } from 'providers';
import { AddressRouteParams, KeyValuePair, NumberOrString, PriceRangeType, Property } from 'types';
import { Chip, Box } from '@mui/material';
import { sales_options } from 'constants/options';
import { useCategoriesQuery } from 'api';
import { useParams } from 'react-router-dom';

const getSalesType = (key: NumberOrString) => {
  return sales_options.find((s) => s.key == key)?.value || 'all';
};

const getCategory = (key: NumberOrString, categoriesPairs: KeyValuePair<string, string>[]) => {
  return categoriesPairs.find((s) => s.key == key)?.value || 'all';
};

const renderProperties = (
  properties: Property[],
  clear: (property: Property, attrName: string) => void
) => {
  const chips: JSX.Element[] = [];
  properties.forEach((property) => {
    property.attrs.forEach((attr) => {
      if (attr.selected)
        chips.push(
          <Chip
            key={attr.name}
            label={attr.name}
            variant="outlined"
            onDelete={() => clear(property, attr.name)}
          />
        );
    });
  });

  return chips;
};

const renderPrice = (priceRange: PriceRangeType, clearPrice: (price: 'min' | 'max') => void) => {
  const price: JSX.Element[] = [];
  if (priceRange.min !== undefined) {
    price.push(
      <Chip
        key={'price-min'}
        label={`Min price: ${priceRange.min}`}
        variant="outlined"
        onDelete={() => clearPrice('min')}
      />
    );
  }
  if (priceRange.max !== undefined) {
    price.push(
      <Chip
        key={'price-max'}
        label={`Max price: ${priceRange.max}`}
        variant="outlined"
        onDelete={() => clearPrice('max')}
      />
    );
  }

  return price;
};

const renderSalesType = (salesType: NumberOrString | undefined, clear: () => void) => {
  if (salesType && salesType !== 'all')
    return [
      <Chip
        key={'sales-type'}
        label={getSalesType(salesType)}
        variant="outlined"
        onDelete={() => clear()}
      />
    ];
};

const renderCategory = (
  options: KeyValuePair<string, string>[],
  categories: NumberOrString[],
  clear: (category: NumberOrString) => void
) => {
  return categories.map((category, index) => {
    return (
      <Chip
        key={`${category}-${index}`}
        label={getCategory(category, options)}
        variant="outlined"
        onDelete={() => clear(category)}
      />
    );
  });
};

const FilterChips = () => {
  const { address } = useParams<AddressRouteParams>();
  const { data } = useCategoriesQuery({ address });
  const {
    properties,
    setProperties,
    price,
    setPrice,
    salesType,
    setSalesType,
    categories,
    setCategories
  } = useFilters();

  const categoriesPairs = useMemo(() => {
    return data?.categories.map((cat) => {
      return { key: cat?.tag, value: cat?.name };
    }) as KeyValuePair<string, string>[];
  }, [data]);

  const clearSelection = (property: Property, attrName: string) => {
    const p = { ...property };
    const index = p.attrs.findIndex((attr) => attr.name == attrName);
    if (index > -1) {
      p.attrs[index].selected = false;
      setProperties(p);
    }
  };

  const clearPrice = (priceToClear: 'min' | 'max') => {
    if (priceToClear == 'min') setPrice({ ...price, min: undefined });
    if (priceToClear == 'max') setPrice({ ...price, max: undefined });
  };

  return (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
      {renderProperties(properties, clearSelection)}
      {renderPrice(price || {}, clearPrice)}
      {renderSalesType(salesType, () => setSalesType(''))}
      {renderCategory(categoriesPairs, categories, (category) => {
        const c = [...categories].filter((c) => c !== category);
        setCategories(c);
      })}
    </Box>
  );
};

export default FilterChips;
