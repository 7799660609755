import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { Divider, Stack, Typography } from '@mui/material';
import Button from 'components/Button';
import { PriceRangeType } from 'types';
import { NumberInput } from 'components';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1)
}));

type PriceRangeProps = {
  range: PriceRangeType;
  apply: (price?: PriceRangeType) => void;
  reset: () => void;
} & BoxProps;

const PriceRange = ({ range, apply, reset, ...props }: PriceRangeProps) => {
  const [price, setPrice] = useState<PriceRangeType>(range);

  const onReset = () => {
    // Reset state
    setPrice({});
    // Reset callback (parent component)
    reset();
  };

  useEffect(() => {
    setPrice(range);
  }, [range]);

  return (
    <Container {...props}>
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <NumberInput
            placeholder="Min"
            value={price.min}
            onChange={(v) => setPrice({ ...price, min: v })}
          />
          <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
          <NumberInput
            placeholder="Max"
            value={price.max}
            onChange={(v) => setPrice({ ...price, max: v })}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button ctx="secondary" sx={{ flex: 1 }} onClick={onReset}>
            <Typography variant="button">Reset</Typography>
          </Button>
          <Button ctx="primary" sx={{ flex: 1 }} onClick={() => apply(price)}>
            <Typography variant="button">Apply</Typography>
          </Button>
        </Box>
      </Stack>
    </Container>
  );
};

export default PriceRange;
