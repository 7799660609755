import React from 'react';
import { styled, Box, Typography, Skeleton, BoxProps } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value?: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ display: 'flex' }}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
};

type NFTDetailsItem = {
  title?: string;
  subTitle?: string | JSX.Element;
  loading?: boolean;
} & BoxProps;

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: '0.5rem',
  background: theme.input.background,
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    // Match [md, ∞)
    //       [900px, ∞)
    flexDirection: 'column'
  }
}));

export const NFTDetailsItem = ({ title, subTitle, loading, ...rest }: NFTDetailsItem) => {
  return (
    <StyledBox {...rest}>
      <Typography variant="body2" color="text.primary">
        {loading ? <Skeleton width={100} /> : title}
      </Typography>
      {typeof subTitle == 'string' ? (
        <Typography variant="body2" color="text.secondary">
          {loading ? <Skeleton width={100} /> : subTitle}
        </Typography>
      ) : (
        subTitle
      )}
    </StyledBox>
  );
};
