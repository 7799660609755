import React, { useEffect, useRef, useState } from 'react';
import { Chip, IconButton, Skeleton, styled, SvgIcon } from '@mui/material';
import { ReactComponent as PlayCircleIconShadow } from './../../assets/cards/play.svg';
import Explore from '@mui/icons-material/Launch';

type Props = {
  src: string;
  alt?: string;
  ratio?: number;
  animation_mime?: string | null;
  isLoading?: boolean;
  // Fill available space
  fill?: boolean;
  clickable?: boolean;
  onClick?: () => void;
  onRawClick?: (url: string) => void;
  // video
  animation?: string | null;
  // hd
  className?: string;
  // original media (ipfs?)
  raw_image?: string | null;
  raw_animation?: string | null;
  // number of erc1155 editions
  editions?: string;
  preventEvent?: boolean;
  displayTheme?: string | null;
};

const Container = styled('div')<{ ratio?: number }>(({ ratio }) => ({
  overflow: 'hidden',
  paddingTop: `${ratio || 100}%`,
  position: 'relative',
  '& .skeleton,img,video': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  '.hd': {
    position: 'absolute',
    bottom: 10,
    right: 10,
    display: 'flex',
    gap: '1rem'
  },
  '.editions': {
    position: 'absolute',
    right: 10,
    top: 10,
    background: '#3D3AE9',
    border: 0,
    padding: '2px'
  },
  '.padded': {
    padding: '1.5rem',
    objectFit: 'contain'
  },
  '.default, .contained': {
    objectFit: 'contain'
  },
  '.covered': {
    objectFit: 'cover'
  },
  '.svgIcons': {
    transform: 'scale(1.8)'
  }
}));

const ImageContainer = ({
  src,
  alt,
  fill = false,
  ratio = 100,
  clickable,
  isLoading,
  onClick,
  onRawClick,
  animation,
  raw_image,
  raw_animation,
  editions,
  className,
  preventEvent = true,
  animation_mime,
  displayTheme = 'default'
}: Props): JSX.Element => {
  const vidRef = useRef<HTMLVideoElement>(null);
  const [imgSrc, setImgSrc] = useState(src);
  const [loading, setLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [showPlayer, setShowPlayer] = useState(true);

  useEffect(() => {
    if (showVideo && vidRef.current) vidRef.current.play();
  }, [showVideo]);

  useEffect(() => {
    setShowPlayer(true);
    setShowVideo(false);
    setShowAudio(false);
    setImgSrc(src);
  }, [src]);

  return (
    <Container
      onClick={(e) => {
        if (preventEvent) e.preventDefault(), onClick && onClick();
      }}
      ratio={ratio}
      style={{ height: fill ? '100%' : 0, cursor: clickable ? 'pointer' : undefined }}
      sx={{ background: (theme) => theme.backgroundBorder }}
    >
      {(loading || isLoading) && <Skeleton variant="rectangular" className="skeleton" />}
      <img
        className={className + ' ' + displayTheme}
        src={imgSrc}
        alt={alt}
        loading="lazy"
        onLoad={() => setLoading(false)}
        style={{
          visibility: loading || isLoading || showVideo ? 'hidden' : 'visible'
        }}
      />
      <div className="hd">
        {animation && showPlayer && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (animation_mime?.includes('video')) setShowVideo(true);
              else if (animation_mime?.includes('audio')) setShowAudio(true);
              else setImgSrc(animation);
              setShowPlayer(false);
            }}
          >
            <SvgIcon className="svgIcons">
              <PlayCircleIconShadow />
            </SvgIcon>
          </IconButton>
        )}
        {(raw_image || raw_animation) && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRawClick?.(raw_animation || raw_image || '');
            }}
          >
            <Explore sx={{ color: (theme) => theme.palette.text.primary, fontSize: 35 }} />
          </IconButton>
        )}
      </div>
      {animation && showVideo && <video ref={vidRef} src={animation} controls />}
      {animation && showAudio && (
        <audio
          style={{ position: 'absolute', top: ' 50%', right: 0, width: '100%' }}
          src={animation}
          controls
          autoPlay
        />
      )}
      {editions && (
        <Chip
          className="editions"
          label={editions}
          sx={{ color: 'white', fontWeight: 700, px: 0 }}
          variant="outlined"
        />
      )}
    </Container>
  );
};

export default ImageContainer;
