import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, styled } from '@mui/material';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { setMode } from 'store/actions';
import { GlobalState } from 'store';
import { useLocalStorage } from 'hooks';
import { Mode } from 'types';

const ModeSwitcherButton = styled(Button)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  borderRadius: '1.4375rem',
  '&:hover': {
    background: theme.palette.background.default,
    transitionDuration: '0.5s'
  }
}));

const ModeSwitcher = () => {
  const [_, setThemeMode] = useLocalStorage<Mode | undefined>('mode', undefined);
  const dispatch = useDispatch();
  const mode = useSelector((global: GlobalState) => global.state.mode);

  //dispatch(setMode.create({ mode: 'light' })
  const onSwitch = (mode: Mode) => {
    setThemeMode(mode);
    dispatch(setMode.create({ mode }));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <ModeSwitcherButton onClick={() => onSwitch('light')}>
        <LightModeOutlinedIcon sx={{ color: (theme) => theme.colors.lightGrey1 }} />
        {mode == 'light' && (
          <Typography variant="body2" color="text.secondary" textTransform={'none'}>
            light
          </Typography>
        )}
      </ModeSwitcherButton>
      <ModeSwitcherButton onClick={() => onSwitch('dark')}>
        <DarkModeOutlinedIcon sx={{ color: (theme) => theme.colors.lightGrey1 }} />
        {mode == 'dark' && (
          <Typography variant="body2" color="text.secondary" textTransform={'none'}>
            Dark
          </Typography>
        )}
      </ModeSwitcherButton>
    </Box>
  );
};

export default ModeSwitcher;
