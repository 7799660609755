// Api
import { useUserRankingQuery } from 'api';
import { DaysFilter, RankingTarget, UserRankingQuery } from 'types/generated';

// Types
import { NumberOrString } from 'types';

type Props = {
  category_tags?: NumberOrString[];
  days_filter: DaysFilter;
  page: number;
  size: number;
};

type Response = {
  data?: UserRankingQuery;
  error?: string | unknown;
  isLoading: boolean;
  isFetching: boolean;
  count: number;
};

const useUserRanking = ({ category_tags, days_filter, page, size }: Props): Response => {
  const { data, error, isLoading, isFetching } = useUserRankingQuery({
    input: {
      days_filter,
      category_tags: category_tags?.map((c) => c.toString()),
      target: RankingTarget.User
    },
    pagination: {
      page,
      size
    }
  });

  return {
    data,
    error: error && 'Failed to load data',
    isLoading,
    isFetching,
    count: data?.ranking.count || 0
  };
};

export default useUserRanking;
