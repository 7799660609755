import { Property } from 'types';
import { AttributesQuery } from 'types/generated';

export const toAttributesQuery = (properties: Property[]): AttributesQuery[] => {
  const attributes: AttributesQuery[] = [];

  properties.forEach((property) => {
    property.attrs.forEach((attr) => {
      if (attr.selected) attributes.push({ trait_type: property.name, value: attr.name });
    });
  });

  return attributes;
};
