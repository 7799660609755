import React, { useEffect, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { NFTOffer, PriceSymbol, StepStatus } from 'types';
import Step from '../Step';
import { Button, Price } from 'components';
import { useAuthorizeBasketOfferMutation } from 'api';
import { useWeb3React } from '@web3-react/core';
import { OfferRequestParam } from './utils';
import { bulkAcceptOffers } from 'services/exchange';
import { OfferTicket } from 'types/generated';
import { Done } from './Done';

type Props = {
  price: number;
  token: PriceSymbol;
  step: number;
  currentStep: number;
  nftOffer: NFTOffer;
  // callback, called once the step has been completed successfully
  complete: () => void;
  // custom offer message
  offerMsg: string;
};

type SignProps = {
  price: number;
  token: PriceSymbol;
  status: StepStatus;
  onSign: () => void;
  msg: string;
};

const Sign = ({ onSign, status, price, token, msg }: SignProps) => {
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        {msg}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {status !== StepStatus.IN_PROGRESS && (
          <Button
            ctx="primary"
            onClick={() => onSign()}
            sx={{ mt: 1, borderRadius: '0.625rem', width: '6rem', marginLeft: '-3.84rem' }}
          >
            <Typography variant="button">Sign</Typography>
          </Button>
        )}
      </Box>
    </Stack>
  );
};

const CompleteSigning = ({
  step,
  currentStep,
  token,
  price,
  complete,
  nftOffer,
  offerMsg
}: Props) => {
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();
  const [authorizeOffer] = useAuthorizeBasketOfferMutation();
  const { account, hooks } = useWeb3React();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
    }
  }, [currentStep]);

  // todo: fix type issues
  const sign = async () => {
    if (!account || !provider) {
      setError('Unauthenticated');
      return;
    }

    try {
      setStatus(StepStatus.IN_PROGRESS);

      const { token_id } = nftOffer;
      const { quantity, offer_id, buyer } = nftOffer.offer;
      const basket = [{ id: offer_id, amount: quantity.toString(), token_id }];

      // get offer ticket
      const authorizeOffer_response = await authorizeOffer({
        input: OfferRequestParam(basket, buyer, account)
      }).unwrap();

      const { offers_tickets, invalidated_uuids } = authorizeOffer_response.authorizeBasket;

      if (invalidated_uuids?.length > 0) {
        throw new Error('Validation failed !');
      }
      await bulkAcceptOffers(provider, offers_tickets as OfferTicket[]);

      setStatus(StepStatus.DONE);
      complete();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Acceptation failed !');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={
        status == StepStatus.DONE ? <Done value={price}/> : 'Accept Offer'
      }
      step={step}
      currentStep={currentStep}
      status={status}
      el={
        status == StepStatus.STARTED || status == StepStatus.IN_PROGRESS ? (
          <Sign price={price} token={token} status={status} onSign={sign} msg={offerMsg} />
        ) : undefined
      }
      error={error}
    />
  );
};

export default CompleteSigning;
