import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton, Paper, styled } from '@mui/material';
import { Avatar, HtmlTooltip, ModeSwitcher } from 'components';
import { BaseLink, StepStatus } from 'types';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'constants/routes';
import { useWeb3React } from '@web3-react/core';
import { useLoginMutation, useLoginNonceMutation, useLogoutMutation, useUserQuery } from 'api';
import config from 'config';
import { useLocalStorage } from 'hooks';
import { LoginMutation } from 'types/generated';
import { useAuth } from 'providers';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import { verifyOwnershipSignature } from 'services/exchange';

const items: BaseLink[] = [
  {
    page: 'My Dashboard',
    link: AppRoutes.dashboard
  },
  { page: 'My Collectibles', link: AppRoutes.my_collectbles },
  { page: 'Create', link: AppRoutes.create },
  { page: 'Transfer', link: AppRoutes.transfer },
  { page: 'Settings', link: AppRoutes.settings }
];

const Container = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.1rem',
  background:
    theme.palette.mode == 'dark' ? theme.input.background : theme.palette.background.default,
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;',
  borderRadius: '0.5rem'
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  '&:hover': {
    background: theme.palette.action.hover,
    transitionDuration: '0.5s'
  }
}));

const renderer = (item: BaseLink, goTo: () => void) => {
  return (
    <BoxWrapper
      onClick={() => goTo()}
      key={item.page}
      sx={{
        paddingX: '1rem',
        paddingY: '0.5rem',
        cursor: 'pointer',
        alignItems: 'center',
        borderRadius: '0.5rem',
        color: (theme) => theme.palette.text.secondary,
        ':hover': { color: (theme) => theme.palette.text.primary }
      }}
    >
      <Typography variant="body1">{item.page}</Typography>
    </BoxWrapper>
  );
};

const listRenderer = (
  name: string | undefined,
  goTo: (link: string) => void,
  close: () => void,
  complete: () => void
) => {
  const dispatch = useDispatch();
  const { hooks, account } = useWeb3React();
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();
  const { usePriorityConnector } = hooks;
  const [_, setLastconnector] = useLocalStorage<string | undefined>('last-connector', undefined);
  const [logoutSession] = useLogoutMutation();
  const connector = usePriorityConnector();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();
  const { loggedIn, setLoggedIn } = useAuth();
  // Login
  const [login] = useLoginMutation();
  const [loginNonce] = useLoginNonceMutation();
  
  const logout = () => {
    connector?.deactivate();
    setLastconnector(undefined);
    logoutSession({})
      .unwrap()
      .catch(() => {
        console.error('logout failed!');
      });
  };
  const sign = async () => {
    try {
      if (!provider || !account) {
        dispatch(openModal.create({ modal: 'WALLET' }));
        return;
      }
      setStatus(StepStatus.IN_PROGRESS);

      const nonce = await loginNonce({ address: account }).unwrap();
      if (!nonce?.loginNonce) throw new Error('Auth failed !');
      // Sign using nonce sent by the backend
      const signature = await verifyOwnershipSignature(provider, nonce.loginNonce);
      // Login
      await login({ address: account, nonce: nonce.loginNonce, signature })
        .unwrap()
        .then((res: LoginMutation) => {
          if (res.login) {
            setStatus(StepStatus.DONE);
            setLoggedIn(true);
            complete();
          } else {
            throw new Error('Auth failed !');
          }
        })
        .catch(() => {
          throw new Error('Auth failed !');
        });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Auth failed !');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    account ? (
      <Container elevation={1} sx={{ p: '1rem' }} onMouseLeave={close}>
          {name && (
            <Typography variant="body1" color="text.primary" sx={{ paddingX: '1rem', paddingY: '0.5rem', fontWeight: 700 }}>
              {name}
            </Typography>
          )}
          {items.map((item) => renderer(item, () => goTo(item.link)))}
          <BoxWrapper
            onClick={logout}
            sx={{
              paddingX: '1rem',
              paddingY: '0.5rem',
              cursor: 'pointer',
              alignItems: 'center',
              borderRadius: '0.5rem',
              color: (theme) => theme.palette.text.secondary,
              ':hover': { color: (theme) => theme.palette.text.primary }
            }}
          >
            <Typography variant="body1">Log out</Typography>
          </BoxWrapper>
          <ModeSwitcher />
      </Container>
    ) : (
      <Container elevation={1} sx={{ p: '1rem' }} onMouseLeave={close}>
          <BoxWrapper
            onClick={sign}
            sx={{
              paddingX: '1rem',
              paddingY: '0.5rem',
              cursor: 'pointer',
              alignItems: 'center',
              borderRadius: '0.5rem',
              color: (theme) => theme.palette.text.secondary,
              ':hover': { color: (theme) => theme.palette.text.primary }
            }}
          >
            <Typography variant="body1">Log in</Typography>
          </BoxWrapper>
          <ModeSwitcher />
      </Container>
    )
  );
};

export const Profile = (): JSX.Element => {
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const { data } = useUserQuery({ address: account || '' }, { skip: !account });
  const [open, setOpen] = useState(false);
  const openTooltip = () => !open && setOpen(true);
  const avatar = data?.user.avatar || config.default_avatar;
  const name = data?.user.name || '';

  const goTo = (link: string) => navigate(link);

  return (
    <Box onMouseLeave={() => setOpen(false)}>
      <IconButton>
        <HtmlTooltip
          arrow={true}
          sx={{
            '& .MuiTooltip-arrow': {
              color: (theme) => theme.input.background
            }
          }}
          placement="bottom-end"
          open={open}
          title={listRenderer(
            name,
            (link) => goTo(link),
            () => setOpen(false),
            () => void(0)
          )}
        >
          <div>
            <Avatar src={avatar} onMouseOver={openTooltip} />
          </div>
        </HtmlTooltip>
      </IconButton>
    </Box>
  );
};
