import React from 'react';
import { AddressRouteParams, Property } from 'types';
import { VirtuosoGrid } from 'react-virtuoso';
import { VContainer, VList } from 'helpers/virtuosoHelpers';
import { useNavigate, useParams } from 'react-router-dom';

// Context
import { useAuth, useFilters, useResize } from 'providers';

// Hooks
import { useNFTs } from 'hooks';

// Types
import { AttributesQuery, SearchTarget } from 'types/generated';
import { AppRoutes } from 'constants/routes';
import { initNftWithSale, InnerItem } from 'helpers/nfts';
import { useLikedAssetsQuery, useLikeMutation } from 'api';

export const goToNft = (assetId: string) => `/${AppRoutes.nft.replace(':address', assetId)}`;

const SIZE = 30;
const INIT_NFTS = [...Array(SIZE).keys()].map(() => initNftWithSale);

const toAttributes = (properties: Property[]): AttributesQuery[] => {
  const attributes: AttributesQuery[] = [];
  properties.forEach((property) => {
    property.attrs.forEach((attr) => {
      if (attr.selected) attributes.push({ trait_type: property.name, value: attr.name });
    });
  });

  return attributes;
};

export const Nfts = () => {
  const navigate = useNavigate();
  const { address } = useParams<AddressRouteParams>();
  const { loggedIn } = useAuth();
  const { data: likedAssets } = useLikedAssetsQuery({}, { skip: !loggedIn });
  const [like] = useLikeMutation();
  const { config } = useResize();
  const { categories, keywords, salesType, price, sortBy, properties } = useFilters();
  const { nfts, isLoading, loadMore, updateLikes } = useNFTs({
    target: SearchTarget.Nfts,
    collectionAddress: address,
    sortBy,
    keywords,
    categories,
    salesType,
    attributes: toAttributes(properties),
    price,
    size: SIZE,
    skip: !address
  });

  const onLike = (assetId: string, likes: number) => {
    updateLikes(assetId, likedAssets?.[assetId] ? --likes : ++likes);
    like({ assetId, value: !likedAssets?.[assetId] }).unwrap();
  };

  const itemContent = (index: number) => {
    const nft = isLoading ? INIT_NFTS[index] : nfts[index];
    return (
      nft && (
        <InnerItem
          hideAvatar={true}
          nft={nft}
          goTo={() => navigate(goToNft(nft.asset_id))}
          liked={likedAssets?.[nft.asset_id]}
          onLike={() => onLike(nft.asset_id, nft.likes)}
          isLoading={isLoading}
        />
      )
    );
  };

  return (
    <VirtuosoGrid
      useWindowScroll={true}
      totalCount={isLoading ? SIZE : nfts.length}
      endReached={loadMore}
      overscan={{ main: 200, reverse: 200 }}
      components={{
        Item: VContainer(config),
        List: VList
      }}
      itemContent={itemContent}
    />
  );
};
