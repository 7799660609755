// 3rd party libs
import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';

type Props = {
  children: React.ReactNode;
} & BoxProps;

const ResponsiveContainer = styled(Box)(({ theme }) => ({
  margin: '2% 4%',
  [theme.breakpoints.up('md')]: {
    // Match [md, ∞)
    //       [900px, ∞)
    margin: '2% 6%'
  },
  [theme.breakpoints.up('xl')]: {
    // Match [lg, ∞)
    //       [1200px, ∞)
    margin: '2% 10%'
  }
}));

const Container = ({ children, ...rest }: Props) => {
  return <ResponsiveContainer {...rest}>{children}</ResponsiveContainer>;
};

export default Container;
