import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useCheckSessionMutation } from 'api';
import { CheckSessionMutation } from 'types/generated';

type Context = {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
};

const defaultValue = {
  loggedIn: false,
  setLoggedIn: () => undefined
};

const AuthContext = createContext<Context>(defaultValue);

export const useAuth = (): Context => {
  return useContext(AuthContext);
};

export const AuthProvider = (props: { children: ReactNode }): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const { account } = useWeb3React();
  // Check whether session is still active or not
  const [checkSession] = useCheckSessionMutation();

  useEffect(() => {
    if (account) {
      checkSession({ address: account })
        .unwrap()
        .then((response: CheckSessionMutation) => {
          const isLoggedIn = response.checkSessionContext;
          setLoggedIn(isLoggedIn);
        });
    } else setLoggedIn(false);
  }, [account]);

  const value = {
    loggedIn,
    setLoggedIn
  };
  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};
