import React, { useEffect, useState } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import {
  Approve,
  DropDownInput,
  Button,
  DatePicker,
  ProcessBid,
  NumberInput,
  Price
} from 'components';
import { Bid, NFTOffer, NumberOrString, PriceSymbol } from 'types';
import { Renderer } from '../Renderer';
import { NftPreview, OfferKind, OfferSide, OfferStatus } from 'types/generated';
import { getTokenOptions } from 'constants/options';
import { useAssets } from 'hooks';
import { batch, openModal, setPrevious } from 'store/actions';

type OfferProps = {
  confirmOffer: (offer: Bid, nftOffer: NFTOffer) => void;
  nft: NftPreview & { editions: string };
};

const validate = (
  price: NumberOrString | undefined,
  qty: NumberOrString | undefined,
  max: number
) => {
  const err: Record<string, string> = {};
  if (!price) err['price'] = 'Enter price';
  if (Number(qty) > max) err['quantity'] = `Max quantity ${max}`;
  return { err };
};

const OfferForm = ({ confirmOffer, nft }: OfferProps) => {
  const dispatch = useDispatch();
  const [price, setPrice] = useState<NumberOrString>();
  const [quantity, setQuantity] = useState<NumberOrString>('1');
  const [expirationDate, setExpirationDate] = React.useState<Date>(new Date());
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const { wavax } = useAssets();

  const onConfirmOffer = () => {
    const { err } = validate(price, quantity, Number(nft.editions));
    if (Object.keys(err).length !== 0) setErrors(err);
    else {
      const nftOffer: NFTOffer = {
        ...nft,
        offer: {
          bought_quantity: quantity.toString(),
          buyer: '',
          expiration_date: expirationDate.getTime(),
          floor_diff: '',
          kind: OfferKind.Nft,
          merkle_root: '',
          offer_id: '',
          payment_token: '',
          quantity: max.toString(),
          side: OfferSide.Sent,
          start_date: 0,
          status: OfferStatus.Live,
          total_quantity: max.toString(),
          unitary_price: '',
          unitary_price_float: price as number
        }
      };
      confirmOffer(
        {
          type: 'nft',
          price: Number(price),
          quantity: quantity as number,
          expirationDate: expirationDate,
          token_id: nft.token_id,
          collection: {
            address: nft.collection.address,
            avatar: nft.collection.avatar,
            name: nft.name
          }
        },
        nftOffer
      );
    }
  };

  const convertAvax = () => {
    dispatch(
      batch.create({
        actions: [
          setPrevious.create({ previous: 'MAKE OFFER' }),
          openModal.create({ modal: 'SWAP' })
        ]
      })
    );
  };

  const erc1155 = nft.collection.kind == 'erc1155';
  const max = nft.editions || 1;

  return (
    <Stack spacing={2}>
      <Stack spacing={3} sx={{ my: 2 }}>
        <Stack spacing={1}>
          <Typography variant="body2" color="text.secondary" textAlign="start">
            Price
          </Typography>
          <DropDownInput
            placeholder="Enter price"
            value={price}
            numeric={true}
            onValueChange={(v) => {
              if (errors['price']) setErrors({ ...errors, ['price']: undefined });
              setPrice(v);
            }}
            defaultKey={'WAVAX'}
            onOptionChange={(v) => undefined}
            options={getTokenOptions(['WAVAX'])}
            error={errors['price'] !== undefined}
          />
          {errors['price'] && (
            <Typography variant="caption" color="red">
              {errors['price']}
            </Typography>
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="caption" color="text.secondary">
              Balance:
            </Typography>
            <Price value={wavax} symbol={PriceSymbol.WAVAX} size={10} />
          </Stack>
        </Stack>
        {erc1155 && (
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary" textAlign="start">
              Quantity {`${quantity || 1}/${max}`}
            </Typography>
            <NumberInput
              sx={{ width: '100%', borderRadius: '0.5rem', height: '3.5rem' }}
              value={quantity || 1}
              step={1}
              onChange={(value) => {
                if (errors['quantity']) setErrors({ ...errors, ['quantity']: undefined });
                setQuantity(value as number);
              }}
              error={errors['quantity'] !== undefined}
            />
            <Typography variant="caption" color="red">
              {errors['quantity']}
            </Typography>
          </Stack>
        )}
        <Stack spacing={1}>
          <Typography variant="body2" color="text.secondary" textAlign="start">
            Expiration
          </Typography>
          <DatePicker
            defaultKey="30"
            date={expirationDate}
            onChange={(d) => {
              if (d) setExpirationDate(d);
            }}
          />
        </Stack>
      </Stack>

      <Stack>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button ctx="primary" onClick={onConfirmOffer}>
            <Typography variant="button">Make Offer</Typography>
          </Button>
          <Button ctx="secondary" onClick={convertAvax}>
            <Typography variant="button">Convert AVAX</Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

const MakeOffer = () => {
  const nft = useSelector((global: GlobalState) => global.state.nft);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [confirmOffer, setConfirmedOffer] = useState<Bid>();
  const [nftOffer, setNftOffer] = useState<NFTOffer>();

  useEffect(() => {
    setCurrentStep(1);
  }, []);

  const onConfirmOffer = (offer: Bid, nftOffer: NFTOffer) => {
    setConfirmedOffer(offer);
    setNftOffer(nftOffer);
  };

  return (
    //todo: pass bid to renderer
    <Renderer title="Make Offer" nft={!nftOffer ? nft : undefined} nftOffer={nftOffer}>
      {!confirmOffer && nft && <OfferForm confirmOffer={onConfirmOffer} nft={nft} />}
      {/** Steps */}
      {confirmOffer && (
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Divider sx={{ my: 3 }} variant="middle" />
          <Approve
            bid={confirmOffer}
            token={PriceSymbol.WAVAX}
            step={1}
            currentStep={currentStep}
            complete={() => setCurrentStep(2)}
          />
          <ProcessBid
            step={2}
            currentStep={currentStep}
            complete={() => undefined}
            token={PriceSymbol.WAVAX}
            bid={confirmOffer}
          />
        </Stack>
      )}
    </Renderer>
  );
};

export default MakeOffer;
