import * as React from 'react';
import { SvgIcon, useTheme } from '@mui/material';
import { ReactComponent as Dark } from '../../assets/header-top/logo-dark.svg';
import { ReactComponent as Light } from '../../assets/header-top/logo-light.svg';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'constants/routes';

type Props = {
  clickable?: boolean;
};

const Logo = ({ clickable = true }: Props): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDark = theme.palette.mode == 'dark';
  return (
    <SvgIcon
      sx={{ cursor: clickable ? 'pointer' : 'default', width: '2rem', height: '2rem', mr: 1 }}
      onClick={() => clickable && navigate(AppRoutes.home)}
    >
      {isDark ? <Dark width="100%" height="100%" /> : <Light width="100%" height="100%" />}
    </SvgIcon>
  );
};

export default Logo;
