// 3rd party libs
import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, IconButtonProps } from '@mui/material';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: theme.border,
  borderRadius: 8,
  '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
    borderRadius: 8
  },
  '& > *': { color: theme.palette.text.secondary }
}));

const SquareIconButton = ({ children, ...rest }: IconButtonProps) => {
  return <StyledIconButton {...rest}>{children}</StyledIconButton>;
};

export default SquareIconButton;
