/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { CollectionRanking, SortClickHandler, SortState } from 'types';
import { Stack, Typography, Box, styled, Chip, Skeleton } from '@mui/material';
import { Avatar, Chart, Price } from 'components';
import { compact, priceFormatter } from 'lib/format';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type Props = {
  ranking: CollectionRanking;
  homePage?: boolean;
  goTo: () => void;
};

type HeasersProps = {
  sort: Record<string, SortState>;
  hide: boolean;
  click: SortClickHandler;
};

const StyledRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '4.5rem',
  padding: theme.spacing(1),
  borderRadius: '0.5rem',
  ':hover': { background: theme.palette.action.hover }
}));

const StyledMobileRow = styled(Stack)(({ theme }) => ({
  borderRadius: '0.5rem',
  ':hover': { background: theme.palette.action.hover }
}));

const StyledMobileCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  borderRadius: '0.5rem',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  height: '100%',
  border: theme.border
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '4.5rem',
  padding: theme.spacing(1),
  borderRadius: '0.5rem'
}));

const StyledCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0.5),
  height: '100%'
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  border: theme.border,
  color: theme.palette.text.secondary,
  padding: theme.spacing(2, 1),
  width: 'fit-content'
}));

const Key = (caption: string) => {
  return (
    <Typography variant="caption" color="text.secondary">
      {caption}
    </Typography>
  );
};

export const RenderRow = ({ ranking, homePage, goTo }: Props) => {
  const {
    rank,
    avatar,
    address,
    certified,
    name,
    volume,
    floor_price,
    average_price,
    total_owners,
    supply,
    series
  } = ranking;

  return (
    <StyledRow sx={{ cursor: 'pointer' }} onClick={goTo}>
      <StyledCell sx={{ flexDirection: 'row', justifyContent: 'center', flex: 0.5 }}>
        <Typography variant="body2">{rank}</Typography>
      </StyledCell>
      <StyledCell
        sx={{ flexDirection: 'row', gap: 1, alignItems: 'center', flex: 1.5, overflow: 'hidden' }}
      >
        <Avatar
          src={avatar}
          approved={certified}
          sx={{ width: '3.125rem', height: '3.125rem' }}
          top={20}
        />
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {name}
        </Typography>
      </StyledCell>
      <StyledCell sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        {homePage && Key('Volume')}
        <Price value={priceFormatter.format(volume)} size={10} />
      </StyledCell>
      <StyledCell sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        {homePage && Key('Floor')}
        <Price value={priceFormatter.format(floor_price)} size={10} />
      </StyledCell>
      <StyledCell sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        {homePage && Key('Av.Price')}
        <Price value={priceFormatter.format(average_price)} size={10} />
      </StyledCell>
      <StyledCell sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        {homePage && Key('Owners')}
        <Typography variant="body2"> {compact.format(total_owners)}</Typography>
      </StyledCell>
      <StyledCell sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        {homePage && Key('Items')}
        <Typography variant="body2"> {compact.format(supply)}</Typography>
      </StyledCell>
      <StyledCell sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        <div style={{ height: '50px', width: '100%' }}>
          <Chart
            displayXaxis={false}
            enableTooltip={false}
            lineOnHover={false}
            data={{
              datasets: [
                {
                  type: 'line',
                  data: series,
                  borderColor: '#26B45D',
                  fill: true,
                  tension: 0.3,
                  pointRadius: 0,
                  pointHoverBackgroundColor: 'transparent',
                  pointHoverBorderColor: 'transparent'
                }
              ]
            }}
          />
        </div>
      </StyledCell>
    </StyledRow>
  );
};

export const RenderMobileRow = ({ ranking, goTo }: Props) => {
  const { rank, avatar, certified, name, volume, floor_price, average_price } = ranking;

  return (
    <StyledMobileRow>
      <StyledRow>
        <StyledCell sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant="body2">{rank}</Typography>
        </StyledCell>
        <StyledCell
          sx={{ flexDirection: 'row', gap: 1, alignItems: 'center', flex: 2, overflow: 'hidden' }}
        >
          <Avatar
            src={avatar}
            approved={certified}
            sx={{ width: '3.125rem', height: '3.125rem', cursor: 'pointer' }}
            top={20}
            onClick={goTo}
          />
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {name}
          </Typography>
        </StyledCell>
      </StyledRow>
      <StyledRow sx={{ gap: 1 }}>
        <StyledMobileCell>
          <Price value={priceFormatter.format(volume)} size={10} />
        </StyledMobileCell>
        <StyledMobileCell>
          <Price value={priceFormatter.format(floor_price)} size={10} />
        </StyledMobileCell>
        <StyledMobileCell>
          <Price value={priceFormatter.format(average_price)} size={10} />
        </StyledMobileCell>
      </StyledRow>
    </StyledMobileRow>
  );
};

export const skeletonRow = (mobile: boolean) => {
  return mobile ? (
    <StyledMobileRow>
      <StyledRow>
        <StyledCell sx={{ flex: 1 }}>
          <Skeleton width={'100%'} />
        </StyledCell>
      </StyledRow>
      <StyledRow sx={{ gap: 1 }}>
        <StyledMobileCell>
          <Skeleton width={'100%'} />
        </StyledMobileCell>
        <StyledMobileCell>
          <Skeleton width={'100%'} />
        </StyledMobileCell>
        <StyledMobileCell>
          <Skeleton width={'100%'} />
        </StyledMobileCell>
      </StyledRow>
    </StyledMobileRow>
  ) : (
    <StyledRow>
      <StyledCell sx={{ flex: 0.5 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 1.5 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 1 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 1 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 1 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 1 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 1 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 1 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
    </StyledRow>
  );
};

export const Headers = ({ sort, click, hide }: HeasersProps) => {
  if (hide) return null;
  const chip = (header: string, key: keyof CollectionRanking) => {
    const sortable = !!sort[key];
    return (
      <StyledChip
        deleteIcon={
          sortable ? (
            sort[key] == SortState.ascending ? (
              <ArrowDropUpIcon />
            ) : sort[key] == SortState.descending ? (
              <ArrowDropDownIcon />
            ) : (
              <></>
            )
          ) : (
            <></>
          )
        }
        onClick={() => sortable && click(key, sort[key])}
        onDelete={() => sortable && click(key, sort[key])}
        label={<Typography variant="body2">{header}</Typography>}
        variant="outlined"
        clickable={sortable}
      />
    );
  };

  return (
    <StyledHeader>
      <StyledCell sx={{ flex: 0.5, flexDirection: 'row', justifyContent: 'center' }}>
        {chip('Rank', 'rank')}
      </StyledCell>
      <StyledCell sx={{ flex: 1.5, flexDirection: 'row' }}>
        {chip('Collections', 'name')}
      </StyledCell>
      <StyledCell sx={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        {chip('Volume', 'volume')}
      </StyledCell>
      <StyledCell sx={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        {chip('Floor', 'floor_price')}
      </StyledCell>
      <StyledCell sx={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        {chip('Av.Price', 'average_price')}
      </StyledCell>
      <StyledCell sx={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        {chip('Owners', 'total_owners')}
      </StyledCell>
      <StyledCell sx={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        {chip('Supply', 'supply')}
      </StyledCell>
      <StyledCell sx={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        {chip('Graph', 'series')}
      </StyledCell>
    </StyledHeader>
  );
};
export const toDataset = (
  type: string,
  label: string,
  data: number[],
  fill = true,
  color = '#1f78c1',
  backgroundColor?: string
) => {
  return {
    type,
    label,
    data,
    borderColor: color,
    backgroundColor,
    fill: fill,
    tension: 0.3
  };
};
