// Api
import { useLazyActivitiesQuery } from 'api';
import { useEffect, useRef, useState } from 'react';
import { EnhancedActivity } from 'types';

// Types
import { ActivityResult, ActivityTarget, ActivityType, DaysFilter } from 'types/generated';

/**
 * Hook to query Activities
 */

type Props = {
  // page size (paging offset)
  size: number;
  asset_id?: string;
  collection_address?: string;
  days_filter?: DaysFilter;
  target: ActivityTarget;
  types: ActivityType[];
  user_address?: string;
  // Skip if true
  skip: boolean;
};

type Response = {
  activities: EnhancedActivity[];
  error?: string | unknown;
  isLoading: boolean;
  isFetching: boolean;
  loadMore: () => void;
};

export const toActivity = (activities?: ActivityResult[]): EnhancedActivity[] => {
  if (!activities) return [];
  return activities?.map((a) => {
    const name =
      a.type == ActivityType.CollectionOffer ? 'On Collection' : a.nft ? a.nft?.name : '';
    return { ...a, name };
  });
};

const useActivities = ({
  asset_id,
  collection_address,
  days_filter,
  target,
  types,
  user_address,
  skip,
  size
}: Props): Response => {
  const hasMore = useRef(true);
  const shouldReset = useRef(true);
  const page = useRef(1);
  const [search, result] = useLazyActivitiesQuery();
  const [activities, setActivities] = useState<EnhancedActivity[]>([]);

  const trigger = () =>
    search({
      input: {
        asset_id,
        collection_address,
        days_filter,
        target,
        types,
        user_address
      },
      pagination: {
        page: page.current++,
        size
      }
    });

  useEffect(() => {
    if (!skip) trigger();
  }, []);

  useEffect(() => {
    shouldReset.current = true;
    hasMore.current = true;
    page.current = 1;
    trigger();
  }, [
    target,
    asset_id,
    collection_address,
    days_filter,
    target,
    JSON.stringify(types),
    user_address
  ]);

  useEffect(() => {
    if (!result.isSuccess) return;
    if (shouldReset.current) {
      shouldReset.current = false;
      hasMore.current = result.data.activity.hasMore;
      setActivities(toActivity(result.data.activity.activities as ActivityResult[]));
    } else {
      hasMore.current = result.data.activity.hasMore;
      setActivities([
        ...activities,
        ...toActivity(result.data.activity.activities as ActivityResult[])
      ]);
    }
  }, [result.data]);

  const loadMore = () => {
    if (hasMore.current) trigger();
    trigger();
  };

  return {
    activities,
    error: result.error && 'Failed to load activities',
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    loadMore
  };
};

export default useActivities;
