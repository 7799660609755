import { Property } from 'types';
import {
  Collection,
  CollectionQuery,
  CollectionStats,
  NotificationSettingsQuery,
  ProfileQuery,
  UserNotificationSettings,
  UserProfile,
  UserQuery
} from 'types/generated';

const MAX_ATTRIBUTES = 100;

// convert attributes_distributions to properties 'Property[]'
export const getProperties = (data?: CollectionQuery): Property[] => {
  const properties: Property[] = [];
  data?.collection.attributes_distributions?.forEach((attr) => {
    properties.push({
      name: attr.trait_type,
      attrs:
        attr.values?.slice(0, MAX_ATTRIBUTES).map((value, i) => {
          return {
            name: value,
            count: (attr?.counts && attr.counts[i]) || 0,
            selected: false
          };
        }) || []
    });
  });
  return properties;
};

/**
 * Process useCollectionQuery response
 */
export const processCollectionQueryResponse = (
  data?: CollectionQuery
): {
  collection: Collection;
  stats: CollectionStats;
} => {
  const collection: Collection = {
    name: '',
    address: '',
    avatar: '',
    certified: false,
    thumbnails: [],
    ...data?.collection.collection
  };

  const stats: CollectionStats = {
    total_minted: '0',
    total_owners: 0,
    in_sale: 0,
    sold: 0,
    volume: 0,
    weekly_volume: 0,
    floor_price: 0,
    highest_price: 0,
    average_price: 0,
    average_royalties_percent: 0,
    ...data?.collection.stats
  };

  return {
    collection,
    stats
  };
};

/**
 * Process useUserQuery response
 */
export const processUserQueryResponse = (
  data?: UserQuery
): {
  user: UserProfile;
} => {
  const user: UserProfile = {
    address: '',
    name: '',
    login: '',
    description: '',
    mail: '',
    avatar: '',
    cover: '',
    twitter: '',
    facebook: '',
    instagram: '',
    telegram: '',
    website: '',
    youtube: '',
    discord: '',
    certified: false,
    metadata: {
      followers: 0,
      following: 0,
      liked_nfts: 0,
      owned_nfts: 0
    },
    stats: {
      amount_traded: '',
      available_offers: '',
      purchases: '',
      ratio_purchase_sale: '',
      sales: '',
      wallet_value: ''
    },
    ...data?.user
  };

  return { user };
};

/**
 * Process useProfileQuery response
 */
export const processProfileQueryResponse = (
  data?: ProfileQuery
): {
  user: UserProfile;
} => {
  const user: UserProfile = {
    address: '',
    name: '',
    login: '',
    description: '',
    mail: '',
    avatar: '',
    cover: '',
    twitter: '',
    facebook: '',
    instagram: '',
    telegram: '',
    website: '',
    youtube: '',
    discord: '',
    certified: false,
    metadata: {
      followers: 0,
      following: 0,
      liked_nfts: 0,
      owned_nfts: 0
    },
    stats: {
      amount_traded: '',
      available_offers: '',
      purchases: '',
      ratio_purchase_sale: '',
      sales: '',
      wallet_value: ''
    },
    ...data?.profile
  };

  return { user };
};

/**
 * Process useNotificationSettingsQuery response
 */
export const processNotificationSettingsQuery = (
  data?: NotificationSettingsQuery
): {
  notificationSettings: UserNotificationSettings;
} => {
  const notificationSettings: UserNotificationSettings = {
    on_website: true,
    by_email: false,
    by_telegram: false,
    global_offer_threshold: 0,
    collection_settings: [],
    events_kinds: {
      auction_expiration: false,
      bid_received: false,
      item_sold: false,
      newsletter: false,
      offer_accepted: false,
      offer_received: false,
      outbid_received: false
    },
    ...data?.notificationSettings
  };

  return { notificationSettings };
};
