import React from 'react';
import { Stack, Typography, styled, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SquareIconButton, Avatar } from 'components';
import { FileUpload } from 'types';
import { useDropzone } from 'react-dropzone';

const AvatarContainer = styled(Box)(({ theme }) => ({
  background: theme.input.background,
  height: '6rem',
  width: '6rem',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

type Props = {
  file: FileUpload | undefined;
  setFile: (file: FileUpload) => void;
};

const CollectionAvatar = ({ file, setFile }: Props) => {
  const { open, getRootProps, getInputProps } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/*': []
    },
    onDrop: (files) => {
      const { lastModified, type, name, size } = files[0];
      setFile({
        lastModified,
        type,
        name,
        size,
        fileToUpload: files[0],
        preview: URL.createObjectURL(files[0])
      });
    }
  });

  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary" textAlign="center">
        Avatar
      </Typography>
      <AvatarContainer {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {file?.preview ? (
          <Avatar src={file.preview} onClick={open} sx={{ height: '6rem', width: '6rem' }} />
        ) : (
          <SquareIconButton size="small" onClick={open}>
            <AddIcon />
          </SquareIconButton>
        )}
      </AvatarContainer>
      <Typography
        variant="caption"
        color="text.secondary"
        textAlign="center"
        sx={{ maxWidth: '6rem' }}
      >
        JPG, PNG or GIF. Max 30MB
      </Typography>
    </Stack>
  );
};

export default CollectionAvatar;
