import * as React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Chip, styled } from '@mui/material';
import { useAuth } from 'providers';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { batch, openModal } from 'store/actions';

type Props = {
  likes?: number;
  liked?: boolean;
  onLike?: () => void;
};

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.secondary,
  background: 'transparent',
  cursor: 'pointer'
}));

const Like = ({ likes, liked, onLike }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const { loggedIn } = useAuth();

  const handleClick = () => {
    if (!account) {
      dispatch(batch.create({ actions: [] }));
    } else if (!loggedIn) {
      dispatch(openModal.create({ modal: 'AUTH' }));
    } else onLike?.();
  };

  return (
    <StyledChip
      onClick={handleClick}
      size="small"
      icon={<FavoriteIcon style={{ color: liked ? 'red' : undefined }} />}
      label={likes}
    />
  );
};

export default Like;
