import React, { useState } from 'react';
import { DropDownElement, Price, PriceRange } from 'components';
import { Typography, Box, styled, BoxProps } from '@mui/material';
import { NumberOrString, PriceRangeType } from 'types';

type Props = {
  range: PriceRangeType;
  setPriceRange: (range: PriceRangeType) => void;
} & BoxProps;

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  '.price': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.3rem'
  }
});

export const DropDownPriceRange = ({ range, setPriceRange, ...props }: Props) => {
  const [open, setOpen] = useState(false);

  const valueRenderer = () => {
    const { min, max } = range;
    if (!min && !max)
      return (
        <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
          All price range
        </Typography>
      );
    else {
      return (
        <Container sx={{ ml: 1, flex: 1 }}>
          {min && renderRange('From', min)}
          {max && renderRange('To', max)}
        </Container>
      );
    }
  };

  const renderRange = (label: string, price: NumberOrString) => {
    return (
      <div className="price">
        <Typography variant="body2" color="text.primary">
          {label}
        </Typography>
        <Price value={price} />
      </div>
    );
  };

  return (
    <DropDownElement
      element={
        <PriceRange
          onClick={(e) => e.stopPropagation()}
          range={range}
          apply={(price) => {
            setPriceRange(price || {});
            setOpen(false);
          }}
          reset={() => setPriceRange({})}
          sx={{ maxWidth: '20rem' }}
        />
      }
      valueRenderer={valueRenderer()}
      {...props}
    />
  );
};
