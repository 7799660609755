// 3rd party libs
import React, { useState } from 'react';
import { Grid, Stack, useMediaQuery } from '@mui/material';
import { toCollectionsRanking } from 'helpers/rankingHelpers';
import { useCollectionRanking } from 'hooks';
import { CollectionRanking, NumberOrString } from 'types';
import { StyledBox, StyledGrid, NameRenderer, RankedAvatar, PriceRenderer } from '../utils';
import { DropDownSelect } from 'components';
import { days_filter_options } from 'constants/options';
import { DaysFilter } from 'types/generated';
import { goToCollection } from 'helpers/collections';
import { useNavigate } from 'react-router-dom';
import { formatAddress } from 'helpers/formatAddress';
import { theme } from 'themes';

type Props = {
  ranking: CollectionRanking;
  loading: boolean;
  goTo: () => void;
};

const initRankings: CollectionRanking[] = [...Array(18).keys()].map(() => {
  return {
    rank: 0,
    avatar: '',
    address: '',
    certified: false,
    name: '',
    volume: 0,
    floor_price: 0,
    average_price: 0,
    total_owners: 0,
    supply: 0,
    thumbnails: [],
    series: []
  };
});

const Renderer = ({ ranking, loading, goTo }: Props) => {
  const { rank, avatar, certified, name, address, floor_price, volume, average_price } = ranking;
  const format_name = name && name != 'anonymous' ? name : formatAddress(address);
  return (
    <StyledBox onClick={goTo}>
      <RankedAvatar rank={rank} avatar={avatar} certified={certified} loading={loading} />
      <Stack sx={{ p: '1rem', minWidth: 0, flex: 3 }} justifyContent="center">
        <NameRenderer
          name={format_name}
          loading={loading}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        />
        <PriceRenderer caption="Floor price" value={floor_price} loading={loading} />
      </Stack>
      <Stack sx={{ p: '1rem', flex: 1 }} alignItems="flex-end">
        <PriceRenderer caption="Volume" value={volume} loading={loading} />
        <PriceRenderer caption="Av.price" value={average_price} loading={loading} />
      </Stack>
    </StyledBox>
  );
};

const RendererMobile = ({ ranking, loading, goTo }: Props) => {
  const { rank, avatar, certified, name, address, floor_price, volume, average_price } = ranking;
  const format_name = name && name != 'anonymous' ? name : formatAddress(address);
  return (
    <StyledBox onClick={goTo}>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Stack direction="row" alignItems="center">
          <RankedAvatar rank={rank} avatar={avatar} certified={certified} loading={loading} />
          <NameRenderer
            name={format_name}
            loading={loading}
            sx={{ ml: 1.5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          />
        </Stack>
        <Stack
          direction="row"
          sx={{ p: '1rem' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <PriceRenderer caption="Floor price" value={floor_price} loading={loading} />
          <PriceRenderer caption="Volume" value={volume} loading={loading} />
          <PriceRenderer caption="Av.price" value={average_price} loading={loading} />
        </Stack>
      </Stack>
    </StyledBox>
  );
};

export const Collections = () => {
  const navigate = useNavigate();
  const [daysFilter, setDaysFilter] = useState<NumberOrString>('');

  const { data, isFetching } = useCollectionRanking({
    days_filter: (daysFilter as DaysFilter) || DaysFilter.All,
    page: 1,
    size: 18
  });

  const rankings = isFetching ? initRankings : toCollectionsRanking(data);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Stack spacing={1}>
      <DropDownSelect
        pairs={days_filter_options}
        defaultKey={daysFilter}
        empty="All time"
        onSelectChange={(v) => setDaysFilter(v as DaysFilter)}
        sx={{ width: '15rem', ml: 'auto' }}
        disablePortal={false}
      />
      <Grid container direction="row" spacing={1}>
        {rankings.map((ranking, i) => {
          return (
            <StyledGrid key={i} item xs={12} sm={12} md={6} lg={4}>
              {matches && (
                <Renderer
                  ranking={ranking}
                  loading={isFetching}
                  goTo={() => navigate(goToCollection(ranking.address))}
                />
              )}
              {!matches && (
                <RendererMobile
                  ranking={ranking}
                  loading={isFetching}
                  goTo={() => navigate(goToCollection(ranking.address))}
                />
              )}
            </StyledGrid>
          );
        })}
      </Grid>
    </Stack>
  );
};
