import React, { Fragment, useMemo, useState } from 'react';
import { Typography, MenuItem, MenuList, ListItemIcon, ListItemText } from '@mui/material';
import { Property } from 'types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { selectedAttrsRenderer } from 'helpers/propertyHelpers';
import { PropertyRenderer } from './Property';

type Props = {
  properties: Property[];
  setProperty: (properties: Property) => void;
  keyword?: string;
};

const initState = (properties: Property[]) => {
  const record: Record<string, boolean> = {};
  properties.forEach((element) => {
    record[element.name] = false;
  });
  return record;
};

const NFTProperties = ({ properties, setProperty, keyword }: Props) => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>(initState(properties));

  const handleChange = (name: string) => {
    const v = !expanded[name];
    setExpanded({ ...expanded, [name]: v });
  };

  const filteredProperties = useMemo(() => {
    if (!keyword) return properties;
    return properties.filter(
      (property) =>
        property.name.includes(keyword) ||
        property.attrs.some((attr) => attr.name?.includes(keyword))
    );
  }, [keyword, properties]);

  return (
    <MenuList>
      <div>
        {filteredProperties.length == 0 ? (
          <Typography variant="body2" color="text.secondary" textAlign="center" sx={{ p: 1 }}>
            No attributes found!
          </Typography>
        ) : (
          filteredProperties.map((p) => {
            return (
              <Fragment key={p.name}>
                <MenuItem
                  disableRipple={true}
                  sx={{ pl: 1, width: '100%', borderRadius: '0.5rem', height: '4rem' }}
                  onClick={() => handleChange(p.name)}
                >
                  <ListItemText>{selectedAttrsRenderer(p, keyword)}</ListItemText>
                  <ListItemIcon>
                    <KeyboardArrowDownIcon
                      sx={{
                        transform: expanded[p.name] ? 'rotate(180deg)' : 'inherit',
                        ml: 'auto',
                        color: (theme) => theme.palette.text.secondary
                      }}
                    />
                  </ListItemIcon>
                </MenuItem>
                {expanded[p.name] && (
                  <PropertyRenderer property={p} keyword={keyword} setProperty={setProperty} />
                )}
              </Fragment>
            );
          })
        )}
      </div>
    </MenuList>
  );
};

export default NFTProperties;
