import React from 'react';
import { Stack } from '@mui/material';
import { SelectedSales } from './SelectedSales';
import { PriceInput } from './PriceInput';
import { ReserveForUser } from './ReserveForUser';
import { StartingDate } from './StartingDate';
import { useSale } from '../context/saleContext';

export const Form = () => {
  const {
    type,
    price,
    reservePrice,
    startDate,
    expirationDate,
    setStartDate,
    setExpirationDate,
    setReservePrice,
    setPriceUnit,
    setType
  } = useSale();

  //Disable Launch TMP
  const disableReserveForUser = true;

  return (
    <Stack spacing={2}>
      <StartingDate label="Starting date" date={startDate} set={setStartDate} />
      <PriceInput label="Set price for all" price={price} set={setPriceUnit} />
      <SelectedSales />
      {!disableReserveForUser && <ReserveForUser />}
    </Stack>
  );
};
