import React, { useEffect, useMemo } from 'react';
import { Search } from 'components';
import { Typography, styled, Box, debounce, BoxProps } from '@mui/material';
import { Scrollable } from 'helpers/scrollable';
import Avatar from 'components/Avatar';
import { Collection } from 'types/generated';
import { formatAddress } from 'helpers/formatAddress';

type Props = {
  collection?: Collection;
  setCollection: (collection?: Collection) => void;
  collections: Collection[];
  filtered?: Collection[];
  onInputChange: (keyword: string) => void;
  clear?: () => void;
} & BoxProps;

const SearchContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  width: '18rem',
  height: '20rem'
}));

const StyledRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  cursor: 'pointer',
  padding: theme.spacing(1),
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.pxToRem(15),
  borderRadius: '0.5rem',
  ':hover': {
    background: theme.palette.action.hover
  }
}));

const CollectionSearchRenderer = ({
  collections,
  collection,
  setCollection,
  filtered,
  onInputChange,
  clear,
  ...rest
}: Props) => {
  const debouncedSearchChange = useMemo(() => debounce(onInputChange, 300), []);

  useEffect(() => {
    return () => {
      debouncedSearchChange.clear();
    };
  }, []);

  const renderRow = (collection: Collection) => {
    const { address, name, avatar, certified } = collection;
    return (
      <StyledRow
        key={address}
        onClick={() => {
          setCollection(collection);
        }}
      >
        <Avatar src={avatar} approved={certified} iconStyle={{ width: 16, height: 16 }} />
        <Typography variant="body2">
          {name && name != 'anonymous' ? name : formatAddress(address)}
        </Typography>
      </StyledRow>
    );
  };

  const renderResults = () => {
    return (
      <Scrollable
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.1,
          height: '16rem',
          maxHeight: '16rem',
          overflow: 'auto',
          pr: 1
        }}
      >
        {(filtered || collections).map(renderRow)}
      </Scrollable>
    );
  };

  return (
    <SearchContainer {...rest}>
      <Search
        onClick={(e) => e.stopPropagation()}
        defaultValue={collection && collection.name}
        placeholder="Keywords"
        inputChange={debouncedSearchChange}
        clearInput={() => {
          setCollection(undefined);
          clear?.();
        }}
        sx={{
          height: '3rem',
          borderRadius: '0.5rem'
        }}
      />
      {renderResults()}
    </SearchContainer>
  );
};

export default CollectionSearchRenderer;
