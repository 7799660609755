import React, { useState } from 'react';
import { Container, Footer } from 'components';
import { Banner } from './Banner';
import { CreateTypePicker } from './TypePicker';
import { TokenKind } from 'types';
import Main from './Main';

const Create = () => {
  const [tokenKind, setTokenKind] = useState<TokenKind>();

  return (
    <>
      <Container sx={{ paddingBottom: '5rem' }}>
        {!tokenKind ? (
          <>
            <Banner />
            <CreateTypePicker set={setTokenKind} />
          </>
        ) : (
          <Main
            tokenKind={tokenKind}
            back={() => setTokenKind(undefined)}
            switchType={() =>
              setTokenKind(tokenKind == TokenKind.erc721 ? TokenKind.erc1155 : TokenKind.erc721)
            }
          />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Create;
