export const isValidEmail = (email: string): boolean => {
  // Very basic validation
  const validRegex = /^\S+@\S+\.\S+$/;
  if (!email) return true;
  return validRegex.test(email);
};

export const isValidHttpUrl = (url: string | undefined): boolean => {
  if (!url) return true;
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return !!urlPattern.test(url);
};
