import { Box, Pagination as Paging } from '@mui/material';
import React from 'react';

type Props = {
  count: number;
  size: number;
  page: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

export const Pagination = ({ count, size, page, onChange }: Props): JSX.Element | null => {
  const showPagination = count > size;
  if (!showPagination) return null;
  return <Paging page={page} count={Math.floor(count / size)} onChange={onChange} />;
};
