import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from 'components';
import { Profile, ProfileLinks, Vision } from './types';
import { UserModif } from 'types/generated';
import { isValidEmail, isValidHttpUrl } from 'helpers/validators';

export const validateFormBtn = (
  caption: string,
  save: () => void,
  disabled = false
): JSX.Element => (
  <Box>
    <Button ctx="primary" onClick={save} disabled={disabled} size="large" sx={{ width: '8rem' }}>
      <Typography variant="button">{caption}</Typography>
    </Button>
  </Box>
);

export const validate = (profile?: Profile): { errors: Record<string, string> } => {
  const errors: Record<string, string> = {};
  if (!profile) return { errors };

  if (!isValidEmail(profile.mail)) errors['mail'] = 'Please enter a valid email';
  for (const r of ['facebook', 'twitter', 'instagram', 'website', 'discord', 'telegram']) {
    const key = r as keyof ProfileLinks;
    if (!isValidHttpUrl(profile[key])) errors[key] = `${key} url is not valid`;
  }

  return { errors };
};

export const shallowEqulityObjects = (
  obj1: Record<string, string>,
  obj2: Record<string, string>
): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) return false;
  }
  return true;
};

export const getProfileInput = (profile: Profile | undefined, excludes: string[]) => {
  const input: Record<string, string | null> = {};
  if (!profile) return {};
  Object.keys(profile).forEach((p) => {
    const key = p as keyof Profile;
    const skip = excludes.includes(key);
    if (!skip) input[key] = profile[key];
  });
  return input as UserModif;
};

export const validateVision = (vision: Vision | undefined) => {
  const visionErrors: Record<string, string> = {};
  const password = vision?.password;
  const confirm = vision?.confirm;
  if (password && password.length < 8)
    visionErrors['password'] = 'The password must be at least 8 characters';
  if (confirm !== password) visionErrors['confirm'] = 'The passwords are not the same';

  return { visionErrors };
};
