import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from 'components/Avatar';
import Skeleton from '@mui/material/Skeleton';
import { Button, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';

/**
 * Display a background image and a centered avatar
 */

type Props = {
  src: string;
  avatar?: string;
  height: string;
  isApproved?: boolean;
  isCreator?: boolean;
  set?: (url: string, file?: File) => void;
};

const AvatarContainer = styled(Box)({
  position: 'absolute',
  top: 'calc(100% - 2.5rem)',
  left: 'calc(50% - 2.5rem)'
});

const avatarRenderer = (collectionAvatarSrc: string, isApproved?: boolean) => {
  return (
    <AvatarContainer>
      <Avatar
        top={45}
        src={collectionAvatarSrc}
        approved={isApproved}
        sx={{ width: 80, height: 80, border: (theme) => `0.2rem solid ${theme.input.background}` }}
      />
    </AvatarContainer>
  );
};

const Background = ({ src, height, avatar, isApproved, isCreator, set }: Props) => {
  const [loading, setLoading] = useState(true);
  const { open, getRootProps, getInputProps } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/*': []
    },
    onDrop: (files) => {
      set?.(URL.createObjectURL(files[0]), files[0]);
    }
  });

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      avatar && URL.revokeObjectURL(avatar);
    };
  }, []);

  return (
    <div
      style={{ position: 'relative', height: height }}
      {...getRootProps({ className: 'dropzone' })}
    >
      <input {...getInputProps()} />
      {loading && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        />
      )}
      <img
        src={src}
        loading="lazy"
        onLoad={() => setLoading(false)}
        style={{
          visibility: loading ? 'hidden' : 'visible',
          objectFit: 'cover',
          height,
          width: '100%'
        }}
      />
      {avatar && avatarRenderer(avatar, isApproved)}
      {isCreator && (
        <Button
          variant="outlined"
          sx={{
            position: 'absolute',
            bottom: 10,
            right: 10,
            border: (theme) => theme.border,
            borderRadius: '0.5rem',
            ':hover': {
              border: (theme) => theme.border
            }
          }}
          onClick={open}
        >
          <Typography variant="button" color="text.primary">
            Change cover
          </Typography>
        </Button>
      )}
    </div>
  );
};

export default Background;
