import React from 'react';
import { styled, Switch } from '@mui/material';

/*
const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'unset',
    width: 16,
    height: 16,
    margin: 2
  }
}));
*/

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: '0.4rem !important',
  '.MuiSwitch-track': {
    borderRadius: '7rem !important'
  },
  '.Mui-checked+.MuiSwitch-track': {
    backgroundColor: `${theme.button.primary.background} !important`,
    opacity: '1 !important',
    borderRadius: '7rem !important'
  },
  '.MuiSwitch-thumb': {
    backgroundColor: '#FFFFFF !important'
  },
  '.Mui-disabled': {
    opacity: '0.3'
  }
}));

export default StyledSwitch;
