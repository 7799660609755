import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { PriceSymbol, StepStatus } from 'types';
import Step from '../Step';
import { Price } from 'components';

type Props = {
  price: number;
  token: PriceSymbol;
  step: number;
  currentStep: number;
  // callback, called once the step has been completed successfully
  complete: () => void;
};

type SignProps = {
  price: number;
  token: PriceSymbol;
  status: StepStatus;
};

const Complete = ({ price, token }: Omit<SignProps, 'status' | 'onSign'>) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      <Typography variant="body2" sx={{ color: (theme) => theme.colors.green }}>
        Done!
      </Typography>
      <Typography variant="body2" color="text.primary">
        Counter Offer successfully processed
      </Typography>
      <Price value={price} symbol={token} />
    </Box>
  );
};

const Progress = () => {
  return (
    <Typography variant="body2" color="text.secondary">
      Accept the signature request in your wallet and wait for your counter offer to process
    </Typography>
  );
};

// TODO remove once <OwnershipStep /> is connected to Metamask
function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const CompleteCounterOffer = ({ step, currentStep, token, price, complete }: Props) => {
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
      confirmTransation();
    }
  }, [currentStep]);

  const confirmTransation = () => {
    setStatus(StepStatus.IN_PROGRESS);
    sleep(2000).then(() => {
      setStatus(StepStatus.DONE);
      complete();
    });
  };

  return (
    <Step
      title={
        status == StepStatus.DONE ? (
          <Complete price={price} token={token} />
        ) : (
          'Complete Counter Offer'
        )
      }
      el={status == StepStatus.IN_PROGRESS ? <Progress /> : undefined}
      step={step}
      currentStep={currentStep}
      status={status}
      error={error}
    />
  );
};

export default CompleteCounterOffer;
