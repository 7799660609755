/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { ActivityType } from 'types/generated';
import { EnhancedActivity, PriceSymbol, SortClickHandler, SortState } from 'types';
import { Divider, Skeleton, Stack, Typography, Box, styled, Chip } from '@mui/material';
import { ActivityIcon } from 'helpers/events';
import { Avatar, Price } from 'components';
import { priceFormatter } from 'lib/format';
import { formatAddress } from 'helpers/formatAddress';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { blockExplorer, collectionRenderer, typeRenderer } from './utils';

type Props = {
  activity: EnhancedActivity;
  network: string;
  isNftPage?: boolean;
  goTo: (page: 'nft' | 'collection' | 'user', id: string) => void;
};

type HeasersProps = {
  sort: Record<string, SortState>;
  hide: boolean;
  click: SortClickHandler;
  isNftPage?: boolean;
};

const StyledRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '4.5rem',
  padding: theme.spacing(1),
  borderRadius: '0.5rem',
  ':hover': { background: theme.palette.action.hover }
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '4rem',
  padding: theme.spacing(1),
  borderRadius: '0.5rem'
}));

const StyledCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0.5),
  height: '100%'
}));

const LinkedTypography = styled(Typography)({
  cursor: 'pointer'
});

const StyledChip = styled(Chip)(({ theme }) => ({
  border: theme.border,
  color: theme.palette.text.secondary,
  padding: theme.spacing(2, 1),
  width: 'fit-content'
}));

export const RenderActivity = ({ activity, network, goTo, isNftPage }: Props) => {
  const {
    nft,
    collection,
    type,
    price,
    from,
    to,
    from_login,
    to_login,
    timestamp,
    name,
    quantity,
    transaction_hash
  } = activity;
  const avatar = type == ActivityType.CollectionOffer ? collection?.avatar : nft?.thumbnail;
  const collectionName = collection?.name || '';
  const collectionAddress = collection?.address || '';
  const certified = collection?.certified || false;
  const symbol =
    type == ActivityType.Offer || type == ActivityType.CollectionOffer
      ? PriceSymbol.WAVAX
      : PriceSymbol.AVAX;

  return (
    <StyledRow>
      <Box sx={{ flex: 1, overflow: 'hidden' }}>
        <StyledCell sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
          <ActivityIcon type={type} />
          <Typography
            variant="body2"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {typeRenderer[type]}
          </Typography>
        </StyledCell>
      </Box>
      {!isNftPage && (
        <Box sx={{ flex: 1, overflow: 'hidden' }}>
          <StyledCell sx={{ flexDirection: 'row', gap: 2 }}>
            <Avatar
              onClick={() => {
                if (type == ActivityType.CollectionOffer) goTo('collection', collection?.address);
                else goTo('nft', nft?.asset_id || '');
              }}
              src={avatar || ''}
              sx={{
                cursor: 'pointer',
                width: '3rem',
                height: '3rem',
                borderRadius: type == ActivityType.CollectionOffer ? undefined : '0.5rem'
              }}
            />
            <Stack sx={{ justifyContent: 'center', minWidth: 0 }}>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: type == ActivityType.CollectionOffer ? '#3D3AE9' : undefined
                }}
              >
                {name || '--'}
              </Typography>
              {collectionRenderer(collectionName, certified, goTo, collectionAddress)}
            </Stack>
          </StyledCell>
        </Box>
      )}
      <Box sx={{ flex: 1 }}>
        <StyledCell sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Price value={priceFormatter.format(price)} size={10} symbol={symbol} />
        </StyledCell>
      </Box>
      <Box sx={{ flex: 1 }}>
        <StyledCell sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="body2">{quantity}</Typography>
        </StyledCell>
      </Box>
      <Box sx={{ flex: 1, overflow: 'hidden' }}>
        <StyledCell sx={{ justifyContent: 'center' }}>
          <LinkedTypography
            variant="body2"
            onClick={() => goTo('user', from)}
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {from_login && from_login != 'anonymous' ? from_login : formatAddress(from)}
          </LinkedTypography>
        </StyledCell>
      </Box>
      <Box sx={{ flex: 1, overflow: 'hidden' }}>
        <StyledCell sx={{ justifyContent: 'center' }}>
          <LinkedTypography
            variant="body2"
            onClick={() => goTo('user', to)}
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {to_login && to_login != 'anonymous' ? to_login : formatAddress(to)}
          </LinkedTypography>
        </StyledCell>
      </Box>
      <Box sx={{ flex: 1, overflow: 'hidden' }}>
        <StyledCell
          sx={{ flexDirection: 'row', gap: 1, alignItems: 'center', justifyContent: 'flex-end' }}
        >
          {blockExplorer(network, transaction_hash, timestamp)}
        </StyledCell>
      </Box>
    </StyledRow>
  );
};

export const RenderMobileActivity = ({ activity, goTo, network }: Props) => {
  const {
    nft,
    collection,
    type,
    price,
    from,
    to,
    from_login,
    to_login,
    timestamp,
    name,
    quantity,
    transaction_hash
  } = activity;
  const avatar = type == ActivityType.CollectionOffer ? collection?.avatar : nft?.thumbnail;
  const collectionName = collection?.name || '';
  const collectionAddress = collection?.address || '';
  const certified = collection?.certified || false;
  const symbol =
    type == ActivityType.Offer || type == ActivityType.CollectionOffer
      ? PriceSymbol.WAVAX
      : PriceSymbol.AVAX;

  return (
    <StyledRow sx={{ borderBottom: (theme) => theme.border }}>
      <Stack sx={{ flex: 1, overflow: 'hidden' }}>
        <StyledCell sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ flex: 1, overflow: 'hidden' }}
          >
            <Avatar
              onClick={() => {
                if (type == ActivityType.CollectionOffer) goTo('collection', collection?.address);
                else goTo('nft', nft?.asset_id || '');
              }}
              src={avatar || ''}
              sx={{
                cursor: 'pointer',
                width: '3rem',
                height: '3rem',
                borderRadius: type == ActivityType.CollectionOffer ? undefined : '0.5rem'
              }}
            />
            <Stack sx={{ flex: 1, minWidth: 0 }}>
              <Typography
                variant="body2"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {name || '--'}
              </Typography>
              {collectionRenderer(collectionName, certified, goTo, collectionAddress)}
            </Stack>
          </Stack>
          <Stack alignItems="flex-end" spacing={0.5} sx={{ flex: 1, overflow: 'hidden' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography
                variant="body2"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {typeRenderer[type]}
              </Typography>
              <ActivityIcon type={type} />
            </Box>
            <Price value={priceFormatter.format(price)} size={10} symbol={symbol} />
            <Typography variant="body2">{quantity}</Typography>
          </Stack>
        </StyledCell>
        <StyledCell sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="caption" color="text.secondary">
            From
          </Typography>
          <LinkedTypography
            variant="body2"
            onClick={() => goTo('user', from)}
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {from_login && from_login !== 'anonymous' ? from_login : formatAddress(from)}
          </LinkedTypography>
          <Typography variant="caption" color="text.secondary">
            To
          </Typography>
          <LinkedTypography
            variant="body2"
            onClick={() => goTo('user', to)}
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {to_login && to_login !== 'anonymous' ? to_login : formatAddress(to)}
          </LinkedTypography>
        </StyledCell>
        <StyledCell sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="caption" color="text.secondary">
            Date
          </Typography>
          {blockExplorer(network, transaction_hash, timestamp)}
        </StyledCell>
      </Stack>
    </StyledRow>
  );
};

export const RenderNftMobileActivity = ({ activity, goTo }: Props) => {
  const { type, price, from, to, from_login, to_login } = activity;
  const symbol =
    type == ActivityType.Offer || type == ActivityType.CollectionOffer
      ? PriceSymbol.WAVAX
      : PriceSymbol.AVAX;

  return (
    <>
      <StyledRow>
        <Stack direction={'row'} justifyContent="space-between" sx={{ flex: 1 }}>
          <StyledCell sx={{ width: '50%' }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <ActivityIcon type={type} />
              <Typography
                variant="body2"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {typeRenderer[type]}
              </Typography>
            </Box>
            <Typography variant="caption" color="text.secondary">
              Price
            </Typography>
            <Price value={priceFormatter.format(price)} size={10} symbol={symbol} />
          </StyledCell>
          <StyledCell sx={{ width: '50%' }}>
            <Stack>
              <Typography variant="caption" color="text.secondary">
                From
              </Typography>
              <LinkedTypography
                variant="body2"
                onClick={() => goTo('user', from)}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {from_login && from_login != 'anonymous' ? from_login : formatAddress(from)}
              </LinkedTypography>
              <Typography variant="caption" color="text.secondary">
                To
              </Typography>
              <LinkedTypography
                variant="body2"
                onClick={() => goTo('user', to)}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {to_login && to_login != 'anonymous' ? to_login : formatAddress(to)}
              </LinkedTypography>
            </Stack>
          </StyledCell>
        </Stack>
      </StyledRow>
      <Divider sx={{ my: 1 }} variant="middle" />
    </>
  );
};

export const skeletonRow = (mobile: boolean, isNftPage?: boolean) => {
  return mobile ? (
    <StyledRow>
      <StyledCell sx={{ flex: 1 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
    </StyledRow>
  ) : (
    <StyledRow>
      <StyledCell sx={{ flex: 1 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      {!isNftPage && (
        <StyledCell sx={{ flex: 2 }}>
          <Skeleton width={'100%'} />
        </StyledCell>
      )}
      <StyledCell sx={{ flex: 0.5 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 0.5 }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 1, overflow: 'hidden' }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 1, overflow: 'hidden' }}>
        <Skeleton width={'100%'} />
      </StyledCell>
      <StyledCell sx={{ flex: 1, overflow: 'hidden' }}>
        <Skeleton width={'100%'} />
      </StyledCell>
    </StyledRow>
  );
};

export const Headers = ({ sort, click, hide, isNftPage }: HeasersProps) => {
  if (hide) return null;
  const chip = (header: string, key: keyof EnhancedActivity) => {
    const sortable = !!sort[key];
    return (
      <StyledChip
        deleteIcon={
          sortable ? (
            sort[key] == SortState.ascending ? (
              <ArrowDropUpIcon />
            ) : sort[key] == SortState.descending ? (
              <ArrowDropDownIcon />
            ) : (
              <></>
            )
          ) : (
            <></>
          )
        }
        onClick={() => sortable && click(key, sort[key])}
        onDelete={() => sortable && click(key, sort[key])}
        label={<Typography variant="body2">{header}</Typography>}
        variant="outlined"
        clickable={sortable}
      />
    );
  };

  return (
    <StyledHeader>
      <StyledCell sx={{ flex: 1 }}>{chip('Type', 'type')}</StyledCell>
      {!isNftPage && <StyledCell sx={{ flex: 1 }}>{chip('Items', 'name')}</StyledCell>}
      <StyledCell sx={{ flex: 1, alignItems: 'center' }}>{chip('Price', 'price')}</StyledCell>
      <StyledCell sx={{ flex: 1, alignItems: 'center' }}>{chip('Qty', 'quantity')}</StyledCell>
      <StyledCell sx={{ flex: 1 }}>{chip('From', 'from')}</StyledCell>
      <StyledCell sx={{ flex: 1 }}>{chip('To', 'to')}</StyledCell>
      <StyledCell sx={{ flex: 1, alignItems: 'flex-end' }}>{chip('Date', 'timestamp')}</StyledCell>
    </StyledHeader>
  );
};
