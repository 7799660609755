import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { Button, NumberInput, InputField } from 'components';
import { Divider } from '@mui/material';
import { Renderer } from '../Renderer';
import { NumberOrString, TransferedBalance } from 'types';
import { batch, openModal, transfer } from 'store/actions';
import { useBalanceMutation } from 'api';

type FormProps = {
  transfer: (senTo: string, qty: NumberOrString) => void;
  max: number;
  is1155?: boolean;
};

const Form = ({ max, is1155, transfer }: FormProps) => {
  const [sendTo, setSendTo] = useState<string>();
  const [qty, setQty] = useState<NumberOrString>('1');
  const [error, setError] = useState<Record<string, string | undefined>>({});

  const onValidate = () => {
    if (!sendTo) setError({ ...error, ['sendTo']: 'Enter c-chain address' });
    else if (!qty) setError({ ...error, ['qty']: 'Enter quantity' });
    else transfer(sendTo, qty);
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary" textAlign="start">
          Send To
        </Typography>
        <InputField
          sx={{
            height: '3.5rem',
            fontSize: 14,
            px: 2,
            borderRadius: '0.5rem'
          }}
          value={sendTo}
          set={(v) => {
            if (error?.['sendTo']) setError({ ...error, ['sendTo']: undefined });
            setSendTo(v as string);
          }}
          placeholder={'Enter the c-address'}
          err={error?.['sendTo']}
        />
        {is1155 && (
          <>
            <Typography variant="body2" color="text.secondary" textAlign="start">
              Quantity
            </Typography>
            <NumberInput
              sx={{ width: '100%', height: '3.5rem', borderRadius: '0.5rem' }}
              value={qty}
              step={1}
              max={max}
              error={error?.['qty'] !== undefined}
              onChange={(v) => {
                if (error?.['qty']) setError({ ...error, ['qty']: undefined });
                setQty(v as number);
              }}
            />
          </>
        )}
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Button ctx="primary" sx={{ width: '8rem' }} onClick={onValidate}>
          <Typography variant="button">Validate</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

const TransferNFT = () => {
  const dispatch = useDispatch();
  const nft = useSelector((global: GlobalState) => global.state.listNFTForSale);
  const [max, setMax] = useState(1);
  const [balance] = useBalanceMutation();
  const is1155 = nft?.collection.kind == 'erc1155';

  useEffect(() => {
    if (nft && is1155) {
      balance({ assetId: nft?.asset_id })
        .unwrap()
        .then((res) => {
          setMax(Number(res.balance));
        });
    }
  }, [nft]);

  const onTransfer = (senTo: string, qty: NumberOrString) => {
    if (nft) {
      const erc: TransferedBalance = {
        amountToTransfer: qty as number,
        nft,
        available_amount: '',
        balance: '',
        kalao_exchange_approved: false
      };
      const is1155 = nft.collection.kind == 'erc1155';
      const erc721 = is1155 ? [] : [erc];
      const erc1155 = is1155 ? [erc] : [];
      dispatch(
        batch.create({
          actions: [
            transfer.create({ transferAssets: { erc721, erc1155, to: senTo } }),
            openModal.create({ modal: 'TRANSFER' })
          ]
        })
      );
    }
  };

  return (
    <Renderer title="Listing for sale" nft={nft} quantity={is1155 ? max : undefined}>
      <Divider sx={{ my: 3 }} variant="middle" />
      {/** Steps */}
      <Stack spacing={2}>
        <Form transfer={onTransfer} is1155={is1155} max={max} />
      </Stack>
    </Renderer>
  );
};

export default TransferNFT;
