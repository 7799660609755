import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { NFTOffer, StepStatus } from 'types';
import Step from '../Step';
import { useCancelDealMutation } from 'api';
import { useWeb3React } from '@web3-react/core';
import { DealKind } from 'types/generated';
import { bulkCancelOffers } from 'services/exchange';
import { Done } from './Done';

type Props = {
  step: number;
  currentStep: number;
  nftOffer: NFTOffer;
  // callback, called once the step has been completed successfully
  complete: () => void;
};

const Progress = () => {
  return (
    <Typography variant="body2" color="text.secondary">
      Please sign the transaction in your wallet to cancel the offer
    </Typography>
  );
};

const Complete = () => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Typography variant="body2" sx={{ color: (theme) => theme.colors.green, mr: 1 }}>
        Done!
      </Typography>
      <Typography variant="body2" color="text.primary">
        Offer successfully canceled
      </Typography>
    </Box>
  );
};

const Unoffering = ({ step, currentStep, complete, nftOffer }: Props) => {
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();
  const [cancelOffer] = useCancelDealMutation();
  const { account, hooks } = useWeb3React();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
      confirmTransation();
    }
  }, [currentStep]);

  const confirmTransation = async () => {
    if (!account || !provider) {
      setStatus(StepStatus.ERROR);
      setError('Unauthenticated');
      return;
    }
    try {
      setStatus(StepStatus.IN_PROGRESS);

      const { cancelDeal } = await cancelOffer({
        input: { kind: DealKind.Offer, uuid: nftOffer.offer.offer_id },
        address: account
      }).unwrap();

      if (!cancelDeal) throw new Error('Validation failed !');

      //todo: call exchange contract if contract cancel is needed
      if (!cancelDeal.complete && cancelDeal.offer_ticket)
        await bulkCancelOffers(provider, cancelDeal.offer_ticket);

      setStatus(StepStatus.DONE);
      complete();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Cancel failed !');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={status == StepStatus.DONE ? <Done offer={nftOffer} /> : 'Cancel your offer'}
      step={step}
      currentStep={currentStep}
      status={status}
      error={error}
      el={status == StepStatus.IN_PROGRESS ? <Progress /> : undefined}
    />
  );
};

export default Unoffering;
