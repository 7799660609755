import React from 'react';
import Box from '@mui/material/Box';
import { Link, Stack, styled, Typography } from '@mui/material';
import { GenericCard, Swiper } from 'components';
import { ReactComponent as Apple } from '../../assets/logo/apple.svg';
import { ReactComponent as Windows } from '../../assets/logo/windows.svg';
import { GenericCardProps } from 'types';

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0, 5)
}));

const StyledLink = styled(Link)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: 'flex',
  alignItems: 'center',
  background: theme.input.background,
  marginTop: theme.spacing(1),
  borderRadius: '1.75rem',
  ':hover': {
    background: theme.palette.action.hover
  }
}));

const breakpoints = {
  // when window width is >= 320px
  320: {
    slidesPerView: 2,
    spaceBetween: 20
  },
  640: {
    slidesPerView: 3,
    spaceBetween: 20
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 20
  },
  1024: {
    slidesPerView: 5,
    spaceBetween: 20
  }
};

const mockMetaverseBanner: GenericCardProps[] = [];

const VisionPageBanner = () => {
  return (
    <Root sx={{ mt: { md: 3, lg: 5 }, mb: 2 }}>
      <Typography variant="h1" textAlign="center" color="text.primary" sx={{ mt: 2 }}>
        Kalao vision
      </Typography>
      <Typography variant="body1" color="text.secondary" textAlign="center">
        Install our application and dive into our Metaverse experience. It’s the next level of NFT’s
        marketplace.
      </Typography>
      <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
        <StyledLink href="#" underline="none" color="text.primary">
          <Apple width={25} height={25} />
          <Typography sx={{ ml: 1 }}>Apple</Typography>
        </StyledLink>
        <StyledLink href="#" underline="none" color="text.primary" sx={{ ml: 2 }}>
          <Windows width={25} height={25} />
          <Typography sx={{ ml: 1 }}>Windows</Typography>
        </StyledLink>
      </Stack>
      <Swiper
        navigation={true}
        items={mockMetaverseBanner.map((d, i) => (
          <GenericCard key={d.address} {...d} />
        ))}
        options={{ breakpoints }}
      />
    </Root>
  );
};

export default VisionPageBanner;
