import React from 'react';
import { Stack, Typography, styled } from '@mui/material';

import { Property } from 'types';

const PropertyContainer = styled(Stack)({
  borderRadius: '0.5rem'
});

const highlight = (value: string, i: number) => (
  <Typography key={i} variant="body1" sx={{ background: '#3D3AE9', color: '#fff' }}>
    {value}
  </Typography>
);

export const selectedAttrsRenderer = (
  property: Property,
  // Search keyword
  keyword: string | undefined,
  el?: JSX.Element
) => {
  const attrs = property.attrs.filter((attr) => attr.selected).map((a) => a.name);
  return (
    <PropertyContainer key={`${property.name}`} direction="row" alignItems="center" spacing={1}>
      <Stack justifyContent="center" sx={{ flex: 1, minWidth: 0 }}>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ flex: 1, display: 'flex' }}
          component="span"
        >
          {keyword
            ? property.name
                .split(new RegExp(`(${keyword})`, 'g'))
                .map((s, i) => (s.includes(keyword) ? highlight(s, i) : s))
            : property.name}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          noWrap
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {attrs.join(', ')}
        </Typography>
      </Stack>
      {el}
    </PropertyContainer>
  );
};
