// 3rd party libs
import React from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Link,
  useTheme,
  Divider,
  Box,
  styled
} from '@mui/material';
import { ReactComponent as Dark } from '../../assets/logo/logo_dark.svg';
import { ReactComponent as Light } from '../../assets/logo/logo_light.svg';
import { Container } from 'components';

import SocialNetworks from './socialNetworks';
import { AppRoutes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

////////////
//
//  Description:
//  Footer
//
//  Usage:
//  <Footer  />
//
////////////

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

const listItemRenderer = (
  value: string,
  link: string,
  target: string | undefined,
  navigate?: (path: string) => void
) => {
  return (
    <ListItem sx={{ pl: 0, py: 0 }}>
      {target ? (
        <Link variant="body1" underline="none" href={link} target={target}>
          {value}
        </Link>
      ) : (
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate?.(`/${link}`)}
        >
          {value}
        </Typography>
      )}
    </ListItem>
  );
};

const title = (value: string) => {
  return (
    <Typography variant="h4" color="text.primary">
      {value}
    </Typography>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDark = theme.palette.mode == 'dark';

  const goTo = (path: string) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Root>
      <Divider variant="middle" sx={{ mb: 4, mx: 0 }} />
      <Container sx={{ paddingTop: '3rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
            <Box sx={{ width: { xs: 100, md: 200 }, display: 'inline-block' }}>
              {isDark ? <Dark /> : <Light />}
            </Box>
            <Box sx={{ my: 3, mb: 1 }}>
              <SocialNetworks
                twitter="https://twitter.com/GetKalao"
                telegram="https://t.me/kalaoklo"
                discord="https://discord.gg/WjcRtmZKPB"
                medium="https://medium.com/@Kalao"
                youtube="https://www.youtube.com/c/Kalao-KLO"
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={8} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Grid container spacing={5}>
              <Grid item xs>
                {title('Explore')}
                <List sx={{ mb: 3 }}>
                  {listItemRenderer('Collections', AppRoutes.explore_collections, undefined, goTo)}
                  {listItemRenderer('NFTs', AppRoutes.explore_nfts, undefined, goTo)}
                  {listItemRenderer('Artists', AppRoutes.explore_artists, undefined, goTo)}
                </List>
              </Grid>
              <Grid item xs>
                {title('Other')}
                <List>
                  {listItemRenderer('Activity', AppRoutes.activity, undefined, goTo)}
                  {listItemRenderer('Ranking', AppRoutes.ranking, undefined, goTo)}
                  {listItemRenderer('Mint', 'https://go.kalao.io', '_blank')}
                  {listItemRenderer('Rewards', AppRoutes.rewards, undefined, goTo)}
                </List>
              </Grid>
              <Grid item xs>
                {title('Account')}
                <List>
                  {listItemRenderer('Dashboard', AppRoutes.dashboard, undefined, goTo)}
                  {listItemRenderer('Collectibles', AppRoutes.my_collectbles, undefined, goTo)}
                  {listItemRenderer('Sell', AppRoutes.sell, undefined, goTo)}
                  {listItemRenderer('Create', AppRoutes.create, undefined, goTo)}
                  {listItemRenderer('Transfer', AppRoutes.transfer, undefined, goTo)}
                </List>
              </Grid>
              <Grid item xs>
                {title('Links')}
                <List>
                  {listItemRenderer('Docs', 'https://docs.kalao.io', '_blank')}
                  {listItemRenderer(
                    'Privacy Policy',
                    'https://docs.kalao.io/faq/legal/privacy-policy',
                    '_blank'
                  )}
                  {listItemRenderer(
                    'Terms of services',
                    'https://docs.kalao.io/faq/legal/terms-of-services',
                    '_blank'
                  )}
                  {listItemRenderer(
                    'Buy $KLO?',
                    'https://app.paraswap.io/#/?network=avalanche',
                    '_blank'
                  )}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ my: 4, mx: 0, opacity: 0.5 }} />
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textAlign: { xs: 'center', sm: 'left' } }}
        >
          &copy; Kalao {new Date().getFullYear()}. Powered by Avalanche.
        </Typography>
      </Container>
    </Root>
  );
};

export default Footer;
