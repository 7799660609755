import React, { useEffect, useState } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { Price, ApproveAsset, OwnerShipStep, Listing, Chips } from 'components';
import { Divider } from '@mui/material';
import { Renderer } from '../Renderer';
import { EventType } from 'types';
import { EventIcon } from 'helpers/events';
import { NftPreview } from 'types/generated';
import { setCreateDealId } from 'store/actions';
import { priceFormatter } from 'lib/format';

// render basket (multiple nfts)
const renderDirectSales = (nfts: NftPreview[]) => {
  let total = 0;
  const thumbnails: string[] = [];
  nfts?.forEach((nft) => {
    const { sale } = nft;
    if (sale) {
      total += sale.unitary_price_float * Number(sale.quantity);
      thumbnails.push(nft.thumbnail);
    }
  });
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Chips
        thumbnails={thumbnails}
        maxChips={3}
        sx={{ left: 0, border: (theme) => theme.border, cursor: 'default' }}
        onClick={() => undefined}
      />
      <Stack spacing={1} justifyContent="flex-end">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption" fontWeight="600">Direct sale</Typography>
          <EventIcon event={EventType.Sale} />
        </Box>
        <Price value={priceFormatter.format(total)} variant="h2" justifyContent="flex-end" />
      </Stack>
    </Box>
  );
};

export const getAddresses = (nfts: NftPreview[]) => {
  const addresses = new Set<string>();

  nfts.forEach((nft) => {
    addresses.add(nft.collection.address);
  });
  return [...addresses.values()];
};

const ListForSale = () => {
  const dispatch = useDispatch();
  const items = useSelector((global: GlobalState) => global.state.items);
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    setCurrentStep(1);
  }, []);

  const complete = (dealIds: string[]) => {
    dispatch(setCreateDealId.create({ dealId: dealIds[0] }));
  };

  if (!items) return null;

  return (
    <Renderer title="List your items">
      {renderDirectSales(items)}
      <Divider sx={{ my: 3 }} variant="middle" />
      {/** Steps */}
      <Stack spacing={2}>
        <OwnerShipStep step={1} currentStep={currentStep} complete={() => setCurrentStep(2)} />
        <ApproveAsset
          step={2}
          currentStep={currentStep}
          complete={() => setCurrentStep(3)}
          collections={getAddresses(items)}
        />
        <Listing step={3} currentStep={currentStep} complete={complete} items={items} />
      </Stack>
    </Renderer>
  );
};

export default ListForSale;
