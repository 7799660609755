import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { StepStatus } from 'types';
import Step from '../Step';
import { useCreateDealMutation, useSaleNonceMutation } from 'api';
import { useWeb3React } from '@web3-react/core';
import { saleSignature } from 'services/exchange';
import { useNetwork } from 'hooks';
import { DealKind, NftPreview, OffChainSaleInput } from 'types/generated';
import { toBackendSale } from '../utils';
import { Done } from './Done';

type Props = {
  step: number;
  currentStep: number;
  items?: NftPreview[];
  // callback, called once the step has been completed successfully
  complete: (dealIds: string[]) => void;
};

const progress = (total: number, signed: number) => {
  return (
    <Typography variant="body2" color="text.secondary">
      {`Please sign the ${total} transaction${
        total > 1 ? 's' : ''
      } in your wallet to confirm the listing. Signed ${signed}/${total} transaction${
        total > 1 ? 's' : ''
      }`}
    </Typography>
  );
};

const Listing = ({ step, currentStep, complete, items }: Props) => {
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();
  const [info, setInfo] = useState<{ total: number; signed: number }>({
    total: 0,
    signed: 0
  });
  const [createDeal] = useCreateDealMutation();
  const [getNonce] = useSaleNonceMutation();
  const { account, provider } = useWeb3React();
  const { supportedChainId } = useNetwork();

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
      confirmTransation();
    }
  }, [currentStep]);

  const confirmTransation = async () => {
    setStatus(StepStatus.IN_PROGRESS);
    if (!items) {
      setStatus(StepStatus.ERROR);
      setError('No item was selected for listing');
      return;
    }

    await confirmDirect(items);
  };

  const confirmDirect = async (nfts: NftPreview[]) => {
    if (!account || !provider) {
      setStatus(StepStatus.ERROR);
      setError('Unauthenticated');
      return;
    }

    setInfo({ ...info, total: nfts.length });

    try {
      // Build sales
      const sales = await Promise.all(
        nfts.map(
          async (nft) =>
            await getNonce()
              .unwrap()
              .then((response) => {
                return toBackendSale(nft, account, response.saleNonce, supportedChainId);
              })
        )
      );

      const signedSales: OffChainSaleInput[] = [];
      for (const s of sales) {
        const signature = await saleSignature(s, provider);
        setInfo((prev) => {
          return { ...prev, signed: prev.signed++ };
        });
        signedSales.push({ ...s, signature });
      }

      // Create sales
      const response = await Promise.all(
        (
          await signedSales
        ).map(async (sale) => {
          return await createDeal({
            input: {
              kind: DealKind.Sale,
              sale
            }
          })
            .unwrap()
            .catch(() => {
              throw new Error('Listing failed !');
            });
        })
      );

      setStatus(StepStatus.DONE);
      complete(response.map((r) => r.createDeal));
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Listing failed !');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={status == StepStatus.DONE ? <Done total_listings={info.total} /> : `Listing`}
      step={step}
      currentStep={currentStep}
      status={status}
      el={status == StepStatus.IN_PROGRESS ? progress(info.total, info.signed) : undefined}
      error={error}
    />
  );
};

export default Listing;
