// 3rd
import { useEffect, useState, useRef } from 'react';
// Api
import { useLazyBalancesQuery } from 'api';

// Types
import { SearchNftSort, UserBalance } from 'types/generated';

/**
 * Hook to query collections
 */

type Props = {
  // page size (paging offset)
  size: number;
  // Collectin address
  collection?: string;
  // search keywords
  keywords?: string;
  // sort by method
  sort?: SearchNftSort;
  // the user address
  user: string;
  // Skip if true
  skip: boolean;
  // filter out sales where available_amount == 0
  filterSales?: boolean;
};

type Response = {
  userBalances: UserBalance[];
  error?: string | unknown;
  isLoading: boolean;
  loadMore: (reset?: boolean) => void;
  refresh: () => void;
};

// Filter out all sales where available_amount == 0
export const filter = (r: UserBalance[]): UserBalance[] =>
  r?.filter((balance) => Number(balance.available_amount) > 0);

const useBalances = ({
  collection,
  keywords,
  sort,
  user,
  skip,
  size = 50,
  filterSales = true
}: Props): Response => {
  const hasMore = useRef(true);
  const shouldReset = useRef(true);
  const page = useRef(1);
  const [balances, result] = useLazyBalancesQuery();
  const [userBalances, setUserBalances] = useState<UserBalance[]>([]);

  const trigger = () =>
    balances({
      input: {
        user,
        collection,
        keywords,
        sort
      },
      pagination: {
        page: page.current++,
        size
      }
    });

  useEffect(() => {
    if (!skip) trigger();
  }, []);

  useEffect(() => {
    shouldReset.current = true;
    hasMore.current = true;
    page.current = 1;
    trigger();
  }, [collection, keywords, sort, user]);

  useEffect(() => {
    if (!result.isSuccess) return;
    const userB = filterSales
      ? filter(result.data.balances.balances as UserBalance[])
      : (result.data.balances.balances as UserBalance[]);

    if (shouldReset.current) {
      shouldReset.current = false;
      hasMore.current = result.data.balances.hasMore;
      setUserBalances(userB);
    } else {
      setUserBalances([...userBalances, ...userB]);
    }
  }, [result.data]);

  const refresh = () => {
    shouldReset.current = true;
    page.current = 1;
    trigger();
  };

  const loadMore = () => {
    if (hasMore.current) trigger();
    trigger();
  };

  return {
    userBalances,
    error: result.error && 'Failed to load NFTs',
    isLoading: result.isLoading,
    loadMore,
    refresh
  };
};

export default useBalances;
