import React from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ReactComponent as SingleDark } from '../../../assets/create-nft/single-dark.svg';
import { ReactComponent as MultipleDark } from '../../../assets/create-nft/multiple-dark.svg';
import { ReactComponent as SingleLight } from '../../../assets/create-nft/single-light.svg';
import { ReactComponent as MultipleLight } from '../../../assets/create-nft/multiple-light.svg';
import { Button } from 'components';
import { TokenKind } from 'types';

type Props = {
  type: TokenKind;
  back: () => void;
  switchType: () => void;
};

const Header = ({ type, back, switchType }: Props) => {
  const theme = useTheme();

  const title =
    type == TokenKind.erc721 ? 'Create Single Collectible' : 'Create Multiple Collectibles';
  const subTitle = type == TokenKind.erc721 ? 'Switch to Multiple' : 'Switch to Single';

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: { xs: 2, sm: 5 } }}>
      <IconButton sx={{ alignSelf: 'flex-start' }} onClick={() => back()}>
        <ChevronLeftIcon />
      </IconButton>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flex: 1 }}>
        {type == TokenKind.erc721 ? 
          theme.palette.mode == 'dark' ? <SingleDark /> : <SingleLight /> : 
          theme.palette.mode == 'dark' ? <MultipleDark /> : <MultipleLight /> 
        }
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Button
        ctx="secondary"
        sx={{ alignSelf: { xs: 'flex-end', sm: 'flex-start' } }}
        onClick={() => switchType()}
      >
        <Typography variant="button">{subTitle}</Typography>
      </Button>
    </Box>
  );
};

export default Header;
