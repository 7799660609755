import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store';
import {
  Purchase,
  OwnerShipStep,
  Button,
  Miniature,
  MiniatureInfo,
  Price,
  NumberInput
} from 'components';
import { Divider } from '@mui/material';
import { Renderer } from '../Renderer';
import { NFTSale, PriceSymbol } from 'types';
import { priceFormatter } from 'lib/format';

const Erc1155Renderer = (props: {
  nftSale: NFTSale;
  qty: number;
  setQty: (qty: number) => void;
  error?: string;
}) => {
  const { nftSale, qty, setQty, error } = props;
  const { sale, thumbnail } = nftSale;
  const price = sale.unitary_price_float;
  const availableAmount = Number(sale.quantity);
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Miniature thumbnail={thumbnail} />
      <MiniatureInfo nft={nftSale} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          {error ? (
            <Typography variant="caption" color="red">
              {error}
            </Typography>
          ) : (
            <Typography variant="caption" color="text.secondary">{`${
              qty || 1
            }/${availableAmount}`}</Typography>
          )}
          <NumberInput
            sx={{ width: '4.5rem', borderRadius: '0.5rem' }}
            value={qty || 1}
            step={1}
            onChange={(value) => setQty(value as number)}
          />
        </Stack>
        <Price value={priceFormatter.format(price)} symbol={PriceSymbol.AVAX} variant="h4" />
      </Box>
    </Box>
  );
};

const BuyItem = () => {
  const nftSale = useSelector((global: GlobalState) => global.state.nftSale);
  const [error, setError] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  // TODO: Add an error state for the 'BuyItem' modal
  const [newNftSale, setNewNftSale] = useState({ ...nftSale! });
  const [qty, setQty] = useState(1);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const needUserValidation =
    nftSale?.collection.kind == 'erc1155' && Number(nftSale?.sale.quantity) > 1;

  useEffect(() => {
    if (needUserValidation) setCurrentStep(0);
    else setCurrentStep(1);
  }, []);

  const set = (qty: number) => {
    setError('');
    setQty(qty);
  };

  const validate = () => {
    if (qty > Number(nftSale?.sale.quantity)) setError(`Max ${nftSale?.sale.quantity}`);
    else {
      setNewNftSale({
        ...newNftSale,
        sale: { ...newNftSale.sale, quantity: (qty || 1).toString() }
      });
      setCurrentStep(1);
    }
  };

  return (
    <Renderer
      title="Buy Item"
      customRenderer={
        needUserValidation ? (
          <Erc1155Renderer nftSale={nftSale} qty={qty} setQty={set} error={error} />
        ) : undefined
      }
      nftSale={!needUserValidation ? nftSale : undefined}
    >
      {needUserValidation && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            ctx="primary"
            sx={{ width: '8rem' }}
            onClick={validate}
            disabled={currentStep > 0}
          >
            <Typography variant="button">Validate</Typography>
          </Button>
        </Box>
      )}
      <Divider sx={{ my: 3 }} variant="middle" />
      {/** Steps */}
      <Stack spacing={2}>
        <OwnerShipStep step={1} currentStep={currentStep} complete={() => setCurrentStep(2)} />
        <Purchase step={2} currentStep={currentStep} complete={() => undefined} sale={newNftSale} />
      </Stack>
    </Renderer>
  );
};

export default BuyItem;
