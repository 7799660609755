import React, { ReactChild } from 'react';
import { SxProps, Theme, Link as MuiLink } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import Web from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import { ReactComponent as Discord } from '../../assets/dash/discord.svg';
import { SquareIconButton } from 'components';

type Props = {
  instagram?: string | null;
  twitter?: string | null;
  facebook?: string | null;
  website?: string | null;
  discord?: string | null;
  telegram?: string | null;
};

type LinkProps = {
  children: ReactChild;
  link: string;
};

const sx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary
};

const Link = ({ link, children }: LinkProps) => {
  return (
    <MuiLink target="_blank" href={link} underline="none">
      {children}
    </MuiLink>
  );
};

const SocialNetworks = ({ instagram, twitter, facebook, website, discord, telegram }: Props) => {
  return (
    <>
      {twitter && (
        <Link link={twitter}>
          <SquareIconButton sx={sx} size="small">
            <TwitterIcon fontSize="small" />
          </SquareIconButton>
        </Link>
      )}
      {instagram && (
        <Link link={instagram}>
          <SquareIconButton sx={sx} size="small">
            <InstagramIcon fontSize="small" />
          </SquareIconButton>
        </Link>
      )}
      {discord && (
        <Link link={discord}>
          <SquareIconButton sx={sx} size="small">
            <Discord style={{ width: 20, height: 20 }} fontSize="small" />
          </SquareIconButton>
        </Link>
      )}
      {telegram && (
        <Link link={telegram}>
          <SquareIconButton sx={sx} size="small">
            <TelegramIcon fontSize="small" />
          </SquareIconButton>
        </Link>
      )}
      {facebook && (
        <Link link={facebook}>
          <SquareIconButton sx={sx} size="small">
            <FacebookIcon fontSize="small" />
          </SquareIconButton>
        </Link>
      )}
      {website && (
        <Link link={website}>
          <SquareIconButton sx={sx} size="small">
            <Web fontSize="small" />
          </SquareIconButton>
        </Link>
      )}
    </>
  );
};

export default SocialNetworks;
