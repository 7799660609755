import React from 'react';
import {
  FormControlLabel,
  FormGroup,
  RadioGroup as MuiRadioGroup,
  Typography
} from '@mui/material';
import { KeyValuePair, NumberOrString } from 'types';
import { Radio } from 'components';

type Props = {
  defaultValue: NumberOrString;
  empty?: string;
  pairs: KeyValuePair<NumberOrString, NumberOrString>[];
  onChange: (value: string) => void;
};

const radioButton = (key: string | undefined, value: string, checked: boolean) => {
  return (
    <FormControlLabel
      sx={{ px: 1 }}
      key={key}
      value={key}
      control={
        <Radio
          size="small"
          checked={checked}
          sx={{
            '&, &.Mui-checked': {
              color: 'text.primary'
            }
          }}
        />
      }
      label={
        <Typography variant="body2" color={checked ? 'text.primary' : 'text.secondary'}>
          {value}
        </Typography>
      }
    />
  );
};

const RadioGroup = ({ pairs, defaultValue, onChange, empty }: Props) => {
  return (
    <FormGroup>
      <MuiRadioGroup value={defaultValue} onChange={(_, value) => onChange(value)}>
        {empty && radioButton('', empty, defaultValue == '')}
        {pairs.map((entry) =>
          radioButton(entry.key as string, entry.value as string, defaultValue == entry.key)
        )}
      </MuiRadioGroup>
    </FormGroup>
  );
};

export default RadioGroup;
