import React, { useEffect, useMemo } from 'react';

import { Box, BoxProps, debounce } from '@mui/material';
import { Search } from 'components';

type SearchInputProps = {
  keyword: string;
  set: (keyword: string) => void;
} & BoxProps;

const SearchInput = ({ keyword, set, ...rest }: SearchInputProps) => {
  useEffect(() => {
    return () => {
      debouncedSearchChange.clear();
    };
  }, []);

  const handleSearchChange = (search: string): void => {
    if (search) set(search);
  };

  const clearInput = () => {
    set('');
  };

  const debouncedSearchChange = useMemo(() => debounce(handleSearchChange, 500), [keyword]);
  return (
    <Box {...rest}>
      <Search
        placeholder="Search"
        inputChange={debouncedSearchChange}
        clearInput={clearInput}
        sx={{ borderRadius: '5.625rem', height: '3rem' }}
      />
    </Box>
  );
};

export default SearchInput;
