import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import Avatar from 'components/Avatar';
import { GenericCardProps, GenericCardInfo } from 'types';
import { ImageContainer } from 'components';

const StyledHomeCard = styled(Box)({
  width: '100%',
  borderRadius: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  background: 'transparent'
});

const InfoRenderer = (info: GenericCardInfo) => {
  if (!info) return null;
  const { avatar, name, certified, count } = info;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        pt: '0.5rem',
        pb: '2rem',
        px: '0.5rem'
      }}
    >
      <Avatar src={avatar} approved={certified} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: 0
        }}
      >
        <Typography
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          variant="body1"
          color="text.primary"
        >
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {count} items
        </Typography>
      </Box>
    </Box>
  );
};

const GenericCard = ({ image, info }: GenericCardProps) => {
  return (
    <StyledHomeCard>
      <Box sx={{ borderRadius: '1.25rem', overflow: 'hidden' }}>
        <ImageContainer src={image} />
      </Box>
      {info && InfoRenderer(info)}
    </StyledHomeCard>
  );
};

export default GenericCard;
