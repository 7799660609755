import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Property, PriceRangeType, NumberOrString } from 'types';
import { ActivityType, Collection, SearchNftSort } from 'types/generated';

type ContextType = {
  activityTypes: ActivityType[];
  categories: NumberOrString[];
  salesType: NumberOrString;
  price: PriceRangeType;
  keywords: string;
  collection?: Collection;
  properties: Property[];
  sortBy: SearchNftSort;
  collections: Collection[];
  period: NumberOrString;
  /**
   * Setters
   */
  setActivityTypes: (type: ActivityType[]) => void;
  setCategories: (categories: NumberOrString[]) => void;
  setSalesType: (salesType: NumberOrString) => void;
  setPrice: (price: PriceRangeType) => void;
  setKeywords: (keywords: string) => void;
  setCollection: (collection?: Collection) => void;
  setProperties: (property: Property) => void;
  setSortBy: (sortBy: SearchNftSort) => void;
  clearAll: () => void;
  setCollections: (collections: Collection[]) => void;
  setPeriod: (period: NumberOrString) => void;
};

const defaultValue = {
  activityTypes: [],
  categories: [],
  salesType: '',
  price: {},
  keywords: '',
  properties: [],
  sortBy: SearchNftSort.TokenIdAsc,
  collections: [],
  period: '',
  /**
   *  Setters
   */
  setActivityTypes: () => undefined,
  setCategories: () => undefined,
  setSalesType: () => undefined,
  setPrice: () => undefined,
  setKeywords: () => undefined,
  clearAll: () => undefined,
  setCollection: () => undefined,
  setProperties: () => undefined,
  setSortBy: () => undefined,
  setCollections: () => undefined,
  setPeriod: () => undefined
};

const FiltersContext = createContext<ContextType>(defaultValue);

export const useFilters = (): ContextType => {
  return useContext(FiltersContext);
};

type Props = {
  properties?: Property[];
  sortBy?: SearchNftSort;
  children: ReactNode;
};

export const FiltersProvider = (props: Props): JSX.Element => {
  const [activityTypes, setActivityTypes] = useState<ActivityType[]>([]);
  const [sortBy, setSortBy] = useState<SearchNftSort>(props.sortBy || SearchNftSort.TokenIdAsc);
  const [categories, setCategories] = useState<NumberOrString[]>([]);
  const [salesType, setSalesType] = useState<NumberOrString>('');
  const [price, setPrice] = useState<PriceRangeType>({});
  const [keywords, setKeywords] = useState('');
  const [collection, setCollection] = useState<Collection | undefined>(undefined);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [period, setPeriod] = useState<NumberOrString>('');

  // TODO remove it from filters provider
  const [innerProperties, setInnerProperties] = useState<Property[]>(props?.properties || []);
  const setProperties = (property: Property) => {
    const updatedProps = [...innerProperties];
    const index = updatedProps.findIndex((p) => p.name === property.name);
    if (index > -1) {
      updatedProps[index] = { ...property };
      setInnerProperties(updatedProps);
    }
  };

  const clearAll = () => {
    setCategories([]);
    setSalesType('');
    setPrice({});
    setKeywords('');
    setCollection(undefined);
    setActivityTypes([]);
    setPeriod('');
  };

  useEffect(() => {
    if (props.properties) setInnerProperties(props.properties);
  }, [props.properties]);

  const value = {
    activityTypes,
    categories,
    salesType,
    price,
    keywords,
    collection,
    collections,
    period,
    setActivityTypes,
    setCategories,
    setSalesType,
    setPrice,
    setKeywords,
    setCollection,
    sortBy,
    setSortBy,
    clearAll,
    setCollections,
    properties: innerProperties,
    setProperties,
    setPeriod
  };
  return <FiltersContext.Provider value={value}>{props.children}</FiltersContext.Provider>;
};
