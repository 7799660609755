import * as React from 'react';
import { KeyValuePair, NumberOrString } from 'types';
import { Typography, BoxProps, MenuList, Popper, Grow, ClickAwayListener } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledDropDown, PopperContainer, InnerPopperContainer, StyledMenuItem } from '../utils';
import { Radio, Checkbox } from 'components';

type Props = {
  pairs: KeyValuePair<NumberOrString, NumberOrString>[];
  empty: NumberOrString;
  onSelectChange?: (key: NumberOrString[]) => void;
  disablePortal?: boolean;
  keys: NumberOrString[];
} & BoxProps;

const menuItem = (
  key: NumberOrString,
  value: NumberOrString,
  checked: boolean,
  defaultSelectedKey: boolean,
  click: (key: NumberOrString) => void
) => {
  return (
    <StyledMenuItem key={`${key}-${value}`} onClick={() => click(key)}>
      {defaultSelectedKey ? <Radio checked={checked} /> : <Checkbox checked={checked} />}
      {value}
    </StyledMenuItem>
  );
};

const DropDownMultiSelect = ({
  pairs,
  onSelectChange,
  empty,
  disablePortal = true,
  keys,
  ...rest
}: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [selected, setSelected] = React.useState<NumberOrString[]>([]);

  React.useEffect(() => {
    setSelected(keys);
  }, [keys]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleClick = (key: NumberOrString, empty: boolean) => {
    if (empty) {
      setSelected([]);
      onSelectChange?.([]);
    } else {
      const keys = selected.includes(key) ? selected.filter((s) => s !== key) : [...selected, key];
      setSelected(keys);
      onSelectChange?.(keys);
    }
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  return (
    <>
      <StyledDropDown
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        {...rest}
      >
        <Typography
          variant="body2"
          sx={{
            ml: 1,
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {selected.length > 0 ? selected.join(', ') : empty}
        </Typography>
        <KeyboardArrowDownIcon />
      </StyledDropDown>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal={disablePortal}
        style={{ width: anchorRef.current?.clientWidth, zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <PopperContainer>
              <ClickAwayListener onClickAway={handleClose}>
                <InnerPopperContainer id="composition-menu">
                  <MenuList onKeyDown={handleListKeyDown}>
                    {menuItem(empty, empty, selected.length == 0, true, (key) =>
                      handleClick(key, true)
                    )}
                    {pairs.map(({ key, value }) =>
                      menuItem(key, value, selected.includes(key), false, (key) =>
                        handleClick(key, false)
                      )
                    )}
                  </MenuList>
                </InnerPopperContainer>
              </ClickAwayListener>
            </PopperContainer>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropDownMultiSelect;
