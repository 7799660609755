// 3rd party libs
import React, { ReactNode } from 'react';
import { Box, Typography, Stack, CircularProgress } from '@mui/material';

type ChartContainerProps = {
  title: string;
  period?: string;
  children: ReactNode;
  loading?: boolean;
  value?: string;
};

type HeaderProps = {
  title: string;
  period?: string;
  value?: string;
};

const Header = ({ title, value, period }: HeaderProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Stack>
        <Typography variant="body1" color="text.secondary">
          {title}
        </Typography>
        {value !== undefined && (
          <Typography variant="h4" color="text.primary">
            {value}
          </Typography>
        )}
      </Stack>
      <Typography variant="body1" color="text.secondary">
        {period}
      </Typography>
    </Box>
  );
};

const Loading = () => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={50} />
    </Box>
  );
};

export const ChartContainer = ({
  title,
  value,
  period,
  loading,
  children
}: ChartContainerProps) => {
  return (
    <Stack
      spacing={1}
      sx={{ flex: 1, padding: '1rem', border: (theme) => theme.border, borderRadius: '0.9375rem' }}
    >
      <Header title={title} value={value} period={period} />
      <Box
        sx={{
          height: '15rem',
          borderRadius: '0.9375rem'
        }}
      >
        {loading ? <Loading /> : children}
      </Box>
    </Stack>
  );
};
