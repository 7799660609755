import React, { useState } from 'react';
import { Box, Stack, Typography, styled, Divider, SvgIcon } from '@mui/material';
import { InputField, NumberInput, SquareIconButton } from 'components';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { TokenKind, NumberOrString, Form, AssetProperty } from 'types';

type Props = {
  type: TokenKind;
  form: Form;
  onChange: (form: Form) => void;
};

type Properties = {
  properties: AssetProperty[];
  add: (property: AssetProperty) => void;
  remove: (index: number) => void;
};

// Placeholders
const NAME = 'e.g. «Redeemable NFT»';
const DESCRIPTION = 'e.g. «Redeemable NFT Card with logo»';
const EXT_URL = 'e.g. «https://xxx…………»';

const StyledTypography = styled(Typography)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(1, 2),
  borderRadius: '0.625rem',
  background: theme.input.background,
  display: 'flex',
  alignItems: 'center'
}));

const propsSx = {
  height: { xs: '3rem', sm: '3.5rem' },
  fontSize: { xs: 14, sm: 16 },
  px: 2,
  borderRadius: '0.5rem'
};

const typographySx = {
  height: { xs: '3rem', sm: '3.5rem' },
  fontSize: { xs: 14, sm: 16 }
};

const sx = {
  mt: 1,
  height: { xs: '3rem', sm: '3.5rem' },
  fontSize: { xs: 14, sm: 16 },
  px: 2,
  borderRadius: '0.5rem'
};

const RenderProperties = ({ properties, add, remove }: Properties) => {
  const [property, setProperty] = useState<AssetProperty>({
    trait_type: '',
    value: ''
  });

  const enterOrClick = () => {
    if (property.trait_type && property.value) {
      setProperty({ trait_type: '', value: '' });
      add({ trait_type: property.trait_type, value: property.value });
    }
  };

  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        Properties (Optional)
      </Typography>
      <Stack sx={{ border: (theme) => theme.border, borderRadius: '0.9375rem', p: 2 }} spacing={2}>
        <Stack direction="row" alignItems="center">
          <SvgIcon>
            <AddIcon />
          </SvgIcon>
          <Typography variant="body2" color="text.primary">
            Add property
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <InputField
            flex={1}
            sx={propsSx}
            value={property.trait_type}
            set={(v) => setProperty({ ...property, trait_type: v })}
            placeholder={'Trait type'}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                enterOrClick();
                ev.preventDefault();
              }
            }}
          />
          <InputField
            flex={1}
            sx={propsSx}
            value={property.value}
            set={(v) => setProperty({ ...property, value: v })}
            placeholder={'Value'}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                enterOrClick();
                ev.preventDefault();
              }
            }}
          />
          <SquareIconButton onClick={() => enterOrClick()}>
            <AddIcon />
          </SquareIconButton>
        </Box>
        {properties && properties.length > 0 && (
          <>
            <Divider sx={{ my: 4 }} />
            <Box sx={{ px: 2 }}>
              <Typography variant="subtitle1" color="text.primary" fontWeight="600" sx={{ mb: 1 }}>
                Your properties
              </Typography>
              {properties.map((key, i) => {
                return (
                  <Box key={i} sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
                    <StyledTypography variant="body1" color="text.primary" sx={typographySx}>
                      {key.trait_type}
                    </StyledTypography>
                    <StyledTypography variant="body1" color="text.primary" sx={typographySx}>
                      {key.value}
                    </StyledTypography>
                    <SquareIconButton onClick={() => remove(i)}>
                      <DeleteIcon />
                    </SquareIconButton>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Stack>
    </Stack>
  );
};

const NFTInfo = ({ form, type, onChange }: Props) => {
  const { name, description, external_url, properties } = form;

  const handleChange = (key: string, value: NumberOrString) => {
    onChange({ ...form, [key]: value });
  };

  return (
    <Stack>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 4 }}>
        Item details
      </Typography>
      <Stack spacing={4}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <InputField
            flex={1}
            sx={sx}
            label={'Name'}
            value={name}
            set={(v) => handleChange('name', v)}
            placeholder={NAME}
          />
          {type == TokenKind.erc1155 && (
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary">
                Number of copies
              </Typography>
              <NumberInput
                sx={{
                  height: { xs: '3rem', sm: '3.5rem', borderRadius: '0.5rem', width: '10rem' }
                }}
                step={1}
                value={form.copies}
                onChange={(v) => handleChange('copies', v)}
              />
            </Stack>
          )}
        </Box>

        <InputField
          sx={sx}
          label={'Description (Optional)'}
          value={description}
          set={(v) => handleChange('description', v)}
          placeholder={DESCRIPTION}
        />
        <InputField
          sx={sx}
          label={'External Url (Optional)'}
          value={external_url}
          set={(v) => handleChange('external_url', v)}
          placeholder={EXT_URL}
        />
        <RenderProperties
          properties={properties}
          add={(property) => {
            const newProperties = [...properties, property];
            onChange({ ...form, properties: newProperties });
          }}
          remove={(key) => {
            const newProperties = [...properties];
            newProperties.splice(key, 1);
            onChange({ ...form, properties: newProperties });
          }}
        />
      </Stack>
    </Stack>
  );
};

export default NFTInfo;
