import React, { useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField, styled, Paper, Typography } from '@mui/material';
import { DropDownBasic } from 'components';
import { useEffect } from 'react';
import { addDays } from 'date-fns';

type DatePickerKey = 'custom' | '1' | '3' | '7' | '30';

type Props = {
  defaultKey?: DatePickerKey;
  date: Date | null;
  onChange: (date: Date) => void;
  modal?: boolean;
};

export const options = [
  {
    key: 'custom',
    value: <Typography variant="body2">Custom</Typography>
  },
  {
    key: '1',
    value: <Typography variant="body2">1 day</Typography>
  },
  {
    key: '3',
    value: <Typography variant="body2">3 days</Typography>
  },
  {
    key: '7',
    value: <Typography variant="body2">7 days</Typography>
  },
  {
    key: '30',
    value: <Typography variant="body2">30 days</Typography>
  }
];

export const BootstrapInput = styled(TextField)(({ theme }) => ({
  outline: 'none',
  flex: 1,
  border: 0,
  '& .MuiInputBase-input': {
    fontSize: 15,
    borderRadius: '0.5rem',
    background: 'transparent',
    padding: '0.8rem 1rem'
    //color: theme.palette.text.secondary
  },
  '& .MuiInput-root': {
    height: '3.5rem',
    width: '100%',
    background: 'transparent',
    borderRadius: '0.5rem',
    flexDirection: 'row-reverse',
    '&:before, :after, :hover:not(.Mui-disabled):before': {
      borderBottom: 0
    },
    '& .MuiSvgIcon-root': { color: theme.palette.text.secondary }
  }
}));

const DatePicker = ({ date, onChange, defaultKey = '30', modal }: Props) => {
  const [option, setOption] = useState(defaultKey);

  useEffect(() => {
    if (option !== 'custom') {
      onChange(addDays(new Date(), Number(option)));
    } else onChange(new Date());
  }, [option]);

  return (
    <Paper
      elevation={0}
      sx={{
        background: (theme) => theme.input.background,
        display: 'flex',
        py: 0.5,
        height: '3.5rem',
        borderRadius: '0.5rem',
        alignItems: 'center'
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          disablePast
          value={date}
          onChange={(d) => d && onChange(d)}
          InputProps={{ style: { height: '100%' } }}
          renderInput={(params) => <BootstrapInput {...params} variant="standard" />}
        />
      </LocalizationProvider>
      <DropDownBasic
        modal={modal}
        defaultKey={defaultKey}
        options={options}
        onOptionChange={(v) => setOption(v as DatePickerKey)}
      />
    </Paper>
  );
};

export default DatePicker;
