import React, { useEffect, useState } from 'react';
import { Stack, Divider, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { ApproveAsset, Chips, TransferTokens } from 'components';
import { TokenKind, TransferAssets, TransferedBalance } from 'types';
import { Renderer } from '../Renderer';
import { transferTransaction } from 'store/actions';
import { kalaoApi } from 'api';

const renderer = (key: string, thumbnails: string[]) => {
  return (
    <Stack alignItems="center" spacing={1} sx={{ flex: 1 }}>
      <Typography variant="body1" color="text.primary">
        {key}
      </Typography>
      <Chips
        thumbnails={thumbnails}
        onClick={() => undefined}
        maxChips={3}
        sx={{ left: 0, border: (theme) => theme.border, cursor: 'default' }}
      />
    </Stack>
  );
};

const rendererTo = (to: string) => {
  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="h6" color="text.secondary">
        To
      </Typography>
      <Typography variant="body1" color="text.primary">
        {to}
      </Typography>
    </Box>
  );
};

const AssetsRenderer = ({ erc721, erc1155 }: Omit<TransferAssets, 'to'>) => {
  const erc721s = erc721.map((erc) => erc.nft.thumbnail);
  const erc1155s = erc1155.map((erc) => erc.nft.thumbnail);
  return (
    <Stack direction="row" sx={{ p: '1rem' }} alignItems="center" justifyContent="center">
      {erc721s.length > 0 && renderer('ERC721', erc721s)}
      {erc1155s.length > 0 && renderer('ERC1155', erc1155s)}
    </Stack>
  );
};

export const getAddresses = (userBalances: TransferedBalance[]) => {
  const addresses = new Set<string>();

  userBalances.forEach((balance) => {
    const { collection } = balance.nft;
    addresses.add(collection.address);
  });
  return [...addresses.values()];
};

const Transfer = () => {
  const dispatch = useDispatch();
  const assets = useSelector(
    (global: GlobalState) => global.state.transferAssets || { erc721: [], erc1155: [], to: '' }
  );
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    setCurrentStep(1);
  }, [assets]);

  const setTransaction = (transferTx: string) => {
    dispatch(transferTransaction.create({ transferTx }));
    dispatch(kalaoApi.util.invalidateTags(['NftDetails']));
  };

  const showErc721 = assets.erc721.length > 0;
  const showErc1155 = assets.erc1155.length > 0;

  return (
    <Renderer title="Transfer">
      <AssetsRenderer erc721={assets.erc721} erc1155={assets.erc1155} />
      {rendererTo(assets.to)}
      <Divider sx={{ my: 3 }} variant="middle" />
      <Stack spacing={2} sx={{ mt: 2 }}>
        <ApproveAsset
          step={1}
          currentStep={currentStep}
          complete={() => (showErc721 ? setCurrentStep(2) : setCurrentStep(3))}
          collections={[...getAddresses(assets.erc721), ...getAddresses(assets.erc1155)]}
        />
        {showErc721 && (
          <TransferTokens
            title="ERC721 NFT(s)"
            tokenKind={TokenKind.erc721}
            step={2}
            userBalances={assets.erc721}
            sendTo={assets.to}
            currentStep={currentStep}
            complete={(tx) => (showErc1155 ? setCurrentStep(3) : setTransaction(tx))}
            disable_redirect={showErc1155 ? true : false}
          />
        )}
        {showErc1155 && (
          <TransferTokens
            title="ERC1155 NFT(s)"
            tokenKind={TokenKind.erc1155}
            step={3}
            userBalances={assets.erc1155}
            currentStep={currentStep}
            sendTo={assets.to}
            complete={(tx) => setTransaction(tx)}
            disable_redirect={false}
          />
        )}
      </Stack>
    </Renderer>
  );
};

export default Transfer;
