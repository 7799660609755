// 3rd party libs
import React, { useEffect, useState } from 'react';
import { Button, Container, Gallery, Footer, InputField } from 'components';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import { Collection, SearchNftSort, UserBalance } from 'types/generated';
import { FiltersProvider, useFilters } from 'providers';
import { useBalances, useNotistack } from 'hooks';
import { GalleryFilters } from 'helpers/gallery/GalleryFilters';
import { useOwnedNftsCollectionsQuery } from 'api';
import { SelectedNFTs } from './SelectedNFTs';
import { useDispatch, useSelector } from 'react-redux';
import { batch, openModal, transfer } from 'store/actions';
import { TransferedBalance } from 'types';
import { GlobalState } from 'store';

const Title = () => {
  return (
    <Stack spacing={2} sx={{ mt: { md: 3, lg: 5 }, mb: 2 }}>
      <Typography variant="h1" textAlign="start">
        Transfer NFT
      </Typography>
      <Typography variant="h5" fontWeight={500}>
        Select the NFTs you want to transfer
      </Typography>
    </Stack>
  );
};

const sx = {
  mt: 1,
  height: { xs: '3rem', sm: '3.5rem' },
  fontSize: 14,
  px: 2,
  borderRadius: '0.5rem'
};

const TransferRenderer = () => {
  const dispatch = useDispatch();
  const transferTx = useSelector((global: GlobalState) => global.state.transferTx);
  const { info } = useNotistack();
  const { account } = useWeb3React();
  const { collection, keywords, sortBy, setCollections } = useFilters();
  const [selected, setSelected] = useState<Map<string, TransferedBalance>>(
    new Map<string, TransferedBalance>()
  );
  const [target, setTarget] = useState('');
  // Query balances
  const { userBalances, loadMore, error, refresh } = useBalances({
    collection: collection?.address,
    user: account || '',
    keywords: keywords || undefined,
    size: 50,
    sort: sortBy as SearchNftSort,
    skip: !account,
    filterSales: false
  });

  useEffect(() => {
    if (transferTx) {
      refresh();
      const record = new Map(selected);
      record.clear();
      setSelected(record);
    }
  }, [transferTx]);

  // Collections of all owned NFTs
  const { data } = useOwnedNftsCollectionsQuery({ userAddress: account }, { skip: !account });

  useEffect(() => {
    if (data) {
      const collections = data.ownedNFTsCollections.filter(
        (collection) => collection.name
      ) as Collection[];
      setCollections(collections);
    }
  }, [data]);

  const select = (userBalance: UserBalance) => {
    const record = new Map(selected);
    const { nft } = userBalance;
    if (record.has(nft.asset_id)) record.delete(nft.asset_id);
    else record.set(nft.asset_id, { ...userBalance, amountToTransfer: 1 });
    setSelected(record);
  };

  const update = (userBalance: TransferedBalance) => {
    const record = new Map(selected);
    record.set(userBalance.nft.asset_id, userBalance);
    setSelected(record);
  };

  const onTransfer = () => {
    if (selected.size == 0) {
      info('No items selected');
      return;
    }
    if (!target) {
      info("Mention the address you're sending this transaction to");
      return;
    }
    const balances = [...selected.values()];
    const erc1155: TransferedBalance[] = [];
    const erc721: TransferedBalance[] = [];

    balances?.forEach((balance) => {
      const kind = balance.nft.collection.kind;
      if (kind == 'erc721') erc721.push(balance);
      if (kind == 'erc1155') erc1155.push(balance);
    });

    dispatch(
      batch.create({
        actions: [
          transfer.create({ transferAssets: { erc721, erc1155, to: target } }),
          openModal.create({ modal: 'TRANSFER' })
        ]
      })
    );
  };

  const isSelected = selected.size > 0;

  return (
    <>
      <Container sx={{ paddingBottom: '5rem' }}>
        <Stack>
          <Title />
          <Grid container direction="row" spacing={{ xs: 2, sm: 2, md: 4, lg: 8 }} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <GalleryFilters />
              <Gallery
                userBalances={userBalances}
                error={error}
                selected={selected}
                select={select}
                loadMore={loadMore}
                showBalance={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Stack spacing={2}>
                <Typography variant="h5" fontWeight={500}>
                  Bulk Transfer
                </Typography>
                <SelectedNFTs
                  selected={[...selected.values()]}
                  update={(userBalance) => update(userBalance)}
                  remove={(userBalance) => select(userBalance)}
                />
                {isSelected && (
                  <>
                    <Box>
                      <Typography variant="h5" fontWeight={600} sx={{ mb: 2 }}>
                        Send To
                      </Typography>
                      <InputField
                        sx={sx}
                        value={target}
                        set={(v) => setTarget(v as string)}
                        placeholder={'e.g. 0x85b4...'}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button ctx="primary" sx={{ px: '2rem', mt: 2 }} onClick={onTransfer}>
                        <Typography variant="button">Transfer Selected</Typography>
                      </Button>
                    </Box>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

const Transfer = () => {
  return (
    <FiltersProvider sortBy={SearchNftSort.RecentlyReceived}>
      <TransferRenderer />
    </FiltersProvider>
  );
};

export default Transfer;
