// 3rd party libs
import React from 'react';
import { Box, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useResize } from 'providers';
import { goToNft, initNftWithSale, InnerItem } from 'helpers/nfts';
import { Container } from 'components';
import { VirtuosoGrid } from 'react-virtuoso';
import { VContainer, VList } from 'helpers/virtuosoHelpers';
import useLikes from 'hooks/useLikes';
import { AddressRouteParams } from 'types';
import { goToCollection } from 'helpers/collections';

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

const SIZE = 30;
const INIT_NFTS = [...Array(SIZE).keys()].map(() => initNftWithSale);

export const Liked = (): JSX.Element => {
  const navigate = useNavigate();
  const { config } = useResize();
  const { address } = useParams<AddressRouteParams>();
  const { nfts, error, isLoading, loadMore } = useLikes({
    size: SIZE,
    skip: !address,
    user: address || ''
  });

  const itemContent = (index: number) => {
    const nft = isLoading ? INIT_NFTS[index] : nfts[index];
    return (
      nft && (
        <InnerItem
          nft={nft}
          goTo={() => navigate(goToNft(nft.asset_id))}
          goToCollection={() => navigate(goToCollection(nft.collection.address))}
          isLoading={isLoading}
        />
      )
    );
  };

  return (
    <Root>
      <Container>
        <VirtuosoGrid
          useWindowScroll={true}
          totalCount={isLoading ? SIZE : nfts.length}
          endReached={() => loadMore()}
          overscan={500}
          components={{
            Item: VContainer(config),
            List: VList
          }}
          itemContent={itemContent}
        />
      </Container>
    </Root>
  );
};
