import React, { useEffect, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { StepStatus } from 'types';
import Step from '../Step';
import { Button } from 'components';
import { useWeb3React } from '@web3-react/core';
import { approveAll } from 'services/exchange';

type Props = {
  step: number;
  currentStep: number;
  // Collections addresses
  collections: string[];
  // callback, called once the step has been completed successfully
  complete: () => void;
};

type SignProps = {
  status: StepStatus;
  onSign: () => void;
};

const Sign = ({ onSign, status }: SignProps) => {
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        We’ll ask your approval for the marketplace to access your collection token. This is a
        one-time only operation.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {status !== StepStatus.IN_PROGRESS && (
          <Button
            ctx="primary"
            onClick={() => onSign()}
            sx={{ mt: 1, borderRadius: '0.625rem', width: '6rem', marginLeft: '-3.84rem' }}
          >
            <Typography variant="button">Approve</Typography>
          </Button>
        )}
      </Box>
    </Stack>
  );
};

const ApproveAsset = ({ step, currentStep, complete, collections }: Props) => {
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();
  const [approval, setApproval] = useState<{ total: number; approved: number }>({
    total: 0,
    approved: 0
  });
  const { account, hooks } = useWeb3React();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
    }
  }, [currentStep]);

  const approve = async () => {
    if (!account || !provider) {
      setStatus(StepStatus.ERROR);
      setError('Unauthenticated');
      return;
    }

    setStatus(StepStatus.IN_PROGRESS);
    setApproval({ ...approval, total: collections.length });

    try {
      await approveAll(provider, account, collections, (approved) =>
        setApproval((prev) => {
          return { ...prev, approved: approved !== undefined ? approved : prev.approved++ };
        })
      );
      // All collections were approved
      StepStatus.DONE
      complete();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Approval failed !');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={
        status == StepStatus.IN_PROGRESS || status == StepStatus.DONE ? (
        `Approve Assets ${approval.approved}/${approval.total}`
        ) : 'Approve Assets'
      }
      step={step}
      currentStep={currentStep}
      status={status}
      el={
        status == StepStatus.STARTED || status == StepStatus.IN_PROGRESS ? (
          <Sign status={status} onSign={approve} />
        ) : undefined
      }
      error={error}
    />
  );
};

export default ApproveAsset;
