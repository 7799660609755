import React from 'react';
import { Stack, Typography } from '@mui/material';
import { DatePicker } from 'components';

type Props = {
  label: string;
  date: Date;
  set: (date: Date) => void;
};

export const StartingDate = ({ label, date, set }: Props) => {
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <DatePicker date={date} onChange={(date) => set(date)} defaultKey="custom" />
    </Stack>
  );
};
