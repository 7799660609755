// 3rd party libs
import React, { useEffect } from 'react';
import { Stack, Typography, Grid } from '@mui/material';
import { Avatar, Button } from 'components';
import { useDropzone } from 'react-dropzone';

type Props = {
  avatar?: string;
  set: (url: string, file?: File) => void;
};

const FileUploader = ({ avatar, set }: Props) => {
  const { open, getRootProps, getInputProps } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/*': []
    },
    onDrop: (files) => {
      set(URL.createObjectURL(files[0]), files[0]);
    }
  });

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      avatar && URL.revokeObjectURL(avatar);
    };
  }, []);

  return (
    <Grid item xs={12} sm={5} md={4} lg={4}>
      <Stack spacing={3}>
        <Typography variant="h4" color="text.primary" fontWeight={500}>
          Profile photo
        </Typography>
        <Stack
          spacing={3}
          sx={{ border: (theme) => theme.border, borderRadius: '1.25rem', p: '2rem' }}
          alignItems="center"
          {...getRootProps({ className: 'dropzone' })}
        >
          <input {...getInputProps()} />
          <Avatar src={avatar || ''} sx={{ width: '10rem', height: '10rem' }} />
          <Button
            ctx="secondary"
            sx={{ border: 0, background: (theme) => theme.input.background }}
            onClick={open}
          >
            <Typography variant="button">Upload Picture</Typography>
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default FileUploader;
