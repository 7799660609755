import React from 'react';
import { styled, Box } from '@mui/material';
import { ResizeToggle } from 'components';

// Context
import { useResize } from 'providers';
// Components
import { FilterSection } from './FilterSection';

import { Nfts } from './Nfts';
import { Collection, CollectionStats } from 'types/generated';
import TopSection from './TopSection';

type Props = {
  stats: CollectionStats;
  collection: Collection;
};

// Styling
const ItemsContainer = styled(Box)({
  margin: '1% 2%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
});

const Items = (props: Props) => {
  const { size, resize } = useResize();

  return (
    <Box sx={{ display: 'flex' }}>
      <FilterSection />
      <ItemsContainer>
        {/** search ... */}
        <TopSection
          collection={props.collection}
          stats={props.stats}
          el={<ResizeToggle size={size} resize={(size) => resize(size)} />}
        />
        {/** NFTs */}
        <Nfts />
      </ItemsContainer>
    </Box>
  );
}

export default Items;
