// 3rd party libs
import React from 'react';
import { Card, CardProps, Typography, Stack, Skeleton, styled } from '@mui/material';
import { Avatar } from 'components';

type InfoProps = {
  info?: string | JSX.Element;
  subTitle?: string;
  avatar?: string;
  loading?: boolean;
  certified?: boolean;
} & CardProps;

const StyledCard = styled(Card)({
  display: 'flex',
  background: 'transparent',
  height: '4rem',
  alignItems: 'center'
});

export const Info = ({
  info,
  subTitle,
  avatar,
  loading,
  certified = false,
  ...rest
}: InfoProps): JSX.Element => {
  return (
    <StyledCard elevation={0} {...rest}>
      <Avatar
        sx={{ width: '3.5rem', height: '3.5rem' }}
        top={28}
        src={avatar || ''}
        approved={certified}
      />
      <Stack sx={{ ml: 2, minWidth: 0 }}>
        {typeof info === 'string' ? (
          <Typography
            variant="body1"
            color="text.primary"
            textTransform="capitalize"
            fontWeight="600"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              mb: '0.05rem'
            }}
          >
            {loading ? <Skeleton width={100} /> : info}
          </Typography>
        ) : (
          info
        )}
        <Typography variant="caption" fontWeight={500} color="text.secondary">
          {loading ? <Skeleton width={50} /> : subTitle}
        </Typography>
      </Stack>
    </StyledCard>
  );
};
