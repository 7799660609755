/* eslint-disable react/display-name */
// 3rd party libs
import React from 'react';

// Componenets
import { ExploreArtistCard } from 'components';

import { useFilters, useResize } from 'providers';
import { Artist } from 'types';

import { VirtuosoGrid } from 'react-virtuoso';
import { VContainer, VList } from 'helpers/virtuosoHelpers';

import { AppRoutes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { useArtists } from 'hooks';

type Props = {
  index: number;
  artist: Artist;
  isLoading?: boolean;
  goTo: () => void;
};

const initArtist: Artist = {
  description: '',
  banner: '',
  avatar: '',
  address: '',
  name: '',
  certified: false
};

const SIZE = 30;
const INIT_ARTISTS = [...Array(SIZE).keys()].map(() => initArtist);

const InnerItem = React.memo(({ artist, goTo, isLoading }: Props) => {
  return <ExploreArtistCard artist={artist} goTo={goTo} isLoading={isLoading} />;
});

export const ArtistsExplorer = (): JSX.Element => {
  const navigate = useNavigate();
  const { config } = useResize();
  const { keywords, categories } = useFilters();
  const { artists, error, isLoading, loadMore } = useArtists({
    keywords,
    categories,
    size: SIZE,
    skip: false
  });

  const goTo = (address: string, certified: boolean) =>
    certified
      ? navigate(`/${AppRoutes.profile.replace(':address', address)}/created`)
      : navigate(`/${AppRoutes.profile.replace(':address', address)}`);

  const itemContent = (index: number) => {
    const artist = isLoading ? INIT_ARTISTS[index] : artists[index];
    return (
      artist && (
        <InnerItem
          artist={artist}
          index={index}
          goTo={() => goTo(artist.address, artist.certified)}
          isLoading={isLoading}
        />
      )
    );
  };

  return (
    <VirtuosoGrid
      useWindowScroll={true}
      totalCount={isLoading ? SIZE : artists.length}
      endReached={() => loadMore()}
      overscan={500}
      components={{
        Item: VContainer(config),
        List: VList
      }}
      itemContent={itemContent}
    />
  );
};
