import React from 'react';
import {
  Box,
  Divider,
  Skeleton,
  Stack,
  styled,
  SvgIcon,
  Typography,
  useTheme
} from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { ReactComponent as Approved } from '../../assets/certified.svg';
import Avatar from 'components/Avatar';
import { ImageContainer, Price } from 'components';
import { compact, priceFormatter } from 'lib/format';
import { Collection, CollectionDetails, CollectionStats } from 'types/generated';
import { useResize } from 'providers';

type Props = {
  data: CollectionDetails;
  goTo: () => void;
  isLoading?: boolean;
};

const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  borderRadius: '1rem',
  background: 'transparent',
  boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 4px;',
  border: theme.border,
  transition: 'all 0.2s',
  ':hover': {
    border: theme.borderHover,
    background:
      theme.palette.mode == 'dark'
        ? theme.colors.backgroundSecondaryDark
        : theme.colors.backgroundPrimaryLight
  }
}));

const ImageListContainer = styled(ImageList)({
  borderTopLeftRadius: '1rem',
  borderTopRightRadius: '1rem',
  margin: 0
});

const AvatarContainer = styled(Box)({
  position: 'absolute',
  top: 'calc(-2.3rem)',
  left: 'calc(50% - 1.71875rem)'
});

const CardInfoContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem'
});

// Utils
const avatarRenderer = (avatar: string, isLoading?: boolean) => {
  return (
    <AvatarContainer>
      {isLoading ? (
        <Skeleton variant="circular" width={55} height={55} />
      ) : (
        <Avatar
          src={avatar}
          sx={{
            width: 55,
            height: 55,
            border: (theme) => `0.2rem solid ${theme.input.background}`
          }}
        />
      )}
    </AvatarContainer>
  );
};

const renderKeyValue = (key: string, value?: string, el?: JSX.Element, isLoading?: boolean) => {
  if (isLoading)
    return (
      <Stack
        spacing={0.5}
        direction="column"
        sx={{ flex: 1, minWidth: 0, height: '3rem' }}
        justifyContent="center"
        alignItems="center"
      >
        <Skeleton width="100%" />
        <Skeleton width="80%" />
      </Stack>
    );
  return (
    <Stack direction="column" sx={{ flex: 1, minWidth: 0, height: '3rem' }} textAlign="center">
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 600,
          height: '1.2rem'
        }}
      >
        {key}
      </Typography>
      {value !== undefined && (
        <Typography
          component="div"
          variant="body1"
          color="text.primary"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 600,
            height: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {value}
        </Typography>
      )}
      {el}
    </Stack>
  );
};

const renderer = (owners: number, floorPrice: number, volume: number, isLoading?: boolean) => {
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" variant="middle" flexItem />}
      spacing={1}
    >
      {renderKeyValue('Owners', compact.format(owners), undefined, isLoading)}
      {renderKeyValue(
        'Floor',
        undefined,
        <Price value={floorPrice} justifyContent="center" />,
        isLoading
      )}
      {renderKeyValue(
        'Volume',
        undefined,
        <Price value={priceFormatter.format(volume)} justifyContent="center" />,
        isLoading
      )}
    </Stack>
  );
};

const collectionInfo = (collection: Collection, stats: CollectionStats, isLoading?: boolean) => {
  const { avatar, name, certified, description } = collection;
  const { total_owners, floor_price, volume, total_minted } = stats;
  const { size } = useResize();
  return (
    <CardInfoContainer>
      {avatarRenderer(avatar, isLoading)}
      <Stack
        direction="row"
        spacing={0.5}
        sx={{ mt: 3, justifyContent: 'center' }}
      >
        {isLoading ? (
          <Skeleton width="50%" />
        ) : (
          <>
            <Typography
              variant="h4"
              color="text.primary"
              fontWeight={600}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                mr: certified ? 0.5 : 0
              }}
            >
              {name || '--'}
            </Typography>
            {certified && (
              <SvgIcon sx={{ width: '1.2rem' }}>
                <Approved />
              </SvgIcon>
            )}
          </>
        )}
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
        {isLoading ? (
          <Skeleton width="30%" />
        ) : (
          <Typography>{`${Number(total_minted)} items`}</Typography>
        )}
      </Box>
      <Box sx={{ height: '4rem', mb: 2 }}>
        {isLoading ? (
          <>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </>
        ) : (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              textAlign: 'center',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
      {renderer(total_owners, floor_price, volume, isLoading)}
    </CardInfoContainer>
  );
};

const ExploreCollectionCard = ({ data, goTo, isLoading }: Props) => {
  const { collection, stats } = data;
  const { thumbnails } = collection;

  return (
    <Card onClick={goTo}>
      <ImageListContainer cols={2} sx={{ overflow: 'hidden' }}>
        <ImageListItem cols={1} rows={1}>
          <ImageContainer src={thumbnails[0]} isLoading={isLoading} displayTheme={'covered'} />
        </ImageListItem>
        <ImageListItem cols={1} rows={1}>
          <ImageContainer src={thumbnails[1]} isLoading={isLoading} displayTheme={'covered'} />
        </ImageListItem>
      </ImageListContainer>
      {collectionInfo(collection, stats, isLoading)}
    </Card>
  );
};

export default ExploreCollectionCard;
