import * as React from 'react';
import {
  styled,
  Tab,
  Tabs as MuiTabs,
  Box,
  Popper,
  Grow,
  MenuList,
  ClickAwayListener,
  MenuItem,
  Paper
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';

type CustomTabProps = {
  label: string;
  value: string;
  disabled?: boolean;
  children?: CustomTabProps[];
};

type Props = {
  currentTab?: number;
  tabs: CustomTabProps[];
  onTabChange: (tab: string) => void;
  onChilChange?: (parent: number, value: string) => void;
  centered?: boolean;
};

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  centered?: boolean;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    allowScrollButtonsMobile
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 'calc(100% - 2rem)',
    width: '100%',
    backgroundColor: theme.palette.text.primary
  },
  zIndex: 99
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '1rem',
  marginRight: theme.spacing(1),
  color: '#757980',
  '&.Mui-selected': {
    color: theme.palette.text.primary
  },
  '&.MuiButtonBase-root': {
    ':hover': {
      color: theme.palette.text.primary
    }
  }
}));

const OptionsContainer = styled(Box)({
  background: 'traansparent',
  paddingTop: '0.5rem'
});

const MenuContainer = styled(Paper)(({ theme }) => ({
  background: theme.input.background,
  borderRadius: '0.9375rem',
  padding: theme.spacing(1)
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  background: 'transparent',
  borderRadius: '0.7rem',
  minWidth: '10rem',
  width: '100%',
  height: '2.5rem',
  fontSize: '1rem',
  color: theme.palette.text.secondary,
  ':hover': {
    color: theme.palette.text.primary
  },
  '&.Mui-selected': {
    color: theme.palette.text.primary
  }
}));

const menuItem = (value: string, click: () => void) => {
  return (
    <StyledMenuItem key={value} onClick={click}>
      {value}
    </StyledMenuItem>
  );
};

const TabWithChildren = (props: {
  label: string;
  tabs: CustomTabProps[];
  parentIndex: number;
  handleChildClick: (value: string) => void;
  selected: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { label, tabs, handleChildClick, parentIndex, selected } = props;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  return (
    <StyledTab
      onClick={handleToggle}
      iconPosition="end"
      label={label}
      value={parentIndex}
      disableRipple
      sx={{ opacity: 1, color: selected ? '#FFF' : undefined }}
      icon={
        <Box
          ref={anchorRef}
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <KeyboardArrowDownIcon fontSize="small" />
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom"
            transition
            style={{ zIndex: 110 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                }}
              >
                <OptionsContainer>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuContainer>
                      <MenuList id="composition-menu" onKeyDown={handleListKeyDown}>
                        {tabs?.map((tab) => menuItem(tab.label, () => handleChildClick(tab.value)))}
                      </MenuList>
                    </MenuContainer>
                  </ClickAwayListener>
                </OptionsContainer>
              </Grow>
            )}
          </Popper>
        </Box>
      }
    />
  );
};

const Tabs = ({ currentTab, tabs, onTabChange, onChilChange }: Props): JSX.Element => {
  const [value, setValue] = useState(currentTab || 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (!tabs[newValue].children) {
      setValue(newValue);
      onTabChange(tabs[newValue].value);
    }
  };

  React.useEffect(() => {
    if (currentTab !== undefined) setValue(currentTab);
  }, [currentTab]);

  const handleChildClick = (parentIndex: number, value: string) => {
    setValue(parentIndex);
    onTabChange(tabs[parentIndex].value);
    onChilChange?.(parentIndex, value);
  };

  return (
    <StyledTabs value={value} onChange={handleChange}>
      {tabs.map((p, index) => {
        const key = `${p.label}-${p.value}`;
        return p.children ? (
          <TabWithChildren
            key={key}
            selected={index == value}
            label={p.label}
            tabs={p.children}
            parentIndex={index}
            handleChildClick={(childIndex) => handleChildClick(index, childIndex)}
          />
        ) : (
          !p.disabled && <StyledTab key={key} label={p.label} value={index} disableRipple />
        );
      })}
    </StyledTabs>
  );
};

export default Tabs;
