// 3rd party libs
import React from 'react';
import Profile from './Profile';
import Notifications from './Notifications';
import Offers from './Offers';
import { Container, Footer } from 'components';
import { SettingsProvider } from './context/settingsContext';
import { useNotificationSettingsQuery, useProfileSettingsQuery } from 'api';
import { Route, Routes } from 'react-router-dom';

export const Settings = (): JSX.Element => {
  const { data: notifications } = useNotificationSettingsQuery();
  const { data: profileSettings } = useProfileSettingsQuery({});

  return (
    <>
      <Container sx={{ paddingBottom: '5rem' }}>
        <SettingsProvider
          defaultValues={{
            initProfile: profileSettings?.profile,
            initNotifications: notifications?.notificationSettings,
            initVision: {
              username: profileSettings?.profile.login || '',
              avatar: profileSettings?.profile.avatar || ''
            }
          }}
        >
          <Routes>
            <Route path="/" element={<Profile />} />
            <Route path="profile" element={<Profile />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="offers" element={<Offers />} />
          </Routes>
        </SettingsProvider>
      </Container>
      <Footer />
    </>
  );
};

export default Settings;
