import React from 'react';
import { Box, Skeleton, Stack, styled, SvgIcon, Typography, useTheme, Chip } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, ImageContainer, Avatar, Sharecode } from 'components';
//import { GalleryInfo } from 'types/generated';
import { Gallery } from 'types';
import { useResize } from 'providers';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';
import { formatAddress } from 'helpers/formatAddress';

type Props = {
  //gallery: GalleryInfo;
  gallery: Gallery;
  goTo: () => void;
  isLoading?: boolean;
};

// Styling
const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '1rem',
  background: 'transparent',
  boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 4px;',
  border: theme.border,
  transition: 'all 0.2s',
  ':hover': {
    border: theme.borderHover,
    background:
      theme.palette.mode == 'dark'
        ? theme.colors.backgroundSecondaryDark
        : theme.colors.backgroundPrimaryLight
  }
}));

const GalleryinfoContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem'
});

const AvatarContainer = styled(Box)({
  position: 'absolute',
  top: 'calc(-2.3rem)',
  left: 'calc(50% - 1.71875rem)'
});

const galleryAvatarRenderer = (
  collectionAvatarSrc: string,
  isApproved?: boolean,
  isLoading?: boolean
) => {
  if (isLoading)
    return (
      <AvatarContainer>
        <Skeleton variant="circular" sx={{ width: 55, height: 55 }} />
      </AvatarContainer>
    );
  return (
    <AvatarContainer>
      <Avatar
        top={25}
        approved={isApproved}
        src={collectionAvatarSrc}
        sx={{ width: 55, height: 55, border: (theme) => `0.2rem solid ${theme.input.background}` }}
      />
    </AvatarContainer>
  );
};

const renderer = (description: string, total_items: number, sharecode: string, isLoading?: boolean) => {
  //Disable Button TMP launch
  return (
    <Stack direction="column" sx={{ flex: 1 }}>
      <Box sx={{ height: '4rem', mt: 2 }}>
        {isLoading ? (
          <>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </>
        ) : (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              textAlign: 'center',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Chip
          sx={{
            flex: 1,
            borderRadius: '5rem',
            height: '3rem',
            backgroundColor: (theme) => theme.input.background
          }}
          label={
            !isLoading && (
              <Typography variant="h6" fontWeight={600}>
                {total_items} items
              </Typography>
            )
          }
        />
        <Chip
          sx={{ flex: 1, borderRadius: '5rem', height: '3rem' }}
          label={
            !isLoading && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Sharecode sharecode={sharecode} variant="h6" />
              </Stack>
            )
          }
          variant="outlined"
        />
      </Box>
    </Stack>
  );
};

const likesRenderer = (likes: number) => {
  const theme = useTheme();
  const color = theme.palette.mode == 'dark' ? '#596067' : theme.colors.red;
  return (
    <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
      <Chip
        size="small"
        icon={<FavoriteIcon sx={{ '&&': { color } }} />}
        label={likes}
        sx={{ background: '#FFFFFF', color }}
      />
    </Box>
  );
};

const galleryInfo = (gallery: Gallery, isLoading?: boolean) => {
  const { title, description, sharecode, total_items, image_cover, creator } = gallery;
  const { name, certified, avatar, address } = creator;
  const { size } = useResize();

  const navigate = useNavigate();
  const goTo = (address: string, certified: boolean, e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
    certified
      ? navigate(`/${AppRoutes.profile.replace(':address', address)}/created`)
      : navigate(`/${AppRoutes.profile.replace(':address', address)}`);
  }

  return (
    <GalleryinfoContainer>
      {galleryAvatarRenderer(avatar, certified, isLoading)}
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{ mt: 3, justifyContent: 'center' }}
        >
          {isLoading ? (
            <Skeleton width="50%" />
          ) : (
            <>
              <Typography
                variant="body2"
                color="text.primary"
                fontWeight={600}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: size == 'comfy' ? '1.32rem' : '1.1rem',
                  mr: certified ? 0.5 : 0,
                  py: 0.5
                }}
              >
                {title || '--'}
              </Typography>
            </>
          )}
        </Stack>
      </Box>
      <Typography
        variant="body1"
        color={ (theme) => { return theme.palette.text.secondary; }}
        onClick={e => !isLoading && goTo(address, certified, e)}
        sx={{
          textAlign: 'center',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 600
        }}
      >
        {isLoading ? 
          <Skeleton width="50%" /> : 
          (name && name != 'anonymous') ? '@'+name : '@'+formatAddress(address)
        }
      </Typography>
      {renderer(description, total_items, sharecode, isLoading)}
    </GalleryinfoContainer>
  );
};

const ExploreGalleryCard = ({ gallery, goTo, isLoading }: Props): JSX.Element => {
  const { image_cover } = gallery;
  const nftGalleryLink = `/${AppRoutes.gallery_page.replace(':sharecode', gallery.sharecode)}`;
  return (
    <Link to={nftGalleryLink} style={{ position: 'relative', textDecoration: 'none' }}>
      <Card onClick={() => !isLoading && goTo()}>
        <ImageContainer src={image_cover} ratio={50} isLoading={isLoading} displayTheme={'covered'} />
        {galleryInfo(gallery, isLoading)}
      </Card>
    </Link>
  )
};

export default ExploreGalleryCard;
