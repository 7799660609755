import React from 'react';
import { FormControlLabel, FormGroup, Typography, Stack } from '@mui/material';
import { KeyValuePair, NumberOrString } from 'types';
import { Checkbox, Radio } from 'components';

type Props = {
  defaultValue: NumberOrString;
  pairs: KeyValuePair<NumberOrString, NumberOrString>[];
  keys: NumberOrString[];
  onChange: (value: NumberOrString[]) => void;
};

const defaultCheckButton = (
  key: NumberOrString,
  value: NumberOrString,
  checked: boolean,
  click: () => void
) => {
  return (
    <FormControlLabel
      key={key}
      value={key}
      onChange={click}
      control={
        <Radio
          size="small"
          checked={checked}
          sx={{
            '&, &.Mui-checked': {
              color: 'text.primary'
            }
          }}
        />
      }
      label={
        <Typography variant="body2" color={checked ? 'text.primary' : 'text.secondary'}>
          {value}
        </Typography>
      }
    />
  );
};

const checkButton = (
  key: NumberOrString,
  value: NumberOrString,
  checked: boolean,
  click: () => void
) => {
  return (
    <FormControlLabel
      key={key}
      value={key}
      onChange={click}
      control={
        <Checkbox
          size="small"
          checked={checked}
          sx={{
            '&, &.Mui-checked': {
              color: 'text.primary'
            }
          }}
        />
      }
      label={
        <Typography variant="body2" color={checked ? 'text.primary' : 'text.secondary'}>
          {value}
        </Typography>
      }
    />
  );
};

const CheckBoxGroup = ({ pairs, defaultValue, onChange, keys }: Props) => {
  const handleClick = (key: NumberOrString, empty: boolean) => {
    if (empty) {
      onChange([]);
    } else {
      const selected = keys.includes(key) ? keys.filter((k) => k !== key) : [...keys, key];
      onChange(selected);
    }
  };

  return (
    <FormGroup>
      <Stack sx={{ p: 1 }}>
        {defaultCheckButton(defaultValue, defaultValue, keys.length == 0, () =>
          handleClick('', true)
        )}
        {pairs.map((entry) =>
          checkButton(entry.key as string, entry.value as string, keys.includes(entry.key), () =>
            handleClick(entry.key, false)
          )
        )}
      </Stack>
    </FormGroup>
  );
};

export default CheckBoxGroup;
