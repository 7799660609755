import React from 'react';
import { Stack, Typography } from '@mui/material';
import { IconFactory, NumberInput } from 'components';
import { NumberOrString } from 'types';

type Props = {
  label?: string;
  price: NumberOrString | undefined;
  set: (price: NumberOrString) => void;
  width?: string;
};

export const PriceInput = ({ label, price, width, set }: Props) => {
  return (
    <Stack spacing={1}>
      {label && (
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
      )}
      <NumberInput
        sx={{ height: '3.5rem', borderRadius: '0.5rem', width }}
        value={price}
        step={0.1}
        onChange={(value) => set(value)}
        startAdornment={<IconFactory icon="avax" size={18} style={{ marginRight: '1rem' }} />}
      />
    </Stack>
  );
};
