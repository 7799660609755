import React, { useEffect, useState } from 'react';
import { Typography, Stack, styled } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import { BigNumberish } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { Button, IconFactory, Address } from 'components';
import { useLocalStorage } from 'hooks';
import { useAssets } from 'hooks';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import { useLogoutMutation } from 'api';

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '0.5rem',
  minWidth: '18rem',
  '.box': {
    padding: theme.spacing(1, 2),
    border: theme.border,
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  }
}));

type AssetProps = {
  symbol: JSX.Element;
  token: string;
  caption: string;
  balance?: string;
};

const Asset = ({ symbol, token, caption, balance }: AssetProps) => {
  return (
    <>
      <div>{symbol}</div>
      <Stack spacing={0} sx={{ flex: 1 }}>
        <Typography variant="body1" fontSize={20} fontWeight={500}>
          {token}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {caption}
        </Typography>
      </Stack>
      <Typography variant="body1" color="text.primary">
        {balance}
      </Typography>
    </>
  );
};

const Account = (): JSX.Element => {
  const dispatch = useDispatch();
  const [balance, setBalance] = useState<string | undefined>('0.0');
  const { hooks, account } = useWeb3React();
  const { usePriorityProvider, usePriorityConnector } = hooks;
  const [_, setLastconnector] = useLocalStorage<string | undefined>('last-connector', undefined);
  const [logoutSession] = useLogoutMutation();

  const provider = usePriorityProvider();
  const connector = usePriorityConnector();

  const { wavax, klo } = useAssets();

  useEffect(() => {
    if (account) {
      try {
        provider
          ?.getBalance(account)
          .then((result: BigNumberish) => {
            setBalance(formatEther(result));
          })
          .catch((_) => {
            /** Ignore, balance remains 0 **/
          });
      } catch (err) {
        /** Ignore, balance remains 0 **/
      }
    }
  }, [account]);

  const logout = () => {
    connector?.deactivate();
    setLastconnector(undefined);
    logoutSession({})
      .unwrap()
      .catch(() => {
        console.error('logout failed!');
      });
  };

  const Swap = () => {
    dispatch(openModal.create({ modal: 'SWAP' }));
  };

  //Disable Part for Launch
  const disableAddFunds = true;
  const disableRewards = true;

  return (
    <StyledContainer spacing={1}>
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography variant="body1" fontSize={20} fontWeight={500}>
          Wallet
        </Typography>
        {account && <Address length={6} address={account} copy={true} />}
      </Stack>
      <div className="box">
        <Asset
          symbol={<IconFactory icon="avax" />}
          token="AVAX"
          caption="Avalanche"
          balance={balance}
        />
      </div>
      <div className="box">
        <Asset
          symbol={<IconFactory icon="wavax" />}
          token="WAVAX"
          caption="Wrapped Avalanche"
          balance={wavax}
        />
      </div>
      <div className="box">
        <Asset
          symbol={<IconFactory icon="klo" />}
          token="KLO"
          caption="Kalao Token"
          balance={klo}
        />
      </div>
      <Stack direction="row" spacing={2} sx={{ px: 4, pt: 2 }}>
        <Button ctx="primary" sx={{ borderRadius: '0.5rem', flex: 1 }} onClick={Swap}>
          <Typography variant="button" fontSize={16}>
            Swap
          </Typography>
        </Button>
        {!disableAddFunds && (
          <>
            <Button ctx="primary" sx={{ borderRadius: '0.5rem', flex: 1 }}>
              <Typography variant="button" fontSize={16}>
                Add Funds
              </Typography>
            </Button>
          </>
        )}
      </Stack>
      {!disableRewards && (
        <>
          <Typography variant="body1" fontSize={20} fontWeight={500} sx={{ pt: 4 }}>
            Rewards
          </Typography>
          <div className="box">
            <Asset
              symbol={<IconFactory icon="klo" />}
              token="KLO"
              caption="Kalao Token"
              balance={'0.0'}
            />
          </div>
          <Stack direction="row" spacing={2} sx={{ px: 4, pt: 2 }}>
            <Button ctx="primary" sx={{ borderRadius: '0.5rem', flex: 1 }}>
              <Typography variant="button" fontSize={16}>
                Claim
              </Typography>
            </Button>
          </Stack>
        </>
      )}
      <Stack direction="row" spacing={2} sx={{ px: 4, pt: 4 }}>
        <Button ctx="secondary" sx={{ borderRadius: '0.5rem', flex: 1 }} onClick={logout}>
          <Typography variant="button" fontSize={16}>
            Logout
          </Typography>
        </Button>
      </Stack>
    </StyledContainer>
  );
};

export default Account;
