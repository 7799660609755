/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ContractFactory, ethers } from 'ethers';
import { erc721 } from 'abi/erc721';
import { erc1155 } from 'abi/erc1155';

//temporary abi imports
import erc1155ABI from 'abi/erc1155configurator.json';
import erc721ABI from 'abi/erc721configurator.json';

import config from 'config';
import { NumberOrString } from 'types';
import { erc721V1 } from 'abi/erc721-v1';

// mint(string memory uri) bytecode
const MINT_BYTECODE = 'd85d3d27';

export const createERC1155 = async (
  provider: ethers.providers.Web3Provider,
  collection: { name: string; symbol: string; royalties: NumberOrString; royaltyRecipient: string }
) => {
  try {
    const signer = provider.getSigner();
    // only fetch abi if required
    // const erc1155ABI = await fetchERC1155ABI();
    const erc1155Instance = new ContractFactory(erc1155ABI.abi, erc1155ABI.bytecode, signer);

    const contract = await erc1155Instance.deploy(
      collection.name,
      collection.symbol,
      collection.royalties,
      collection.royaltyRecipient,
      config.exchange
    );
    await contract.deployTransaction.wait();
    return contract.address;
  } catch (err) {
    console.error(err);
    throw new Error('Deploying collection failed!');
  }
};

export const mintERC1155 = async (
  provider: ethers.providers.Web3Provider,
  params: { collectionAddress: string; to: string; amount: number; uri: string }
) => {
  try {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(params.collectionAddress, erc1155, signer);
    const tx = await contract.mint(params.to, params.amount, params.uri);
    const txResponse = await tx.wait();
    return txResponse;
  } catch (err) {
    console.error(err);
    throw new Error('Minting ERC1155 failed!');
  }
};

export const createERC721 = async (
  provider: ethers.providers.Web3Provider,
  collection: { name: string; symbol: string; royalties: NumberOrString; royaltyRecipient: string }
) => {
  try {
    const signer = provider.getSigner();
    // only fetch abi if required
    // const erc721ABI = await fetchERC721ABI();
    const erc721Instance = new ContractFactory(erc721ABI.abi, erc721ABI.bytecode, signer);

    const contract = await erc721Instance.deploy(
      collection.name,
      collection.symbol,
      collection.royalties,
      collection.royaltyRecipient,
      config.exchange
    );

    await contract.deployTransaction.wait();
    return contract.address;
  } catch (err) {
    console.error(err);
    throw new Error('Deploying collection failed!');
  }
};

export const mintERC721 = async (
  provider: ethers.providers.Web3Provider,
  params: { collectionAddress: string; to: string; uri: string }
) => {
  try {
    const bytecode = await provider.getCode(params.collectionAddress);
    const signer = provider.getSigner();

    let tx;
    let contract;

    if (bytecode.includes(MINT_BYTECODE)) {
      contract = new ethers.Contract(params.collectionAddress, erc721V1, signer);
      tx = await contract.mint(params.uri);
    } else {
      contract = new ethers.Contract(params.collectionAddress, erc721, signer);
      tx = await contract.mint(params.to, params.uri);
    }

    const txResponse = await tx.wait();
    return txResponse;
  } catch (err) {
    throw new Error('Minting ERC721 failed!');
  }
};
