import React, { useState } from 'react';
import { SvgIcon, useTheme, styled, SvgIconProps } from '@mui/material';
import { ReactComponent as WalletDark } from '../../../assets/header-top/wallet-dark.svg';
import { ReactComponent as WalletLight } from '../../../assets/header-top/wallet-light.svg';

const StyledSvg = styled(SvgIcon)(({ theme }) => ({
  cursor: 'pointer',
  width: '1.5rem',
  height: '1.5rem',
  ':hover': {
    path: { fill: theme.palette.text.primary }
  }
}));

const WalletIcon = ({ ...rest }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const isDark = theme.palette.mode == 'dark';
  const icon = isDark ? (
    <WalletDark width="100%" height="100%" />
  ) : (
    <WalletLight width="100%" height="100%" />
  );
  return <StyledSvg {...rest}>{icon}</StyledSvg>;
};

export default WalletIcon;
