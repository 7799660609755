import React, { CSSProperties } from 'react';
import { ReactComponent as Dark } from '../../assets/header-top/logo-dark.svg';
import { ReactComponent as Light } from '../../assets/header-top/logo-light.svg';
import { ReactComponent as AvaxDark } from '../../assets/symbol/avax-dark.svg';
import { ReactComponent as AvaxLight } from '../../assets/symbol/avax-light.svg';
import { ReactComponent as EsKLO } from '../../assets/symbol/esklo.svg';
import { ReactComponent as VeKLO } from '../../assets/symbol/veklo.svg';
import { ReactComponent as Pgl } from '../../assets/symbol/pgl.svg';
import { ReactComponent as Wavax } from '../../assets/cards/wavax.svg';
import { useTheme } from '@mui/material';

type IconFactoryProps = {
  icon: 'klo' | 'veKLO' | 'esKLO' | 'avax' | 'pgl' | 'wavax';
  size?: number;
  style?: CSSProperties;
};

const IconFactory = ({ icon, size = 18, style }: IconFactoryProps) => {
  const mode = useTheme().palette.mode;
  if (icon == 'klo') {
    return mode == 'dark' ? (
      <Dark width={size} height={size} style={style} />
    ) : (
      <Light width={size} height={size} style={style} />
    );
  }
  if (icon == 'wavax') return <Wavax width={size} height={size} style={style} />;

  if (icon == 'avax') {
    return mode == 'dark' ? (
      <AvaxDark width={size} height={size} style={style} />
    ) : (
      <AvaxLight width={size} height={size} style={style} />
    );
  }
  if (icon == 'pgl') return <Pgl />;

  return icon == 'esKLO' ? (
    <EsKLO width={size} height={size} style={style} />
  ) : (
    <VeKLO width={size} height={size} style={style} />
  );
};

export default IconFactory;
