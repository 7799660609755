import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';

type Props = {
  total_listings?: number;
};

export const Done = ({ total_listings }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goTo = (path: string) => {
    dispatch(openModal.create({}));
    navigate(path);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
      <Typography variant="h6" fontWeight="600" sx={{ color: (theme) => theme.colors.green, mr: 1 }}>
        Congrats! NFT(s) successfully listed
      </Typography>
      <Stack sx={{ width: '100%' }}>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button ctx="primary" onClick={() => goTo(AppRoutes.dashboard)} sx={{ marginLeft: '-3.84rem' }}>
            <Typography variant="button">View My Listings</Typography>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};