// 3rd party libs
import React from 'react';
import { Box, styled, useMediaQuery } from '@mui/material';

// Componenets
import { FilterChips, ResizeToggle, Filters, MobileFilters, Sorting } from 'components';
import { TopSectionContainer } from 'helpers/topSection';
import { GridSizeProvider, useResize } from 'providers/gridResizeProvider';
import { Explorer } from './explorer';
import { FiltersProvider } from 'providers';
import { theme } from 'themes';
import { RightContainer } from 'helpers/rightContainer';
import { SearchNftSort } from 'types/generated';

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

const Container = styled(Box)({
  margin: '1% 2%'
});

const CollectionsRenderer = () => {
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { size, resize } = useResize();
  const filters = matches ? (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
      <Filters showCategories={true} showKeywords={true} />
      <FilterChips />
    </Box>
  ) : (
    <MobileFilters activeFilters={['categories']} search={true} sorting={<Sorting sortType={'sortExploreCollections'} defaultKey={'top'} />} />
  );
  return (
    <Root>
      <TopSectionContainer>
        {filters}
        {matches && (
          <RightContainer>
            <Sorting sortType={'sortExploreCollections'} defaultKey={'top'} />
            <ResizeToggle size={size} resize={(size) => resize(size)} />
          </RightContainer>
        )}
      </TopSectionContainer>
      <Container>
        <Explorer />
      </Container>
    </Root>
  );
};

export const Collections = () => (
  <GridSizeProvider size="comfy">
    <FiltersProvider sortBy={SearchNftSort.Top}>
      <CollectionsRenderer />
    </FiltersProvider>
  </GridSizeProvider>
);
