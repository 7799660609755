import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Asset, StepStatus } from 'types';
import Step from '../Step';
import { useIpfsUploadMutation } from 'api';
import { IpfsUploadKind } from 'types/generated';

type Props = {
  step: number;
  currentStep: number;
  nft: Asset;
  complete: (url: string) => void;
};

const Progress = (props: { msg: string }) => {
  return (
    <Typography variant="body2" color="text.secondary">
      {props.msg}
    </Typography>
  );
};

const getKindFile = (file:any) => {
  if (file.type.includes('video')) {
    return IpfsUploadKind.Videoasset;
  } else if (file.type.includes('audio')) {
    return IpfsUploadKind.Audioasset;
  } else {
    return IpfsUploadKind.Imageasset;
  }
};

const Upload = ({ step, currentStep, nft, complete }: Props) => {
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();
  const [uploadToIpfs] = useIpfsUploadMutation();
  const [progressMsg, setProgressMsg] = useState('');
  const { fileUrl, fileCoverUrl, form } = nft;
  const { name, description, external_url, properties } = form;

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
      uploadAsset();
    }
  }, [currentStep]);

  const uploadAsset = async () => {
    setStatus(StepStatus.IN_PROGRESS);

    try {
      // Upload and get the image ipfs hash
      const blob = await fetch(fileUrl).then((r) => r.blob());
      setProgressMsg('Uploading file asset');
      const imageURL = await uploadToIpfs({
        kind: getKindFile(blob),
        file: blob
      })
        .unwrap()
        .catch(() => {
          throw new Error('Token upload to ipfs failed');
        });
      
      // Upload and get the preview image ipfs hash when animation
      let previewURL;
      if (fileCoverUrl) {
        const blobPreview = await fetch(fileCoverUrl).then((r) => r.blob());
        setProgressMsg('Uploading preview image asset');
        previewURL = await uploadToIpfs({
          kind: getKindFile(blobPreview),
          file: blobPreview
        })
          .unwrap()
          .catch(() => {
            throw new Error('Preview image upload to ipfs failed');
          });
      }

      // Build the metadata with the form infos
      let metadataToUpload;
      if (fileCoverUrl && previewURL) {
        metadataToUpload = JSON.stringify({
          name,
          description,
          external_url,
          image: previewURL.ipfsUpload,
          animation_url: imageURL.ipfsUpload,
          attributes: properties
        });
      } else {
        metadataToUpload = JSON.stringify({
          name,
          description,
          external_url,
          image: imageURL.ipfsUpload,
          attributes: properties
        });
      }
      
      // Create JSON file in memory
      const metadataFile: File = new File([metadataToUpload], 'metadata.json', {
        type: 'application/json'
      });

      // Upload and get the metadata IPFS hash
      setProgressMsg('Uploading metadatas');
      await uploadToIpfs({
        kind: IpfsUploadKind.Jsonmetas,
        file: metadataFile
      })
        .unwrap()
        .then((res) => {
          complete(res.ipfsUpload);
          setStatus(StepStatus.DONE);
        })
        .catch(() => {
          throw new Error('Token metadatas upload failed');
        });
        
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Cancel failed !');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={
        status == StepStatus.DONE ? (
          'Assets Uploaded'
        ) : (
          'Upload'
        )
      }
      step={step}
      currentStep={currentStep}
      status={status}
      error={error}
      el={status == StepStatus.IN_PROGRESS ? <Progress msg={progressMsg} /> : undefined}
    />
  );
};

export default Upload;
