import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import NavBar from 'components/Tabs';
import { Button, Container } from 'components';
import { Collections } from './Collections';
import { Users } from './Users';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';

const tabs: { label: string; value: string }[] = [
  { label: 'Top Collections', value: 'collections' },
  { label: 'Top Users', value: 'users' }
];

export const Ranking = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('collections');

  const goToRanking = () => navigate(`/${AppRoutes.ranking}`);

  const memoizedCollections = useMemo(() => <Collections />, []);
  const memoizedUsers = useMemo(() => <Users />, []);

  return (
    <Container>
      <Box sx={{ borderBottom: (theme) => theme.border, mb: 2 }}>
        <NavBar tabs={tabs} onTabChange={(tab) => setTab(tab)} />
      </Box>
      {tab == 'collections' && memoizedCollections}
      {tab == 'users' && memoizedUsers}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 5 }}>
        <Button ctx="secondary" size="large" onClick={goToRanking}>
          <Typography variant="button" color="text.primary">
            Go to ranking
          </Typography>
        </Button>
      </Box>
    </Container>
  );
};
