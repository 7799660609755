// 3rd
import { useEffect, useState, useRef } from 'react';
// Api
import { useLazyNFTsQuery } from 'api';

// Types
import { AttributesQuery, NftPreview, SearchNftSort, SearchTarget } from 'types/generated';
import { NumberOrString, PriceRangeType } from 'types';

/**
 * Hook to query Nfts
 */

type Props = {
  // page size (paging offset)
  size: number;
  target: SearchTarget;
  categories?: NumberOrString[];
  salesType?: NumberOrString;
  price?: PriceRangeType;
  collectionAddress?: string;
  creator?: string;
  owner?: string;
  keywords?: string;
  sortBy?: SearchNftSort;
  certified?: boolean;
  attributes?: AttributesQuery[];
  // Skip if true
  skip: boolean;
};

type Response = {
  nfts: NftPreview[];
  error?: string | unknown;
  isLoading: boolean;
  loadMore: () => void;
  updateLikes: (assetId: string, likes: number) => void;
};

const useNFTs = ({
  categories,
  salesType,
  price,
  collectionAddress,
  creator,
  owner,
  skip,
  target,
  keywords,
  sortBy,
  attributes,
  certified,
  size
}: Props): Response => {
  const hasMore = useRef(true);
  const shouldReset = useRef(true);
  const page = useRef(1);
  const [search, result] = useLazyNFTsQuery();
  const [nfts, setNfts] = useState<NftPreview[]>([]);

  const trigger = () => {
    search({
      input: {
        target,
        keywords,
        sort: sortBy,
        attributes,
        category_tags: categories?.map((c) => c.toString()),
        sale_type: salesType?.toString(),
        price_range: price ? { max: price?.max as number, min: price?.min as number } : undefined,
        collection_address: collectionAddress,
        creator,
        certified,
        owner
      },
      pagination: {
        page: page.current++,
        size
      }
    });
  };

  useEffect(() => {
    if (!skip) trigger();
  }, []);

  useEffect(() => {
    shouldReset.current = true;
    hasMore.current = true;
    page.current = 1;
    trigger();
  }, [
    target,
    categories,
    salesType,
    price,
    collectionAddress,
    creator,
    owner,
    setNfts,
    keywords,
    sortBy,
    JSON.stringify(attributes)
  ]);

  useEffect(() => {
    if (!result.isSuccess) return;
    if (shouldReset.current) {
      shouldReset.current = false;
      hasMore.current = result.data.search.hasMore;
      setNfts(result.data.search.nfts as NftPreview[]);
    } else {
      hasMore.current = result.data.search.hasMore;
      setNfts([...nfts, ...(result.data.search.nfts as NftPreview[])]);
    }
  }, [result.data]);

  const updateLikes = (assetId: string, likes: number) => {
    const updateNfts = nfts.map((nft) => {
      return { ...nft, likes: nft.asset_id === assetId ? likes : nft.likes };
    });
    setNfts(updateNfts);
  };

  const loadMore = () => {
    if (hasMore.current) trigger();
  };

  return {
    nfts,
    error: result.error && 'Failed to load data',
    isLoading: result.isLoading,
    loadMore,
    updateLikes
  };
};

export default useNFTs;
