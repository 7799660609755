/* eslint-disable react/display-name */
// 3rd party libs
import React from 'react';

// Componenets
import { ExploreGalleryCard } from 'components';

import { useFilters, useResize } from 'providers';
import { Gallery } from 'types';

import { VirtuosoGrid } from 'react-virtuoso';
import { VContainer, VList } from 'helpers/virtuosoHelpers';

import { AppRoutes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
//import { useGalleries } from 'hooks';

type Props = {
  index: number;
  gallery: Gallery;
  isLoading?: boolean;
  goTo: () => void;
};

const initGallery: Gallery = {
  sharecode: '',
  title: '',
  description: '',
  total_items: 0,
  total_likes: 0,
  image_cover: '',
  image_360: '',
  creator: {
    address: '',
    name: '',
    avatar: '',
    certified: false
  }
};

const SIZE = 30;
const INIT_GALLERIES = [...Array(SIZE).keys()].map(() => initGallery);

const InnerItem = React.memo(({ gallery, goTo, isLoading }: Props) => {
  return <ExploreGalleryCard gallery={gallery} goTo={goTo} isLoading={isLoading} />;
});

export const GalleriesExplorer = (): JSX.Element => {
  const navigate = useNavigate();
  const { config } = useResize();
  const { keywords } = useFilters();
  /*
  const { galleries, error, isLoading, loadMore } = useGalleries({
    keywords,
    size: SIZE,
    skip: false
  });
  */
  //TEMP
  const galleries = [
    {
      sharecode : "XD546DD",
      title : "Sample 1 Gallery Vision",
      description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend hendrerit orci in ullamcorper. Mauris ac massa ut nulla cursus suscipit non at nisl. Donec suscipit nec dolor et sagittis. Vivamus ante mauris, consectetur et suscipit in, commodo id nibh. Nam pharetra consequat tortor, ac condimentum odio tincidunt ac.",
      total_items : 7,
      total_likes : 37,
      image_cover : "https://storage.googleapis.com/beta-kalao/galleries/sample-test/cover.jpg",
      image_360 : "https://storage.googleapis.com/beta-kalao/galleries/sample-test/360.jpg",
      creator: {
        address: "0x0347f0571275e583299e2661c64770a25001739d",
        name: "Sinobired",
        avatar: "https://storage.googleapis.com/beta-kalao/users/0x0347f0571275e583299e2661c64770a25001739d/avatar",
        certified: true
      }
    },
    {
      sharecode : "SP5DD89",
      title : "Sample 2 Gallery Vision",
      description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend hendrerit orci in ullamcorper. Mauris ac massa ut nulla cursus suscipit non at nisl. Donec suscipit nec dolor et sagittis. Vivamus ante mauris, consectetur et suscipit in, commodo id nibh. Nam pharetra consequat tortor, ac condimentum odio tincidunt ac.",
      total_items : 3,
      total_likes : 37,
      image_cover : "https://storage.googleapis.com/beta-kalao/galleries/sample-test/cover.jpg",
      image_360 : "https://storage.googleapis.com/beta-kalao/galleries/sample-test/360.jpg",
      creator: {
        address: "0xd5de5a673c2fafefbbe942b6a9cbd30599d65ec4",
        name: "Roger is Back",
        avatar: "https://storage.googleapis.com/beta-kalao/users/0xd5de5a673c2fafefbbe942b6a9cbd30599d65ec4/avatar",
        certified: true
      }
    }
  ];
  const isLoading = false;
  const loadMore = () => {
    return false;
  }

  const goTo = (sharecode: string) => navigate(`/${AppRoutes.gallery_page.replace(':sharecode', sharecode)}`);

  const itemContent = (index: number) => {
    const gallery = isLoading ? INIT_GALLERIES[index] : galleries[index];
    return (
      gallery && (
        <InnerItem
          gallery={gallery}
          index={index}
          goTo={() => goTo(gallery.sharecode)}
          isLoading={isLoading}
        />
      )
    );
  };

  return (
    <VirtuosoGrid
      useWindowScroll={true}
      totalCount={isLoading ? SIZE : galleries.length}
      endReached={() => loadMore()}
      overscan={500}
      components={{
        Item: VContainer(config),
        List: VList
      }}
      itemContent={itemContent}
    />
  );
};
