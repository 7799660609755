import * as React from 'react';
import { AppBar as MuiAppBar, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Logo, AutoComplete, MobileAutoComplete, DesktopWallet } from 'components';
import { BarLinks } from './BarLinks';
import { MobileBarLinks } from './MobileBarLinks';
import { Profile } from './Profile';
import { theme } from 'themes';

const AppBar = (): JSX.Element => {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <MuiAppBar
      position="sticky"
      sx={{
        background: 'inherit',
        boxShadow: 'none',
        borderBottom: 1,
        borderColor: 'divider',
        height: '5rem',
        display: { xs: 'flex', justifyContent: 'center' },
        '& .MuiToolbar-root': { px: 2 }
      }}
    >
      <Toolbar disableGutters sx={{ px: '1rem', py: '1rem', position: 'relative' }}>
        {/** Logo */}
        <Logo />
        {/** App links */}
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', gap: '2rem' } }}>
          <BarLinks />
        </Box>
        {/** AutoComplete Search */}
        {matches && <AutoComplete />}
        {/** Mobile AutoComplete Search */}
        {!matches && <MobileAutoComplete />}
        <Box
          sx={{
            flexGrow: 0,
            display: {
              xs: 'none',
              md: 'flex',
              alignItems: 'center'
            }
          }}
        >
          {/** Notifications 
          <Box sx={{ ml: '1rem' }}>
            <IconButton size="small">
              <Badge variant="dot" color="error">
                <NotificationsNoneOutlinedIcon sx={{ color: (theme) => theme.colors.grey100 }} />
              </Badge>
            </IconButton>
          </Box>
          */}
          <Box sx={{ ml: '1rem' }}>
            <DesktopWallet />
          </Box>
          {/** Profile Settings */}
          <Box sx={{ ml: '1rem' }}>
            <Profile />
          </Box>
        </Box>
        {/** Mobile wallet */}
        <Box
          sx={{
            ml: 1,
            display: { xs: 'flex', md: 'none' },
            border: (theme) => theme.border,
            p: 1,
            borderRadius: '0.5rem'
          }}
        >
          <DesktopWallet />
        </Box>
        {/** Nav bar in small screen */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <MobileBarLinks />
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
