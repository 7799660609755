import React, { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useNetwork } from 'hooks';
import Account from './Account';
import WrongNetwork from './WrongNetwork';
import Web3Connectors from './Web3Connectors';
import { useDispatch } from 'react-redux';
import { setWalletStatus } from 'store/actions';

type Props = {
  help?: boolean;
};

const Web3 = ({ help }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { hooks } = useWeb3React();
  const { usePriorityIsActive } = hooks;
  const { isValid } = useNetwork();
  const isActive = usePriorityIsActive();

  useEffect(() => {
    dispatch(setWalletStatus.create({ valid: isValid && isActive }));
  }, [isValid, isActive]);

  let content: JSX.Element = <Web3Connectors help={help} />;
  if (isActive) {
    content = isValid ? <Account /> : <WrongNetwork />;
  }

  return content;
};

export default Web3;
