import React from 'react';
import { Grid, styled, Box, Typography, Stack, useTheme } from '@mui/material';
import { ReactComponent as SingleDark } from '../../../assets/create-nft/single-dark.svg';
import { ReactComponent as MultipleDark } from '../../../assets/create-nft/multiple-dark.svg';
import { ReactComponent as SingleLight } from '../../../assets/create-nft/single-light.svg';
import { ReactComponent as MultipleLight } from '../../../assets/create-nft/multiple-light.svg';
import { TokenKind } from 'types';

type Props = {
  set: (type: TokenKind) => void;
};

const StyledCard = styled(Box)(({ theme }) => ({
  borderRadius: '1.25rem',
  height: '30rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  padding: theme.spacing(5),
  '&.single': {
    background: '#75798025'
  },
  '&.multiple': {
    border: theme.border
  },
  ':hover': {
    background: theme.palette.action.hover
  },
  cursor: 'pointer'
}));

export const CreateTypePicker = ({ set }: Props) => {
  const theme = useTheme();
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={{ xs: 2, md: 2, lg: 3 }}
    >
      <Grid item xs={12} md={5} lg={4}>
        <StyledCard className="single" onClick={() => set(TokenKind.erc721)}>
          {theme.palette.mode == 'dark' ? <SingleDark height={'12rem'} /> : <SingleLight height={'12rem'} />}
          <Stack spacing={1} alignItems="center">
            <Typography variant="h3" sx={{ mb: 4 }}>Single</Typography>
            <Typography variant="subtitle1" color="text.secondary" sx={{ textAlign: 'center' }}>
              One unique NFT only will be created
            </Typography>
          </Stack>
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <StyledCard className="multiple" onClick={() => set(TokenKind.erc1155)}>
          {theme.palette.mode == 'dark' ? <MultipleDark height={'12rem'} /> : <MultipleLight height={'12rem'} />}
          <Stack spacing={1} alignItems="center">
            <Typography variant="h3" sx={{ mb: 4 }}>Multiple</Typography>
            <Typography variant="subtitle1" color="text.secondary" sx={{ textAlign: 'center' }}>
              Create multiple editions of the same Art
            </Typography>
          </Stack>
        </StyledCard>
      </Grid>
    </Grid>
  );
};
