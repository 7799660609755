/* eslint-disable react/display-name */
// 3rd party libs
import React from 'react';

// Componenets
import { ExploreCollectionCard } from 'components';

import { useFilters, useResize } from 'providers';
import { VirtuosoGrid } from 'react-virtuoso';
import { VContainer, VList } from 'helpers/virtuosoHelpers';
import { useNavigate } from 'react-router-dom';
import { CollectionDetails } from 'types/generated';
import { useCollections } from 'hooks';
import { goToCollection, initCollection } from 'helpers/collections';

type Props = {
  data: CollectionDetails;
  goTo: () => void;
  isLoading?: boolean;
};

const InnerItem = React.memo(({ data, goTo, isLoading }: Props) => {
  return <ExploreCollectionCard data={data} goTo={() => goTo()} isLoading={isLoading} />;
});

const SIZE = 30;
const COLLECTIONS = [...Array(SIZE).keys()].map(() => initCollection);

export const Explorer = (): JSX.Element => {
  const navigate = useNavigate();
  const { config } = useResize();
  const { categories, keywords, sortBy } = useFilters();
  const { collections, isLoading, loadMore } = useCollections({
    categories,
    keywords,
    sortBy,
    certified: true,
    size: SIZE,
    skip: false
  });

  const itemContent = (index: number) => {
    const data = isLoading ? COLLECTIONS[index] : collections[index];
    return (
      data && (
        <InnerItem
          data={data}
          goTo={() => !isLoading && navigate(goToCollection(data.collection.address))}
          isLoading={isLoading}
        />
      )
    );
  };

  return (
    <VirtuosoGrid
      useWindowScroll={true}
      totalCount={isLoading ? SIZE : collections.length}
      endReached={() => loadMore()}
      overscan={500}
      components={{
        Item: VContainer(config),
        List: VList
      }}
      itemContent={itemContent}
    />
  );
};
