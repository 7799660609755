import * as React from 'react';

import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'inherit',
    padding: 0
  }
}));

export default HtmlTooltip;
