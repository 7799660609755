import React from 'react';
import { BoxProps, keyframes, SxProps, Theme } from '@mui/material';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { Chips } from 'components';

type Props = {
  onClick: () => void;
  maxChips: number;
} & BoxProps;

const flash = keyframes`
  from { opacity: 1; }	
	to { opacity: 0.8; }
  `;

const customSx: SxProps<Theme> = {
  margin: (theme) => theme.spacing(1, 1, 0, 0),
  background: (theme) => theme.colors.blue,
  width: 'fit-content',
  cursor: 'pointer',
  left: '94%',
  zIndex: 100,
  animation: `${flash} 600ms ease infinite alternate`,
  ':hover': {
    background: (theme) => theme.colors.buttonBlue + '80'
  }
};

const BasketChip = ({ maxChips, onClick, ...rest }: Props) => {
  const basket = useSelector((global: GlobalState) => global.state.basket);
  if (basket.size == 0) return null;
  const chips = [...basket.values()].map((nft) => nft.thumbnail);

  return <Chips thumbnails={chips} onClick={onClick} maxChips={maxChips} {...rest} sx={customSx} />;
};

export default BasketChip;
