// 3rd party libs
import React from 'react';
import { Drawer, useMediaQuery } from '@mui/material';
import {
  BuyItem,
  CustomDialog,
  MakeOffer,
  NFTsBasket,
  CancelSale,
  UpdateSale,
  CheckoutBasket,
  AcceptOffer,
  ListForSale,
  CreateNFT,
  Web3,
  CancelOffer,
  CollectionCreator,
  Swap,
  Authentication,
  TelegramNotifs,
  EmailNotifs,
  Transfer,
  ListNFT,
  TransferNFT,
  AdvancedOffer
} from 'components';
import { theme } from 'themes';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { openModal } from 'store/actions';
import { Modal } from 'types';
import CloseIcon from '@mui/icons-material/Close';
import { DrawerHeader } from 'helpers/drawerHeader';

const getContent = (modal: Modal | undefined) => {
  if (!modal) return null;
  return modal == 'BASKET' ? (
    <NFTsBasket />
  ) : modal == 'BUY ITEM' ? (
    <BuyItem />
  ) : modal == 'MAKE OFFER' ? (
    <MakeOffer />
  ) : modal == 'CANCEL OFFER' ? (
    <CancelOffer />
  ) : modal == 'CANCEL SALE' ? (
    <CancelSale />
  ) : modal == 'UPDATE SALE' ? (
    <UpdateSale />
  ) : modal == 'CHECKOUT_BASKET' ? (
    <CheckoutBasket />
  ) : modal == 'ACCEPT OFFER' ? (
    <AcceptOffer />
  ) : modal == 'LIST FOR SALE' ? (
    <ListForSale />
  ) : modal == 'CREATE NFT' ? (
    <CreateNFT />
  ) : modal == 'CREATE COLLECTION' ? (
    <CollectionCreator />
  ) : modal == 'WALLET' ? (
    <Web3 help={true} />
  ) : modal == 'SWAP' ? (
    <Swap />
  ) : modal == 'AUTH' ? (
    <Authentication />
  ) : modal == 'TELEGRAM NOTIFICATIONS' ? (
    <TelegramNotifs />
  ) : modal == 'EMAIL NOTIFICATIONS' ? (
    <EmailNotifs />
  ) : modal == 'TRANSFER' ? (
    <Transfer />
  ) : modal == 'LIST NFT FOR SALE' ? (
    <ListNFT />
  ) : modal == 'TRANSFER NFT' ? (
    <TransferNFT />
  ) : modal == 'ADVANCED OFFER' ? (
    <AdvancedOffer />
  ) : null;
};

const Dialog = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const modal = useSelector((global: GlobalState) => global.state.modal);

  if (!modal) return null;

  const closeDialog = () => dispatch(openModal.create({}));
  return (
    <>
      {matches && (
        <CustomDialog open={!!modal} onClose={() => closeDialog()}>
          {getContent(modal)}
        </CustomDialog>
      )}
      {!matches && (
        <Drawer
          anchor="bottom"
          open={!!modal}
          onClose={() => closeDialog()}
          PaperProps={{
            sx: {
              width: '100%',
              height: '100%',
              background: (theme) => theme.palette.background.default
            }
          }}
        >
          <DrawerHeader>
            <CloseIcon className="close" onClick={() => closeDialog()} />
          </DrawerHeader>
          {getContent(modal)}
        </Drawer>
      )}
    </>
  );
};

export default Dialog;
