// 3rd party libs
import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Avatar from 'components/Avatar';
import Typography from '@mui/material/Typography';
import { CircularProgress, Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AutoCompleteBase } from 'types/api.types';
import { useAutocompleteMutation } from 'api';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';
import { formatAddress } from 'helpers/formatAddress';

type Props = {
  close?: () => void;
} & PaperProps;

const ResultsContainer = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: '0.1rem',
  background: 'transparent'
}));

const Result = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0.9375rem',
  padding: theme.spacing(1),
  cursor: 'pointer',
  ':hover': {
    background: theme.palette.action.hover,
    transitionDuration: '0.3s',
    svg: {
      display: 'block'
    }
  }
}));

const resultRenderer = (
  result: AutoCompleteBase,
  type: string,
  goTo: (id: string, certified?: boolean) => void
) => {
  const { id, name, avatar, certified } = result;

  return (
    <Result key={id} onClick={() => goTo(id, certified)}>
      <Avatar src={avatar} approved={certified} iconStyle={{ fontSize: 18 }} />
      <Typography variant="body2" color="text.primary" ml={2}>
        {name && name != 'anonymous' ? name : formatAddress(id)}
      </Typography>
      <ChevronRightIcon sx={{ display: 'none', ml: 'auto' }} />
    </Result>
  );
};

const renderer = (
  key: string,
  results: AutoCompleteBase[],
  type: string,
  goTo: (id: string, certified?: boolean) => void
) => {
  if (results.length == 0) return null;
  return (
    <Stack>
      <Typography variant="body2" color="text.secondary">
        {key}
      </Typography>
      {results.map((result) => resultRenderer(result, type, goTo))}
    </Stack>
  );
};

const Loading = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress sx={{ color: 'GrayText' }} />
    </Box>
  );
};

const NoItemFound = () => {
  return (
    <Box>
      <Typography variant="body2" color="text.secondary">
        No items found
      </Typography>
    </Box>
  );
};

const oneNotEmpty = (arrays: unknown[][]) => {
  return arrays.some((a) => a?.length > 0);
};

const AutoCompleteRenderer = ({ close, ...rest }: Props): JSX.Element | null => {
  const [_, { data, isLoading, reset }] = useAutocompleteMutation({
    fixedCacheKey: 'auto-complete'
  });
  const navigate = useNavigate();
  const { collections, sales, artists } = data?.autocomplete || {
    collections: [],
    sales: [],
    artists: []
  };

  // Redirect to 'collection' | 'nft' | 'artist' pages
  const goTo = (target: 'collection' | 'nft' | 'artist', id: string, certified?: boolean) => {
    const { collection_page, nft, profile } = AppRoutes;
    if (target == 'collection') navigate(`/${collection_page.replace(':address', id)}`);
    if (target == 'nft') navigate(`/${nft.replace(':address', id)}`);
    if (target == 'artist') {
      certified
        ? navigate(`/${profile.replace(':address', id)}/created`)
        : navigate(`/${profile.replace(':address', id)}`);
    }
    reset();
    close?.();
  };

  if (!isLoading && !data) return null;

  return (
    <ResultsContainer elevation={0} {...rest}>
      {isLoading ? (
        <Loading />
      ) : !oneNotEmpty([collections, sales, artists]) ? (
        <NoItemFound />
      ) : (
        <>
          {renderer('Collections', collections, 'collection', (id) => goTo('collection', id))}
          {renderer('NFTs', sales, 'nft', (id) => goTo('nft', id))}
          {renderer('Artists', artists, 'artist', (id, certified) => goTo('artist', id, certified))}
        </>
      )}
    </ResultsContainer>
  );
};

export default AutoCompleteRenderer;
