import React from 'react';
import { Grid, Skeleton, Stack, Typography, styled } from '@mui/material';
import { NFTDetailsItem } from './helpers';
import { NftAttribute } from 'types/generated';
import { shortCompact } from 'lib/format';

type Props = {
  attributes: NftAttribute[];
  loading?: boolean;
};

type PropertyItemProps = {
  attribute: NftAttribute;
  loading?: boolean;
};

const Empty = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '6rem'
});

const NFTPropertyItem = ({ attribute, loading }: PropertyItemProps) => {
  const { trait_type, value, proba } = attribute;
  return (
    <Stack
      sx={{
        border: (theme) => theme.border,
        borderRadius: '1rem',
        background: 'transparent',
        p: 2,
        height: '100%'
      }}
    >
      <Typography variant="body1" color="text.primary" sx={{ mb: 2, textTransform: 'capitalize' }}>
        {loading ? <Skeleton width={100} /> : trait_type.toLowerCase()}
      </Typography>
      <NFTDetailsItem
        title={value}
        subTitle={`${shortCompact.format(proba || 0)}% probability`}
        loading={loading}
      />
    </Stack>
  );
};

const propertiesSkeleton = (size: number) => {
  return [...Array(size).keys()].map((i) => {
    return (
      <Grid key={i} item xs={12} md={4}>
        <NFTPropertyItem attribute={{ trait_type: '', value: '' }} loading={true} />
      </Grid>
    );
  });
};

export const NFTProperties = ({ attributes, loading }: Props) => {
  return (
    <Grid container spacing={3}>
      {loading ? (
        propertiesSkeleton(9)
      ) : attributes.length == 0 ? (
        <Empty sx={{ px: '2rem' }}>
          <Typography variant="body1" color="text.primary">
            No properties
          </Typography>
        </Empty>
      ) : (
        attributes.map((attr, index) => {
          const key = `${attr.trait_type}-${index}`;
          return (
            <Grid key={key} item xs={12} md={4}>
              <NFTPropertyItem attribute={attr} loading={loading} />
            </Grid>
          );
        })
      )}
    </Grid>
  );
};
