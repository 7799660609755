import React from 'react';
import { Stack, Typography, Box, Link, useTheme } from '@mui/material';

type Props = {
  title: string;
  subTitle: string;
  link?: { url: string, target: string, label: string  };
};

export const Banner = ({ title, subTitle, link }: Props) => {
  const theme = useTheme();
  const isDark = theme.palette.mode == 'dark';
  return (
    <Stack spacing={2} sx={{ mt: { xs: 3, lg: 5 }, mb: 5 }}>
      <Typography variant="h1" textAlign="center">
        {title}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" textAlign="center">
        {subTitle}
      </Typography>
      {link && (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Link href={link.url} variant="body1" sx={{ color: isDark ? theme.colors.white : theme.colors.grey100 }} underline="none" target={link.target} >
            {link.label}
          </Link>
        </Box>
      )}
    </Stack>
  );
};

export default Banner;
