import React from 'react';
import { Typography, Stack, SvgIcon } from '@mui/material';
import { ReactComponent as Approved } from '../../assets/certified.svg';
import { NftPreview } from 'types/generated';

type Props = {
  nft: NftPreview;
  el?: JSX.Element;
};

const MiniatureInfo = ({ nft, el }: Props) => {
  const { name, collection } = nft;
  return (
    <Stack direction="column" justifyContent="center" sx={{ flex: 1, minWidth: 0 }}>
      <Typography
        variant="body1"
        color="text.primary"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      >
        {name}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'inline-flex',
          verticalAlign: 'middle'
        }}
      >
        {collection.name}
        {collection.certified && (
          <SvgIcon sx={{ width: '1rem', ml: 0.5 }}>
            <Approved />
          </SvgIcon>
        )}
      </Typography>
      {el}
    </Stack>
  );
};

export default MiniatureInfo;
