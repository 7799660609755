import React, { useEffect, useState } from 'react';
import { CollectionSearchRenderer, DropDownElement } from 'components';
import { BoxProps, Typography } from '@mui/material';
import { AutocompleteBase, Collection, SearchTarget } from 'types/generated';
import { useAutocompleteMutation } from 'api';

type Props = {
  mobile?: boolean;
  collection?: Collection;
  setCollection: (collection?: Collection) => void;
} & BoxProps;

const toCollections = (res: AutocompleteBase[]): Collection[] => {
  const collections: Collection[] = [];
  res?.forEach((r) =>
    collections.push({
      address: r.id,
      name: r.name,
      certified: r.certified,
      avatar: r.avatar,
      thumbnails: []
    })
  );
  return collections;
};

const CollectionAutoComplete = ({ setCollection, collection, mobile, ...props }: Props) => {
  const [topCollections, setTopCollections] = useState<Collection[]>([]);
  const [filtered, setFiltered] = useState<Collection[] | undefined>();
  const [autoComplete] = useAutocompleteMutation();

  useEffect(() => {
    /**
     * Query top collections (only one time)
     */
    autoComplete({
      input: { targets: [SearchTarget.Collections], certified: false, top: true }
    })
      .unwrap()
      .then((res) => {
        setTopCollections(toCollections(res.autocomplete.collections));
      });
  }, []);

  const handleSearchChange = (search: string): void => {
    if (search.length >= 3) {
      autoComplete({
        input: {
          word_begin: search,
          targets: [SearchTarget.Collections],
          certified: false,
          top: false
        }
      })
        .unwrap()
        .then((res) => setFiltered(toCollections(res.autocomplete.collections)));
    }
  };

  return mobile ? (
    <CollectionSearchRenderer
      collection={collection}
      collections={topCollections}
      filtered={filtered}
      onInputChange={handleSearchChange}
      setCollection={(c) => {
        setCollection(c);
        if (!c) setFiltered(undefined);
      }}
      {...props}
    />
  ) : (
    <DropDownElement
      element={
        <CollectionSearchRenderer
          collection={collection}
          collections={topCollections}
          filtered={filtered}
          onInputChange={handleSearchChange}
          setCollection={(c) => {
            setCollection(c);
            if (!c) setFiltered(undefined);
          }}
        />
      }
      valueRenderer={
        <Typography
          variant="body2"
          sx={{
            ml: 1,
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {collection ? collection.name : 'All collections'}
        </Typography>
      }
      {...props}
    />
  );
};

export default CollectionAutoComplete;
