import * as React from 'react';

import { Stack, StackProps, styled, Box, Typography } from '@mui/material';

type Props = {
  title: string;
  switcher: JSX.Element;
  dropDown: JSX.Element;
} & StackProps;

const HeaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1.5rem'
});

export const Header = ({ title, switcher, dropDown }: Props) => {
  return (
    <HeaderContainer sx={{ p: '1rem' }}>
      <Stack spacing={1}>
        <Typography variant="h4" color="text.primary">
          {title}
        </Typography>
        {switcher}
      </Stack>
      {dropDown}
    </HeaderContainer>
  );
};
