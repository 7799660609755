import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Link, styled, Typography } from '@mui/material';
import { Button, Container, Swiper } from 'components';
import { KalaoGoCard } from 'components';
import { KalaoGo } from 'types';

const Root = styled(Box)(({ theme }) => ({
  background: theme.input.background
}));

const breakpoints = {
  300: {
    slidesPerView: 1,
    spaceBetween: 10
  },
  700: {
    slidesPerView: 2,
    spaceBetween: 10
  },
  900: {
    slidesPerView: 3,
    spaceBetween: 10
  },
  1200: {
    slidesPerView: 3,
    spaceBetween: 10
  },
  1536: {
    slidesPerView: 4,
    spaceBetween: 10
  }
};

const topSectionRenderer = () => {
  return (
    <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      <Typography variant="h2" color="text.primary">
        Kalao Go
      </Typography>
    </Box>
  );
};

const initGo: KalaoGo = {
  address: '',
  name: '',
  url_icon: '',
  url_cover: '',
  description: '',
  total_items: 0,
  url_go: '',
  status_tag: 'upcoming',
  type_tag: 'free_mint',
  url_twitter: '',
  start_date: '',
  start_time: '',
  starting_date: '',
  status_name: '',
  type_name: '',
  starting_price: '',
  reserve_price: '',
  last_price: ''
};

const Go = () => {
  const [kalaoGo, setKalaoGo] = useState<KalaoGo[]>([...Array(10).keys()].map(() => initGo));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('https://api.kalao.io/go/get_home_marketplace?filter_day=30').then((data) => {
      data.text().then((res) => {
        const go = JSON.parse(res);
        const parse = go.result as KalaoGo[];
        setKalaoGo(parse);
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <Root>
      <Container sx={{ mb: { xs: 2, md: 5 } }}>
        <Swiper
          style={{ marginLeft: '1%', marginRight: '1%' }}
          el={topSectionRenderer()}
          navigation={true}
          items={kalaoGo.map((go) => (
            <Box key={go.address} sx={{ p: 1 }}>
              <KalaoGoCard go={go} isLoading={isLoading} />
            </Box>
          ))}
          options={{
            breakpoints
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
          <Link href="https://go.kalao.io/" underline="none" target="_blank">
            <Button ctx="secondary" size="large" sx={{ width: '8rem' }}>
              <Typography variant="button">View all</Typography>
            </Button>
          </Link>
        </Box>
      </Container>
    </Root>
  );
};

export default Go;
