import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Collections } from './collections';

const Created = () => {
  return (
    <Routes>
      <Route path="/" element={<Collections />} />
      <Route path="collections" element={<Collections />} />
    </Routes>
  );
};

export default Created;
