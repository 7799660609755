import { fromUnixTime } from 'date-fns';
import { GraphNode, StatsRanges } from 'types/generated';

type Data = {
  y: number;
  x: number;
};

type Series = {
  market_cap: Data[];
  average_price: Data[];
  volume: Data[];
  floor_price: Data[];
  buyers: Data[];
  sellers: Data[];
  nfts: Data[];
  owners: Data[];
  sales: Data[];
  offers: Data[];
  sold: Data[];
};

export const toStats = (graph?: { nodes: GraphNode[] }): Series => {
  const stats: Series = {
    market_cap: [],
    average_price: [],
    volume: [],
    floor_price: [],
    buyers: [],
    sellers: [],
    nfts: [],
    owners: [],
    sales: [],
    offers: [],
    sold: []
  };
  graph?.nodes.forEach((node) => {
    const {
      market_cap,
      average_price,
      volume,
      floor_price,
      buyers,
      sellers,
      owners,
      offers,
      sales,
      sold,
      x_coordinate
    } = node;
    const x = fromUnixTime(x_coordinate).getTime();
    stats.market_cap.push({ y: market_cap, x });
    stats.average_price.push({ y: average_price, x });
    stats.volume.push({ y: volume, x });
    stats.floor_price.push({ y: floor_price, x });
    stats.buyers.push({ y: buyers, x });
    stats.sellers.push({ y: sellers, x });
    //stats.nfts.push(node.nfts);
    stats.owners.push({ y: owners, x });
    stats.sales.push({ y: sales, x });
    stats.offers.push({ y: offers, x });
    stats.sold.push({ y: sold, x });
  });

  return stats;
};

export const toDataset = (
  type: string,
  label: string,
  data: { x: number; y: number }[],
  fill = true,
  color = '#1f78c1',
  backgroundColor?: string,
  yAxisID?: string
) => {
  return {
    type,
    label,
    data,
    borderColor: color,
    backgroundColor,
    fill: fill,
    tension: 0.3,
    pointHoverBackgroundColor: '#1f78c1',
    maxBarThickness: 6,
    yAxisID
  };
};

export const periods: { [key in StatsRanges]: string } = {
  all: 'All time',
  daily: 'Last 24h',
  monthly: 'Last 30 days',
  trimestrial: 'Last 3 months',
  weekly: 'Last 7 days',
  yearly: 'Last 12 months'
};
