import { addDays } from 'date-fns';
import { ethers } from 'ethers';
import { NftPreview, OffChainSaleInput } from 'types/generated';

/**
 * Convert direct sale to the expected api payload (BackendSale)
 */
export const toBackendSale = (
  nft: NftPreview,
  account: string,
  nonce: number,
  chainId: number
): OffChainSaleInput => {
  if (!nft.sale) throw new Error('Invalid data');
  return {
    token_address: nft.collection.address,
    asset_id: nft.asset_id,
    token_id: nft.token_id,
    seller: account,
    // Why amount_sold is typed as string
    in_sale_amount: nft.sale.quantity.toString() || '1',
    unitary_price: ethers.utils.parseEther(nft.sale.unitary_price_float.toString()).toString(),
    // We don't need starting/expiration dates for a direct sale !
    starting_ts: Math.round(new Date().getTime() / 1000),
    expiration_ts: Math.round(addDays(new Date(), 182).getTime() / 1000), // sale must expire in 6 months
    nonce,
    chain_id: chainId,
    managed: false,
    min_unitary_price: '0',
    signature: ''
  };
};
