// 3rd party libs
import React from 'react';
import { SxProps, Theme, Grid, Divider, Box, Link } from '@mui/material';
import { Container, Banner, Footer } from 'components';
import Kalao from './Kalao';
import KalaoStaking from './KalaoStaking';
import KalaoRewards from './KalaoRewards';
import KalaoEscrowed from './KalaoEscrowed';
import KalaoVesting from './KalaoVesting';
import Pgl from './Pgl';

const sxComingSoon: SxProps<Theme> = {
  filter: 'blur(0.2rem)',
};

const sxDisabled: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  backgroundColor: 'transparent'
};

const Rewards = () => {
  return (
    <>
      <Container sx={{ paddingBottom: '5rem' }}>
        <Banner
          title="Staking Rewards"
          subTitle="Staking capabilities coming soon on Marketplace V2"
          link={{ url: 'https://marketplace.v1.kalao.io/klo/staking', target: '_blank', label: 'Please click here to access Staking menu on Marketplace V1' }}
        />
        <Box sx={sxComingSoon}>
          <Box sx={sxDisabled}></Box>
          <Divider sx={{ my: 4 }} />
          <Kalao />
          <Grid container direction="row" spacing={2} sx={{ mt: 1 }}>
            <KalaoStaking />
            <KalaoRewards />
            <KalaoEscrowed />
            <KalaoVesting />
          </Grid>
          <Pgl />
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Rewards;
