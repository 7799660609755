import React from 'react';
import Box from '@mui/material/Box';
import { styled, Typography, Grid, Stack } from '@mui/material';
import Button from 'components/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Container, PartnerCard } from 'components';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';
import { Partner } from 'types';

type Props = {
  partner: Partner;
  isLoading?: boolean;
};

const IconContainer = styled(Box)`
  transform: translateY(0.125rem) rotate(-45deg);
`;

const Root = styled(Box)(({ theme }) => ({
  background: theme.input.background
}));

const HomeBanner = ({ partner, isLoading }: Props) => {
  const navigate = useNavigate();

  return (
    <Root>
      <Container style={{ marginBottom: 0 }}>
        <Grid container direction="row" spacing={{ xs: 2, sm: 2, md: 4, lg: 10 }} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Stack spacing={4}>
              <Typography
                variant="h1"
                sx={{
                  textAlign: { xs: 'center', md: 'start' }
                }}
                color="text.primary"
              >
                Explore, Create and Experience NFTs on Avalanche
              </Typography>
              <Typography
                variant="h3"
                color="text.secondary"
                sx={{
                  textAlign: { xs: 'center', md: 'start' }
                }}
              >
                Kalao is your community-first NFT marketplace.
              </Typography>
              <Box
                sx={{ display: 'flex', gap: '1rem', justifyContent: { xs: 'center', md: 'start' } }}
              >
                <Button
                  size="small"
                  ctx="primary"
                  onClick={() => navigate(AppRoutes.explore)}
                  endIcon={
                    <IconContainer>
                      <ArrowForwardIcon fontSize="small" />
                    </IconContainer>
                  }
                >
                  <Typography variant="button">Explore now</Typography>
                </Button>
                <Button size="small" ctx="secondary" onClick={() => navigate(AppRoutes.create)}>
                  <Typography variant="button">Create now</Typography>
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PartnerCard partner={partner} imgWidth="100%" isLoading={isLoading} isPrimary={true} />
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default HomeBanner;
