import React, { ReactNode } from 'react';
import { Box, Skeleton, Stack, styled, Typography } from '@mui/material';
import Avatar from 'components/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { addToBasket, batch, openModal, setNFT, setSale } from 'store/actions';
import { Button, CollectionOverview, HtmlTooltip, ImageContainer, Like, Price } from 'components';
import { GlobalState } from 'store';
import { Collection, NftOfferPreview, NftPreview, NftSalePreview } from 'types/generated';
import { compact, priceFormatter } from 'lib/format';
import { PriceSymbol } from 'types';
import { useWeb3React } from '@web3-react/core';
import { Selector } from 'helpers/nfts';
import { equalsIgnoringCase } from 'helpers/common';
import { useResize } from 'providers';
import { AppRoutes } from 'constants/routes';
import { Link, useNavigate } from 'react-router-dom';
import { goToCollection as goToCollectionHelper } from 'helpers/collections';

type Props = {
  // true -  when the card is shown in the collection page
  hideAvatar?: boolean;
  nft: NftPreview;
  owned?: boolean;
  goTo?: () => void;
  goToCollection?: () => void;
  liked?: boolean;
  onLike?: () => void;
  isLoading?: boolean;
  showBalance?: boolean;
};

type InfoProps = {
  nft: NftPreview;
  hideAvatar: boolean;
  buy: () => void;
  makeOffer: () => void;
  skip: boolean;
  goToCollection?: () => void;
  children: ReactNode;
  size: string;
  isLoading?: boolean;
};

const Stacked = styled('div')<{ stackedtop: number; stackedheight: number; scale: number }>(
  ({ theme, stackedtop, stackedheight, scale }) => ({
    position: 'absolute',
    top: `${stackedtop}%`,
    height: `${stackedheight}%`,
    width: '100%',
    overflow: 'hidden',
    border: theme.border,
    transform: `scaleX(${scale})`,
    transformOrigin: 'bottom',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    boxShadow: 'rgba(0, 0, 0, 0) 0px 1px -4px;'
  })
);

// Styling
const Card = styled(Box)<{ stacked?: boolean }>(({ theme, stacked }) => ({
  transform: stacked ? 'scale(0.95)' : undefined,
  transformOrigin: 'bottom',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '1rem',
  background: 'transparent',
  boxShadow: !stacked ? 'rgba(0, 0, 0, 0) 0px 1px 4px;' : undefined,
  border: theme.border,
  transition: 'all 0.2s',
  ':hover': {
    border: theme.borderHover,
    background:
      theme.palette.mode == 'dark'
        ? theme.colors.backgroundSecondaryDark
        : theme.colors.backgroundPrimaryLight,
    '.image-container': {
      opacity: 0.8
    },
    '.circle,.action-button': {
      display: 'block',
      transition: 'all 0.5s'
    },
    '.best-offer': {
      display: 'none'
    }
  },

  // Appears when hovering the NFT card
  '.circle': {
    display: 'none',
    position: 'absolute',
    top: 5,
    left: 5,
    alignItems: 'center',
    justifyContent: 'center',
    '&.selected': { display: 'block' }
  },
  '.action-button': {
    display: 'none'
  }
}));

const AvatarContainer = styled(Box)({
  position: 'absolute',
  top: 'calc(-1.71875rem)',
  left: 'calc(1rem)'
});

export const CardInfoContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem'
});

const avatarRenderer = (collection: Collection, isLoading?: boolean) => {
  const { certified, avatar, address } = collection;
  const navigate = useNavigate();
  const onCollection = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(goToCollectionHelper(collection.address));
  };
  if (isLoading)
    return (
      <AvatarContainer>
        <Skeleton variant="circular" sx={{ width: 55, height: 55 }} />
      </AvatarContainer>
    );
  return (
    <HtmlTooltip
      arrow={true}
      sx={{
        '& .MuiTooltip-arrow': {
          color: (theme) => theme.palette.background.paper
        }
      }}
      placement="top-start"
      title={<CollectionOverview address={address} />}
    >
      <AvatarContainer>
        <Avatar
          top={25}
          approved={certified}
          src={avatar}
          sx={{
            width: 55,
            height: 55,
            cursor: 'pointer',
            border: (theme) => `0.2rem solid ${theme.input.background}`
          }}
          onClick={onCollection}
          iconStyle={{ fontSize: 20 }}
        />
      </AvatarContainer>
    </HtmlTooltip>
  );
};

const Sale = (props: { sale?: NftSalePreview | null; size: string; isLoading?: boolean }) => {
  if (!props.sale) return null;
  const { isLoading, sale } = props;
  const price = sale.unitary_price_float;
  return (
    <Stack>
      <Typography variant={props.size == 'comfy' ? 'body1' : 'body2'} color="text.secondary">
        {isLoading ? <Skeleton width={50} /> : 'Price'}
      </Typography>
      <Price
        value={priceFormatter.format(price)}
        sx={{ flex: 1 }}
        variant={props.size == 'comfy' ? 'subtitle1' : 'subtitle2'}
        fontWeight="600"
        isLoading={isLoading}
      />
    </Stack>
  );
};

const Offer = (props: { offer?: NftOfferPreview | null; isLoading?: boolean }) => {
  if (!props.offer || !props.offer.unitary_price_float) return null;
  const price = props.offer.unitary_price_float;
  const isLoading = props?.isLoading;
  return (
    <Stack className="best-offer">
      <Typography variant="caption" color="text.secondary">
        {isLoading ? <Skeleton width={50} /> : 'Best offer'}
      </Typography>
      <Price
        value={priceFormatter.format(price)}
        symbol={PriceSymbol.WAVAX}
        sx={{ flex: 1 }}
        justifyContent="flex-end"
        isLoading={isLoading}
      />
    </Stack>
  );
};

// Buy or Make an offe in case the nft is not listed for sale
const ActionButtons = (props: {
  skip: boolean;
  nft: NftPreview;
  buy: () => void;
  makeOffer: () => void;
}) => {
  const { skip, nft, buy, makeOffer } = props;

  const onBuy = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    buy();
  };
  const onMakeOffer = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    makeOffer();
  };

  if (skip) return null;
  else if (nft?.sale)
    return (
      <Button
        ctx="primary"
        sx={{ borderRadius: '0.5rem', p: 0.5, width: '6rem' }}
        className="action-button"
        onClick={onBuy}
      >
        <Typography variant="button">Buy</Typography>
      </Button>
    );
  else
    return (
      <Button
        ctx="primary"
        sx={{ borderRadius: '0.5rem', p: 0.5, width: '6rem' }}
        className="action-button"
        onClick={onMakeOffer}
      >
        <Typography variant="button">Make offer</Typography>
      </Button>
    );
};

const Info = ({
  nft,
  hideAvatar,
  buy,
  skip,
  makeOffer,
  children,
  size,
  isLoading
}: InfoProps) => {
  const { name, collection, sale, offer } = nft;
  return (
    <CardInfoContainer>
      {!hideAvatar && avatarRenderer(collection, isLoading)}
      {children}
      <Typography
        variant="body2"
        color="text.primary"
        sx={{
          pt: !hideAvatar ? 3 : 0,
          pb: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: size == 'comfy' ? '1.32rem' : '1.1rem',
          fontWeight: 600
        }}
      >
        {isLoading ? <Skeleton /> : name}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          height: '3rem'
        }}
      >
        <Sale sale={sale} size={size} isLoading={isLoading} />
        <Stack sx={{ ml: 'auto' }} alignItems="flex-end">
          <Offer offer={offer} isLoading={isLoading} />
          {!isLoading && <ActionButtons skip={skip} nft={nft} buy={buy} makeOffer={makeOffer} />}
        </Stack>
      </Box>
    </CardInfoContainer>
  );
};

const ExploreNFTCard = ({
  nft,
  hideAvatar = false,
  goTo,
  goToCollection,
  liked,
  onLike,
  owned,
  isLoading,
  showBalance
}: Props): JSX.Element => {
  const { account } = useWeb3React();
  const { size } = useResize();
  const dispatch = useDispatch();
  const isSelected = useSelector((global: GlobalState) => global.state.basket).has(nft.asset_id);

  const onSelect = () => {
    if (nft.sale) dispatch(addToBasket.create({ nftSale: { ...nft, sale: nft.sale } }));
  };

  const onBuy = () => {
    if (nft.sale) {
      dispatch(
        batch.create({
          actions: [
            setSale.create({ nftSale: { ...nft, sale: nft.sale } }),
            openModal.create({ modal: 'BUY ITEM' })
          ]
        })
      );
    }
  };

  const onMakeOffer = () => {
    dispatch(
      batch.create({
        actions: [
          setNFT.create({
            nft: { ...nft, editions: '1' }
          }),
          openModal.create({ modal: 'MAKE OFFER' })
        ]
      })
    );
  };

  const skip = owned || equalsIgnoringCase(account, nft.sale?.seller);
  const stacked = equalsIgnoringCase(nft.collection.kind || '', 'erc1155');

  //Disable Button TMP launch
  const disableLike = true;

  // Display 'editions' for 1155
  const editions =
    !showBalance || nft?.collection.kind !== 'erc1155'
      ? undefined
      : nft?.balance
      ? compact.format(Number(nft?.balance))
      : undefined;

  const nftPageLink = `/${AppRoutes.nft.replace(':address', nft.asset_id)}`;

  return (
    <Link to={nftPageLink} style={{ position: 'relative', textDecoration: 'none' }}>
      {stacked && (
        <>
          <Stacked stackedtop={0.05} stackedheight={2.5} scale={0.78}>
            <ImageContainer src={nft.thumbnail} />
          </Stacked>
          <Stacked stackedtop={2.5} stackedheight={2.5} scale={0.86}>
            <ImageContainer src={nft.thumbnail} />
          </Stacked>
        </>
      )}
      <Card stacked={stacked}>
        <ImageContainer
          className={'image-container'}
          src={nft.thumbnail}
          animation={nft.animation_url}
          animation_mime={nft.animation_mime}
          clickable={true}
          onClick={goTo}
          isLoading={isLoading}
          editions={editions}
          displayTheme={nft.collection.display_theme}
        />
        <Selector
          isSelected={isSelected}
          className={isSelected ? 'circle selected' : 'circle'}
          click={onSelect}
          skip={nft?.sale == undefined || skip}
        />
        <Info
          nft={nft}
          hideAvatar={hideAvatar}
          buy={onBuy}
          makeOffer={onMakeOffer}
          skip={skip}
          goToCollection={goToCollection}
          size={size}
          isLoading={isLoading}
        >
          {!disableLike && (
            <Box sx={{ position: 'absolute', top: 5, right: 5 }}>
              <Like likes={nft.likes} liked={liked} onLike={onLike} />
            </Box>
          )}
        </Info>
      </Card>
    </Link>
  );
};

export default ExploreNFTCard;
