import React from 'react';
import { Accept } from 'react-dropzone';
import { ReactComponent as FileUploadDark } from '../../../assets/create-nft/upload-dark.svg';
import { ReactComponent as FileUploadLight } from '../../../assets/create-nft/upload-light.svg';
import { ReactComponent as ImageDark } from '../../../assets/create-nft/upload-image-dark.svg';
import { ReactComponent as ImageLight } from '../../../assets/create-nft/upload-image-light.svg';
import { theme } from 'themes';
import { Typography } from '@mui/material';

type Props = {
  title?: string;
};

export const onlyImages: Accept = {
  'image/jpeg': ['.jpeg', '.png', '.webp', '.gif']
};

export const allMedia: Accept = {
  'image/jpeg': ['.jpeg', '.png', '.webp', '.gif', '.mp3', '.mp4'],
  'audio/mpeg': ['.mp3'],
  'video/mp4': ['.mp4']
};

export const UploaderIconLabel = () => {
  return (
    <>
      {theme.palette.mode == 'dark' ? <FileUploadDark /> : <FileUploadLight />}
      <Typography variant="body2" color="text.secondary" sx={{ mt: 4, mb: 2 }}>
        JPG, PNG, GIF, WEBP, MP3 or MP4. Max 100MB
      </Typography>
    </>
  );
};

export const ImageIconLabel = ({ title }: Props) => {
  return (
    <>
      {title && (
        <Typography variant="h5" fontWeight={500} sx={{ mb: 1 }}>
          {title}
        </Typography>
      )}
      {theme.palette.mode == 'dark' ? <ImageDark /> : <ImageLight />}
      <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
        Drag and drop or Browse to upload. <br />
        JPG, PNG, GIF or WEBP. Max 100MB
      </Typography>
    </>
  );
};
