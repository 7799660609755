import React, { useMemo, useState } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Certified } from '../../../assets/certified.svg';

// Types
import { EventType, Modal, NFTOffer, PriceSymbol } from 'types';

// Components
import { Activity, DropDownSelect, HtmlTooltip, Price, SquareIconButton } from 'components';

// Helpers
import Avatar from 'components/Avatar';
import { EventIcon } from 'helpers/events';
import { formatDistance, fromUnixTime } from 'date-fns';
import { activity_offers_options } from 'constants/options';
import { useDispatch } from 'react-redux';
import { batch, openModal, setOffer } from 'store/actions';
import { useWeb3React } from '@web3-react/core';
import { useMyOffersQuery } from 'api';
import { AttributesQuery, OfferKind, OfferSide, OfferStatus } from 'types/generated';
import { priceFormatter } from 'lib/format';
import { useNavigate } from 'react-router-dom';
import { goToNft } from 'helpers/nfts';
import { goToCollection } from 'helpers/collections';
import { Header } from './Header';
import { Switcher } from './Switcher';

type Action = 'accept' | 'cancel';

type OfferButtonsProps = {
  type: EventType;
  status: OfferStatus;
  click: (action: Action) => void;
};

const StyledRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '4.5rem',
  padding: theme.spacing(1),
  borderRadius: '0.5rem',
  ':hover': { background: theme.palette.action.hover }
}));

const StyledMobileRow = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  borderRadius: '0.5rem',
  ':hover': { background: theme.palette.action.hover }
}));

const StyledCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0.5),
  height: '100%'
}));

const AttributesContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '0.5rem',
  background: '#000'
}));

const type = (side: OfferSide) => {
  return side == OfferSide.Sent ? EventType.Offer : EventType.OfferReceived;
};

const Key = (caption: string) => {
  return (
    <Typography variant="caption" color="text.secondary">
      {caption}
    </Typography>
  );
};

const toStatus = (status: OfferStatus): string => {
  return Object.keys(OfferStatus)[Object.values(OfferStatus).indexOf(status)];
};

const propertiesRenderer = (attrs: string | undefined | null) => {
  if (!attrs) return <></>;
  const dic: Record<string, string[]> = {};
  const { attributes } = JSON.parse(attrs);

  (attributes as AttributesQuery[]).forEach((attribute) => {
    const { trait_type, value } = attribute;
    if (dic[trait_type]) dic[trait_type].push(value);
    else {
      dic[trait_type] = [value];
    }
  });

  const renderer = (trait_type: string, values: string[]) => {
    return (
      <Box key={trait_type} sx={{ display: 'flex', gap: 1 }}>
        <Typography variant="caption" color="#FFF" fontWeight={500}>
          {trait_type}:
        </Typography>
        <Typography variant="caption" color="GrayText" fontWeight={500}>
          {values.join(', ')}
        </Typography>
      </Box>
    );
  };

  return (
    <AttributesContainer spacing={0}>
      {Object.keys(dic).map((key) => renderer(key, dic[key]))}
    </AttributesContainer>
  );
};

const OfferButtons = ({ type, status, click }: OfferButtonsProps) => {
  let content: JSX.Element | null = null;

  if (type == EventType.OfferReceived)
    content = (
      <SquareIconButton size="small" onClick={() => click('accept')}>
        <CheckIcon fontSize="small" />
      </SquareIconButton>
    );
  else if (type == EventType.Offer && status == OfferStatus.Live)
    content = (
      <SquareIconButton size="small" onClick={() => click('cancel')}>
        <CloseIcon fontSize="small" />
      </SquareIconButton>
    );

  return content;
};

// Render offer on nft/collection
const avatar = (
  name: string,
  thumbnail: string,
  collection: string,
  certified: boolean,
  shape: string,
  color: string | undefined,
  attributes: string | undefined | null,
  goTo: () => void,
  goToCollection: () => void
) => {
  return (
    <>
      <Avatar
        onClick={goTo}
        src={thumbnail}
        sx={{ borderRadius: shape, width: '3.125rem', height: '3.125rem', cursor: 'pointer' }}
        top={20}
      />
      <Stack sx={{ flex: 1, minWidth: 0 }}>
        <HtmlTooltip
          placement="top-start"
          title={propertiesRenderer(attributes)}
          sx={{
            '& .MuiTooltip-arrow': {
              color: (theme) => theme.input.background
            }
          }}
        >
          <Typography
            variant="body2"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color
            }}
          >
            {name}
          </Typography>
        </HtmlTooltip>

        <Stack direction="row" spacing={0.5} alignItems="center" sx={{ cursor: 'pointer' }}>
          <Typography variant="caption" color="text.secondary" onClick={goToCollection}>
            {collection}
          </Typography>
          {certified && <Certified style={{ width: 16, height: 16 }} />}
        </Stack>
      </Stack>
    </>
  );
};

const offerRenderer = (
  nftOffer: NFTOffer,
  colors: Record<string, string>,
  click: (action: Action) => void,
  goTo: () => void,
  goToCollection: () => void
) => {
  const { name, collection, offer, asset_id, thumbnail } = nftOffer;
  const { name: title, certified } = collection;
  const {
    offer_id,
    side,
    unitary_price_float,
    floor_diff,
    expiration_date,
    buyer,
    status,
    bought_quantity,
    total_quantity,
    quantity,
    kind,
    attributes
  } = offer;
  const expiration = fromUnixTime(expiration_date);
  const qty = side == OfferSide.Received ? quantity : `${bought_quantity}/${total_quantity}`;
  const color =
    status == OfferStatus.Cancelled || status == OfferStatus.Expired
      ? colors.red
      : status == OfferStatus.Filled
      ? colors.green
      : 'text.primary';

  return (
    <StyledRow key={`${offer_id}-${asset_id}`}>
      <StyledCell sx={{ flex: 1 }}>
        {Key('Type')}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <EventIcon event={type(side)} />
          <Typography variant="caption">{type(side)}</Typography>
        </Box>
      </StyledCell>
      <StyledCell sx={{ flexDirection: 'row', alignItems: 'center', gap: 1, minWidth: 0, flex: 2 }}>
        {kind == OfferKind.Nft
          ? avatar(
              name,
              thumbnail,
              title,
              certified,
              '0.5rem',
              undefined,
              undefined,
              goTo,
              goToCollection
            )
          : avatar(
              kind == OfferKind.Collection ? 'On Collection' : 'On Properties',
              kind == OfferKind.Collection ? collection.avatar : thumbnail,
              title,
              certified,
              '50%',
              '#3D3AE9',
              attributes,
              goToCollection,
              goToCollection
            )}
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Offer price')}
        <Price
          value={priceFormatter.format(unitary_price_float)}
          size={12}
          symbol={PriceSymbol.WAVAX}
        />
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Qty')}
        <Typography variant="body2">{qty}</Typography>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Floor diff')}
        <Typography variant="body2">{floor_diff}</Typography>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Expiration')}
        <Typography variant="body2">
          {formatDistance(expiration, new Date(), { addSuffix: true })}
        </Typography>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Status')}
        <Typography variant="body2" sx={{ color }}>
          {toStatus(status)}
        </Typography>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'flex-end', flex: 1 }}>
        <OfferButtons status={status} type={type(side)} click={click} />
      </StyledCell>
    </StyledRow>
  );
};

const mobileRenderer = (
  nftOffer: NFTOffer,
  colors: Record<string, string>,
  click: (action: Action) => void,
  goTo: () => void,
  goToCollection: () => void
) => {
  const { name, collection, offer, asset_id, thumbnail } = nftOffer;
  const { name: title, certified } = collection;
  const {
    offer_id,
    side,
    expiration_date,
    buyer,
    unitary_price_float,
    status,
    bought_quantity,
    total_quantity,
    quantity,
    kind,
    attributes
  } = offer;
  const expiration = fromUnixTime(expiration_date);
  const color =
    status == OfferStatus.Cancelled || status == OfferStatus.Expired
      ? colors.red
      : status == OfferStatus.Filled
      ? colors.green
      : 'text.primary';

  return (
    <StyledMobileRow key={`${offer_id}-${asset_id}`}>
      <StyledCell sx={{ flexDirection: 'row', alignItems: 'center', gap: 1, minWidth: 0, flex: 2 }}>
        {kind == OfferKind.Nft
          ? avatar(
              name,
              thumbnail,
              title,
              certified,
              '0.5rem',
              undefined,
              undefined,
              goTo,
              goToCollection
            )
          : avatar(
              kind == OfferKind.Collection ? 'On Collection' : 'On Properties',
              kind == OfferKind.Collection ? collection.avatar : thumbnail,
              title,
              certified,
              '50%',
              '#3D3AE9',
              attributes,
              goToCollection,
              goToCollection
            )}
      </StyledCell>
      <Stack direction="row" justifyContent="space-between">
        <StyledCell sx={{ flex: 1 }}>
          {Key('Offer price')}
          <Price
            value={priceFormatter.format(unitary_price_float)}
            symbol={PriceSymbol.WAVAX}
            size={12}
          />
        </StyledCell>
        <StyledCell sx={{ alignItems: 'flex-end', flex: 1 }}>
          {Key('Status')}
          <Typography variant="body2" sx={{ color }}>
            {status}
          </Typography>
        </StyledCell>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="self-end">
        <StyledCell sx={{ flex: 1 }}>
          {Key('Expiration')}
          <Typography variant="body2">
            {formatDistance(expiration, new Date(), { addSuffix: true })}
          </Typography>
        </StyledCell>
        <StyledCell sx={{ alignItems: 'flex-end', flex: 1 }}>
          <OfferButtons status={status} type={type(side)} click={click} />
        </StyledCell>
      </Stack>
    </StyledMobileRow>
  );
};

export const Offers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { account } = useWeb3React();
  const [side, setSide] = useState<OfferSide>(OfferSide.All);
  const [status, setStatus] = useState<OfferStatus>(OfferStatus.Live);
  const { data } = useMyOffersQuery({ address: account || '', kind: side }, { skip: !account });

  const offers = useMemo(() => (data?.myOffers || []) as NFTOffer[], [data]);

  // filter status
  const iltered = useMemo(() => {
    if (status == OfferStatus.All) return offers;
    return offers.filter((offer) => offer.offer.status == status);
  }, [status, data]);

  const handleClick = (action: Action, nftOffer: NFTOffer) => {
    const modal: Modal = action == 'accept' ? 'ACCEPT OFFER' : 'CANCEL OFFER';

    dispatch(
      batch.create({
        actions: [setOffer.create({ nftOffer }), openModal.create({ modal })]
      })
    );
  };

  const goTo = (address: string) => navigate(goToNft(address));
  const gotoCollection = (address: string) => navigate(goToCollection(address));
  const current = side === OfferSide.All ? '' : side === OfferSide.Received ? 'Received' : 'Made';

  return (
    <Activity
      headers={
        <Header
          title="Offers"
          switcher={
            <Switcher
              current={current}
              options={['Made', 'Received']}
              click={(v) => {
                const offerSide =
                  v === current
                    ? OfferSide.All
                    : v === 'Received'
                    ? OfferSide.Received
                    : OfferSide.Sent;
                setSide(offerSide);
              }}
            />
          }
          dropDown={
            <DropDownSelect
              defaultKey={status}
              pairs={activity_offers_options}
              onSelectChange={(v) => setStatus(v as OfferStatus)}
              disablePortal={false}
              sx={{ width: '10rem' }}
            />
          }
        />
      }
      data={iltered}
      renderer={(el) =>
        matches
          ? offerRenderer(
              el,
              theme.colors,
              (action) => handleClick(action, el),
              () => goTo(el.asset_id),
              () => gotoCollection(el.collection.address)
            )
          : mobileRenderer(
              el,
              theme.colors,
              (action) => handleClick(action, el),
              () => goTo(el.asset_id),
              () => gotoCollection(el.collection.address)
            )
      }
      height={500}
      empty={
        <Typography variant="body1" color="text.primary">
          No offers
        </Typography>
      }
    />
  );
};
