import React from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Sorting, FilterChips, SearchInput, SquareIconButton, Button } from 'components';
import FilterListIcon from '@mui/icons-material/FilterList';

import { TopSectionContainer } from 'helpers/topSection';
import { useDispatch, useSelector } from 'react-redux';
import { batch, openFilters, openModal, setAdvancdOffer } from 'store/actions';
import { GlobalState } from 'store';
import { useFilters } from 'providers';
import { RightContainer } from 'helpers/rightContainer';
import { Collection, CollectionStats } from 'types/generated';

type Props = {
  el: JSX.Element;
  stats: CollectionStats;
  collection: Collection;
};

const TopSection = (props: Props) => {
  const { keywords, setKeywords } = useFilters();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const open = useSelector((global: GlobalState) => global.state.openFilters);

  const handleClick = () => dispatch(openFilters.create({ open: !open }));

  const handleClickOffer = () => {
    const { collection, stats } = props;
    dispatch(
      batch.create({
        actions: [
          setAdvancdOffer.create({ advancedOffer: { collection, stats } }),
          openModal.create({ modal: 'ADVANCED OFFER' })
        ]
      })
    );
  };

  return matches ? (
    <TopSectionContainer sx={{ minHeight: '5rem' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1 }}>
        <SearchInput keyword={keywords} set={setKeywords} sx={{ width: '20rem', height: '3rem' }} />
        <FilterChips />
      </Box>
      <RightContainer>
        <Button ctx="primary" size="small">
          <Typography variant="button" onClick={handleClickOffer}>Make Collection Offer</Typography>
        </Button>
        <Sorting sortType={'sortCollection'} defaultKey={'price_asc'} />
        {props.el}
      </RightContainer>
    </TopSectionContainer>
  ) : (
    <TopSectionContainer sx={{flexDirection: 'column', height: '12rem'}}>
      <Box sx={{width: '100%', textAlign: 'center'}}>
        <SearchInput keyword={keywords} set={setKeywords} sx={{width: '100%'}} />
        <Button ctx="primary" size="small" sx={{ my: 1, width: '100%' }}>
          <Typography variant="button" onClick={handleClickOffer}>Make Collection Offer</Typography>
        </Button>
        <Stack direction="row">
          <Box sx={{width: '40%', textAlign: 'center', mr: 1}}>
            <SquareIconButton onClick={handleClick} sx={{width: '100%', height: '100%'}}>
              <FilterListIcon />
              <Typography variant="body2">Filters</Typography>
            </SquareIconButton>
          </Box>
          <Box sx={{width: '60%', textAlign: 'center'}}>
            <Sorting sortType={'sortCollection'} defaultKey={'price_asc'} />
          </Box>
        </Stack>
      </Box>
    </TopSectionContainer>
  );
}

export default TopSection;
