import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import { KeyValuePair } from 'types';
import { compact, priceFormatter } from 'lib/format';
import { Collection, CollectionStats } from 'types/generated';
import { useNavigate } from 'react-router-dom';
import { goToArtist } from 'helpers/artists';
import { Address, Price, SocialNetworks } from 'components';
import { formatAddress } from 'helpers/formatAddress';

type Props = {
  children: React.ReactNode;
  stats: CollectionStats;
  collection: Collection;
};

const Container = styled(Box)({
  margin: '1% 2%'
});

const Summary = styled(Box)(({ theme }) => ({
  background: theme.input.background,
  minWidth: 'fit-content',
  padding: theme.spacing(2, 4),
  borderRadius: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  ':hover': {
    background: theme.palette.action.hover
  }
}));

const renderTopSection = (collection: Collection) => {
  const { name, address, creator, creator_name, instagram, twitter, facebook, discord, website, telegram } =
    collection;
  const navigate = useNavigate();
  return (
    <Stack spacing={1} sx={{ mb: 1 }}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="h2" color="text.primary" fontWeight="600">
          {(name && name != 'anonymous') ? name : formatAddress(address)}
        </Typography>
        <Address address={address} copy={true} length={4} />
        {creator_name && creator_name != 'anonymous' && (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(goToArtist(creator))}
          >
            @{creator_name}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={1}>
        <SocialNetworks
          instagram={instagram}
          twitter={twitter}
          facebook={facebook}
          discord={discord}
          website={website}
          telegram={telegram}
        />
      </Stack>
    </Stack>
  );
};

const renderSummary = (data: KeyValuePair<string, string|JSX.Element>[]) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mb: 1
      }}
    >
      <Box sx={{ display: 'flex', overflowY: 'hidden', gap: 2, my: 3 }}>
        {data.map((d) => (
          <Summary key={d.key}>
            <Typography variant="body1" color="text.secondary">
              {d.key}
            </Typography>
            <Typography variant="h4" color="text.primary">
              {d.value}
            </Typography>
          </Summary>
        ))}
      </Box>
    </Box>
  );
};

const renderDescription = (description: string) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
      <Typography
        variant="subtitle1"
        color="text.secondary"
        textAlign="center"
        maxWidth={700}
        sx={{ fontSize: { xs: 13, md: 15 } }}
      >
        {description}
      </Typography>
    </Box>
  );
};

const CollectionSummary = ({ collection, children, stats }: Props) => {
  const { total_minted, total_owners, floor_price, volume } = stats;
  const { description } = collection;
  const summary: KeyValuePair<string, string|JSX.Element>[] = [
    { key: 'Items', value: compact.format(Number(total_minted)) },
    { key: 'Owners', value: compact.format(total_owners) },
    { key: 'Floor Price', value: <Price value={priceFormatter.format(floor_price)} justifyContent="center" variant="h4" /> },
    { key: 'Total Volume', value: <Price value={priceFormatter.format(volume)} justifyContent="center" variant="h4" /> }
  ];
  return (
    <Container sx={{ mt: 6 }}>
      {renderTopSection(collection)}
      {renderSummary(summary)}
      {renderDescription(description || '')}
      <Box sx={{ textAlign: 'center', mt: 1 }}>{children}</Box>
    </Container>
  );
};

export default CollectionSummary;
