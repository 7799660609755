import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { ActivityTable, Container, Filters, MobileFilters } from 'components';
import { TopSectionContainer } from 'helpers/topSection';
import { useActivities } from 'hooks';
import { useWeb3React } from '@web3-react/core';
import { ActivityTarget, ActivityType } from 'types/generated';
import { FiltersProvider, useFilters } from 'providers';
import { theme } from 'themes';

const SIZE = 50;

const ActivityRenderer = () => {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { account } = useWeb3React();
  const { activityTypes } = useFilters();
  const { activities, isLoading, loadMore } = useActivities({
    target: ActivityTarget.Artist,
    types: activityTypes.length == 0 ? [ActivityType.All] : activityTypes,
    user_address: account,
    skip: !account,
    size: SIZE
  });

  const filters = matches ? (
    <Filters showActivityTypes={true} width="15rem" />
  ) : (
    <MobileFilters activeFilters={['activityTypes']} />
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TopSectionContainer>{filters}</TopSectionContainer>
      <Container>
        <ActivityTable
          activities={activities}
          loading={isLoading}
          loadMore={loadMore}
          useWindowScroll={true}
        />
      </Container>
    </Box>
  );
};

const Activity = (): JSX.Element => {
  return (
    <FiltersProvider>
      <ActivityRenderer />
    </FiltersProvider>
  );
};

export default Activity;
