import React from 'react';
import { Typography, Box, styled, useTheme, Chip, Stack } from '@mui/material';
import { ReactComponent as VisionDark } from '../../../assets/vision/logo-vision-dark.svg';
import { ReactComponent as VisionLight } from '../../../assets/vision/logo-vision-light.svg';
import { Sharecode, VisionDownloadApp } from 'components';

type Props = {
  sharecode: string;
};

const VisionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.input.background,
  borderRadius: '1.25rem',
  padding: '1rem 2rem'
}));

const GalleryVision = ({
  sharecode
}: Props) => {
  const theme = useTheme();
  return (
    <VisionBox>
      <Box textAlign="center">
        <Typography variant="body1" color="text.secondary" sx={{mb: 1}}>
          Access Code On APP
        </Typography>
        {theme.palette.mode == 'dark' ? <VisionDark width="250px" /> : <VisionLight width="250px"  />}
        <Box sx={{my: 3}}>
          <Chip
            sx={{ 
              background: theme.button.primary.background,
              flex: 1,
              borderRadius: '5rem',
              padding: '1.5rem 2rem'
            }}
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <Sharecode sharecode={sharecode} variant="h6" color="text.primary" />
              </Stack>
            }
          />
        </Box>
        <VisionDownloadApp />
      </Box>
    </VisionBox>
  );
};

export default GalleryVision;
