// 3rd party libs
import React from 'react';
import { Box, styled, useMediaQuery } from '@mui/material';

// Componenets
import { Filters, MobileFilters, Sorting, Container } from 'components';
import { TopSectionContainer } from 'helpers/topSection';
import { FiltersProvider, useFilters, useResize } from 'providers';
import { VirtuosoGrid } from 'react-virtuoso';
import { VContainer, VList } from 'helpers/virtuosoHelpers';
import { useNavigate, useParams } from 'react-router-dom';
import { useNFTs } from 'hooks';
import { SearchTarget, SearchNftSort } from 'types/generated';
import { AddressRouteParams } from 'types';
import { goToNft, initNftWithSale, InnerItem } from 'helpers/nfts';
import { RightContainer } from 'helpers/rightContainer';
import { theme } from 'themes';
import { goToCollection } from 'helpers/collections';

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

const SIZE = 30;
const INIT_NFTS = [...Array(SIZE).keys()].map(() => initNftWithSale);

const OwnedRenderer = () => {
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const { config } = useResize();
  const { address } = useParams<AddressRouteParams>();
  const { sortBy, salesType, keywords } = useFilters();
  const { nfts, error, isLoading, loadMore } = useNFTs({
    keywords,
    sortBy,
    target: SearchTarget.Collectibles,
    salesType,
    owner: address,
    skip: !address,
    size: SIZE
  });

  const itemContent = (index: number) => {
    const nft = isLoading ? INIT_NFTS[index] : nfts[index];
    return (
      nft && (
        <InnerItem
          nft={nft}
          owned={true}
          goTo={() => navigate(goToNft(nft.asset_id))}
          goToCollection={() => navigate(goToCollection(nft.collection.address))}
          isLoading={isLoading}
          showBalance={true}
        />
      )
    );
  };

  const filters = matches ? (
    <Filters showKeywords={true} />
  ) : (
    <MobileFilters activeFilters={[]} search={true} />
  );

  return (
    <Root>
      <TopSectionContainer>
        {filters}
        {matches && (
          <RightContainer>
            <Sorting defaultKey={'price_asc'} sortType={'sortOwnedNfts'} />
          </RightContainer>
        )}
      </TopSectionContainer>
      <Container>
        <VirtuosoGrid
          useWindowScroll={true}
          totalCount={isLoading ? SIZE : nfts.length}
          endReached={() => loadMore()}
          overscan={500}
          components={{
            Item: VContainer(config),
            List: VList
          }}
          itemContent={itemContent}
        />
      </Container>
    </Root>
  );
};

export const Owned = () => (
  <FiltersProvider sortBy={SearchNftSort.PriceAsc}>
    <OwnedRenderer />
  </FiltersProvider>
);
