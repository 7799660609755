import React from 'react';
import { Grid, Typography, styled, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Components
import { HtmlTooltip, Price } from 'components';
import { UserDashboardStatistics } from 'types/generated';
import { NumberOrString } from 'types';

type Props = {
  stats: UserDashboardStatistics;
};

type CardProps = {
  title: NumberOrString;
  value: NumberOrString | JSX.Element;
  info?: string;
};

const DashCardContainer = styled(Box)(({ theme }) => ({
  height: '8rem',
  borderRadius: '0.9375rem',
  background: theme.input.background,
  padding: theme.spacing(3, 3, 2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}));

const tooltip = (info: string) => {
  return (
    <HtmlTooltip
      placement="top"
      title={
        <Typography
          variant="body2"
          color="#FFF"
          sx={{
            background: '#000',
            borderRadius: '0.5rem',
            width: 'fit-content',
            p: 1
          }}
        >
          {info}
        </Typography>
      }
      sx={{
        '& .MuiTooltip-arrow': {
          color: (theme) => theme.input.background
        }
      }}
    >
      <InfoOutlinedIcon sx={{ fontSize: 20, ml: 1 }} />
    </HtmlTooltip>
  );
};

const DashboardCard = ({ title, value, info }: CardProps) => {
  return (
    <DashCardContainer>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex' }}
      >
        {title}
        {info && tooltip(info)}
      </Typography>
      {typeof value === 'number' || typeof value === 'string' ? (
        <Typography variant="h3" color="text.primary" fontWeight={500}>
          {value}
        </Typography>
      ) : (
        value
      )}
    </DashCardContainer>
  );
};

export default DashboardCard;

export const Summary = ({ stats }: Props) => {
  const { wallet_value, amount_traded, available_offers, ratio_purchase_sale } = stats;
  return (
    <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={2} sm={4} md={3}>
        <DashboardCard
          title="Wallet value"
          value={<Price value={Number(wallet_value || '0')} variant="h3" />}
          info={'Sum of the Floor Prices of all your NFTs'}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <DashboardCard
          title="Amount traded"
          value={<Price value={Number(amount_traded || '0')} variant="h3" />}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <DashboardCard title="Offers received" value={available_offers || '--'} />
      </Grid>
      <Grid item xs={2} sm={4} md={3}>
        <DashboardCard title="Ratio (purchase/sell)" value={ratio_purchase_sale} />
      </Grid>
    </Grid>
  );
};
