import { BasketItem, BasketRequest, DealKind } from 'types/generated';

export const OfferRequestParam = (
  basket: BasketItem[],
  buyer: string,
  seller: string
): BasketRequest => {
  return {
    kind: DealKind.Offer,
    basket: basket,
    buyer,
    seller
  };
};
