// 3rd party libs
import React from 'react';
import { Grid, Typography, Stack, Divider } from '@mui/material';
import { Button, Container, Footer, Gallery } from 'components';
import Summary from './Summary';
import { Form } from './Form';
import { SaleProvider, useSale } from './context/saleContext';
import { useDispatch, useSelector } from 'react-redux';
import { batch, listForSale, openModal } from 'store/actions';
import { toNFTSales, validate } from './utils';
import { useOwnedNftsCollectionsQuery } from 'api';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { Collection, SearchNftSort } from 'types/generated';
import { FiltersProvider, useFilters } from 'providers';
import { GlobalState } from 'store';
import { useBalances } from 'hooks';
import { GalleryFilters } from 'helpers/gallery/GalleryFilters';

const Title = () => {
  return (
    <Stack spacing={2} sx={{ mt: { md: 3, lg: 5 }, mb: 2 }}>
      <Typography variant="h1" textAlign="start">
        Sell NFT
      </Typography>
      <Typography variant="h5" fontWeight={500}>
        Select the NFTs you want to sell
      </Typography>
    </Stack>
  );
};

const SellRenderer = () => {
  const dealId = useSelector((global: GlobalState) => global.state.createDealId);
  const { hooks } = useWeb3React();
  const { usePriorityAccount } = hooks;
  const account = usePriorityAccount();
  const { setCollections } = useFilters();
  // Action dispatcher
  const dispatch = useDispatch();
  // States
  const { sales: currentSales, updateSales, startDate } = useSale();
  // Collections of all owned NFTs
  const { data } = useOwnedNftsCollectionsQuery({ userAddress: account }, { skip: !account });

  const { select, sales, reset } = useSale();
  // Filters
  const { collection, keywords, sortBy } = useFilters();
  // Query balances
  const { userBalances, loadMore, error, refresh } = useBalances({
    collection: collection?.address,
    user: account || '',
    keywords: keywords || undefined,
    size: 50,
    sort: sortBy as SearchNftSort,
    skip: !account
  });

  useEffect(() => {
    if (dealId) {
      refresh();
      reset();
    }
  }, [dealId]);

  useEffect(() => {
    if (data) {
      const collections = data.ownedNFTsCollections.filter(
        (collection) => collection.name
      ) as Collection[];
      setCollections(collections);
    }
  }, [data]);

  const handleClick = () => {
    const { sales, valid } = validate([...currentSales.values()]);
    if (!valid) updateSales(sales);
    else
      dispatch(
        batch.create({
          actions: [
            listForSale.create({ items: toNFTSales([...sales.values()], startDate.getTime()) }),
            openModal.create({ modal: 'LIST FOR SALE' })
          ]
        })
      );
  };

  return (
    <>
      <Container sx={{ paddingBottom: '5rem' }}>
        <Stack>
          <Title />
          <Grid container direction="row" spacing={{ xs: 2, sm: 2, md: 4, lg: 10 }} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <GalleryFilters />
              <Gallery
                userBalances={userBalances}
                error={error}
                selected={sales}
                select={select}
                loadMore={loadMore}
              />
              <Divider sx={{ my: 4 }} variant="middle" />
              <Form />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Summary />
            </Grid>
          </Grid>
          <div>
            <Button ctx="primary" onClick={() => handleClick()} size="large" sx={{ width: '8rem' }}>
              <Typography variant="button">Validate</Typography>
            </Button>
          </div>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

const Sell = () => {
  return (
    <FiltersProvider sortBy={SearchNftSort.RecentlyReceived}>
      <SaleProvider>
        <SellRenderer />
      </SaleProvider>
    </FiltersProvider>
  );
};

export default Sell;
