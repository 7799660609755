import { formatAddress } from 'helpers/formatAddress';
import { UserRanking } from 'types';
import { ArtistInfo, ArtistStats, UserRankingQuery } from 'types/generated';

export const parseUser = (user?: Partial<ArtistInfo> | null): ArtistInfo => {
  return {
    avatar: user?.avatar || '',
    address: user?.address || '',
    certified: user?.certified || false,
    name: (user?.name && user?.name!='anonymous' ? user?.name : formatAddress(user?.address)) || '',
    banner: user?.banner || '',
    description: user?.description || ''
  };
};

export const parseUserStats = (stats?: ArtistStats | null): ArtistStats => {
  return {
    bought: stats?.bought || 0,
    sold: stats?.sold || 0,
    volume: stats?.volume || 0
  };
};

export const toUsersRanking = (data?: UserRankingQuery | undefined): UserRanking[] => {
  return (
    data?.ranking.items.map((item) => {
      return {
        rank: item.rank,
        ...parseUser(item?.user),
        ...parseUserStats(item?.user_stats)
      };
    }) || []
  );
};
