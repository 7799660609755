import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Drawer, Typography } from '@mui/material';
import WalletIcon from '../Icon';
import { useWeb3React } from '@web3-react/core';
import { Web3 } from 'components';
import { walletConnect } from 'connectors/walletConnect';
import { metaMask } from 'connectors/metamask';
import { useLocalStorage } from 'hooks';
import { coinbaseWallet } from 'connectors/coinbase';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setWalletStatus } from 'store/actions';
import { useNetwork } from 'hooks';
import { avalanche } from 'connectors/avalanche';
import { DrawerHeader } from 'helpers/drawerHeader';

const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const DesktopWallet = () => {
  const dispatch = useDispatch();
  const [openWallet, setOpenWallet] = useState(false);
  const { hooks, connector } = useWeb3React();
  const { usePriorityIsActive, usePriorityProvider } = hooks;
  const { isValid } = useNetwork();
  const isActive = usePriorityIsActive();
  const [lastConnector, _] = useLocalStorage<string | undefined>('last-connector', undefined);
  const { supportedChainId } = useNetwork();
  const provider = usePriorityProvider();

  useEffect(() => {
    dispatch(setWalletStatus.create({ valid: isValid && isActive }));
  }, [isValid, isActive]);

  useEffect(() => {
    switch (lastConnector) {
      case 'metamask':
        metaMask.connectEagerly().catch((_) => {
          /** Ignore **/
        });
        break;
      case 'avalanche':
        avalanche.connectEagerly();
        break;
      case 'walletconnect':
        walletConnect.connectEagerly();
        break;
      case 'coinbase':
        coinbaseWallet.connectEagerly();
        break;
    }
  }, []);

  const connectMetamaskMobileApp = useCallback(async () => {
    if (window.ethereum)
      try {
        await provider?.send('wallet_requestPermissions', [{ eth_accounts: {} }]);
        await metaMask.activate(supportedChainId);
      } catch (err: unknown) {
        console.log(err);
      }
  }, [window.ethereum]);

  useEffect(() => {
    if (isMobile()) connectMetamaskMobileApp();
  }, [connectMetamaskMobileApp, window.ethereum]);

  const header = isActive ? 'Wallet' : 'Connect your wallet';

  return (
    <>
      <WalletIcon onClick={() => setOpenWallet(true)} />
      <Drawer
        anchor="right"
        open={openWallet}
        onClose={() => setOpenWallet(false)}
        PaperProps={{
          sx: {
            width: '20%',
            minWidth: '22rem',
            height: '100%',
            background: (theme) => theme.palette.background.default
          }
        }}
      >
        <DrawerHeader>
          <CloseIcon className="close" onClick={() => setOpenWallet(false)} />
          <Typography
            variant="h4"
            color="text.primary"
            textAlign="center"
            fontWeight={500}
            sx={{ flex: 1 }}
          >
            {header}
          </Typography>
        </DrawerHeader>
        <Divider />
        <Web3 help={true} />
      </Drawer>
    </>
  );
};

export default DesktopWallet;
