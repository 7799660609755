import { styled, Box } from '@mui/material';

/**
 * Sticky section
 */
export const TopSectionContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100%',
  position: 'sticky',
  top: '4.9rem',
  zIndex: 100,
  minHeight: '6rem',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));
