// 3rd party libs
import React from 'react';
import { Link, Stack, SxProps, Theme, Typography } from '@mui/material';
import Explore from '@mui/icons-material/Launch';

type LinkExtProps = {
  name?: string;
  url: string;
  target?: '_blank' | '_self';
  variant?: 'h5' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2';
};

const sx: SxProps<Theme> = {
  cursor: 'pointer',
  ':hover': { color: (theme) => theme.palette.text.secondary }
};

const LinkExternal = ({
  name = 'URL',
  url,
  target = '_blank',
  variant = 'body1'
}: LinkExtProps): JSX.Element => {
  return (
    <Link
      target={target}
      href={url}
      underline="none"
    >
      <Stack direction="row" alignItems={'center'} spacing={1}>
        <Typography variant={variant}>
          {name}
        </Typography>
        <Explore fontSize={'small'} sx={sx} />
      </Stack>
    </Link>
  );
};

export default LinkExternal;
