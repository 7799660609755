// 3rd party libs
import React from 'react';
import { Box } from '@mui/material';
import { Background, Tabs, UserInfo } from 'components';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dash from './dashboard';
import { Owned } from './owned';
import { Liked } from './liked';
import Activity from './activity';
import { Settings } from './Settings';
import Created from './Created';
import { AppRoutes } from 'constants/routes';
import { useProfileQuery, useUploadAssetMutation } from 'api';
import { useWeb3React } from '@web3-react/core';
import { processProfileQueryResponse } from 'helpers/processQueryResponse';
import { compact } from 'lib/format';
import { useNotistack } from 'hooks';
import { AssetKind } from 'types/generated';

const DEFAULT_TAB = '/dashboard';

const getCurrentTab = <T extends { value: string }>(path: string, tabs: T[]) => {
  const s = path.split('/').filter(Boolean);
  if (s.length == 0 || s.length == 1) return 0;
  else return tabs.findIndex((tab) => tab.value == s[1]);
};

const Dashboard = () => {
  const location = useLocation();
  const { success, error } = useNotistack();
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const { data } = useProfileQuery({});
  const [upload] = useUploadAssetMutation();
  const { user } = processProfileQueryResponse(data);
  const { cover, avatar, certified, metadata } = user;
  const { owned_nfts, liked_nfts } = metadata;

  const goto = () => account && navigate(`/${AppRoutes.profile.replace(':address', account)}`);

  const tabs: { label: string; value: string; children?: { label: string; value: string }[] }[] = [
    { label: 'Dashboard', value: 'dashboard' },
    { label: `Owned ${compact.format(owned_nfts)}`, value: 'owned' },
    {
      label: 'Created',
      value: 'created',
      children: [{ label: 'Collections', value: 'collections' }]
    },
    { label: `Liked ${compact.format(liked_nfts)}`, value: 'liked' },
    { label: 'Activity', value: 'activity' },
    {
      label: 'Settings',
      value: 'settings',
      children: [
        { label: 'Profile', value: 'profile' },
        { label: 'Notifications', value: 'notifications' },
        { label: 'Offers', value: 'offers' }
      ]
    }
  ];

  const changeBackground = (url: string, file?: File) => {
    file &&
      upload({
        kind: AssetKind.ProfileCover,
        file
      })
        .then(() => success('Collection cover successfully uploaded'))
        .catch(() => error('Failed to upload collection cover!'));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Background
        src={cover}
        avatar={avatar}
        isApproved={certified}
        height="18rem"
        isCreator={true}
        set={changeBackground}
      />
      <UserInfo user={user} isPublic={false} goTo={() => goto()} />
      <Box
        sx={{
          borderBottom: (theme) => theme.border,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Tabs
          tabs={tabs}
          currentTab={getCurrentTab(location.pathname, tabs)}
          onTabChange={(tab) => navigate(tab == 'dashboard' ? DEFAULT_TAB : tab)}
          onChilChange={(parent, value) => {
            if (parent == 2) navigate(`created/${value}`);
            if (parent == 5) navigate(`settings/${value}`);
          }}
        />
      </Box>

      <Routes>
        <Route path="/" element={<Dash stats={user.stats} />} />
        <Route path="owned" element={<Owned />} />
        <Route path="created/*" element={<Created />} />
        <Route path="liked" element={<Liked />} />
        <Route path="activity" element={<Activity />} />
        <Route path="settings/*" element={<Settings />} />
      </Routes>
    </Box>
  );
};

export default Dashboard;
