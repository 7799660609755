// 3rd party libs
import React, { useCallback, useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';

// Componenets
import Container from 'components/Container';
import { SearchInput, MetaverseCard, ResizeToggle } from 'components';
import { TopSectionContainer } from 'helpers/topSection';
import VisionPageBanner from './Banner';
import { Metaverse } from 'types';
import { GridSizeProvider, useResize } from 'providers';
import { VirtuosoGrid } from 'react-virtuoso';
import { VContainer, VList } from 'helpers/virtuosoHelpers';

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

const Vision = () => {
  const { size, resize, config } = useResize();
  const [meta, setMeta] = useState<Metaverse[]>([]);

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      setMeta((c) => [...c]);
    }, 200);
  }, [setMeta]);

  useEffect(() => {
    const timeout = loadMore();
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Root>
      <VisionPageBanner />
      <TopSectionContainer>
        {/*
          TODO [hmachaao]: update the code once vision is wrapped inside a filter provider
          <SearchInput sx={{ width: '15rem', height: '3rem' }} />
        */}
        <ResizeToggle size={size} resize={(size) => resize(size)} />
      </TopSectionContainer>
      <Container>
        <VirtuosoGrid
          useWindowScroll={true}
          totalCount={meta.length}
          endReached={loadMore}
          overscan={500}
          components={{
            Item: VContainer(config),
            List: VList
          }}
          itemContent={(index) => {
            const metaverse = meta[index];
            return metaverse && <MetaverseCard metaverse={metaverse} />;
          }}
        />
      </Container>
    </Root>
  );
};

const KalaoVision = () => (
  <GridSizeProvider>
    <Vision />
  </GridSizeProvider>
);

export default KalaoVision;
