import React, { useMemo, useState } from 'react';

import { activity_options, days_filter_options, sales_options } from 'constants/options';
import {
  PriceRange,
  RadioGroup,
  NFTProperties,
  SearchInput,
  CollectionAutoComplete,
  Search,
  CheckBoxGroup,
  CollectionFilter
} from 'components';
import { debounce, MenuList } from '@mui/material';
import { useFilters } from 'providers';
import { useCategoriesQuery } from 'api';
import { FilterOption, KeyValuePair } from 'types';
import { MenuItemContent } from 'helpers/menu';
import { ActivityType } from 'types/generated';
import { PropertiesOfferButton } from './PropertiesOfferButton';

type Props = {
  activeFilters: FilterOption[];
};

const FilterList = ({ activeFilters }: Props) => {
  const {
    properties,
    setProperties,
    setCategories,
    categories,
    setSalesType,
    salesType,
    setPrice,
    price,
    collection,
    setCollection,
    keywords,
    setKeywords,
    collections,
    activityTypes,
    setActivityTypes,
    period,
    setPeriod
  } = useFilters();
  const categoriesPairs: KeyValuePair<string, string>[] = [];
  const [keyword, setKeyword] = useState('');

  const debouncedSearchChange = useMemo(() => debounce(setKeyword, 300), []);

  // call api to get all categories
  const { data } = useCategoriesQuery();

  if (data) {
    data.categories.forEach((cat) => {
      categoriesPairs.push({ key: cat?.tag, value: cat?.name });
    });
  }

  return (
    <MenuList sx={{ '& > li': { borderTop: (theme) => theme.border }, py: 0 }}>
      <MenuItemContent title="Collections" show={activeFilters.includes('collections')}>
        <CollectionAutoComplete
          sx={{ width: '100%' }}
          collection={collection}
          mobile={true}
          setCollection={setCollection}
        />
      </MenuItemContent>
      <MenuItemContent title="Collections" show={activeFilters.includes('ownedCollections')}>
        <CollectionFilter
          collection={collections.find((c) => c.address == collection?.address)}
          collections={collections}
          setCollection={setCollection}
          sx={{ width: '100%' }}
          mobile={true}
        />
      </MenuItemContent>
      <MenuItemContent title="Categories" show={activeFilters.includes('categories')}>
        <CheckBoxGroup
          keys={categories}
          defaultValue="All categories"
          pairs={categoriesPairs}
          onChange={(v) => {
            setCategories(v);
          }}
        />
      </MenuItemContent>

      <MenuItemContent title="Activities" show={activeFilters.includes('activityTypes')}>
        <CheckBoxGroup
          defaultValue={'All types'}
          pairs={activity_options}
          keys={activityTypes}
          onChange={(keys) => setActivityTypes(keys as ActivityType[])}
        />
      </MenuItemContent>

      <MenuItemContent title="Sales Types" show={activeFilters.includes('sales')}>
        <RadioGroup
          defaultValue={salesType || ''}
          empty="All sales types"
          pairs={sales_options}
          onChange={(v) => setSalesType(v)}
        />
      </MenuItemContent>

      <MenuItemContent title="Period" show={activeFilters.includes('period')}>
        <RadioGroup
          defaultValue={period}
          empty={'All time'}
          pairs={days_filter_options}
          onChange={(v) => setPeriod(v)}
        />
      </MenuItemContent>

      <MenuItemContent title="Price" show={activeFilters.includes('price')}>
        <PriceRange
          range={price || {}}
          apply={(price) => setPrice(price || {})}
          reset={() => setPrice({})}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            '& > div': { maxWidth: '20rem' }
          }}
        />
      </MenuItemContent>

      <MenuItemContent title="Properties" show={activeFilters.includes('properties')}>
        <PropertiesOfferButton />
        <Search
          placeholder="Keywords"
          clearInput={() => debouncedSearchChange('')}
          inputChange={debouncedSearchChange}
          sx={{ height: '3rem', borderRadius: '0.5rem', mt: 1 }}
        />
        <NFTProperties properties={properties} setProperty={setProperties} keyword={keyword} />
      </MenuItemContent>

      <MenuItemContent title="Search by name" show={activeFilters.includes('keywords')}>
        <SearchInput keyword={keywords} set={setKeywords} />
      </MenuItemContent>
    </MenuList>
  );
};

export default FilterList;
