import { isValidHttpUrl } from 'helpers/validators';
import { Collection, CollectionInfoUpdate } from 'types/generated';

const keys = ['facebook', 'twitter', 'instagram', 'website', 'discord', 'telegram'];

export const toPayload = (collection: Collection): CollectionInfoUpdate => {
  return {
    address: collection.address,
    description: collection?.description,
    discord: collection?.discord,
    facebook: collection?.facebook,
    instagram: collection?.instagram,
    telegram: collection?.telegram,
    twitter: collection?.twitter,
    website: collection?.website,
    youtube: collection?.youtube
  };
};

export const validate = (collection: Collection): { errors: Record<string, string> } => {
  const errors: Record<string, string> = {};

  for (const r of keys) {
    const key = r as keyof Collection;
    if (!isValidHttpUrl(collection[key])) errors[key] = `${key} url is not valid`;
  }

  return { errors };
};
