export const configs = {
  uiText: {
    loadButtonLabel: "Click to<br>Load<br>Panorama",
    loadingLabel: "Loading...",
    bylineLabel: "by %s",
    noPanoramaError: "No panorama image was specified.",
    fileAccessError: "The file %s could not be accessed.",
    malformedURLError: "There is something wrong with the panorama URL.",
    iOS8WebGLError:
      "Due to iOS 8's broken WebGL implementation, only progressive encoded JPEGs work for your device (this panorama uses standard encoding).",
    genericWebGLError:
      "Your browser does not have the necessary WebGL support to display this panorama.",
    textureSizeError:
      "This panorama is too big for your device! It's %spx wide, but your device only supports images up to %spx wide. Try another device. (If you're the author, try scaling down the image.)",
    unknownError: "Unknown error. Check developer console.",
  },
  styles: {
    width: "600px",
    height: "400px",
    background: "#000000",
  },
  equirectangularOptions: {
    haov: 360,
    vaov: 180,
    vOffset: 0,
    ignoreGPanoXMP: false,
  },
  panoramaConfigs: {
    autoLoad: false,
    autoRotate: 0,
    autoRotateInactivityDelay: 0,
    autoRotateStopDelay: 0,
    preview: "",
    showZoomCtrl: true,
    keyboardZoom: true,
    mouseZoom: true,
    doubleClickZoom: false,
    draggable: true,
    friction: 0.15,
    disableKeyboardCtrl: false,
    showFullscreenCtrl: true,
    showControls: true,
    touchPanSpeedCoeffFactor: 1,
    yaw: 0,
    pitch: 0,
    maxPitch: 90,
    minPitch: -90,
    maxYaw: 180,
    minYaw: -180,
    hfov: 100,
    minHfov: 50,
    maxHfov: 120,
    multiResMinHfov: false,
    backgroundColor: [0, 0, 0],
    avoidShowingBackground: false,
    compass: false,
    northOffset: 0,
    hotSpots: [],
    hotSpotDebug: false,
  },
};