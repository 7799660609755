import React from 'react';
import { FilterList, SquareIconButton } from 'components';
import SideMenu from './SideMenu';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  useMediaQuery,
  useTheme,
  Drawer,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { openFilters } from 'store/actions';
import { GlobalState } from 'store';

export const FilterSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const open = useSelector((global: GlobalState) => global.state.openFilters);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(openFilters.create({ open: false }));
  };

  return matches ? (
    <SideMenu>
      <MenuItem
        sx={{
          height: '5rem'
        }}
        onClick={handleClick}
        disableRipple={true}
      >
        <ListItemText>Filters</ListItemText>
        <ListItemIcon>
          <SquareIconButton size="small" onClick={handleClick}>
            <ChevronLeftIcon />
          </SquareIconButton>
        </ListItemIcon>
      </MenuItem>
      <FilterList activeFilters={['sales', 'price', 'properties']} />
    </SideMenu>
  ) : (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => dispatch(openFilters.create({ open: false }))}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          background: (theme) => theme.palette.background.default
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '5rem',
          px: 1
        }}
      >
        <Button onClick={handleClick}>
          <Typography variant="button" color="text.secondary">
            Clear All
          </Typography>
        </Button>
        <Button onClick={handleClick}>
          <Typography variant="button" color="text.primary">
            Done
          </Typography>
        </Button>
      </Box>
      <FilterList activeFilters={['sales', 'price', 'properties']} />
    </Drawer>
  );
};
