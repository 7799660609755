import React from 'react';
import { Stack, Typography } from '@mui/material';
import { NumberInput } from 'components';
import { NumberOrString } from 'types';

type Props = {
  label: string;
  price: NumberOrString | undefined;
  set: (price: NumberOrString) => void;
};

export const PriceInput = ({ label, price, set }: Props) => {
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <NumberInput
        sx={{ height: '3.5rem', borderRadius: '0.5rem' }}
        value={price}
        step={0.1}
        onChange={(value) => set(value)}
      />
    </Stack>
  );
};
