// 3rd party libs
import React from 'react';
import { styled, Typography, Divider, Box } from '@mui/material';
import { Price } from 'components';
import SalesPreview from '../Preview';
import { useSale } from '../context/saleContext';
import { PriceSymbol, SaleType } from 'types';

const StyledBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: theme.border,
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  borderRadius: '1.25rem',
  '& > div.nft': {
    display: 'flex',
    gap: theme.spacing(1)
  },
  '& > div.summary': {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

// Styling
const PreviewContainer = styled(Box)({
  position: 'sticky',
  // Avoid overlapping with the navbar bottom border
  top: 'calc(5.5rem - 0.0625rem)'
});

const Summary = () => {
  const { sales, auction, type } = useSale();
  let total = 0;
  if (type == SaleType.direct) {
    sales.forEach((nft, _) => {
      total += Number(nft.quantity) * Number(nft.price);
    });
  } else {
    if (auction) {
      total += Number(auction.quantity) * Number(auction.price);
    }
  }

  return (
    <PreviewContainer>
      <SalesPreview />
      <Divider sx={{ my: 4 }} variant="middle" />
      <Box sx={{ px: 2 }}>
        <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
          You will receive
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Listing is free! At the time of the sale, the following fees will be applied.
        </Typography>
      </Box>
      <StyledBox>
        <div className="summary">
          <Typography variant="subtitle2" color="text.primary">
            Service Fee
          </Typography>
          <Typography variant="caption" color="text.primary">
            0%
          </Typography>
        </div>
        <div className="summary">
          <Typography variant="h4" color="text.secondary" fontWeight={500}>
            Total
          </Typography>
          <Price
            value={total}
            variant="h4"
            symbol={type == SaleType.direct ? PriceSymbol.AVAX : PriceSymbol.WAVAX}
          />
        </div>
      </StyledBox>
    </PreviewContainer>
  );
};

export default Summary;
