import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box, Grid, Stack, Skeleton, IconButton, styled } from '@mui/material';
import { ImageContainer, Like } from 'components';
import { Edit, Offer, Purchase, SaleOrTransfer } from './utils';
import { Info } from '../utils/Info';
import SyncIcon from '@mui/icons-material/Sync';

import { useDispatch, useSelector } from 'react-redux';
import { addToBasket, batch, listNFTForSale, openModal, setNFT, setSale } from 'store/actions';
import {
  Collection,
  NftBase,
  NftInteractions,
  NftMetadataAssets,
  NftMetadatas,
  NftSalePreview
} from 'types/generated';
import { Stats } from '../types';
import { compact } from 'lib/format';
import { toNFtPreview, toNftSale } from '../utils';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';
import { useLikedAssetsQuery, useLikeMutation, useRefreshNFTMutation } from 'api';
import { useAuth } from 'providers';
import { Selector } from 'helpers/nfts';
import { GlobalState } from 'store';
import { useWeb3React } from '@web3-react/core';
import { equalsIgnoringCase } from 'helpers/common';
import { formatAddress } from 'helpers/formatAddress';
import { useNotistack } from 'hooks';

type Props = {
  loading: boolean;
  base: NftBase;
  collection: Collection;
  stats: Stats;
  metadata: NftMetadatas;
  interactions: NftInteractions;
  bestOffer?: number | null;
  sale?: NftSalePreview | null;
  assets: NftMetadataAssets;
};

const Card = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: '1rem',
  overflow: 'hidden',
  border: theme.border,
  ':hover': {
    '.image-container': {
      opacity: 0.8
    },
    '.circle': {
      display: 'block',
      transition: 'all 0.5s'
    },
    '.best-offer': {
      display: 'none'
    }
  },
  // Appears when hovering the NFT card
  '.circle': {
    display: 'none',
    position: 'absolute',
    top: 5,
    left: 5,
    alignItems: 'center',
    justifyContent: 'center',
    '&.selected': { display: 'block' }
  }
}));

const StyledSync = styled(IconButton)({
  '.sync': {
    animation: 'spin 2s linear infinite',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)'
      },
      '100%': {
        transform: 'rotate(0deg)'
      }
    }
  }
});

const NFTOverview = ({
  metadata,
  base,
  collection,
  loading,
  stats,
  interactions,
  bestOffer,
  sale,
  assets
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const isSelected = useSelector((global: GlobalState) => global.state.basket).has(base.asset_id);
  const { loggedIn } = useAuth();
  const { data: likedAssets } = useLikedAssetsQuery({}, { skip: !loggedIn });
  const [like] = useLikeMutation();
  const [refresh] = useRefreshNFTMutation();
  const [refreshing, setRefreshing] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();
  const { success } = useNotistack();
  const { name: nftName, description } = metadata;
  const { name, certified, avatar, address, display_theme } = collection;
  const { total_minted } = stats;
  const { likes } = interactions;
  const { hd_animation_url, hd_image_url, raw_animation_url, raw_image_url, hd_animation_mime } =
    assets;

  const goTo = () => navigate(`/${AppRoutes.collection_page.replace(':address', address)}`);

  const onLike = () => {
    like({ assetId: base.asset_id, value: !likedAssets?.[base.asset_id] }).unwrap();
  };

  const onRefresh = () => {
    setRefreshing(true);
    refresh({ assetId: base.asset_id })
      .unwrap()
      .then(() => {
        timerRef.current = setTimeout(() => {
          setRefreshing(false);
          success('Item queued for an update. Check back in a minute...');
        }, 2000);
      });
  };

  const onSelect = () => {
    if (sale)
      dispatch(addToBasket.create({ nftSale: toNftSale(base, metadata, collection, sale) }));
  };

  const openNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const purchase = (sale: NftSalePreview) => {
    dispatch(
      batch.create({
        actions: [
          setSale.create({
            nftSale: toNftSale(base, metadata, collection, sale)
          }),
          openModal.create({ modal: 'BUY ITEM' })
        ]
      })
    );
  };

  const makeOffer = () => {
    dispatch(
      batch.create({
        actions: [
          setNFT.create({
            nft: {
              ...toNFtPreview(base, metadata, collection, sale),
              editions: base.editions
            }
          }),
          openModal.create({ modal: 'MAKE OFFER' })
        ]
      })
    );
  };

  const edit = (sale: NftSalePreview, action: 'edit price' | 'cancel sale') => {
    dispatch(
      batch.create({
        actions: [
          setSale.create({ nftSale: toNftSale(base, metadata, collection, sale) }),
          openModal.create({ modal: action == 'edit price' ? 'UPDATE SALE' : 'CANCEL SALE' })
        ]
      })
    );
  };

  const listForSale = () => {
    dispatch(
      batch.create({
        actions: [
          listNFTForSale.create({ nft: toNFtPreview(base, metadata, collection) }),
          openModal.create({ modal: 'LIST NFT FOR SALE' })
        ]
      })
    );
  };

  const transfer = () => {
    dispatch(
      batch.create({
        actions: [
          listNFTForSale.create({ nft: toNFtPreview(base, metadata, collection) }),
          openModal.create({ modal: 'TRANSFER NFT' })
        ]
      })
    );
  };

  const editions = collection.kind == 'erc1155' ? compact.format(Number(base.editions)) : undefined;
  const isOwner = sale
    ? equalsIgnoringCase(account, sale.seller)
    : equalsIgnoringCase(account, base.owner);

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <Box sx={{ mb: 4 }}>
      <Grid container columnSpacing={{ xs: 1, md: 5, lg: 9 }}>
        <Grid item xs={12} md={5} lg={6}>
          <Card>
            <ImageContainer
              className={'image-container'}
              src={hd_image_url}
              animation={hd_animation_url}
              raw_image={raw_image_url}
              raw_animation={raw_animation_url}
              animation_mime={hd_animation_mime}
              editions={editions}
              onRawClick={openNewTab}
              displayTheme={display_theme}
            />
            <Selector
              isSelected={isSelected}
              className={isSelected ? 'circle selected' : 'circle'}
              click={onSelect}
              skip={sale == undefined || equalsIgnoringCase(account, base.owner)}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={7} lg={6}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
            <Info
              onClick={goTo}
              sx={{ cursor: 'pointer' }}
              info={name && name != 'anonymous' ? name : formatAddress(address)}
              subTitle={`${compact.format(Number(total_minted))} Items`}
              avatar={avatar}
              certified={certified}
              loading={loading}
            />
            {loading ? (
              <Skeleton width={100} height={30} />
            ) : (
              <Stack direction="row" alignItems={'center'} spacing={0.5}>
                <Like likes={likes} liked={likedAssets?.[base.asset_id]} onLike={onLike} />
                <StyledSync sx={{ width: 30, height: 30 }} onClick={onRefresh}>
                  <SyncIcon className={refreshing ? 'sync' : undefined} />
                </StyledSync>
              </Stack>
            )}
          </Box>
          <Typography variant="h1" color="text.primary" textTransform="capitalize" sx={{ mb: 4 }}>
            {loading ? <Skeleton width={200} /> : nftName}
          </Typography>
          <Box sx={{ display: { xs: 'block', sm: 'flex' }, gap: 2, mb: 2 }}>
            {loading ? (
              <>
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: '0.5rem' }}
                  width={'50%'}
                  height={200}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: '0.5rem' }}
                  width={'50%'}
                  height={200}
                />
              </>
            ) : (
              <>
                {sale && !isOwner && <Purchase sale={sale} onBuy={() => purchase(sale)} />}
                {sale && isOwner && (
                  <Edit
                    sale={sale}
                    onEdit={() => edit(sale, 'edit price')}
                    onCancel={() => edit(sale, 'cancel sale')}
                  />
                )}
                <Offer
                  sale={sale}
                  bestOffer={bestOffer}
                  isOwner={isOwner}
                  onMakeOffer={makeOffer}
                  onSale={listForSale}
                  onTransfer={transfer}
                />
              </>
            )}
          </Box>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            {loading ? (
              <>
                <Skeleton animation="wave" height={10} width="90%" sx={{ mb: 2 }} />
                <Skeleton animation="wave" height={10} width="90%" sx={{ mb: 2 }} />
                <Skeleton animation="wave" height={10} width="80%" />
              </>
            ) : (
              description
            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NFTOverview;
