import React from 'react';
import { IconButton, InputBase, Paper, Stack, styled, SxProps } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { NumberOrString } from 'types';

type Props = {
  value?: NumberOrString;
  onChange: (v: NumberOrString) => void;
  placeholder?: string;
  step?: number;
  disableDecimal?: boolean;
  arrows?: boolean;
  sx?: SxProps;
  error?: boolean;
  max?: number;
  startAdornment?: React.ReactNode;
};

const SquareIconButton = styled(IconButton)({
  borderRadius: 0,
  '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
    borderRadius: 0
  },
  padding: 0
});

const NumberInput = ({
  value,
  onChange,
  placeholder,
  step,
  sx,
  disableDecimal,
  error,
  arrows = true,
  max,
  startAdornment
}: Props) => {
  const handleClick = (step: number) => {
    if (value !== undefined) {
      const n = Number(value) + step;
      if (n < 0) return;
      if (max && n > max) return;
      onChange(n >= 0 ? Number(n.toFixed(1)) : 0);
    } else onChange(0);
  };
  return (
    <Paper
      elevation={0}
      sx={{
        ...sx,
        background: (theme) => theme.input.background,
        display: 'flex',
        alignItems: 'center',
        p: 0.5,
        border: error ? '1px solid #EF5454' : null
      }}
    >
      <InputBase
        sx={{ flex: 1, fontSize: 14, mx: 1, borderRadius: '0.625rem' }}
        value={value == undefined ? '' : value}
        onChange={(e) => {
          const value = disableDecimal
            ? e.target.value
                .replace(/[^0-9]/g, '')
                .replace(/(\..*?)\..*/g, '$1')
                .replace(/^0[^.]/, '0')
            : e.target.value
                .replace(/[^0-9.]/g, '')
                .replace(/(\..*?)\..*/g, '$1')
                .replace(/^0[^.]/, '0');

          if (max && Number(value) > max) return;
          onChange(value);
        }}
        placeholder={placeholder}
        startAdornment={startAdornment}
      />
      {arrows && (
        <Stack>
          <SquareIconButton size="small" onClick={() => handleClick(step || 0.1)}>
            <ArrowDropUpIcon fontSize="small" />
          </SquareIconButton>
          <SquareIconButton size="small" onClick={() => handleClick(-(step || 0.1))}>
            <ArrowDropDownIcon fontSize="small" />
          </SquareIconButton>
        </Stack>
      )}
    </Paper>
  );
};

export default NumberInput;
