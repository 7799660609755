import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { PriceSymbol, StepStatus, Bid } from 'types';
import Step from '../Step';
// Api
import {
  useAttributesProofMutation,
  useCreateDealMutation,
  useSaleNonceMutation,
  useTokenIdsProofMutation
} from 'api';
import { getOffer } from './utils';
import { useWeb3React } from '@web3-react/core';
import { useNetwork } from 'hooks';
import { offerSignature } from 'services/exchange';
import { AttributesProofQuery, DealKind, TokenIdsProofQuery } from 'types/generated';
import { toAttributesQuery } from 'helpers/attributes';
import { Done } from './Done';

type Props = {
  bid: Bid;
  token: PriceSymbol;
  step: number;
  currentStep: number;
  // callback, called once the step has been completed successfully
  complete: () => void;
};

const processing = () => {
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        Please sign the transaction in your wallet to submit the offer
      </Typography>
    </Stack>
  );
};

const ProcessBid = ({ step, currentStep, bid, complete }: Props) => {
  const { account, hooks } = useWeb3React();
  const { usePriorityProvider } = hooks;
  const { supportedChainId } = useNetwork();
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();
  const [getNonce] = useSaleNonceMutation();
  const [createDeal] = useCreateDealMutation();
  const provider = usePriorityProvider();
  const [tokenIdsProof] = useTokenIdsProofMutation();
  const [attributesProof] = useAttributesProofMutation();
  let attrsProofResponse: AttributesProofQuery = {
    AttributesProof: { merkleroot: '', token_ids: [] }
  };
  let tokenIdsProofResponse: TokenIdsProofQuery = { TokenIdsProof: '' };

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
      processDeal();
    }
  }, [currentStep]);

  const processDeal = async () => {
    if (!account || !provider) {
      setStatus(StepStatus.ERROR);
      setError('Unauthenticated');
      return;
    }

    setStatus(StepStatus.IN_PROGRESS);

    try {
      if (bid.type == 'nft') {
        tokenIdsProofResponse = await tokenIdsProof({ tokenIds: [bid.token_id] })
          .unwrap()
          .catch(() => {
            throw new Error('Make offe failed');
          });
      }
      if (bid.type == 'attributes') {
        attrsProofResponse = await attributesProof({
          attributes: toAttributesQuery(bid.properties),
          collectionAddress: bid.collection.address
        })
          .unwrap()
          .catch(() => {
            throw new Error('Make offe failed');
          });
      }

      // Get nonce
      const nonce = await getNonce().unwrap();
      // Build offer
      const offer = getOffer(
        account,
        nonce.saleNonce,
        supportedChainId,
        bid,
        bid.type == 'nft'
          ? [bid.token_id]
          : bid.type == 'attributes'
          ? attrsProofResponse.AttributesProof.token_ids
          : [],
        bid.type == 'attributes' ? toAttributesQuery(bid.properties) : [],
        bid.type == 'nft'
          ? tokenIdsProofResponse.TokenIdsProof
          : bid.type == 'attributes'
          ? attrsProofResponse.AttributesProof.merkleroot
          : undefined
      );

      // await the signature hash
      const signature = await offerSignature(offer, provider);
      // create offer on a collection
      await createDeal({
        input: {
          kind: DealKind.Offer,
          offer: { ...offer, buyer_signature: signature }
        }
      })
        .unwrap()
        .then((data) => {
          setStatus(StepStatus.DONE);
        })
        .catch((err) => {
          if (Array.isArray(err)) setError(err[0]?.message);
          else setError('Offer failed !');
          setStatus(StepStatus.ERROR);
        });

      complete();
    } catch (err) {
      console.log(err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Offer failed !');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={status == StepStatus.DONE ? <Done value={bid.price} /> : 'Submit Offer'}
      step={step}
      currentStep={currentStep}
      status={status}
      el={
        status == StepStatus.STARTED || status == StepStatus.IN_PROGRESS ? processing() : undefined
      }
      error={error}
    />
  );
};

export default ProcessBid;
