// 3rd party libs
import React from 'react';
import { Button as MuiButton, ButtonProps, darken, styled } from '@mui/material';

type StyledButtonProps = {
  // default primary
  ctx: 'primary' | 'secondary' | 'secondaryV2';
  error?: boolean;
} & ButtonProps;

const StyledButton = styled(MuiButton)<StyledButtonProps>(({ theme, ctx = 'primary', error }) => ({
  color: error ? theme.palette.error.main : theme.button[ctx].color,
  background: theme.button[ctx].background,
  border: theme.button[ctx].border,
  borderRadius: '5.625rem',
  borderColor: error ? theme.palette.error.main : undefined,
  padding: theme.spacing(1, 2),
  '&.MuiButton-sizeLarge': {
    padding: theme.spacing(2, 2)
  },
  ':hover': {
    background: darken(
      theme.button[ctx].background as string,
      ctx == 'secondary' && theme.palette.mode == 'light' ? 0.05 : 0.2
    )
  }
}));

const Button = ({ children, ctx, ...rest }: StyledButtonProps) => {
  return (
    <StyledButton ctx={ctx} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;
