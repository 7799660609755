import React from 'react';
import { Typography, Divider, Box } from '@mui/material';
import { GridItem, RenderKeyValue, PrimaryButton, SecondaryButton, StakingInput } from '../utils';
import { IconFactory } from 'components';

const KalaoStaking = () => {
  return (
    <GridItem
      sx={{ border: (theme) => theme.border, borderColor: (theme) => theme.palette.text.primary }}
      spacing={2}
    >
      <div className="header">
        <Typography variant="h4" color="text.primary" fontWeight={500}>
          KLO Staking
        </Typography>
        <IconFactory icon="klo" />
      </div>
      <div className="container">
        <RenderKeyValue property="Wallet" value="2000" symbol="klo" secondaryValye="($ 200)" />
        <RenderKeyValue property="Staked" value="20000" symbol="klo" secondaryValye="($ 200)" />
        <Divider variant="middle" sx={{ my: 2 }} />
        <RenderKeyValue property="Max APR Multiplier (veKLO)" value="300%" />
        <RenderKeyValue
          property="Current veKLO Boost"
          value="2000"
          secondaryValye={
            <Box component="span" sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
              <IconFactory icon="veKLO" size={10} />
              <Typography variant="caption" color="text.secondary">
                13000
              </Typography>
            </Box>
          }
        />
      </div>
      <div className="buttons">
        <PrimaryButton caption="Stake" click={() => undefined} />
        <SecondaryButton caption="Unstake" click={() => undefined} />
        <SecondaryButton caption="Buy $KLO" click={() => undefined} />
        <SecondaryButton caption="Transfer account" click={() => undefined} />
      </div>
      <StakingInput
        caption="Staking amount"
        value={''}
        balance="200"
        symbol="klo"
        fiat="($ 90)"
        onChange={() => undefined}
        onClick={() => undefined}
      />
    </GridItem>
  );
};

export default KalaoStaking;
