// Api
import { useCollectionRankingQuery } from 'api';
import { CollectionRankingQuery, DaysFilter, RankingTarget } from 'types/generated';

// Types
import { NumberOrString } from 'types';

type Props = {
  category_tags?: NumberOrString[];
  days_filter: DaysFilter;
  page: number;
  size: number;
};

type Response = {
  data?: CollectionRankingQuery;
  error?: string | unknown;
  isLoading: boolean;
  isFetching: boolean;
  count: number;
};

const useCollectionRanking = ({ category_tags, days_filter, page, size }: Props): Response => {
  const { data, error, isLoading, isFetching } = useCollectionRankingQuery({
    input: {
      days_filter,
      category_tags: category_tags?.map((c) => c.toString()),
      target: RankingTarget.Collection
    },
    pagination: {
      page,
      size
    }
  });

  return {
    data,
    error: error && 'Failed to load data',
    isLoading,
    isFetching,
    count: data?.ranking.count || 0
  };
};

export default useCollectionRanking;
