// 3rd party libs
import React from 'react';
import { Box } from '@mui/material';
import { Banner } from 'components';
import { matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Collections } from './Collections';
import { NFTs } from './NFTs';
import { Artists } from './Artists';
import { Galleries } from './Galleries';
import { GalleriesBanner } from './Galleries/GalleriesBanner';
import { ExploreTabs } from './Tabs';

const Explore = () => {
  const location = useLocation();
  const showDefaultHeader = !matchPath('/explore/galleries', location.pathname);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      {showDefaultHeader && (
        <Banner
          title="Explore"
          subTitle="Explore and trade the amazing collections and NFTs on Kalao marketplace"
        />
      )}
      {!showDefaultHeader && (
        <GalleriesBanner />
      )}
      <ExploreTabs />

      <Routes>
        <Route path="/" element={<Navigate to="collections" />} />
        <Route path="collections" element={<Collections />} />
        <Route path="nfts" element={<NFTs />} />
        <Route path="artists" element={<Artists />} />
        <Route path="galleries" element={<Galleries />} />
        <Route path="*" element={<Navigate to="collections" replace />} />
      </Routes>
    </Box>
  );
};

export default Explore;
