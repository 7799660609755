// Third party
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, Middleware } from 'redux';
import { kalaoApi } from 'api';
import walletMiddleware from './walletMiddleware';

import { reducer as stateReducer } from './state';

const reducer = combineReducers({
  state: stateReducer,
  [kalaoApi.reducerPath]: kalaoApi.reducer
});

const middlewares: Middleware[] = [kalaoApi.middleware, walletMiddleware];

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middlewares)
});

//export const store = createStore(stateReducer);

export type GlobalState = ReturnType<typeof store.getState>;
