import React from 'react';

import { SearchInput, Sorting, MobileFilters, CollectionFilter } from 'components';
import { Box, useMediaQuery } from '@mui/material';
import { theme } from 'themes';
import { useFilters } from 'providers';

export const GalleryFilters = () => {
  const { keywords, setKeywords, collections, collection, setCollection } = useFilters();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box sx={{ display: 'flex', height: '3.rem', gap: 2, mb: 3, px: 1 }}>
      {matches && (
        <>
          <SearchInput sx={{ flex: 1 }} keyword={keywords} set={setKeywords} />
          <CollectionFilter
            collection={collections.find((c) => c.address == collection?.address)}
            collections={collections}
            setCollection={setCollection}
            sx={{ flex: 1 }}
          />
          <Sorting defaultKey={'recently_received'} sortType={'sortSellNfts'} sx={{ flex: 1 }} />
        </>
      )}
      {!matches && <MobileFilters activeFilters={['ownedCollections']} search={true} />}
    </Box>
  );
};
