import React, { useEffect, useState } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { OwnerShipStep, Miniature, Upload, Mint } from 'components';
import { Divider } from '@mui/material';
import { Renderer } from '../Renderer';
import { Asset } from 'types';

const renderNFT = (nft: Asset) => {
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Miniature thumbnail={nft.fileCoverUrl?nft.fileCoverUrl:nft.fileUrl} />
      <Stack justifyContent="center" spacing={1}>
        <Typography variant="body2">{nft.form.name}</Typography>
        <Typography variant="body2" color="text.secondary">
          {nft.tokenKind}
        </Typography>
      </Stack>
    </Box>
  );
};

const CreateNFT = () => {
  const nft = useSelector((global: GlobalState) => global.state.createNFT);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [ipfsUploadUrl, setIpfsUploadUrl] = useState<string>('');

  useEffect(() => {
    setCurrentStep(1);
  }, []);

  if (!nft) return null;
  return (
    <Renderer title="Create NFT" customRenderer={renderNFT(nft)}>
      <Divider sx={{ my: 3 }} variant="middle" />
      <Stack spacing={2}>
        <OwnerShipStep step={1} currentStep={currentStep} complete={() => setCurrentStep(2)} />
        <Upload
          step={2}
          currentStep={currentStep}
          nft={nft}
          complete={(url) => {
            setIpfsUploadUrl(url);
            setCurrentStep(3);
          }}
        />
        <Mint
          step={3}
          currentStep={currentStep}
          nft={nft}
          ipfsUploadUrl={ipfsUploadUrl}
          complete={() => undefined}
        />
      </Stack>
    </Renderer>
  );
};

export default CreateNFT;
