import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { DropDownInput, Button, NumberInput } from 'components';
import { Divider } from '@mui/material';
import { Renderer } from '../Renderer';
import { NFTSale, NumberOrString } from 'types';
import { SaleKind, SaleStatus } from 'types/generated';
import { getTokenOptions } from 'constants/options';
import { batch, listForSale, openModal } from 'store/actions';
import { useBalanceMutation } from 'api';

type FormProps = {
  validate: (price: NumberOrString, qty: NumberOrString) => void;
  max: number;
  is1155?: boolean;
};

const Form = ({ max, is1155, validate }: FormProps) => {
  const [price, setPrice] = useState<NumberOrString>();
  const [qty, setQty] = useState<NumberOrString>('1');
  const [error, setError] = useState<Record<string, string | undefined>>({});

  const onValidate = () => {
    if (!price) setError({ ...error, ['price']: 'Enter a valid price' });
    else if (!qty) setError({ ...error, ['qty']: 'Enter quantity' });
    else validate(price, qty);
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary" textAlign="start">
          Price
        </Typography>
        <DropDownInput
          defaultKey={'AVAX'}
          value={price}
          numeric={true}
          onValueChange={(v) => {
            if (error?.['price']) setError({ ...error, ['price']: undefined });
            setPrice(v);
          }}
          onOptionChange={(v) => undefined}
          options={getTokenOptions(['AVAX'])}
          error={error?.['price'] !== undefined}
        />
        {is1155 && (
          <>
            <Typography variant="body2" color="text.secondary" textAlign="start">
              Quantity
            </Typography>
            <NumberInput
              sx={{ width: '100%', height: '3.5rem', borderRadius: '0.5rem' }}
              value={qty}
              step={1}
              max={max}
              error={error?.['qty'] !== undefined}
              onChange={(v) => {
                if (error?.['qty']) setError({ ...error, ['qty']: undefined });
                setQty(v as number);
              }}
            />
          </>
        )}
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Button ctx="primary" sx={{ width: '8rem' }} onClick={onValidate}>
          <Typography variant="button">Validate</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

/**
 *
 * List NFt for sale from nft details page
 */

const ListNFT = () => {
  const dispatch = useDispatch();
  const nft = useSelector((global: GlobalState) => global.state.listNFTForSale);
  const [max, setMax] = useState(1);
  const [balance] = useBalanceMutation();
  const is1155 = nft?.collection.kind == 'erc1155';

  useEffect(() => {
    if (nft && is1155) {
      balance({ assetId: nft?.asset_id })
        .unwrap()
        .then((res) => {
          setMax(Number(res.balance));
        });
    }
  }, [nft]);

  const validate = (price: NumberOrString, qty: NumberOrString) => {
    if (nft) {
      const item: NFTSale = {
        ...nft,
        sale: {
          expiration_date: new Date().getTime(),
          floor_diff: '0',
          kind: SaleKind.Direct,
          sale_id: '',
          seller: '',
          start_date: new Date().getTime(),
          status: SaleStatus.Live,
          unitary_price: '0',
          quantity: qty as string,
          unitary_price_float: Number(price)
        }
      };
      dispatch(
        batch.create({
          actions: [
            listForSale.create({ items: [item] }),
            openModal.create({ modal: 'LIST FOR SALE' })
          ]
        })
      );
    }
  };

  return (
    <Renderer title="Listing for sale" nft={nft} quantity={is1155 ? max : undefined}>
      <Divider sx={{ my: 3 }} variant="middle" />
      {/** Steps */}
      <Stack spacing={2}>
        <Form validate={validate} is1155={is1155} max={max} />
      </Stack>
    </Renderer>
  );
};

export default ListNFT;
