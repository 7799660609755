// 3rd party libs
import React from 'react';
import { Box } from '@mui/material';
import { Authentication } from 'components';
import { useAuth } from 'providers';

type Props = {
  children?: React.ReactNode;
};

const PrivateRoute = ({ children }: Props): JSX.Element => {
  const { loggedIn } = useAuth();
  if (!loggedIn)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 4
        }}
      >
        <Authentication />
      </Box>
    );
  return <>{children}</>;
};

export default PrivateRoute;
