import React from 'react';
import { Box, Chip, Stack, styled, Typography, useTheme } from '@mui/material';
import Avatar from 'components/Avatar';
import { Price } from 'components';
import { HotBid } from 'types';

// Card dimensions
type Dims = {
  // card width (exposed to handle Responsiveness )
  width?: number;
};

type Props = HotBid & Dims;

const Image = styled('img')({
  borderRadius: '1.25rem',
  width: '100%',
  height: '100%',
  objectFit: 'contain'
});

const ImageContainer = styled(Box)({
  position: 'relative',
  flex: 1
});

const AvatarContainer = styled(Box)({
  position: 'absolute',
  top: 'calc(100% - 2.3rem)',
  left: 'calc(50% - 1.71875rem)'
});

const StyledBidCard = styled(Box)<Dims>(({ width, theme }) => ({
  width: `${width || '100%'}`,
  borderRadius: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  background: 'transparent',
  ':hover': {
    background: theme.palette.action.hover
  },
  '& > div': {
    padding: theme.spacing(2)
  }
}));

const StyledCountDown = styled(Chip)({
  position: 'absolute',
  top: '3%',
  left: '3%'
});

const avatarRenderer = (avatar: string, certified: boolean) => {
  const theme = useTheme();
  return (
    <AvatarContainer>
      <Avatar
        src={avatar}
        approved={certified}
        top={20}
        style={{ border: `0.2rem solid ${theme.input.background}` }}
        sx={{ width: 55, height: 55 }}
      />
    </AvatarContainer>
  );
};

const InfoRenderer = (name: string, price: number) => {
  return (
    <Stack alignItems="center" sx={{ pt: 4 }}>
      <Typography variant="subtitle1" color="text.secondary">
        {name}
      </Typography>
      <Price value={price} variant="h4" />
    </Stack>
  );
};

const BidCard = ({ avatar, name, certified, thumbnail, price, endTime, width }: Props) => {
  return (
    <StyledBidCard width={width}>
      <div>
        <ImageContainer>
          <Image src={thumbnail} />
          {avatarRenderer(avatar, certified)}
          <StyledCountDown
            sx={{ fontSize: 15, color: 'black', background: 'white' }}
            // label={<Countdown date={endTime} />}
            label={''}
          />
        </ImageContainer>
        {InfoRenderer(name, price)}
      </div>
    </StyledBidCard>
  );
};

export default BidCard;
