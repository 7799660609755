import React from 'react';
import { Box, Skeleton, Stack, styled, SvgIcon, Typography, useTheme } from '@mui/material';
import { ReactComponent as Approved } from '../../assets/approved.svg';
import { Button, ImageContainer, Avatar } from 'components';
import { ArtistInfo } from 'types/generated';
import { useResize } from 'providers';

type Props = {
  artist: ArtistInfo;
  goTo: () => void;
  isLoading?: boolean;
};

// Styling
const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '1rem',
  background: 'transparent',
  boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 4px;',
  border: theme.border,
  transition: 'all 0.2s',
  ':hover': {
    border: theme.borderHover,
    background:
      theme.palette.mode == 'dark'
        ? theme.colors.backgroundSecondaryDark
        : theme.colors.backgroundPrimaryLight
  }
}));

const ArtistinfoContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem'
});

const AvatarContainer = styled(Box)({
  position: 'absolute',
  top: 'calc(-2.3rem)',
  left: 'calc(50% - 1.71875rem)'
});

const artistAvatarRenderer = (
  collectionAvatarSrc: string,
  isApproved?: boolean,
  isLoading?: boolean
) => {
  if (isLoading)
    return (
      <AvatarContainer>
        <Skeleton variant="circular" sx={{ width: 55, height: 55 }} />
      </AvatarContainer>
    );
  return (
    <AvatarContainer>
      <Avatar
        top={25}
        approved={isApproved}
        src={collectionAvatarSrc}
        sx={{ width: 55, height: 55, border: (theme) => `0.2rem solid ${theme.input.background}` }}
      />
    </AvatarContainer>
  );
};

const renderer = (description: string, isLoading?: boolean) => {
  //Disable Button TMP launch
  const disableButton = true;
  return (
    <Stack direction="column" sx={{ flex: 1 }}>
      <Box sx={{ height: '4rem', mt: 2 }}>
        {isLoading ? (
          <>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </>
        ) : (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              textAlign: 'center',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
      {!disableButton && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button ctx="secondary" sx={{ flex: 1, border: 0 }}>
            <Typography variant="button">Follow</Typography>
          </Button>
          <Button ctx="secondary" sx={{ flex: 1 }}>
            <Typography variant="button">Message</Typography>
          </Button>
        </Box>
      )}
    </Stack>
  );
};

const artistInfo = (artist: ArtistInfo, isLoading?: boolean) => {
  const { name, certified, avatar, description } = artist;
  const { size } = useResize();
  const disable_tagname = true;
  return (
    <ArtistinfoContainer>
      {artistAvatarRenderer(avatar, certified, isLoading)}
      <div>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{ mt: 3, justifyContent: 'center' }}
        >
          {isLoading ? (
            <Skeleton width="50%" />
          ) : (
            <>
              <Typography
                variant="body2"
                color="text.primary"
                fontWeight={600}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: size == 'comfy' ? '1.32rem' : '1.1rem',
                  mr: certified ? 0.5 : 0,
                  py: 0.5
                }}
              >
                {name || '--'}
              </Typography>
            </>
          )}
        </Stack>
      </div>
      {!disable_tagname && (
        <Typography
          variant="body1"
          color="blue"
          sx={{
            textAlign: 'center',
            color: '#41AFFF',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 600
          }}
        >
          {isLoading ? <Skeleton width="50%" /> : `@${name}`}
        </Typography>
      )}
      {renderer(description, isLoading)}
    </ArtistinfoContainer>
  );
};

const ExploreArtistCard = ({ artist, goTo, isLoading }: Props): JSX.Element => {
  const { banner } = artist;
  return (
    <Card onClick={() => !isLoading && goTo()}>
      <ImageContainer src={banner} ratio={50} isLoading={isLoading} displayTheme={'covered'} />
      {artistInfo(artist, isLoading)}
    </Card>
  );
};

export default ExploreArtistCard;
