// 3rd party libs
import React, { useEffect, useState } from 'react';
import { Box, styled, Typography, Stack } from '@mui/material';
import { Avatar } from 'components';
import { useSettings } from '../context/settingsContext';
import { validateFormBtn } from '../utils';
import {
  CollectionNotificationSettingInput,
  UserNotificationSettings,
  UserNotificationsSettingsInput
} from 'types/generated';
import { useOwnedNftsCollectionsQuery, useUpdateNotificationSettingsMutation } from 'api';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';
import { useWeb3React } from '@web3-react/core';
import { PriceInput } from './PriceInput';
import { OwnedNftsCollectionThreshold, toCollectionThresholds } from './utils';
import { useNotistack } from 'hooks';
import { formatAddress } from 'helpers/formatAddress';

const StyledBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: theme.border,
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  borderRadius: '1.25rem'
}));

const Offers = () => {
  const { success, error } = useNotistack();
  const navigate = useNavigate();
  const { account } = useWeb3React();

  // Call api to get user owned nfts collections
  const { data } = useOwnedNftsCollectionsQuery({ userAddress: account }, { skip: !account });
  const { ownedNFTsCollections } = data || { ownedNFTsCollections: [] };
  const [update] = useUpdateNotificationSettingsMutation();
  const { notifications, setNotifications } = useSettings();
  const [collections, setCollections] = useState<OwnedNftsCollectionThreshold[]>(
    toCollectionThresholds(ownedNFTsCollections, notifications?.collection_settings)
  );

  useEffect(() => {
    setCollections(
      toCollectionThresholds(ownedNFTsCollections, notifications?.collection_settings)
    );
  }, [ownedNFTsCollections, notifications?.collection_settings]);

  const set = (key: keyof UserNotificationSettings, value: unknown) => {
    if (notifications) setNotifications({ ...notifications, [key]: value });
  };

  const updateCollectonsThresholds = () => {
    const collectionsSettings: CollectionNotificationSettingInput[] = [...collections].map(
      (collection) => {
        const { address, offer_threshold } = collection;
        return {
          address,
          offer_threshold: offer_threshold as number
        };
      }
    );
    const input: UserNotificationsSettingsInput = {
      global_offer_threshold: notifications?.global_offer_threshold,
      collection_settings: collectionsSettings
    };
    update({ input })
      .unwrap()
      .then(() => {
        set('collection_settings', collectionsSettings);
        success('Offers thresholds successfully updated');
      })
      .catch(() => {
        error('Offers thresholds Update Failed!');
      });
  };

  const renderCollection = (ownedCollections: OwnedNftsCollectionThreshold, index: number) => {
    const { avatar, certified, name, address, offer_threshold } = ownedCollections;

    return (
      <Box
        key={`${index}-${address}`}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 2 }}>
          <Avatar
            top={45}
            src={avatar}
            approved={certified}
            onClick={() => navigate(`/${AppRoutes.collection_page.replace(':address', address)}`)}
            sx={{
              width: 80,
              height: 80,
              cursor: 'pointer'
            }}
          />
          <Typography
            variant="h6"
            color="text.primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(`/${AppRoutes.collection_page.replace(':address', address)}`)}
          >
            {name && name != 'anonymous' ? name : formatAddress(address)}
          </Typography>
        </Box>
        <PriceInput
          price={offer_threshold}
          set={(v) => {
            const c = [...collections];
            c[index] = { ...c[index], offer_threshold: v.toString() };
            setCollections(c);
          }}
          width="8rem"
        />
      </Box>
    );
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h3" color="text.primary">
        Offers
      </Typography>
      <Typography variant="h4" color="text.primary" fontWeight={500}>
        Minimum bid threshold
      </Typography>
      <PriceInput
        label={
          'Receive notifications only for offers equal or greater than the price set below.'
        }
        price={notifications?.global_offer_threshold}
        set={(v) => {
          set('global_offer_threshold', v.toString());
          const newThresholds = [...collections].map((c) => {
            return { ...c, offer_threshold: v.toString() };
          });
          setCollections(newThresholds);
        }}
      />
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary">
          Enable notifications at collection level only for offers above the minimum price set below.
        </Typography>
        <StyledBox>{collections.map((c, index) => renderCollection(c, index))}</StyledBox>
      </Stack>
      {validateFormBtn('Update', () => updateCollectonsThresholds())}
    </Stack>
  );
};

export default Offers;
