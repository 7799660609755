// 3rd party libs
import React from 'react';
import { Box, styled, useMediaQuery } from '@mui/material';

// Componenets
import { FilterChips, ResizeToggle, Filters, MobileFilters } from 'components';
import { TopSectionContainer } from 'helpers/topSection';
import { useResize, FiltersProvider, GridSizeProvider } from 'providers';

import { ArtistsExplorer } from './ArtistsExplorer';
import { theme } from 'themes';
import { RightContainer } from 'helpers/rightContainer';

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

// Styling
const Container = styled(Box)({
  margin: '1% 2%'
});

const ArtistsRenderer = () => {
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { size, resize } = useResize();
  const filters = matches ? (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
      <Filters showKeywords={true} />
      <FilterChips />
    </Box>
  ) : (
    <MobileFilters activeFilters={[]} search={true} />
  );
  return (
    <Root>
      <TopSectionContainer>
        {filters}
        {matches && (
          <RightContainer>
            <ResizeToggle size={size} resize={(size) => resize(size)} />
          </RightContainer>
        )}
      </TopSectionContainer>
      <Container>
        <ArtistsExplorer />
      </Container>
    </Root>
  );
};

export const Artists = () => (
  <GridSizeProvider>
    <FiltersProvider>
      <ArtistsRenderer />
    </FiltersProvider>
  </GridSizeProvider>
);
