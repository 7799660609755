import React, { ReactNode } from 'react';
import { Grid, styled, Stack, StackProps, Typography, Box } from '@mui/material';
import { Button, InputField, IconFactory } from 'components';
import { NumberOrString } from 'types';

const Container = styled(Stack)(({ theme }) => ({
  borderRadius: '0.9375rem',
  padding: theme.spacing(2),
  height: '100%',

  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  '& .container': {
    borderRadius: '0.9375rem',
    padding: theme.spacing(2),
    background: theme.input.background
  },

  '& .buttons': {
    display: 'flex',
    gap: '1rem',
    '&.center': {
      justifyContent: 'center'
    }
  }
}));

type GridItemProps = {
  children: ReactNode;
} & StackProps;

export const GridItem = ({ children, ...rest }: GridItemProps) => {
  return (
    <Grid item xs={12} sm={12} md={6}>
      <Container {...rest}>{children}</Container>
    </Grid>
  );
};

/**
 *
 * @param param0
 * @returns
 */

type KeyValueProps = {
  property: string | JSX.Element;
  value: string;
  symbol?: 'klo' | 'veKLO' | 'esKLO' | 'avax';
  secondaryValye?: string | JSX.Element;
};

export const RenderKeyValue = ({ property, value, symbol, secondaryValye }: KeyValueProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="body2" color="text.primary" fontWeight={400}>
        {property}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {symbol && <IconFactory icon={symbol} size={12} />}
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ ml: 1, display: 'flex', alignItems: 'center' }}
        >
          {value}
          {typeof secondaryValye == 'string' ? (
            <Typography variant="caption" color="text.secondary" sx={{ ml: 0.5 }}>
              {secondaryValye}
            </Typography>
          ) : (
            secondaryValye
          )}
        </Typography>
      </Box>
    </Stack>
  );
};

/**
 *
 * @param caption
 * @param click
 */

type ButtonProps = {
  caption: string;
  click: () => void;
};

export const PrimaryButton = ({ caption, click }: ButtonProps) => {
  return (
    <Button ctx="primary" sx={{ borderRadius: '0.5rem', p: 1 }} onClick={click}>
      <Typography variant="button">{caption}</Typography>
    </Button>
  );
};

export const SecondaryButton = ({ caption, click }: ButtonProps) => {
  return (
    <Button
      ctx="secondary"
      sx={{
        borderRadius: '0.5rem',
        p: 1,
        color: (theme) => theme.palette.text.secondary,
        border: (theme) => theme.border
      }}
      onClick={click}
    >
      <Typography variant="button">{caption}</Typography>
    </Button>
  );
};

/**
 *
 * @param caption
 * @param value
 * @param onChange
 * @param balance
 */

type InputProps = {
  caption: string;
  value: NumberOrString;
  balance: string | JSX.Element;
  symbol: 'klo' | 'veKLO' | 'esKLO';
  fiat?: NumberOrString;
  onChange: (value: NumberOrString) => void;
  onClick: () => void;
};

export const StakingInput = ({
  caption,
  value,
  balance,
  symbol,
  fiat,
  onClick,
  onChange
}: InputProps) => {
  return (
    <Stack>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="body2" color="text.secondary">
          {caption}
        </Typography>
        <Button
          ctx="secondary"
          sx={{
            p: 0.5,
            borderRadius: '0.5rem',
            color: (theme) => theme.palette.text.secondary,
            border: (theme) => theme.border
          }}
          onClick={onClick}
        >
          <Typography variant="button" fontSize={10}>
            MAX
          </Typography>
        </Button>
      </Box>
      <InputField
        numeric={true}
        sx={{
          height: '3.5rem',
          fontSize: 15,
          px: 2,
          borderRadius: '0.5rem'
        }}
        value={value}
        set={(v) => onChange(v)}
        placeholder={'Enter amount'}
      />
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        Balance:
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
          <IconFactory icon={symbol} size={10} />
          <Typography variant="caption" color="text.primary" sx={{ ml: 0.5 }}>
            13000
          </Typography>
        </Box>
        <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
          {fiat}
        </Typography>
      </Typography>
    </Stack>
  );
};
