export const priceFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  notation: 'compact'
});

export const compact = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  notation: 'compact',
  compactDisplay: 'short'
});

// TODO create a configurable formatter
export const shortCompact = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  notation: 'compact',
  compactDisplay: 'short'
});
