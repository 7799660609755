import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { NFTSale, PriceSymbol } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { addToBasket, batch, clearBasket, openModal, updateBasket } from 'store/actions';
import { Scrollable } from 'helpers/scrollable';
import { Miniature, Price, Button, MiniatureInfo, NumberInput } from 'components';
import { GlobalState } from 'store';
import { theme } from 'themes';
import { NftPreview } from 'types/generated';
import { priceFormatter } from 'lib/format';
import { useState } from 'react';

const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  background: 'transparent'
}));

const footerRenderer = (
  nfts: NftPreview[],
  quantities: Record<string, number>,
  clear: () => void,
  checkout: () => void
) => {
  let total = 0;
  nfts.forEach((nft) => {
    const { sale } = nft;
    if (sale) {
      if (nft.collection.kind == 'erc1155')
        total += sale.unitary_price_float * (quantities[nft.asset_id] || 1);
      else total += sale.unitary_price_float * Number(sale.quantity);
    }
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 3,
        mt: 2,
        flexWrap: 'wrap'
      }}
    >
      <Stack sx={{ flex: 1 }}>
        <Typography variant="subtitle1" color="text.secondary">
          Total amount
        </Typography>
        <Price value={total} variant="h3" />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ ml: 'auto' }}>
        <Button ctx="secondary" sx={{ width: '8rem' }} onClick={() => clear()}>
          <Typography variant="button">Clear</Typography>
        </Button>
        <Button ctx="primary" sx={{ width: '8rem' }} onClick={() => checkout()}>
          <Typography variant="button">Checkout</Typography>
        </Button>
      </Stack>
    </Box>
  );
};

const Erc1155Renderer = (props: {
  nftSale: NFTSale;
  qty: number;
  setQty: (qty: number) => void;
  error?: string;
}) => {
  const { nftSale, qty, setQty, error } = props;
  const { sale, thumbnail } = nftSale;
  const price = sale.unitary_price_float;
  const availableAmount = Number(sale.quantity);
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Miniature thumbnail={thumbnail} />
      <MiniatureInfo nft={nftSale} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          {error ? (
            <Typography variant="caption" color="red">
              {error}
            </Typography>
          ) : (
            <Typography variant="caption" color="text.secondary">{`${
              qty || 1
            }/${availableAmount}`}</Typography>
          )}
          <NumberInput
            sx={{ width: '4.5rem', borderRadius: '0.5rem' }}
            value={qty || 1}
            step={1}
            onChange={(value) => setQty(value as number)}
          />
        </Stack>
        <Price value={priceFormatter.format(price)} symbol={PriceSymbol.AVAX} variant="h4" />
      </Box>
    </Box>
  );
};

const renderNFT = (nftSale: NFTSale, handleRemove: () => void) => {
  const price = nftSale.sale.unitary_price_float;
  return (
    <Box key={nftSale.asset_id} sx={{ display: 'flex', gap: 1 }}>
      <Miniature thumbnail={nftSale.thumbnail} remove={() => handleRemove()} />
      <MiniatureInfo nft={nftSale} />
      <Price value={priceFormatter.format(price)} symbol={PriceSymbol.AVAX} variant="h4" />
    </Box>
  );
};

const validate = (
  sales: NFTSale[],
  quantities: Record<string, number>
): { error: Record<string, string>; sales: NFTSale[] } => {
  const error: Record<string, string> = {};
  const newSales: NFTSale[] = [];
  sales.forEach((sale) => {
    if (sale.collection.kind == 'erc1155' && Number(sale.sale.quantity) > 1) {
      if ((quantities[sale.asset_id] || 1) > Number(sale.sale.quantity))
        error[sale.asset_id] = `Max ${sale.sale.quantity}`;
      else {
        let s = { ...sale };
        s = { ...s, sale: { ...s.sale, quantity: (quantities[sale.asset_id] || 1).toString() } };
        newSales.push({ ...s });
      }
    } else {
      newSales.push({ ...sale });
    }
  });

  return {
    error,
    sales: newSales
  };
};

const NFTsBasket = () => {
  const dispatch = useDispatch();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const basket = useSelector((global: GlobalState) => global.state.basket);
  const sales = [...basket.values()];
  const [quantities, setQuantities] = useState<Record<string, number>>({});
  const [error, setError] = useState<Record<string, string>>({});

  const handleRemove = (nftSale: NFTSale) => {
    dispatch(addToBasket.create({ nftSale }));
  };

  const clearAll = () => {
    dispatch(clearBasket.create({}));
    dispatch(openModal.create({}));
  };

  const checkout = () => {
    const { error, sales: newSales } = validate(sales, quantities);
    if (Object.keys(error).length == 0)
      dispatch(
        batch.create({
          actions: [
            updateBasket.create({ nftsSales: newSales }),
            openModal.create({ modal: 'CHECKOUT_BASKET' })
          ]
        })
      );
    else setError({ ...error });
  };

  const renderer = (nftSale: NFTSale) => {
    return nftSale.collection.kind == 'erc1155' && Number(nftSale.sale.quantity) > 1 ? (
      <Erc1155Renderer
        key={nftSale.asset_id}
        nftSale={nftSale}
        qty={quantities[nftSale.asset_id]}
        error={error[nftSale.asset_id]}
        setQty={(qty) => {
          setError({ ...error, [nftSale.asset_id]: '' });
          setQuantities({ ...quantities, [nftSale.asset_id]: qty });
        }}
      />
    ) : (
      renderNFT(nftSale, () => handleRemove(nftSale))
    );
  };

  const content = desktop ? (
    <Scrollable
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '25rem',
        overflow: 'auto',
        pr: 1
      }}
    >
      {sales.map(renderer)}
    </Scrollable>
  ) : (
    sales.map(renderer)
  );

  return (
    <Container sx={{ paddingTop: '0' }}>
      <Typography
        variant="h3"
        color="text.primary"
        textAlign="center"
        sx={{ mt: 0, mb: 3, textTransform: 'capitalize' }}
      >
        Buy items
      </Typography>
      <Typography variant="h6" color="text.secondary">
        {`${sales.length} items`}
      </Typography>
      {content}
      <Divider sx={{ my: 3, mx: 0 }} variant="middle" />
      {footerRenderer(sales, quantities, clearAll, checkout)}
    </Container>
  );
};

export default NFTsBasket;
