import React, { useState } from 'react';
import { Typography, Stack, styled } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNetwork } from 'hooks';
import { useWeb3React } from '@web3-react/core';
import { Button } from 'components';

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '0.5rem',
  minWidth: '18rem'
}));

interface Err extends Error {
  code: number;
}

const WrongNetwork = (): JSX.Element => {
  const { hooks } = useWeb3React();
  const [error, setError] = useState();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();
  const { network } = useNetwork();

  const swicthNetwork = async () => {
    try {
      await provider
        ?.send('wallet_switchEthereumChain', [{ chainId: network.chainId }])
        .catch(async (_) => {
          await provider?.send('wallet_addEthereumChain', [network]).catch((err) => {
            setError(err);
          });
        });
    } catch {
      // we shouldn't end up here but adding a paranoid catch
      // ... just in case something went really wrong !
    }
  };

  return (
    <StyledContainer alignItems="center">
      <ErrorOutlineIcon fontSize="large" color="error" sx={{ width: 50, height: 50 }} />
      <Typography variant="body1" color="error">
        Wrong Network
      </Typography>
      <Typography
        component="span"
        variant="body2"
        color="text.secondary"
        textAlign="center"
        sx={{ mt: 2 }}
      >
        Please switch your wallet network to
        <Typography variant="body2" color="text.primary">
          {network.chainName}
        </Typography>
        and try again
      </Typography>
      <Stack alignItems="center">
        <Button ctx="primary" sx={{ borderRadius: '0.5rem', mt: 2 }} onClick={swicthNetwork}>
          <Typography variant="button">Switch network</Typography>
        </Button>
      </Stack>
      {error && (
        <Typography variant="caption" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </StyledContainer>
  );
};

export default WrongNetwork;
