import React from 'react';
import { styled, Box, Stack, Typography } from '@mui/material';
import { Miniature, MiniatureInfo, NumberInput } from 'components';
import { UserBalance } from 'types/generated';
import { Scrollable } from 'helpers/scrollable';
import { TransferedBalance } from 'types';
import { compact } from 'lib/format';

type Props = {
  selected: TransferedBalance[];
  update: (userBalance: TransferedBalance) => void;
  remove: (userBalance: TransferedBalance) => void;
};

const StyledBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: theme.border,
  padding: theme.spacing(2, 1),
  gap: '0.1rem',
  borderRadius: '1.25rem',
  minHeight: '8rem'
}));

const StyledCell = styled(Box)({
  display: 'flex',
  gap: '1rem',
  minWidth: 0
});

const renderer = (
  userBalance: TransferedBalance,
  update: (userBalance: TransferedBalance) => void,
  remove: () => void
) => {
  const { nft, amountToTransfer, balance } = userBalance;
  return (
    <Stack
      key={nft.asset_id}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ px: '0.5rem' }}
    >
      <StyledCell>
        <Miniature thumbnail={nft.thumbnail} remove={remove} removeLeft={true} />
        <MiniatureInfo nft={nft} />
      </StyledCell>
      <Stack alignItems="center">
        <NumberInput
          sx={{ height: '3rem', width: '5rem' }}
          value={amountToTransfer}
          step={1}
          max={Number(balance)}
          disableDecimal={true}
          onChange={(value) => {
            const balance: TransferedBalance = {
              ...userBalance,
              amountToTransfer: value as number
            };
            update(balance);
          }}
        />
      </Stack>
    </Stack>
  );
};

const Empty = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '6rem'
});

export const SelectedNFTs = ({ selected, update, remove }: Props) => {
  return (
    <StyledBox>
      <Scrollable sx={{ maxHeight: 400, overflow: 'auto' }} scrollbarGutter={'stable'}>
        {(selected.length > 0
          ? selected.map((userBalance) => renderer(userBalance, update, () => remove(userBalance)))
          : <Empty>No items selected</Empty>
        )}
      </Scrollable>
    </StyledBox>
  );
};
