import React from 'react';
import { Typography, Grid, Divider, Box, styled } from '@mui/material';
import { Button, PreviewNftCard, PropertiesChip } from 'components';
import { AssetProperty, PriceSymbol, Property, SaleType } from 'types';

type Props = {
  copies: number;
  onMarket?: boolean;
  preview?: string;
  previewMime?: string;
  previewCover?: string;
  name?: string;
  avatar?: string;
  description?: string;
  external_url?: string;
  properties?: AssetProperty[];
  create: () => void;
};
// Styling
const PreviewContainer = styled(Box)({
  position: 'sticky',
  // Avoid overlapping with the navbar bottom border
  top: 'calc(5.5rem - 0.0625rem)'
});

const isValidHttpUrl = (url: string) => {
  const res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g
  );
  return res !== null;
};

const toProperties = (properties?: AssetProperty[]): Property[] => {
  const propsMap = new Map<string, Property>();
  properties?.map((p) => {
    if (propsMap.has(p.trait_type as string))
      propsMap
        .get(p.trait_type as string)
        ?.attrs.push({ name: p.value as string, count: 0, selected: false });
    else
      propsMap.set(p.trait_type as string, {
        name: p.trait_type as string,
        attrs: [{ name: p.value as string, count: 0, selected: false }]
      });
  });
  return [...propsMap.values()];
};

const Preview = ({
  preview,
  previewMime,
  previewCover,
  name,
  avatar,
  description,
  external_url,
  properties,
  create
}: Props) => {
  return (
    <Grid item xs={12} sm={5} md={4} lg={4}>
      <PreviewContainer>
        <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
          Preview
        </Typography>
        <PreviewNftCard
          previewUri={preview}
          previewMime={previewMime}
          previewCoverUri={previewCover}
          name={name}
          avatar={avatar}
          type={SaleType.direct}
          symbol={PriceSymbol.AVAX}
        />
        {(description || external_url) && <Divider sx={{ my: 3 }} />}
        {description && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" fontWeight={500} sx={{ mb: 1 }}>
              Description
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                wordBreak: 'break-all'
              }}
            >
              {description}
            </Typography>
          </Box>
        )}
        {external_url && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" fontWeight={500} sx={{ mb: 1 }}>
              External URL
            </Typography>
            <Typography
              variant="body1"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: (theme) =>
                  isValidHttpUrl(external_url) ? theme.palette.text.secondary : theme.colors.red
              }}
              title={external_url}
            >
              {isValidHttpUrl(external_url) ? external_url : 'Not valid URL'}
            </Typography>
          </Box>
        )}

        {properties && properties.length > 0 && (
          <>
            <Divider sx={{ my: 3 }} />
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" fontWeight={500} sx={{ mb: 1 }}>
                Properties
              </Typography>
              <PropertiesChip properties={toProperties(properties)} />
            </Box>
          </>
        )}
        <Button
          ctx="primary"
          sx={{ mt: 4, display: { xs: 'block', sm: 'none' } }}
          onClick={() => create()}
        >
          <Typography variant="button">Create item</Typography>
        </Button>
      </PreviewContainer>
    </Grid>
  );
};

export default Preview;
