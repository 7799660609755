import React from 'react';
import { Typography, Divider } from '@mui/material';
import { IconFactory } from 'components';
import { GridItem, RenderKeyValue, PrimaryButton, SecondaryButton, StakingInput } from '../utils';

const KalaoEscrowed = () => {
  return (
    <GridItem sx={{ border: (theme) => theme.border, borderColor: '#FFDB3D' }} spacing={2}>
      <div className="header">
        <Typography variant="h4" color="text.primary" sx={{ color: '#FFDB3D' }} fontWeight={500}>
          Escrowed KLO Staking
        </Typography>
        <IconFactory icon="esKLO" />
      </div>
      <div className="container">
        <RenderKeyValue property="Price esKLO" value="$ 0.0710" />
        <RenderKeyValue property="Wallet" value="20000" symbol="esKLO" secondaryValye="($ 200)" />
        <RenderKeyValue property="Staked" value="20000" symbol="esKLO" secondaryValye="($ 200)" />
        <Divider variant="middle" sx={{ my: 2 }} />
        <RenderKeyValue property="APR" value="300%" />
        <Divider variant="middle" sx={{ my: 2 }} />
        <RenderKeyValue
          property="Total Staked"
          symbol="esKLO"
          value="25000"
          secondaryValye="($ 200)"
        />
        <RenderKeyValue
          property="Total Supply"
          symbol="esKLO"
          value="30000"
          secondaryValye="($ 200)"
        />
      </div>
      <div className="buttons">
        <PrimaryButton caption="Stake" click={() => undefined} />
        <SecondaryButton caption="Unstake" click={() => undefined} />
      </div>
      <StakingInput
        caption="Staking amount"
        value={''}
        balance="200"
        symbol="esKLO"
        fiat="($ 90)"
        onChange={() => undefined}
        onClick={() => undefined}
      />
    </GridItem>
  );
};

export default KalaoEscrowed;
