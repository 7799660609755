import { TransferedBalance } from 'types';

type Transfer721Payload = {
  // nft address
  collections: string[];
  // wallet target adress
  to: string[];
  // nft tokenId
  tokenIds: string[];
};

interface Transfer1155Payload extends Transfer721Payload {
  amounts: string[];
}

export const parse721Transfer = (
  balance: TransferedBalance[],
  sendTo: string
): Transfer721Payload => {
  const collections: string[] = [];
  const to: string[] = [];
  const tokenIds: string[] = [];

  balance?.forEach((b) => {
    collections.push(b.nft.collection.address);
    to.push(sendTo);
    tokenIds.push(b.nft.token_id);
  });

  return { collections, to, tokenIds };
};

export const parse1155Transfer = (
  balance: TransferedBalance[],
  sendTo: string
): Transfer1155Payload => {
  const collections: string[] = [];
  const to: string[] = [];
  const tokenIds: string[] = [];
  const amounts: string[] = [];

  balance?.forEach((b) => {
    collections.push(b.nft.collection.address);
    to.push(sendTo);
    tokenIds.push(b.nft.token_id);
    amounts.push(b.amountToTransfer.toString());
  });

  return { collections, to, tokenIds, amounts };
};
