import { useTheme } from '@mui/material';
import React from 'react';

/**
 * Map events to icons
 */

// Sale
import { ReactComponent as SaleDark } from '../assets/events/sale-dark.svg';
import { ReactComponent as SaleLight } from '../assets/events/sale-light.svg';
// Auction
import { ReactComponent as AuctionDark } from '../assets/events/auction-dark.svg';
import { ReactComponent as AuctionLight } from '../assets/events/auction-light.svg';
// Listing
import { ReactComponent as ListingDark } from '../assets/events/listing-dark.svg';
import { ReactComponent as ListingLight } from '../assets/events/listing-light.svg';
// Offer Made
import { ReactComponent as OfferDark } from '../assets/events/offer-dark.svg';
import { ReactComponent as OfferLight } from '../assets/events/offer-light.svg';
// Transfer
import { ReactComponent as TransferDark } from '../assets/events/transfer-dark.svg';
import { ReactComponent as TransferLight } from '../assets/events/transfer-light.svg';

// Offer received
import { ReactComponent as OfferReceivedDark } from '../assets/events/received-dark.svg';
import { ReactComponent as OfferReceivedLight } from '../assets/events/received-light.svg';

// Counte offer
import { ReactComponent as CounterOfferDark } from '../assets/events/counter-offer-dark.svg';
import { ReactComponent as CounterOfferLight } from '../assets/events/counter-offer-light.svg';

// Mint
import { ReactComponent as MintDark } from '../assets/events/minted-dark.svg';
import { ReactComponent as MintLight } from '../assets/events/minted-light.svg';

import { EventType } from 'types';
import { ActivityType } from 'types/generated';

const SIZE = 15;

type Props = {
  event: EventType;
};

const darkIcons: { [key in EventType]: JSX.Element } = {
  Sale: <SaleDark width={SIZE} height={SIZE} />,
  'Offer made': <OfferDark width={SIZE} height={SIZE} />,
  Listing: <ListingDark width={SIZE} height={SIZE} />,
  Transfer: <TransferDark width={SIZE} height={SIZE} />,
  Auction: <AuctionDark width={SIZE} height={SIZE} />,
  'Offer received': <OfferReceivedDark width={SIZE} height={SIZE} />,
  'Counter offer': <CounterOfferDark width={SIZE} height={SIZE} />
};

const lightIcons: { [key in EventType]: JSX.Element } = {
  Sale: <SaleLight width={SIZE} height={SIZE} />,
  'Offer made': <OfferLight width={SIZE} height={SIZE} />,
  Listing: <ListingLight width={SIZE} height={SIZE} />,
  Transfer: <TransferLight width={SIZE} height={SIZE} />,
  Auction: <AuctionLight width={SIZE} height={SIZE} />,
  'Offer received': <OfferReceivedLight width={SIZE} height={SIZE} />,
  'Counter offer': <CounterOfferLight width={SIZE} height={SIZE} />
};

export const EventIcon = ({ event }: Props): JSX.Element => {
  const mode = useTheme().palette.mode;
  return mode == 'dark' ? darkIcons[event] : lightIcons[event];
};

///////////////////////////////////////////////////////////

type ActivityProps = {
  type: ActivityType;
};

const darkActivityIcons: { [key in ActivityType]: JSX.Element } = {
  sale: <SaleDark width={SIZE} height={SIZE} />,
  offer: <OfferDark width={SIZE} height={SIZE} />,
  listing: <ListingDark width={SIZE} height={SIZE} />,
  transfer: <TransferDark width={SIZE} height={SIZE} />,
  collection_offer: <CounterOfferDark width={SIZE} height={SIZE} />,
  mint: <MintDark width={SIZE} height={SIZE} />,
  all: <></>
};

const lightActivityIcons: { [key in ActivityType]: JSX.Element } = {
  sale: <SaleLight width={SIZE} height={SIZE} />,
  offer: <OfferLight width={SIZE} height={SIZE} />,
  listing: <ListingLight width={SIZE} height={SIZE} />,
  transfer: <TransferLight width={SIZE} height={SIZE} />,
  collection_offer: <CounterOfferLight width={SIZE} height={SIZE} />,
  mint: <MintLight width={SIZE} height={SIZE} />,
  all: <></>
};

export const ActivityIcon = ({ type }: ActivityProps): JSX.Element => {
  const mode = useTheme().palette.mode;
  return mode == 'dark' ? darkActivityIcons[type] : lightActivityIcons[type];
};
