/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ethers, utils } from 'ethers';
import { erc721 } from 'abi/erc721';
import { exchangeAbi } from 'abi/exchangeContract';
import { erc20 } from 'abi/erc20';
import config from 'config';
import {
  CancelledOfferTicket,
  CancelledSaleTicket,
  OffChainSaleInput,
  OfferInput,
  OfferTicket,
  SaleTicket
} from 'types/generated';

// Batch transfer erc721 tokens from differents collection with exchangeContract
export const batchTransfer721 = async (
  tokens: string[],
  to: string[],
  _id: string[],
  provider: ethers.providers.Web3Provider
) => {
  try {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(config.exchange, exchangeAbi, signer);

    const tx = await contract.batchTransferERC721(tokens, to, _id);
    const txResponse = await tx.wait();
    return txResponse;
  } catch (err) {
    throw new Error('Transfer failed');
  }
};

// Batch transfer erc1155 tokens from the same 1155 collection
export const batchTransfer1155 = async (
  tokens: string[],
  to: string[],
  _id: string[],
  amounts: string[],
  provider: ethers.providers.Web3Provider
) => {
  try {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(config.exchange, exchangeAbi, signer);

    const tx = await contract.batchTransferERC1155(tokens, to, _id, amounts);
    const txResponse = await tx.wait();
    return txResponse;
  } catch (err) {
    throw new Error('Transfer failed');
  }
};

export const convertOfferPriceInWei = (price: number) => {
  return ethers.utils.parseEther(price.toString()).toString();
};

export const bulkBuy = async (
  provider: ethers.providers.Web3Provider,
  tickets: SaleTicket[],
  price: string
) => {
  const signer = provider.getSigner();
  const contract = new ethers.Contract(config.exchange, exchangeAbi, signer);

  const options = {
    value: ethers.utils.parseUnits(price, 'wei').toString()
  };

  const tx = await contract.bulkBuy(tickets, options);
  return tx;
};

// function to swap avax into Wavax
export const swapAvax = async (provider: ethers.providers.Web3Provider, amount: string) => {
  const signer = provider.getSigner();
  const contract = new ethers.Contract(config.wavax, erc20, signer);
  try {
    const tx = await contract.deposit({
      value: utils.parseEther(amount)
    });
    const txResponse = await tx.wait();
    return txResponse;
  } catch (err) {
    throw new Error('Swap failed');
  }
};

// function to swap wavax into avax
export const swapWavax = async (provider: ethers.providers.Web3Provider, amount: string) => {
  const signer = provider.getSigner();
  const contract = new ethers.Contract(config.wavax, erc20, signer);

  try {
    const tx = await contract.withdraw(ethers.utils.parseEther(amount));
    const txResponse = await tx.wait();
    return txResponse;
  } catch (err) {
    throw new Error('Swap failed');
  }
};

export const bulkAcceptOffers = async (
  provider: ethers.providers.Web3Provider,
  tickets: OfferTicket[]
) => {
  try {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(config.exchange, exchangeAbi, signer);
    const tx = await contract.bulkOffersAccept(tickets);
    const txResponse = await tx.wait();
    return txResponse;
  } catch (err) {
    throw new Error('Accepting offer failed');
  }
};

export const bulkCancelSales = async (
  provider: ethers.providers.Web3Provider,
  ticket: CancelledSaleTicket
) => {
  try {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(config.exchange, exchangeAbi, signer);
    const tx = await contract.cancelSale(ticket);
    const txResponse = await tx.wait();

    return txResponse;
  } catch (err) {
    throw new Error('Cancelling sales failed');
  }
};

export const bulkCancelOffers = async (
  provider: ethers.providers.Web3Provider,
  ticket: CancelledOfferTicket
) => {
  try {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(config.exchange, exchangeAbi, signer);
    const tx = await contract.cancelOffer(ticket);
    const txResponse = await tx.wait();
    return txResponse;
  } catch (err) {
    throw new Error('Cancelling offer failed');
  }
};

export const verifyOwnershipSignature = async (
  provider: ethers.providers.Web3Provider,
  nonce: string
) => {
  const signer = provider.getSigner();
  return signer.signMessage(nonce);
};

export const approveWavax = async (
  provider: ethers.providers.Web3Provider,
  buyer: string,
  price: number,
  quantity: number
) => {
  const signer = provider.getSigner();
  const wavax = new ethers.Contract(config.wavax, erc20, signer);

  const balance = await wavax.balanceOf(buyer);
  if (price * quantity > balance) {
    throw new Error('Insufficient funds');
  }

  const allowance = await wavax.allowance(buyer, config.exchange);

  if (price * quantity > Number(ethers.utils.formatUnits(allowance)))
    //note: we catch if the user cancels the approve
    return await wavax
      .approve(
        config.exchange,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff' // note: approve infinite ammount of erc20
      )
      .catch(() => {
        throw new Error('Approval rejected by the user');
      });
};

export const approveAll = async (
  provider: ethers.providers.Web3Provider,
  account: string,
  collections: string[],
  update: (approved?: number) => void
) => {
  const signer = provider.getSigner();
  // Error here (no signer)
  if (!signer) throw new Error('Undefined signer');
  // Get all collections contracts to be approved
  const contracts: ethers.Contract[] = collections.map((contract) => {
    return new ethers.Contract(contract, erc721, signer);
  });

  const values = await Promise.all(
    contracts.map(async (contract) => await contract.isApprovedForAll(account, config.exchange))
  );

  // Count already approved collections
  const approved = values.filter((value) => value).length;
  // Update the UI
  update(approved);

  try {
    // Approve 'not already approved' collections
    for (let i = 0; i < values.length; i++) {
      if (!values[i]) {
        const response = await contracts[i].setApprovalForAll(config.exchange, true);
        await response.wait();
        update();
      }
    }
  } catch (err: any) {
    throw new Error('Approve failed!');
  }
};

export const offerSignature = async (
  offer: OfferInput,
  provider: ethers.providers.Web3Provider
) => {
  try {
    const signer = provider.getSigner();
    return await signer._signTypedData(domainData, OfferType, offer);
  } catch (err) {
    throw new Error('User denied message signature');
  }
};

export const saleSignature = async (
  sale: OffChainSaleInput,
  provider: ethers.providers.Web3Provider
) => {
  try {
    const signer = provider.getSigner();
    return await signer._signTypedData(domainData, SaleType, sale);
  } catch (err) {
    throw new Error('User denied message signature');
  }
};

const SaleType = {
  Sale: [
    { name: 'seller', type: 'address' },
    { name: 'token_address', type: 'address' },
    { name: 'token_id', type: 'uint256' },
    { name: 'in_sale_amount', type: 'uint256' },
    { name: 'unitary_price', type: 'uint256' },
    { name: 'expiration_ts', type: 'uint256' },
    { name: 'starting_ts', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'chain_id', type: 'uint256' },
    { name: 'managed', type: 'bool' }
  ]
};

const domainData = {
  name: 'Kalao Exchange',
  version: '1'
};

/*
const ManagedSaleType = {
  Sale: [
    { name: 'seller', type: 'address' },
    { name: 'token_address', type: 'address' },
    { name: 'token_id', type: 'uint256' },
    { name: 'in_sale_amount', type: 'uint256' },
    //{ name: "unitary_price", type: "uint256" },
    { name: 'expiration_ts', type: 'uint256' },
    { name: 'starting_ts', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'chain_id', type: 'uint256' },
    { name: 'min_unitary_price', type: 'uint256' },
    { name: 'managed', type: 'bool' }
  ]
};
*/

const OfferType = {
  Offer: [
    { name: 'buyer', type: 'address' },
    { name: 'token_address', type: 'address' },
    { name: 'merkle_root', type: 'bytes32' },
    { name: 'payment_token', type: 'address' },
    { name: 'amount_to_buy', type: 'uint256' },
    { name: 'unitary_price', type: 'uint256' },
    { name: 'expiration_ts', type: 'uint256' },
    { name: 'starting_ts', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'chain_id', type: 'uint256' }
  ]
};
