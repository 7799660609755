// 3rd party libs
import React, { useEffect, useState } from 'react';
import { Stack, Typography, Grid, Divider, Box } from '@mui/material';
import { Button, Container, InputField } from 'components';
import LanguageIcon from '@mui/icons-material/Language';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ReactComponent as Discord } from '../../../assets/dash/discord.svg';
import { AssetKind, Collection } from 'types/generated';
import { useUpdateCollectionMutation, useUploadAssetMutation } from 'api';
import { useNotistack } from 'hooks';
import FileUploader from './FileUploader';
import { toPayload, validate } from './utils';

type Props = {
  myCollection: Collection;
};

const sx = {
  mt: 1,
  height: { xs: '3rem', sm: '3.5rem' },
  fontSize: 14,
  px: 2,
  borderRadius: '0.9375rem'
};

const Settings = ({ myCollection }: Props) => {
  const [collection, setCollection] = useState<Collection>(myCollection);
  const { success, error, info } = useNotistack();
  const [fileToUpload, setFileToUpload] = useState<File>();

  useEffect(() => {
    setCollection(myCollection);
  }, [myCollection]);

  // Mutations
  const [upload, { isLoading: isAssetLoading }] = useUploadAssetMutation();
  const [updateCollection, { isLoading: isSettingsLoading }] = useUpdateCollectionMutation();
  // Errors state
  const [errors, setErrors] = useState<Record<string, string>>({});

  const save = async () => {
    const { errors: err } = validate(collection);
    if (Object.keys(err).length !== 0) setErrors(err);
    else {
      info('Collection update in progress...', undefined, 6000);
      updateCollection({ input: toPayload(collection) })
        .unwrap()
        .then(() => success('Collection successfully updated'))
        .catch(() => error('Collection update failed !'));
      // Upload avatar
      if (fileToUpload) {
        upload({
          kind: AssetKind.CollectionAvatar,
          file: fileToUpload,
          contractAddress: collection.address
        })
          .unwrap()
          .then(() => success('Collection avatar successfully updated'))
          .catch(() => error('Collection avatar update failed !'));
      }
    }
  };

  const isSaving = isAssetLoading || isSettingsLoading;

  return (
    <Container style={{ filter: isSaving ? 'blur(0.2rem)' : '' }}>
      <Grid container direction="row" spacing={{ xs: 2, sm: 2, md: 4, lg: 10 }}>
        <Grid item xs={12} sm={7} md={8} lg={8}>
          <Stack spacing={3}>
            <Typography variant="h3" color="text.primary">
              Settings
            </Typography>
            <InputField
              sx={{ fontSize: 14, p: 2, borderRadius: '0.9375rem' }}
              rows={8}
              multiline={true}
              label={'Description'}
              value={collection?.description || ''}
              set={(v) => setCollection({ ...collection, description: v as string })}
              placeholder={'About the collection in a few words'}
              disabled={isSaving}
            />
            <Divider sx={{ my: 4 }} />
            <Typography variant="h4" color="text.primary" fontWeight={500}>
              Social networks
            </Typography>
            <InputField
              sx={sx}
              label={'Website'}
              value={collection?.website || ''}
              err={errors['website']}
              set={(v) => {
                if (errors['website']) setErrors({ ...errors, ['website']: '' });
                setCollection({ ...collection, website: v as string });
              }}
              placeholder={'Enter URL'}
              startAdornment={
                <LanguageIcon
                  fontSize="small"
                  sx={{ mr: 2, color: (theme) => theme.palette.text.secondary }}
                />
              }
              disabled={isSaving}
            />
            <InputField
              sx={sx}
              label={'Twitter'}
              value={collection?.twitter || ''}
              err={errors['twitter']}
              set={(v) => {
                if (errors['twitter']) setErrors({ ...errors, ['twitter']: '' });
                setCollection({ ...collection, twitter: v as string });
              }}
              placeholder={'Enter URL'}
              startAdornment={
                <TwitterIcon
                  fontSize="small"
                  sx={{ mr: 2, color: (theme) => theme.palette.text.secondary }}
                />
              }
              disabled={isSaving}
            />
            <InputField
              sx={sx}
              label={'Telegram'}
              value={collection?.telegram || ''}
              err={errors['telegram']}
              set={(v) => {
                if (errors['telegram']) setErrors({ ...errors, ['telegram']: '' });
                setCollection({ ...collection, telegram: v as string });
              }}
              placeholder={'Enter URL'}
              startAdornment={
                <TelegramIcon
                  fontSize="small"
                  sx={{ mr: 2, color: (theme) => theme.palette.text.secondary }}
                />
              }
              disabled={isSaving}
            />
            <InputField
              sx={sx}
              label={'Discord'}
              value={collection?.discord || ''}
              err={errors['discord']}
              set={(v) => {
                if (errors['discord']) setErrors({ ...errors, ['discord']: '' });
                setCollection({ ...collection, discord: v as string });
              }}
              placeholder={'Enter URL'}
              startAdornment={
                <Discord fontSize="small" width="18" height="18" style={{ marginRight: '1rem' }} />
              }
              disabled={isSaving}
            />
            <InputField
              sx={sx}
              label={'Instagram'}
              value={collection?.instagram || ''}
              err={errors['instagram']}
              set={(v) => {
                if (errors['instagram']) setErrors({ ...errors, ['instagram']: '' });
                setCollection({ ...collection, instagram: v as string });
              }}
              placeholder={'Enter URL'}
              startAdornment={
                <InstagramIcon
                  fontSize="small"
                  sx={{ mr: 2, color: (theme) => theme.palette.text.secondary }}
                />
              }
              disabled={isSaving}
            />
          </Stack>
        </Grid>
        <FileUploader
          avatar={collection?.avatar || ''}
          set={(avatar, file) => {
            setCollection({ ...collection, avatar });
            setFileToUpload(file);
          }}
          isSaving={isSaving}
        />
      </Grid>
      <Box sx={{ mt: 3 }}>
        <Button
          ctx="primary"
          onClick={save}
          size="large"
          sx={{ width: '8rem' }}
          disabled={isSaving}
        >
          <Typography variant="button">Update</Typography>
        </Button>
      </Box>
    </Container>
  );
};

export default Settings;
