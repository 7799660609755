// 3rd party libs
import React, { useState } from 'react';
import { Stack, Typography, Grid, Divider } from '@mui/material';
import { InputField } from 'components';
import LanguageIcon from '@mui/icons-material/Language';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ReactComponent as Discord } from '../../../../assets/dash/discord.svg';
import { useSettings } from '../context/settingsContext';
import FileUploader from '../FileUploader';
import { AssetKind } from 'types/generated';
import { useUpdateProfileMutation, useUploadAssetMutation } from 'api';
import { validateFormBtn, validate, getProfileInput } from '../utils';
import { Profile as ProfileType } from '../types';
import { useNotistack } from 'hooks';

const sx = {
  mt: 1,
  height: { xs: '3rem', sm: '3.5rem' },
  fontSize: 14,
  px: 2,
  borderRadius: '0.9375rem'
};

const Profile = () => {
  const { success, error, info } = useNotistack();
  const { profile, setProfile, hasProfileChanged } = useSettings();
  const [avatar, setAvatar] = useState<File>();

  // Mutations
  const [update] = useUpdateProfileMutation();
  const [upload] = useUploadAssetMutation();
  // Errors state
  const [errors, setErrors] = useState<Record<string, string>>({});

  const set = (key: keyof ProfileType, value: string) => {
    if (errors[key]) setErrors({ ...errors, [key]: '' });
    profile && setProfile({ ...profile, [key]: value });
  };

  const isValid = (profile?: ProfileType) => {
    const { errors } = validate(profile);
    if (Object.keys(errors).length !== 0) setErrors({ ...errors });
    else if (hasProfileChanged()) {
      info('No changes detected!');
    } else {
      update({ input: getProfileInput(profile, ['login', 'avatar', 'youtube']) })
        .unwrap()
        .then(() => {
          success('Profile updated successfully');
        })
        .catch(() => {
          error('Profile Update Failed!');
        });
      avatar &&
        upload({
          kind: AssetKind.ProfileAvatar,
          file: avatar
        })
          .unwrap()
          .then(() => {
            success('Profile avatar updated successfully');
          })
          .catch(() => {
            error('Profile avatar update Failed!');
          });
    }
  };

  return (
    <Grid container direction="row" spacing={{ xs: 2, sm: 2, md: 4, lg: 10 }}>
      <Grid item xs={12} sm={7} md={8} lg={8}>
        <Stack spacing={3}>
          <Typography variant="h3" color="text.primary">
            Profile
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You can set preferred display name, create your profile URL and manage other personal
            settings.
          </Typography>
          <Typography variant="h4" color="text.primary" fontWeight={500}>
            Account information
          </Typography>
          <InputField
            sx={sx}
            label={'Username'}
            value={profile?.name || ''}
            set={(v) => set('name', v as string)}
            placeholder={'Choose Your Username'}
          />
          <InputField
            sx={sx}
            label={'Email'}
            value={profile?.mail || ''}
            err={errors['mail']}
            set={(v) => set('mail', v as string)}
            placeholder={'Enter your email'}
          />
          <InputField
            sx={{ fontSize: 14, p: 2, borderRadius: '0.9375rem' }}
            rows={8}
            multiline={true}
            label={'About you'}
            value={profile?.description || ''}
            set={(v) => set('description', v as string)}
            placeholder={'About yourself'}
          />
          <Divider sx={{ my: 4 }} />
          <Typography variant="h4" color="text.primary" fontWeight={500}>
            Socials
          </Typography>
          <InputField
            sx={sx}
            label={'Twitter'}
            value={profile?.twitter || ''}
            err={errors['twitter']}
            set={(v) => set('twitter', v as string)}
            placeholder={'Enter URL'}
            startAdornment={
              <TwitterIcon
                fontSize="small"
                sx={{ mr: 2, color: (theme) => theme.palette.text.secondary }}
              />
            }
          />
          <InputField
            sx={sx}
            label={'Telegram'}
            value={profile?.telegram || ''}
            err={errors['telegram']}
            set={(v) => set('telegram', v as string)}
            placeholder={'Enter URL'}
            startAdornment={
              <TelegramIcon
                fontSize="small"
                sx={{ mr: 2, color: (theme) => theme.palette.text.secondary }}
              />
            }
          />
          <InputField
            sx={sx}
            label={'Discord'}
            value={profile?.discord || ''}
            err={errors['discord']}
            set={(v) => set('discord', v as string)}
            placeholder={'Enter URL'}
            startAdornment={
              <Discord fontSize="small" width="18" height="18" style={{ marginRight: '1rem' }} />
            }
          />
          <InputField
            sx={sx}
            label={'Portfolio Or Website'}
            value={profile?.website || ''}
            err={errors['website']}
            set={(v) => set('website', v as string)}
            placeholder={'Enter URL'}
            startAdornment={
              <LanguageIcon
                fontSize="small"
                sx={{ mr: 2, color: (theme) => theme.palette.text.secondary }}
              />
            }
          />
          <InputField
            sx={sx}
            label={'Instagram'}
            value={profile?.instagram || ''}
            err={errors['instagram']}
            set={(v) => set('instagram', v as string)}
            placeholder={'Enter URL'}
            startAdornment={
              <InstagramIcon
                fontSize="small"
                sx={{ mr: 2, color: (theme) => theme.palette.text.secondary }}
              />
            }
          />
          <InputField
            sx={sx}
            label={'Facebook'}
            value={profile?.facebook || ''}
            err={errors['facebook']}
            set={(v) => set('facebook', v as string)}
            placeholder={'Enter URL'}
            startAdornment={
              <FacebookIcon
                fontSize="small"
                sx={{ mr: 2, color: (theme) => theme.palette.text.secondary }}
              />
            }
          />
          {validateFormBtn('Update', () => isValid(profile))}
        </Stack>
      </Grid>
      <FileUploader
        avatar={profile?.avatar || ''}
        set={(url, file) => {
          set('avatar', url);
          setAvatar(file);
        }}
      />
    </Grid>
  );
};

export default Profile;
