// 3rd
import { useEffect, useState, useRef } from 'react';
// Api
import { useLazyArtistsQuery } from 'api';

// Types
import { ArtistInfo, SearchTarget } from 'types/generated';
import { NumberOrString } from 'types';

/**
 * Hook to query collections
 */

type Props = {
  // page size (paging offset)
  size: number;
  keywords?: string;
  categories?: NumberOrString[];
  // Skip if true
  skip: boolean;
};

type Response = {
  artists: ArtistInfo[];
  error?: string | unknown;
  isLoading: boolean;
  isFetching?: boolean;
  loadMore: (reset?: boolean) => void;
  refresh: () => void;
};

const useArtists = ({ keywords, categories, skip, size }: Props): Response => {
  const hasMore = useRef(true);
  const shouldReset = useRef(true);
  const page = useRef(1);
  const [search, result] = useLazyArtistsQuery();
  const [artists, setArtists] = useState<ArtistInfo[]>([]);

  const trigger = () =>
    search({
      input: {
        keywords,
        category_tags: categories?.map((c) => c.toString()),
        target: SearchTarget.Artists
      },
      pagination: {
        page: page.current++,
        size
      }
    });

  useEffect(() => {
    if (!skip) trigger();
  }, []);

  useEffect(() => {
    shouldReset.current = true;
    hasMore.current = true;
    page.current = 1;
    trigger();
  }, [keywords, categories]);

  useEffect(() => {
    if (!result.isSuccess) return;
    if (shouldReset.current) {
      shouldReset.current = false;
      hasMore.current = result.data.search.hasMore;
      setArtists(result.data.search.artists);
    } else {
      hasMore.current = result.data.search.hasMore;
      setArtists([...artists, ...result.data.search.artists]);
    }
  }, [result.data]);

  const refresh = () => {
    page.current = 1;
    trigger();
  };

  const loadMore = () => {
    if (hasMore.current) trigger();
    trigger();
  };

  return {
    artists,
    error: result.error && 'Failed to load data',
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    loadMore,
    refresh
  };
};

export default useArtists;
