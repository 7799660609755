export const AppRoutes = {
  home: '/',
  explore: 'explore',
  /*
   * explore sub routes
   */
  explore_collections: 'explore/collections',
  explore_nfts: 'explore/nfts',
  explore_artists: 'explore/artists',
  explore_galleries: 'explore/galleries',

  /** collection page */
  collection_page: 'collection/:address',

  /** gallery page */
  gallery_page: 'gallery/:sharecode',

  /** User/Artist (dashboard) */
  dashboard: 'dashboard',
  favorites: 'dashboard/liked',
  settings: 'dashboard/settings',
  my_collectbles: 'dashboard/owned',

  /**
   * Public profile (artits, users, etc...)
   */
  profile: 'profile/:address',

  vision: 'vision',
  rewards: 'rewards',
  ranking: 'stats/rankings',
  activity: 'stats/activity',
  nft: 'nft/:address',

  /**
   * Create NFT
   */
  create: 'create',

  /**
   * Transfer NFT
   */
  transfer: 'transfer',
  /**
   * Sell NFTs
   */
  sell: 'sell'
};
