import React from 'react';
import { Typography, BoxProps, Box, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar } from 'components';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'fit-content',
  padding: theme.spacing(1, 0),
  '& .quantity': {
    fontSize: 15,
    position: 'absolute',
    top: 0,
    right: -2,
    background: '#3D3AE9',
    borderRadius: '50%',
    width: '1.4rem',
    height: '1.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF'
  }
}));

type Props = {
  thumbnail: string;
  quantity?: number;
  size?: number;
  remove?: () => void;
  // Remove icon position
  removeLeft?: boolean;
} & BoxProps;

const Miniature = ({ thumbnail, remove, removeLeft, quantity = 1, size = 4, ...rest }: Props) => {
  const position = removeLeft ? { left: -2 } : { right: -2 };
  return (
    <Container {...rest}>
      <Avatar
        src={thumbnail}
        sx={{
          width: `${size}rem`,
          height: `${size}rem`,
          borderRadius: '0.6rem',
          border: (theme) => theme.border
        }}
      />
      {remove && (
        <CloseIcon
          sx={{
            fontSize: 20,
            position: 'absolute',
            cursor: 'pointer',
            top: 1,
            ...position,
            borderRadius: '50%',
            padding: 0.3,
            fontWeight: 900,
            background: (theme) =>
              theme.palette.mode == 'dark' ? theme.colors.lightBalck : theme.palette.grey[400],
            border: (theme) => theme.border
          }}
          onClick={() => remove && remove()}
        />
      )}
      {quantity > 1 && (
        <Box className="quantity">
          <Typography variant="caption" fontWeight={600}>
            {quantity}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Miniature;
