import { NFTSale } from 'types';
import { SaleKind, SaleStatus } from 'types/generated';
import { Sale } from './type';

export const validate = (s: Sale[]) => {
  let valid = true;
  const sales = [...s];
  sales.forEach((sale) => {
    if (!sale.price) {
      valid = false;
      sale.error['price'] = 'Enter price';
    } else if (Number(sale.quantity) > sale.qty) {
      valid = false;
      sale.error['quantity'] = `Max ${sale.balance}`;
    }
  });
  return {
    valid,
    sales
  };
};

export const toNFTSales = (sales: Sale[], startDate: number): NFTSale[] => {
  const nftSales: NFTSale[] = [];
  sales.forEach((s) => {
    nftSales.push({
      ...s,
      sale: {
        expiration_date: new Date().getTime(),
        floor_diff: '0',
        kind: SaleKind.Direct,
        sale_id: '',
        seller: '',
        start_date: startDate,
        status: SaleStatus.Live,
        unitary_price: '0',
        quantity: s.quantity as string,
        unitary_price_float: Number(s.price)
      }
    });
  });
  return nftSales;
};
