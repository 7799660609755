/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { ActivityType } from 'types/generated';
import { SortState } from 'types';
import { Box, Link, Stack, styled, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as Approved } from '../../assets/certified.svg';
import { formatDistance, fromUnixTime } from 'date-fns';

export const typeRenderer: { [key in ActivityType]: string } = {
  all: 'All',
  collection_offer: 'Collection Offer',
  listing: 'Listing',
  mint: 'Mint',
  offer: 'Offer',
  sale: 'Sale',
  transfer: 'Transfer'
};

export const EmptyPlaceHolder = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(5)
}));

export const collectionRenderer = (
  name: string,
  certified: boolean,
  goTo: (page: 'nft' | 'collection' | 'user', id: string) => void,
  address: string
) => {
  return (
    <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
      <Typography
        variant="caption"
        color="text.secondary"
        onClick={() => goTo('collection', address)}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          cursor: 'pointer'
        }}
      >
        {name || '--'}
      </Typography>
      {certified && (
        <SvgIcon sx={{ width: '1rem' }}>
          <Approved />
        </SvgIcon>
      )}
    </Stack>
  );
};

export const blockExplorer = (explorer: string, hash: string, timestamp: number) => {
  return (
    <Link
      target="_blank"
      sx={{
        minWidth: 0,
        textDecoration: 'none'
      }}
      href={`${explorer}/${hash}`}
    >
      <Typography
        variant="body2"
        align="right"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {formatDistance(fromUnixTime(timestamp), new Date(), { addSuffix: true })}
      </Typography>
    </Link>
  );
};

export const initSortState = (): Record<string, SortState> => {
  return {
    type: SortState.unsorted,
    name: SortState.unsorted,
    price: SortState.unsorted,
    timestamp: SortState.unsorted,
    quantity: SortState.unsorted
  };
};
