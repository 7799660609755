import { Middleware } from 'redux';
import { GlobalState } from 'store';
import * as Actions from './actions';

/**
 *
 * Controls the wallet is valid (connected to the right netwek)
 * ... before processing any actions that requires user signature
 */

const walletMiddleware: Middleware<
  {
    /** We don't need this, thus, keeping it empty */
  },
  GlobalState
> = (storeApi) => (next) => (action) => {
  const isWalletValid = storeApi.getState().state.isWalletValid;
  if (!isWalletValid && Actions.batch.test(action)) {
    storeApi.dispatch(Actions.openModal.create({ modal: 'WALLET' }));
  } else return next(action);
};

export default walletMiddleware;
