import * as React from 'react';
import { Skeleton, Typography, TypographyProps } from '@mui/material';
import { useCountDown } from 'hooks';

type Props = {
  targetDate: Date;
  isLoading?: boolean;
} & TypographyProps;

const CountDown = ({ targetDate, isLoading, ...rest }: Props) => {
  const { days, hours, minutes, seconds } = useCountDown(targetDate);

  let format_days = '';
  if (days > 0) {
    format_days = (days < 10 ? `${days}D` : days) + ' ';
  }

  const format_hours = hours < 10 ? `0${hours}` : String(hours);
  const format_minutes = minutes < 10 ? `0${minutes}` : String(minutes);
  const format_seconds = seconds < 10 ? `0${seconds}` : String(seconds);

  return (
    <Typography {...rest}>
      {isLoading ? (
        <Skeleton width={100} />
      ) : (
        `${format_days}${format_hours}:${format_minutes}:${format_seconds}`
      )}
    </Typography>
  );
};

export default CountDown;
