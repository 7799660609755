import React from 'react';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Badge, Box, BoxProps, styled, Typography } from '@mui/material';
import { Avatar } from 'components';

type Props = {
  onClick: () => void;
  maxChips: number;
  thumbnails: string[];
} & BoxProps;

const BasketContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1, 1, 0, 0),
  position: 'sticky',
  bottom: '2%',
  left: '95%',
  borderRadius: '5.625rem',
  background: theme.input.background,
  width: 'fit-content',
  cursor: 'pointer',
  zIndex: 10,
  ':hover': {
    background: theme.palette.action.hover
  }
}));

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    right: 15,
    top: 10,
    background: '#3D3AE9',
    color: 'white'
  }
});

const renderer = (key: string, src: string) => <Avatar key={key} src={src} />;

const Chips = ({ thumbnails, maxChips, onClick, ...rest }: Props) => {
  const count = thumbnails.length - maxChips;

  return (
    <BasketContainer onClick={onClick} {...rest}>
      <StyledBadge
        color="secondary"
        badgeContent={count > 0 ? <Typography variant="body2">+{count}</Typography> : undefined}
      >
        <AvatarGroup spacing="small" sx={{ p: '1rem', pl: '2rem' }}>
          {thumbnails
            .slice(0, maxChips)
            .map((thumbnail, index) => renderer(`${thumbnail}-${index}`, thumbnail))}
        </AvatarGroup>
      </StyledBadge>
    </BasketContainer>
  );
};

export default Chips;
