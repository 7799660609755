import { Box, IconButton, SvgIcon } from '@mui/material';
import { ExploreNFTCard } from 'components';
import { AppRoutes } from 'constants/routes';
import React from 'react';
import { NftPreview, SaleKind, SaleStatus } from 'types/generated';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ReactComponent as Check } from '../assets/icons/check.svg';

type Props = {
  nft: NftPreview;
  liked?: boolean;
  hideAvatar?: boolean;
  owned?: boolean;
  isLoading?: boolean;
  goTo: () => void;
  goToCollection?: () => void;
  onLike?: () => void;
  showBalance?: boolean;
};

export const goToNft = (assetId: string) => `/${AppRoutes.nft.replace(':address', assetId)}`;

export const InnerItem = React.memo(
  ({
    nft,
    liked,
    goTo,
    goToCollection,
    onLike,
    hideAvatar,
    owned,
    isLoading,
    showBalance
  }: Props) => {
    return (
      <ExploreNFTCard
        nft={nft}
        owned={owned}
        hideAvatar={hideAvatar}
        liked={liked}
        goTo={goTo}
        goToCollection={goToCollection}
        onLike={onLike}
        isLoading={isLoading}
        showBalance={showBalance}
      />
    );
  }
);

InnerItem.displayName = 'NFTS';

/***
 * NFT selector to add/remove an nft to/from the basket
 */
type SelectorProps = {
  isSelected: boolean;
  click: () => void;
  // Owner can't add his assets to basket or buy them
  skip: boolean;
  className: string;
};

export const Selector = ({ isSelected, click, skip, className }: SelectorProps) => {
  const onSelected = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    click();
  };

  if (skip) return null;
  return (
    <Box className={className}>
      <IconButton size="small" onClick={onSelected}>
        {isSelected ? (
          <SvgIcon
            style={{ width: 35, height: 35, background: '#fff', borderRadius: '50%', padding: 1.5 }}
          >
            <Check />
          </SvgIcon>
        ) : (
          // Only direct sales can be added to the cart
          <AddCircleIcon sx={{ fontSize: 35 }} />
        )}
      </IconButton>
    </Box>
  );
};

export const initNftWithSale: NftPreview = {
  asset_id: '',
  collection: { address: '', avatar: '', certified: false, name: '', thumbnails: [] },
  likes: 0,
  name: '',
  thumbnail: '',
  token_id: '',
  sale: {
    expiration_date: 0,
    floor_diff: '0',
    kind: SaleKind.All,
    quantity: '',
    sale_id: '',
    seller: '',
    start_date: 0,
    status: SaleStatus.All,
    unitary_price: '',
    unitary_price_float: 0
  }
};

export const initNft: NftPreview = {
  asset_id: '',
  collection: { address: '', avatar: '', certified: false, name: '', thumbnails: [] },
  likes: 0,
  name: '',
  thumbnail: '',
  token_id: ''
};
