// Corresponds to the deployed environment
export enum Environment {
  dev = 'dev',
  production = 'production'
}

interface Config {
  api: string;
  env: Environment;
  exchange: string;
  null_merkleroot: string;
  wavax: string;
  klo: string;
  default_avatar: string;
  dev_deeplink: string;
  prod_deeplink: string;
}

interface EnvVarConfig {
  // The ENV variable name as defined in the `.env`
  env: string;
  // Whether an error should be thrown if this variable was not provided on
  // build / run
  required?: boolean;
  // The key the processed variable should be available at in the
  // resulting config object.
  // e.g. config.wallet
  key: string;
  // If there should be a default, provide it here. This will be evaluated
  // _before_ the `required`.
  default?: string;
  // Transformation function, a function which will accept the string
  // environment variable and output it however it wants it to be inserted into
  // the resulting `config` object
  transform?: (value: string) => any;
  // Validation function, a function which will accept the final transformed
  // value and return whether it is valid (`true`) or not (`false`). If it's
  // found to be invalid, the parser will throw a validation error.
  validation?: (value: any) => boolean;
}

// Edit the below specification to add more environment variables. It has the
// properties defined in `EnvVarConfig`.
const envVars: EnvVarConfig[] = [
  {
    env: 'REACT_APP_API',
    default: 'https://gql.dev.kalao.io',
    key: 'api',
    validation: (v) => typeof v === 'string' && v !== ''
  },
  {
    env: 'REACT_APP_ENV',
    default: 'dev',
    key: 'env',
    validation: (v) => typeof v === 'string' && v in Environment
  },
  {
    env: 'REACT_APP_EXCHANGE_ADDRESS',
    default: '0xc7B0a494eaE1aFf383f5D27DCBC67559392e5671',
    key: 'exchange',
    validation: (v) => typeof v === 'string' && v !== ''
  },
  {
    env: 'REACT_APP_NULL_MERKLEROOT',
    default: '0x0000000000000000000000000000000000000000000000000000000000000000',
    key: 'null_merkleroot',
    validation: (v) => typeof v === 'string' && v !== ''
  },
  {
    env: 'REACT_APP_WAVAX_ADDRESS',
    default: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
    key: 'wavax',
    validation: (v) => typeof v === 'string' && v !== ''
  },
  {
    env: 'REACT_APP_KLO_ADDRESS',
    default: '0xb27c8941a7Df8958A1778c0259f76D1F8B711C35',
    key: 'klo',
    validation: (v) => typeof v === 'string' && v !== ''
  },
  {
    env: 'REACT_APP_DEFAULT_AVATAR',
    default: 'https://storage.kalao.io/assets-v2/default-avatar.png',
    key: 'default_avatar',
    validation: (v) => typeof v === 'string' && v !== ''
  },
  {
    env: 'REACT_APP_DEV_DEEPLINK',
    key: 'dev_deeplink',
    validation: (v) => typeof v === 'string' && v !== ''
  },
  {
    env: 'REACT_APP_PROD_DEEPLINK',
    key: 'prod_deeplink',
    validation: (v) => typeof v === 'string' && v !== ''
  }
];

const config: Config = envVars.reduce(
  (
    config: Partial<Config>,
    {
      env,
      required = true,
      default: def,
      key,
      transform = (v: string) => v,
      validation = () => true
    }: EnvVarConfig
  ) => {
    let value = process.env[env];
    if (!value && def !== undefined) {
      value = def;
    }

    if (required && !value) {
      throw new Error(`required environment variable "${env}" not set.`);
    }

    value = transform(value!);

    if (!validation(value)) {
      throw new Error(`environment variable "${env}" is invalid.`);
    }

    config = { ...config, [key]: value };

    return config;
  },
  {}
) as Config;

export default config;
