import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { NFTSale, StepStatus } from 'types';
import Step from '../Step';
import { useAuthorizeBasketSaleMutation } from 'api';
import { useWeb3React } from '@web3-react/core';
import {
  toBackendBasket,
  buildBasketFromSale,
  calulateBasketPrice,
  checkBasketRequest
} from './utils';
import { bulkBuy } from 'services/exchange';
import { useNetwork } from 'hooks';
import { SaleTicket } from 'types/generated';
import { useDispatch } from 'react-redux';
import { clearBasket } from 'store/actions';
import { Done } from './Done';

type Props = {
  step: number;
  currentStep: number;
  sale?: NFTSale | null;
  basket?: Map<string, NFTSale>;
  // callback, called once the step has been completed successfully
  complete: () => void;
};

const progress = () => {
  return (
    <Typography variant="body2" color="text.secondary">
      Please confirm the transaction in your wallet to proceed
    </Typography>
  );
};

const Purchase = ({ step, currentStep, complete, sale, basket }: Props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  const [txHash, setTxHash] = useState('');
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const { network } = useNetwork();

  const { account, provider } = useWeb3React();
  const [checkBasket] = useAuthorizeBasketSaleMutation();

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
      confirmTransation();
    }
  }, [currentStep]);

  const confirmTransation = async () => {
    if (!account || !provider) {
      setStatus(StepStatus.ERROR);
      setError('Unauthenticated');
      return;
    }

    try {
      setStatus(StepStatus.IN_PROGRESS);
      const basketToValidate = sale ? buildBasketFromSale(sale) : toBackendBasket(basket);

      const response = await checkBasket({
        input: checkBasketRequest(basketToValidate, account)
      }).unwrap();

      const { sales_tickets, invalidated_uuids } = response.authorizeBasket;

      if (invalidated_uuids?.length > 0) {
        throw new Error('Validation failed !');
      }

      const result = await bulkBuy(
        provider,
        sales_tickets as SaleTicket[],
        calulateBasketPrice(sales_tickets as SaleTicket[])
      );
      // Wait for transaction to be completed
      if (result?.wait) await result.wait();

      if (result?.hash) {
        setStatus(StepStatus.DONE);
        setTxHash(result?.hash);
        dispatch(clearBasket.create({}));
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Purchase failed !');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={
        status == StepStatus.DONE ? (
          <Done explorer={network.blockExplorerUrls[0]} hash={txHash} sale={sale} basket={basket} />
        ) : (
          'Complete Purchase'
        )
      }
      step={step}
      currentStep={currentStep}
      status={status}
      el={status == StepStatus.IN_PROGRESS ? progress() : undefined}
      error={error}
    />
  );
};

export default Purchase;
