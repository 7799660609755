import React, { useEffect, useRef } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { getOptions, processData, Data } from './utils';

ChartJS.register(...registerables);
ChartJS.defaults.font.weight = 'bold';

type Props = {
  data: Data;
  displayYaxis?: boolean;
  displayXaxis?: boolean;
  enableTooltip?: boolean;
  lineOnHover?: boolean;
};

const Chart = ({ data, displayYaxis, displayXaxis, enableTooltip, lineOnHover = true }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chart = useRef<ChartJS>();

  useEffect(() => {
    if (canvasRef.current) {
      chart.current = new ChartJS(canvasRef.current, {
        plugins: lineOnHover
          ? [
              {
                id: 'chart',
                afterDraw: (chart: any) => {
                  if (chart.tooltip?._active?.length) {
                    const x = chart.tooltip._active[0].element.x;
                    const yAxis = chart.scales.y;
                    const ctx = chart.ctx;
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, yAxis.top);
                    ctx.lineTo(x, yAxis.bottom);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = '#E5E5E5';
                    ctx.stroke();
                    ctx.restore();
                  }
                }
              }
            ]
          : undefined,
        data: processData(data),
        options: getOptions({
          title: 'linechart',
          displayYaxis,
          displayXaxis,
          enableTooltip
        })
      });
    }
    return () => {
      chart.current && chart.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (chart.current) {
      const d = chart.current.data;

      for (let index = 0; index < data.datasets.length; index++) {
        d.datasets[index].data = [...data.datasets[index].data];
        d.datasets[index].label = data.datasets[index].label;
      }
      chart.current.update();
    }
  }, [data]);

  return <canvas ref={canvasRef} style={{ height: '100%', width: '100%' }} />;
};

export default Chart;
