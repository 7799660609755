import { useWeb3React } from '@web3-react/core';
import config, { Environment } from 'config';
import { ethers } from 'ethers';
import { useState } from 'react';
/**
 *
 * This hook is used to enforce only one network,
 * ... e.g depending on the environment, e.g dev => fuji, prod => mainnet
 */

const AVAX = {
  name: 'Avalanche',
  symbol: 'AVAX',
  decimals: 18
};

const mainnet = {
  chainName: 'Avalanche Network',
  rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
  nativeCurrency: AVAX,
  chainId: ethers.utils.hexlify(43114),
  blockExplorerUrls: [
    'https://snowtrace.io/tx',
    'https://snowtrace.io/address/',
    'https://snowtrace.io/token/'
  ]
};

const testnet = {
  chainName: 'Avalanche FUJI C-Chain',
  rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
  nativeCurrency: AVAX,
  chainId: ethers.utils.hexlify(43113),
  blockExplorerUrls: [
    'https://testnet.snowtrace.io/tx',
    'https://testnet.snowtrace.io/address/',
    'https://testnet.snowtrace.io/token/'
  ]
};

const useNetwork = () => {
  const { chainId } = useWeb3React();
  const [supportedChainId, _] = useState(config.env == Environment.dev ? 43113 : 43114);
  return {
    supportedChainId,
    network: config.env == Environment.dev ? testnet : mainnet,
    isValid: supportedChainId == chainId
  };
};

export default useNetwork;
