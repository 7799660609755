import { styled } from '@mui/material';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  height: '5rem',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  '.close': {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    ':hover': { color: theme.palette.text.primary }
  }
}));
