import * as React from 'react';
import Box from '@mui/material/Box';
import { Tabs } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';

const tabs: { label: string; value: string }[] = [
  { label: 'Top Collections', value: 'collections' },
  { label: 'Top Users', value: 'users' }
];

export const RankingTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getCurrentTab = (path: string) => {
    const index = tabs.findIndex((tab) => path.includes(tab.value));
    return index > -1 ? index : undefined;
  };

  return (
    <Box sx={{ borderBottom: (theme) => theme.border }}>
      <Tabs
        tabs={tabs}
        currentTab={getCurrentTab(location.pathname)}
        onTabChange={(tab) => navigate(tab)}
      />
    </Box>
  );
};
