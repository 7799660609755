import React, { useMemo, useState } from 'react';
import { CollectionsRanking, Container } from 'components';
import { useCollectionRanking } from 'hooks';
import { Pagination } from '../Pagination';
import { toCollectionsRanking } from 'helpers/rankingHelpers';
import { useFilters } from 'providers';
import { DaysFilter } from 'types/generated';

const SIZE = 100;

export default function Collections() {
  const [page, setPage] = useState(1);
  const { categories, period } = useFilters();
  const { data, error, count, isFetching } = useCollectionRanking({
    days_filter: (period as DaysFilter) || DaysFilter.All,
    category_tags: categories,
    page,
    size: SIZE
  });
  const rankings = useMemo(() => {
    return toCollectionsRanking(data);
  }, [data]);

  return (
    <Container>
      <CollectionsRanking
        loading={isFetching}
        rankings={rankings}
        skeletonCount={SIZE}
        footer={
          <Pagination
            count={count}
            size={rankings.length}
            page={page}
            onChange={(_, page) => setPage(page)}
          />
        }
      />
    </Container>
  );
}
