// 3rd party libs
import React, { useEffect } from 'react';
import { Box } from '@mui/material';

// Compononent
import NFTOverview from './Overview';
import NFTDetails from './Details';
import NFTsSwiper from './MoreNFTs';
import { useParams } from 'react-router-dom';
import { AddressRouteParams } from 'types';
import { useNftQuery } from 'api';
import { toDetails } from './utils';
import { NftDetails } from 'types/generated';
import { Container } from 'components';

const NFT = () => {
  const { address } = useParams<AddressRouteParams>();
  const { data, isFetching } = useNftQuery({ assetId: address || '' }, { skip: !address });
  const {
    base,
    collection,
    metadata,
    stats,
    interactions,
    owner,
    creator,
    attributes,
    moreNfts,
    best_offer,
    sale,
    assets
  } = toDetails(data?.nft as NftDetails);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <Container>
      <NFTOverview
        base={base}
        collection={collection}
        stats={stats}
        interactions={interactions}
        metadata={metadata}
        loading={isFetching}
        bestOffer={best_offer}
        sale={sale}
        assets={assets}
      />
      <NFTDetails
        base={base}
        stats={stats}
        collection={collection}
        metadata={metadata}
        creator={creator}
        owner={owner}
        attributes={attributes}
        loading={isFetching}
      />
      <NFTsSwiper nfts={moreNfts} />
    </Container>
  );
};

export default NFT;
