// 3rd party libs
import React from 'react';
import { Stack, Typography } from '@mui/material';

export const Banner = () => {
  return (
    <Stack spacing={2} sx={{ mt: { md: 3, lg: 5 }, mb: 5 }}>
      <Typography variant="h1" textAlign="center">
        Choose Type
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" textAlign="center">
        Choose “Single” for one of a kind or “Multiple” if you want to sell one collectible multiple
        times
      </Typography>
    </Stack>
  );
};
