import React from 'react';
import { Box, Typography, styled, Stack, Divider } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SquareIconButton, IconFactory } from 'components';
import { PrimaryButton } from '../utils';
import { KeyValuePair } from 'types';
import Overview from '../Overview';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});

const data: KeyValuePair<string, string>[] = [
  { key: 'Staking APR', value: '116.00 %' },
  { key: 'Staking APR', value: '88 982.12 PGL' },
  { key: 'Staking APR', value: '0.00 PGL' }
];

const Pgl = () => {
  return (
    <Stack>
      <Container sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconFactory icon="pgl" size={20} />
          <Typography variant="h4" color="text.primary" fontWeight={500} sx={{ flex: 1 }}>
            Token PGL (KLO-AVAX)
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <PrimaryButton caption="Deposit" click={() => undefined} />
          <SquareIconButton size="medium">
            <RefreshIcon fontSize="medium" />
          </SquareIconButton>
        </Box>
      </Container>
      <Divider sx={{ my: 2, background: (theme) => theme.colors.lightGrey3 }} />
      <Overview data={data} sm={4} />
    </Stack>
  );
};

export default Pgl;
