import React, { useState } from 'react';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FilterList, SearchInput, SquareIconButton } from 'components';
import { FilterOption, KeyValuePair } from 'types';
import { useCategoriesQuery } from 'api';
import { useFilters } from 'providers';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  activeFilters: FilterOption[];
  search?: boolean;
  sorting?: JSX.Element;
};

const MobileFilters = ({ activeFilters, search, sorting }: Props) => {
  const { keywords, setKeywords } = useFilters();
  const [open, setOpen] = useState(false);
  const categoriesPairs: KeyValuePair<string, string>[] = [];

  // call api to get all categories
  const { data, error } = useCategoriesQuery();
  // TODO: handle errors
  if (error) console.log('error');
  if (data) {
    data.categories.forEach((cat) => {
      categoriesPairs.push({ key: cat?.tag, value: cat?.name });
    });
  }

  return (
    <>
      <Stack spacing={2} sx={{ width: '-webkit-fill-available', mt: 1 }}>
        {search && <SearchInput keyword={keywords} set={setKeywords} />}
        <Stack direction="row">
          {activeFilters.length !== 0 && (
            <Box sx={{ width: sorting ? '40%' : '100%', textAlign: 'center', mr: sorting ? 1 : 0 }}>
              <SquareIconButton
                onClick={() => setOpen(true)}
                sx={{ width: '100%', height: '100%' }}
              >
                <FilterListIcon />
                <Typography variant="body2">Filters</Typography>
              </SquareIconButton>
            </Box>
          )}
          {sorting && (
            <Box sx={{ width: activeFilters.length !== 0 ? '60%' : '100%', textAlign: 'center' }}>
              {sorting}
            </Box>
          )}
        </Stack>
      </Stack>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            width: '100%',
            height: '100%',
            background: (theme) => theme.palette.background.default
          }
        }}
      >
        <Box sx={{ p: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
          <SquareIconButton size="small" sx={{}} onClick={() => setOpen(false)}>
            <CloseIcon />
          </SquareIconButton>
        </Box>
        <FilterList activeFilters={activeFilters} />
      </Drawer>
    </>
  );
};

export default MobileFilters;
