import { useWeb3React } from '@web3-react/core';
import { erc20 } from 'abi/erc20';
import config from 'config';
import { BigNumberish, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { formatEther } from 'ethers/lib/utils';

const useAssets = () => {
  const [avax, setAvax] = useState('0.0');
  const [wavax, setWavax] = useState('0.0');
  const [klo, setKloa] = useState('0.0');
  const { hooks, account } = useWeb3React();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();

  const getAssets = () => {
    if (account) {
      try {
        // AVAX
        provider?.getBalance(account).then((result: BigNumberish) => {
          setAvax((prev) => formatEther(result));
        });
        // WAVAX
        const wavaxContract = new ethers.Contract(config.wavax, erc20, provider);
        wavaxContract.balanceOf(account).then((balance: BigNumberish) => {
          setWavax((prev) => formatEther(balance));
        });
        // KLO
        if (config.env == 'production') {
          const kloContract = new ethers.Contract(config.klo, erc20, provider);
          kloContract.balanceOf(account).then((balance: BigNumberish) => {
            setKloa((prev) => formatEther(balance));
          });
        }
      } catch (err) {
        console.error(err);
        /** Ignore, balance remains 0 **/
      }
    }
  };

  useEffect(() => {
    if (account) getAssets();
  }, [account]);

  return {
    getAssets,
    avax,
    wavax,
    klo
  };
};

export default useAssets;
