import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient } from 'graphql-request';
import config from 'config';

// Generated types
import {
  ActivityDocument,
  ActivityQuery,
  ActivityQueryVariables,
  ArtistsDocument,
  ArtistsQuery,
  BalancesDocument,
  BalancesQuery,
  CategoriesDocument,
  CategoriesQuery,
  CollectionDocument,
  CollectionQuery,
  CollectionsDocument,
  CollectionsQuery,
  MyOffersDocument,
  MyOffersQuery,
  NftDocument,
  NftQuery,
  NftsDocument,
  NftsQuery,
  QueryAutocompleteArgs,
  QuerySearchArgs,
  QueryVolumeArgs,
  UserDocument,
  UserQuery,
  VolumeDocument,
  VolumeQuery,
  AutoCompleteQuery,
  AutoCompleteDocument,
  QueryNftArgs,
  AuthorizeBasketOfferDocument,
  CreateDealMutation,
  CreateDealMutationVariables,
  CreateDealDocument,
  CancelDealMutation,
  CancelDealMutationVariables,
  CancelDealDocument,
  SaleNonceMutation,
  SaleNonceMutationVariables,
  SaleNonceDocument,
  MyOffersQueryVariables,
  AuthorizeBasketSaleDocument,
  AuthorizeBasketOfferMutation,
  AuthorizeBasketOfferMutationVariables,
  AuthorizeBasketSaleMutationVariables,
  AuthorizeBasketSaleMutation,
  NftDetailsHistoryQuery,
  NftDetailsHistoryQueryVariables,
  NftDetailsHistoryDocument,
  NftActivityDocument,
  NftActivityQuery,
  NftActivityQueryVariables,
  SalesQuery,
  SalesQueryVariables,
  SalesDocument,
  HomeStatsQuery,
  HomeStatsQueryVariables,
  HomeStatsDocument,
  ListingsAndOffersQuery,
  ListingsAndOffersQueryVariables,
  ListingsAndOffersDocument,
  AttributesExistQuery,
  AttributesExistQueryVariables,
  AttributesExistDocument,
  QueryTokenIdsProofArgs,
  TokenIdsProofDocument,
  TokenIdsProofQuery,
  AttributesProofQuery,
  AttributesProofQueryVariables,
  AttributesProofDocument,
  LoginNonceQuery,
  LoginNonceQueryVariables,
  LoginNonceDocument,
  LoginMutation,
  LoginMutationVariables,
  LoginDocument,
  UpdateProfileDocument,
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
  UploadAssetMutation,
  UploadAssetMutationVariables,
  UploadAssetDocument,
  NotificationSettingsQuery,
  NotificationSettingsQueryVariables,
  NotificationSettingsDocument,
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsDocument,
  UpdateNotificationSettingsMutationVariables,
  OwnedNfTsCollectionsQuery,
  OwnedNfTsCollectionsDocument,
  OwnedNfTsCollectionsQueryVariables,
  SetTelegramNotificationsDocument,
  SetTelegramNotificationsMutationVariables,
  SetTelegramNotificationsMutation,
  UserRankingQuery,
  UserRankingQueryVariables,
  UserRankingDocument,
  CollectionRankingQuery,
  CollectionRankingQueryVariables,
  CollectionRankingDocument,
  SetLoginMutationVariables,
  SetLoginMutation,
  SetLoginDocument,
  SetPasswordDocument,
  SetPasswordMutation,
  SetPasswordMutationVariables,
  ProfileQuery,
  ProfileDocument,
  IpfsUploadMutation,
  IpfsUploadMutationVariables,
  IpfsUploadDocument,
  BalancesQueryVariables,
  ProfileSettingsQuery,
  ProfileSettingsQueryVariables,
  ProfileSettingsDocument,
  LikesDocument,
  LikesQuery,
  LikesQueryVariables,
  LikedAssetsQuery,
  LikedAssetsQueryVariables,
  LikedAssetsDocument,
  LikeMutation,
  LikeMutationVariables,
  LikeDocument,
  ProfileQueryVariables,
  CheckSessionMutation,
  CheckSessionMutationVariables,
  CheckSessionDocument,
  LogoutMutation,
  LogoutMutationVariables,
  LogoutDocument,
  SeriesQuery,
  SeriesQueryVariables,
  SeriesDocument,
  UpdateColllectionMutation,
  UpdateColllectionMutationVariables,
  UpdateColllectionDocument,
  RefreshNftMutation,
  RefreshNftMutationVariables,
  RefreshNftDocument,
  PartnersQuery,
  PartnersQueryVariables,
  PartnersDocument,
  BalanceQuery,
  BalanceQueryVariables,
  BalanceDocument,
  AttributesCountQuery,
  AttributesCountQueryVariables,
  AttributesCountDocument/*,
  GalleriesDocument,
  GalleriesQuery,
  GalleryDocument,
  GalleryQuery*/
} from 'types/generated';
import { AddressRouteParams, SharecodeRouteParams } from 'types';

export const client = new GraphQLClient(`${config.api}/query`, { credentials: 'include' });

export const kalaoApi = createApi({
  reducerPath: 'kalaoApi',
  baseQuery: graphqlRequestBaseQuery({ client }),
  tagTypes: [
    'Sales',
    'Offers',
    'Notifications',
    'Likes',
    'Session',
    'Profile',
    'Collection',
    'NftDetails',
    'User',
    'Gallery'
  ],
  endpoints: (build) => ({
    NFTs: build.query<NftsQuery, QuerySearchArgs>({
      query: (variables: QuerySearchArgs) => ({ document: NftsDocument, variables })
    }),

    // Query collections for (explore page | dashboard | profile)
    Collections: build.query<CollectionsQuery, QuerySearchArgs>({
      query: (variables: QuerySearchArgs) => ({ document: CollectionsDocument, variables })
    }),

    // Query artists for explore page
    Artists: build.query<ArtistsQuery, QuerySearchArgs>({
      query: (variables: QuerySearchArgs) => ({ document: ArtistsDocument, variables })
    }),

    // Query Avtivities (Collection page  | Profile | Activity page)
    Activities: build.query<ActivityQuery, ActivityQueryVariables>({
      query: (variables: ActivityQueryVariables) => ({ document: ActivityDocument, variables })
    }),

    // Query to get collection details
    Collection: build.query<CollectionQuery, AddressRouteParams>({
      query: (variables: AddressRouteParams) => ({ document: CollectionDocument, variables }),
      providesTags: ['Collection']
    }),

    // Query to get user infos
    User: build.query<UserQuery, AddressRouteParams>({
      query: (variables: AddressRouteParams) => ({ document: UserDocument, variables }),
      providesTags: ['User']
    }),

    // Query to get user sales
    Sales: build.query<SalesQuery, SalesQueryVariables>({
      query: (variables) => ({ document: SalesDocument, variables }),
      providesTags: ['Sales']
    }),

    // Query volume stats
    Volume: build.query<VolumeQuery, QueryVolumeArgs | void>({
      query: (variables: QueryVolumeArgs) => ({ document: VolumeDocument, variables })
    }),

    // Query Autocomplete
    Autocomplete: build.mutation<AutoCompleteQuery, QueryAutocompleteArgs>({
      query: (variables: QueryAutocompleteArgs) => ({ document: AutoCompleteDocument, variables })
    }),

    // Query to get all categories
    Categories: build.query<CategoriesQuery, AddressRouteParams | void>({
      query: (variables: AddressRouteParams | void) => ({
        document: CategoriesDocument,
        variables
      })
    }),

    // Query to get nft details
    Nft: build.query<NftQuery, QueryNftArgs>({
      query: (variables: QueryNftArgs) => ({ document: NftDocument, variables }),
      providesTags: ['NftDetails']
    }),

    // Query to get balances
    Balances: build.query<BalancesQuery, BalancesQueryVariables>({
      query: (variables: BalancesQueryVariables) => ({ document: BalancesDocument, variables })
    }),

    // Query My offers
    MyOffers: build.query<MyOffersQuery, MyOffersQueryVariables>({
      query: (variables) => ({ document: MyOffersDocument, variables }),
      providesTags: ['Offers']
    }),

    // Query collections ranking
    CollectionRanking: build.query<CollectionRankingQuery, CollectionRankingQueryVariables>({
      query: (variables: CollectionRankingQueryVariables) => ({
        document: CollectionRankingDocument,
        variables
      })
    }),

    // Query users ranking
    UserRanking: build.query<UserRankingQuery, UserRankingQueryVariables>({
      query: (variables: UserRankingQueryVariables) => ({
        document: UserRankingDocument,
        variables
      })
    }),

    // Query to get the home stats
    HomeStats: build.query<HomeStatsQuery, HomeStatsQueryVariables>({
      query: (variables: HomeStatsQueryVariables) => ({ document: HomeStatsDocument, variables })
    }),

    // Nft detals history
    NftDetailsHistory: build.query<NftDetailsHistoryQuery, NftDetailsHistoryQueryVariables>({
      query: (variables) => ({ document: NftDetailsHistoryDocument, variables })
    }),

    // NFT details activity
    NftActivity: build.query<NftActivityQuery, NftActivityQueryVariables>({
      query: (variables) => ({ document: NftActivityDocument, variables })
    }),

    // NFT listings and offers
    ListingsAndOffers: build.query<ListingsAndOffersQuery, ListingsAndOffersQueryVariables>({
      query: (variables) => ({ document: ListingsAndOffersDocument, variables }),
      providesTags: ['Sales', 'Offers']
    }),

    // Get owned NFTs collections
    OwnedNftsCollections: build.query<
      OwnedNfTsCollectionsQuery,
      OwnedNfTsCollectionsQueryVariables | void
    >({
      query: (variables) => ({ document: OwnedNfTsCollectionsDocument, variables })
    }),

    // Query to get user infos
    Profile: build.query<ProfileQuery, ProfileQueryVariables>({
      query: (variables: ProfileQueryVariables) => ({ document: ProfileDocument, variables }),
      providesTags: ['Notifications', 'Session', 'Profile']
    }),

    AuthorizeBasketOffer: build.mutation<
      AuthorizeBasketOfferMutation,
      AuthorizeBasketOfferMutationVariables
    >({
      query: (variables) => ({ document: AuthorizeBasketOfferDocument, variables })
    }),
    AuthorizeBasketSale: build.mutation<
      AuthorizeBasketSaleMutation,
      AuthorizeBasketSaleMutationVariables
    >({
      query: (variables) => ({ document: AuthorizeBasketSaleDocument, variables })
    }),
    CreateDeal: build.mutation<CreateDealMutation, CreateDealMutationVariables>({
      query: (variables) => ({ document: CreateDealDocument, variables }),
      invalidatesTags: ['Sales', 'Offers', 'NftDetails']
    }),
    CancelDeal: build.mutation<CancelDealMutation, CancelDealMutationVariables>({
      query: (variables) => ({ document: CancelDealDocument, variables }),
      invalidatesTags: ['Sales', 'Offers', 'NftDetails']
    }),
    CancelDealForEdit: build.mutation<CancelDealMutation, CancelDealMutationVariables>({
      query: (variables) => ({ document: CancelDealDocument, variables })
    }),
    SaleNonce: build.mutation<SaleNonceMutation, SaleNonceMutationVariables | void>({
      query: (variables) => ({ document: SaleNonceDocument, variables })
    }),
    // Very an offer on selected attributes could be done
    AttributesExist: build.query<AttributesExistQuery, AttributesExistQueryVariables>({
      query: (variables) => ({ document: AttributesExistDocument, variables })
    }),

    /**
     * Get tokenIds computed merkle root
     */
    TokenIdsProof: build.mutation<TokenIdsProofQuery, QueryTokenIdsProofArgs>({
      query: (variables) => ({ document: TokenIdsProofDocument, variables })
    }),
    /**
     * Get attributes computed merkle root
     */
    AttributesProof: build.mutation<AttributesProofQuery, AttributesProofQueryVariables>({
      query: (variables) => ({ document: AttributesProofDocument, variables })
    }),

    /***
     *
     * Login
     */

    // Get the login nonce
    LoginNonce: build.mutation<LoginNonceQuery, LoginNonceQueryVariables>({
      query: (variables: LoginNonceQueryVariables) => ({ document: LoginNonceDocument, variables })
    }),

    // Check whether session is active or not
    CheckSession: build.mutation<CheckSessionMutation, CheckSessionMutationVariables>({
      query: (variables: CheckSessionMutationVariables) => ({
        document: CheckSessionDocument,
        variables
      }),
      invalidatesTags: ['Session']
    }),

    // login
    Login: build.mutation<LoginMutation, LoginMutationVariables>({
      query: (variables: LoginMutationVariables) => ({ document: LoginDocument, variables }),
      invalidatesTags: ['Likes']
    }),

    // logout
    Logout: build.mutation<LogoutMutation, LogoutMutationVariables>({
      query: (variables: LogoutMutationVariables) => ({ document: LogoutDocument, variables })
    }),

    // Upload User or collection asset's
    UploadAsset: build.mutation<UploadAssetMutation, UploadAssetMutationVariables>({
      query: (variables) => ({ document: UploadAssetDocument, variables }),
      invalidatesTags: ['Profile', 'Collection']
    }),

    // Upload Token metadata Image and JSON file to IPFS
    IpfsUpload: build.mutation<IpfsUploadMutation, IpfsUploadMutationVariables>({
      query: (variables) => ({ document: IpfsUploadDocument, variables })
    }),

    /**
     * Settings -> Notification | offers | Profile | vision
     */

    UpdateProfile: build.mutation<UpdateProfileMutation, UpdateProfileMutationVariables>({
      query: (variables) => ({ document: UpdateProfileDocument, variables }),
      invalidatesTags: ['Notifications', 'User']
    }),

    UpdateNotificationSettings: build.mutation<
      UpdateNotificationSettingsMutation,
      UpdateNotificationSettingsMutationVariables
    >({
      query: (variables) => ({ document: UpdateNotificationSettingsDocument, variables }),
      invalidatesTags: ['Notifications']
    }),
    SetTelegramNotifications: build.mutation<
      SetTelegramNotificationsMutation,
      SetTelegramNotificationsMutationVariables
    >({
      query: (variables) => ({ document: SetTelegramNotificationsDocument, variables }),
      invalidatesTags: ['Notifications']
    }),
    // Query to get profile settings
    ProfileSettings: build.query<ProfileSettingsQuery, ProfileSettingsQueryVariables>({
      query: (variables: ProfileSettingsQueryVariables) => ({
        document: ProfileSettingsDocument,
        variables
      }),
      providesTags: ['Notifications']
    }),
    // Notification settings
    NotificationSettings: build.query<
      NotificationSettingsQuery,
      NotificationSettingsQueryVariables | void
    >({
      query: (variables) => ({ document: NotificationSettingsDocument, variables }),
      providesTags: ['Notifications']
    }),
    SetPassword: build.mutation<SetPasswordMutation, SetPasswordMutationVariables>({
      query: (variables) => ({ document: SetPasswordDocument, variables }),
      invalidatesTags: ['Notifications']
    }),
    SetLogin: build.mutation<SetLoginMutation, SetLoginMutationVariables>({
      query: (variables) => ({ document: SetLoginDocument, variables }),
      invalidatesTags: ['Notifications']
    }),
    // List user likes
    Likes: build.query<LikesQuery, LikesQueryVariables>({
      query: (variables) => ({ document: LikesDocument, variables })
    }),
    // Assets liked by the user
    LikedAssets: build.query<Record<string, boolean>, LikedAssetsQueryVariables | void>({
      query: (variables) => ({ document: LikedAssetsDocument, variables }),
      transformResponse: (response: LikedAssetsQuery) => {
        const likes: Record<string, boolean> = {};
        response?.LikedAssets.forEach((a) => (likes[a] = true));
        return likes;
      },
      providesTags: ['Likes']
    }),
    // Like (or dislike if already liked) an asset
    Like: build.mutation<LikeMutation, LikeMutationVariables>({
      query: (variables) => ({ document: LikeDocument, variables }),
      invalidatesTags: ['Likes']
    }),
    // Collection stats
    Series: build.query<SeriesQuery, SeriesQueryVariables | void>({
      query: (variables) => ({ document: SeriesDocument, variables })
    }),
    // Update collection info
    UpdateCollection: build.mutation<UpdateColllectionMutation, UpdateColllectionMutationVariables>(
      {
        query: (variables) => ({ document: UpdateColllectionDocument, variables }),
        invalidatesTags: ['Collection']
      }
    ),
    // Refresh NFT ( page NFT details)
    RefreshNFT: build.mutation<RefreshNftMutation, RefreshNftMutationVariables>({
      query: (variables) => ({ document: RefreshNftDocument, variables })
    }),
    // Populate partners list (home page)
    Partners: build.query<PartnersQuery, PartnersQueryVariables>({
      query: (variables) => ({ document: PartnersDocument, variables })
    }),
    // Get Balance of a specific NFT by assetId
    // Mutation to disable cache
    Balance: build.mutation<BalanceQuery, BalanceQueryVariables>({
      query: (variables) => ({ document: BalanceDocument, variables })
    }),
    // Count how many NFTs match the attributes selected by the user (make offer on properties)
    AttributesCount: build.query<AttributesCountQuery, AttributesCountQueryVariables>({
      query: (variables) => ({ document: AttributesCountDocument, variables })
    })/*,
    // Query galleries for explore page
    Galleries: build.query<GalleriesQuery, QuerySearchArgs>({
      query: (variables: QuerySearchArgs) => ({ document: GalleriesDocument, variables })
    }),
    // Query to get gallery details
    Gallery: build.query<GalleryQuery, SharecodeRouteParams>({
      query: (variables: SharecodeRouteParams) => ({ document: GalleryDocument, variables }),
      providesTags: ['Gallery']
    })*/
  })
});

export const {
  useSalesQuery,
  useVolumeQuery,
  useAutocompleteMutation,
  useCategoriesQuery,
  useMyOffersQuery,
  useLazyBalancesQuery,
  useUserQuery,
  useSaleNonceMutation,
  useCollectionQuery,
  useNftQuery,
  useCollectionRankingQuery,
  useUserRankingQuery,
  useHomeStatsQuery,
  useCreateDealMutation,
  useAuthorizeBasketOfferMutation,
  useAuthorizeBasketSaleMutation,
  useCancelDealMutation,
  useLazyCollectionsQuery,
  useLazyNFTsQuery,
  useLazyActivitiesQuery,
  useLazyArtistsQuery,
  useNftDetailsHistoryQuery,
  useNftActivityQuery,
  useListingsAndOffersQuery,
  useAttributesExistQuery,
  useTokenIdsProofMutation,
  useAttributesProofMutation,
  useLoginMutation,
  useLogoutMutation,
  useLoginNonceMutation,
  useUpdateProfileMutation,
  useNotificationSettingsQuery,
  useUpdateNotificationSettingsMutation,
  useOwnedNftsCollectionsQuery,
  useSetTelegramNotificationsMutation,
  useSetPasswordMutation,
  useSetLoginMutation,
  useProfileQuery,
  useIpfsUploadMutation,
  useUploadAssetMutation,
  useProfileSettingsQuery,
  useLazyLikesQuery,
  useLikedAssetsQuery,
  useLikeMutation,
  useCheckSessionMutation,
  useSeriesQuery,
  useUpdateCollectionMutation,
  useRefreshNFTMutation,
  useCancelDealForEditMutation,
  usePartnersQuery,
  useBalanceMutation,
  useLazyAttributesCountQuery/*,
  useGalleryQuery,
  useLazyGalleriesQuery*/
} = kalaoApi;
