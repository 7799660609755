import React, { useEffect, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { AutoCompleteRenderer, Search, SquareIconButton } from 'components';
import { Box, debounce, Drawer } from '@mui/material';
import { useAutocompleteMutation } from 'api';
import { SearchTarget } from 'types/generated';

const MobileAutoComplete = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [autoComplete, { reset }] = useAutocompleteMutation({ fixedCacheKey: 'auto-complete' });

  useEffect(() => {
    return () => {
      debouncedSearchChange.clear();
    };
  }, []);

  const handleSearchChange = (search: string): void => {
    if (search.length >= 3) {
      autoComplete({
        input: {
          word_begin: search,
          targets: [
            SearchTarget.Collectibles,
            SearchTarget.Collections,
            SearchTarget.Artists,
            SearchTarget.Sales
          ],
          certified: false,
          top: false
        }
      });
    }
  };

  const debouncedSearchChange = useMemo(() => debounce(handleSearchChange, 300), []);

  return (
    <>
      <SquareIconButton
        onClick={() => setOpen(true)}
        sx={{
          ml: 'auto',
          color: (theme) => theme.palette.text.secondary,
          border: (theme) => theme.border
        }}
      >
        <SearchIcon />
      </SquareIconButton>
      <Drawer
        anchor={'left'}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: '100%', background: (theme) => theme.palette.background.default }
        }}
      >
        <Box sx={{ display: 'flex', height: '5rem' }}>
          <Search
            placeholder="Search..."
            inputChange={debouncedSearchChange}
            clearInput={reset}
            sx={{ width: '100%', borderRadius: 0, mr: 0 }}
            mobile={true}
            mobileCloseClick={() => setOpen(false)}
          />
        </Box>
        <AutoCompleteRenderer
          sx={{
            width: '100%',
            position: 'unset',
            borderRadius: 0,
            p: '1rem',
            background: (theme) => theme.palette.background.default
          }}
          elevation={0}
          close={() => setOpen(false)}
        />
      </Drawer>
    </>
  );
};

export default MobileAutoComplete;
