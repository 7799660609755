import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Background, Button } from 'components';
import CollectionSummary from './Summary';
import Typography from '@mui/material/Typography';
import { CollectionTabs } from './Tabs';
import Items from './Items';
import { useDispatch } from 'react-redux';
import { batch, openModal, setAdvancdOffer } from 'store/actions';
import Activity from './Activity';
import { GridSizeProvider, FiltersProvider, useAuth } from 'providers';
import { useCollectionQuery, useUploadAssetMutation } from 'api';
import { processCollectionQueryResponse, getProperties } from 'helpers/processQueryResponse';
import { AddressRouteParams } from 'types';
import { useWeb3React } from '@web3-react/core';
import { AssetKind, SearchNftSort } from 'types/generated';
import { useNotistack } from 'hooks';
import Settings from './Settings';
import Stats from './Stats';

function equalsIgnoringCase(text: string | undefined, other: string | undefined) {
  if (!text || !other) return false;
  return text.localeCompare(other, undefined, { sensitivity: 'base' }) === 0;
}

const CollectionPage = () => {
  const dispatch = useDispatch();
  const { success, error } = useNotistack();
  const { account } = useWeb3React();
  const { loggedIn } = useAuth();
  const [upload] = useUploadAssetMutation();
  const { address } = useParams<AddressRouteParams>();
  const { data } = useCollectionQuery({ address }, { skip: !address });
  const { collection, stats } = processCollectionQueryResponse(data);
  const { banner, avatar, certified, creator, address: collectionAddress } = collection;

  const changeBackground = (url: string, file?: File) => {
    file &&
      upload({
        kind: AssetKind.CollectionCover,
        file,
        contractAddress: collectionAddress
      })
        .then(() => success('Collection cover successfully uploaded'))
        .catch(() => error('Failed to upload collection cover!'));
  };

  const isCreator = loggedIn && equalsIgnoringCase(creator, account);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <GridSizeProvider>
        <FiltersProvider sortBy={SearchNftSort.PriceAsc} properties={getProperties(data)}>
          <Box sx={{ position: 'relative' }}>
            <Background
              src={banner as string}
              avatar={avatar}
              isApproved={certified}
              height="18rem"
              isCreator={isCreator}
              set={changeBackground}
            />
            <CollectionSummary collection={collection} stats={stats}>
            </CollectionSummary>
            <CollectionTabs isCreator={isCreator} />
            <Routes>
              <Route path="/" element={<Items collection={collection} stats={stats} />} />
              <Route path="/items" element={<Items collection={collection} stats={stats} />} />
              <Route path="/activity" element={<Activity />} />
              <Route path="/stats" element={<Stats />} />
              <Route
                path="/settings"
                element={
                  isCreator ? (
                    <Settings myCollection={collection} />
                  ) : (
                    <Navigate to="items" replace />
                  )
                }
              />
              <Route path="*" element={<Navigate to="items" replace />} />
            </Routes>
          </Box>
        </FiltersProvider>
      </GridSizeProvider>
    </>
  );
};

export default CollectionPage;
