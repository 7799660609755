// 3rd party libs
import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { Button, StyledSwitch } from 'components';

type NotifyProps = {
  topic: string;
  description?: string;
  variant?: 'h5' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2';
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  connectBtn?: boolean;
};

export const Notify = ({
  topic,
  description,
  variant = 'h5',
  checked,
  disabled,
  onChange,
  connectBtn
}: NotifyProps): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', opacity: disabled ? '0.7' : '1' }}>
      <Stack>
        <Typography variant={variant} color={checked ? 'text.primary' : 'text.secondary'} sx={{ mb: 0.5 }}>
          {topic}
        </Typography>
        {description && (
          <Typography variant="body2" color="text.secondary" fontWeight={400}>
            {description}
          </Typography>
        )}
      </Stack>
      {connectBtn ? (
        <Button
          ctx={checked ? 'primary' : 'secondary'}
          disabled={disabled}
          onClick={() => onChange(!checked)}
          sx={{ width: '8rem' }}
        >
          <Typography variant="button">{checked ? 'Disconnect' : 'Connect'}</Typography>
        </Button>
      ) : (
        <StyledSwitch 
          checked={checked}
          disabled={disabled}
          onChange={() => onChange(!checked)}
        />
      )}
    </Box>
  );
};
