// 3rd party libs
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Background, Button, Tabs, UserInfo } from 'components';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

// Components
import { Owned } from './owned';
import { Liked } from './liked';
import Activity from './activity';
import { useUserQuery } from 'api';
import { AddressRouteParams } from 'types';
import Created from './Created';
import { processUserQueryResponse } from 'helpers/processQueryResponse';
import { compact } from 'lib/format';

const getCurrentTab = <T extends { value: string }>(path: string, tabs: T[]) => {
  const s = path.split('/').filter(Boolean);
  if (s.length <= 2) return 0;
  else return tabs.findIndex((tab) => tab.value == s[2]);
};

const Profile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { address } = useParams<AddressRouteParams>();
  const { data, error } = useUserQuery({ address }, { skip: !address });
  const { user } = processUserQueryResponse(data);
  const { cover, avatar, metadata, certified } = user;
  const { owned_nfts, liked_nfts } = metadata;

  const tabs: { label: string; value: string; children?: { label: string; value: string }[] }[] = [
    { label: `Owned ${compact.format(owned_nfts)}`, value: 'owned' },
    {
      label: 'Created',
      value: 'created',
      children: [
        //{ label: 'NFTs', value: 'nfts' },
        { label: 'Collections', value: 'collections' }
      ]
    },
    { label: `Liked ${compact.format(liked_nfts)}`, value: 'liked' },
    { label: 'Activity', value: 'activity' }
  ];

  //Disable Buttons Follow/Message User TMP
  const disableButtonFollowMessage = true;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Background src={cover} avatar={avatar} height="18rem" isApproved={certified} />
      <UserInfo user={user} isPublic={true} />
      {!disableButtonFollowMessage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, my: 2 }}>
          <Button ctx="primary" sx={{ width: '6rem' }}>
            <Typography variant="button">Follow</Typography>
          </Button>
          <Button ctx="secondary" sx={{ width: '6rem' }}>
            <Typography variant="button">Message</Typography>
          </Button>
        </Box>
      )}
      <Box
        sx={{ borderBottom: (theme) => theme.border, display: 'flex', justifyContent: 'center' }}
      >
        <Tabs
          tabs={tabs}
          currentTab={getCurrentTab(location.pathname, tabs)}
          onTabChange={(tab) => navigate(tab)}
          onChilChange={(parent, value) => {
            if (parent == 2) navigate(`created/${value}`);
          }}
        />
      </Box>

      <Routes>
        <Route path="/" element={<Owned />} />
        <Route path="owned" element={<Owned />} />
        <Route path="created/*" element={<Created />} />
        <Route path="liked" element={<Liked />} />
        <Route path="activity" element={<Activity />} />
      </Routes>
    </Box>
  );
};

export default Profile;
