import React, { useEffect, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { Bid, PriceSymbol, StepStatus } from 'types';
import Step from '../Step';
import { Button } from 'components';
import { useWeb3React } from '@web3-react/core';
import { approveWavax } from 'services/exchange';

type Props = {
  token: PriceSymbol;
  step: number;
  currentStep: number;
  bid: Bid;
  // callback, called once the step has been completed successfully
  complete: () => void;
};

type SignProps = {
  status: StepStatus;
  onSign: () => void;
};

const ApproveToken = ({ onSign, status }: SignProps) => {
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
          Your approval is required for the marketplace to access your WAVAX token. This is a one-time only operation
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {status !== StepStatus.IN_PROGRESS && (
          <Button
            ctx="primary"
            onClick={() => onSign()}
            sx={{ mt: 1, borderRadius: '0.625rem', width: '6rem', marginLeft: '-3.84rem' }}
          >
            <Typography variant="button">Approve</Typography>
          </Button>
        )}
      </Box>
    </Stack>
  );
};

const Approve = ({ step, currentStep, token, bid, complete }: Props) => {
  const { account, hooks } = useWeb3React();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
    }
  }, [currentStep]);

  const sign = async () => {
    if (!account || !provider) {
      setStatus(StepStatus.ERROR);
      setError('Unauthenticated');
      return;
    }

    setStatus(StepStatus.IN_PROGRESS);
    try {
      const { price, quantity } = bid;
      // Approve wavax if needed
      const response = await approveWavax(provider, account, price, quantity);

      complete();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Approval failed!');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={`Approve ${token}`}
      step={step}
      currentStep={currentStep}
      status={status}
      el={
        status == StepStatus.STARTED || status == StepStatus.IN_PROGRESS ? (
          <ApproveToken status={status} onSign={sign} />
        ) : undefined
      }
      error={error}
    />
  );
};

export default Approve;
