import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import { Button, LinkExternal } from 'components';

type Props = {
  title: string;
  explorer: string;
  hash: string;
  disable_redirect: boolean;
};

export const Done = ({ title, explorer, hash, disable_redirect }: Props) => {
  const dispatch = useDispatch();

  const onContinue = () => {
    dispatch(openModal.create({}));
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Typography variant="h6" fontWeight="600" sx={{ color: (theme) => theme.colors.green, mr: 1 }}>
        {title} successfully transferred
      </Typography>
      <Stack sx={{ width: '100%' }}>
        <Box>
          <LinkExternal
            url={`${explorer}/${hash}`}
            name="View on explorer"
          />
        </Box>
        {!disable_redirect && (
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Button ctx="primary" onClick={() => onContinue()} sx={{ marginLeft: '-3.84rem' }}>
              <Typography variant="button">Continue</Typography>
            </Button>
          </Box>
        )}
      </Stack>
    </Box>
  );
}; 