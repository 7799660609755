export const exchangeAbi = [
  // Structs
  // Authenticated Functions
  'function bulkBuy((address seller, address token_address, uint256 token_id, uint256 in_sale_amount, uint256 unitary_price, uint256 expiration_ts, uint256 starting_ts, uint256 nonce, uint256 chain_id, uint256 min_unitary_price, bool managed, bytes seller_signature, address buyer, address nft_recipient, uint256 amount_bought, uint256 sale_id, uint256 kalao_fees, uint256 community_fees, uint256 extern_fees, address extern_recipient, uint256 validator_signature_expiration_ts, bool activate_royalties, bytes validator_signature)[] sales) external payable',
  'function bulkOffersAccept((address buyer, address token_address, bytes32 merkle_root, address payment_token, uint256 amount_to_buy, uint256 unitary_price, uint256 expiration_ts, uint256 starting_ts, uint256 nonce, uint256 chain_id, bytes buyer_signature, address seller,  uint256 token_id, uint256 amount_sold, uint256 offer_id, uint256 fees_kalao, uint256 fees_community, uint256 fees_extern, address extern_recipient, uint256 validator_signature_expiration_ts, bool activate_royalties, bytes validator_signature, bytes32[] merkle_proof)[] offers) external ',
  'function cancelOffer((address buyer, address token_address,bytes32 merkle_root, address payment_token, uint256 amount_to_buy, uint256 unitary_price, uint256 expiration_ts, uint256 starting_ts,uint256 nonce, uint256 chain_id, bytes buyer_signature, bytes validator_signature, uint256 offer_id) request) external',
  'function cancelSale((address seller, address token_address, uint256 token_id, uint256 in_sale_amount, uint256 unitary_price, uint256 expiration_ts, uint256 starting_ts, uint256 nonce, uint256 chain_id, bytes seller_signature, bytes validator_signature, uint256 sale_id) request) external',
  'function batchTransferERC721(address[] calldata _tokens, address[] calldata _to, uint256[] calldata _id) public',
  'function batchTransferERC1155(address[] calldata _tokens, address[] calldata _to, uint256[] calldata _id, uint256[] calldata _amounts) public'
];
