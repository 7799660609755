import React, { useEffect, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { StepStatus } from 'types';
import Step from '../Step';
import { Button } from 'components';
import { useWeb3React } from '@web3-react/core';
import { verifyOwnershipSignature } from 'services/exchange';
import { useLoginMutation, useLoginNonceMutation } from 'api';
import { LoginMutation } from 'types/generated';
import { useAuth } from 'providers';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';

type Props = {
  step: number;
  currentStep: number;
  // callback, called once the step has been completed successfully
  complete: () => void;
};

type SignProps = {
  status: StepStatus;
  onSign: () => void;
};

const Sign = ({ onSign, status }: SignProps) => {
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        You will be asked to sign a message in your wallet to verify you as the owner of the
        address.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {status !== StepStatus.IN_PROGRESS && (
          <Button
            ctx="primary"
            onClick={() => onSign()}
            sx={{ mt: 1, borderRadius: '0.625rem', width: '6rem' }}
          >
            <Typography variant="button">Sign</Typography>
          </Button>
        )}
      </Box>
    </Stack>
  );
};

const OwnerShipStep = ({ step, currentStep, complete }: Props) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();
  const { account, hooks } = useWeb3React();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();
  const { loggedIn, setLoggedIn } = useAuth();
  // Login
  const [login] = useLoginMutation();
  const [loginNonce] = useLoginNonceMutation();

  useEffect(() => {
    if (step == currentStep) {
      // Already logged
      if (loggedIn) {
        setStatus(StepStatus.DONE);
        complete();
      } else {
        setStatus(StepStatus.STARTED);
      }
    }
  }, [currentStep, loggedIn]);

  const sign = async () => {
    try {
      if (!provider || !account) {
        dispatch(openModal.create({ modal: 'WALLET' }));
        return;
      }
      setStatus(StepStatus.IN_PROGRESS);

      const nonce = await loginNonce({ address: account }).unwrap();
      if (!nonce?.loginNonce) throw new Error('Auth failed !');
      // Sign using nonce sent by the backend
      const signature = await verifyOwnershipSignature(provider, nonce.loginNonce);
      // Login
      await login({ address: account, nonce: nonce.loginNonce, signature })
        .unwrap()
        .then((res: LoginMutation) => {
          if (res.login) {
            setStatus(StepStatus.DONE);
            setLoggedIn(true);
            complete();
          } else {
            throw new Error('Auth failed !');
          }
        })
        .catch(() => {
          throw new Error('Auth failed !');
        });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Auth failed !');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={
        status == StepStatus.DONE ? (
          'Ownership Verified'
        ) : (
          'Verify Ownership'
        )
      }
      step={step}
      currentStep={currentStep}
      status={status}
      el={
        status == StepStatus.STARTED || status == StepStatus.IN_PROGRESS ? (
          <Sign status={status} onSign={sign} />
        ) : undefined
      }
      error={error}
    />
  );
};

export default OwnerShipStep;
