import React, { useEffect, useState } from 'react';
import { Stack, Typography, styled, Box, Divider } from '@mui/material';
import { InputField, Step, StyledSwitch } from 'components';
import CollectionAvatar from './Avatar';
import { FileUpload, NumberOrString, StepStatus, TokenKind } from 'types';
import Royalties from './Royalties';
import Button from 'components/Button';
import { createERC1155, createERC721 } from 'services/tokenConfigurator';
import { useWeb3React } from '@web3-react/core';
import { GlobalState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useUploadAssetMutation } from 'api';
import { AssetKind } from 'types/generated';
import { openModal, setCollectionAddress } from 'store/actions';

const Container = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(2, 2)
}));

const sx = {
  mt: 1,
  height: { xs: '3rem' },
  fontSize: { xs: 14, sm: 16 },
  px: 2,
  borderRadius: '0.5rem'
};

const preambule = `You’re about to create your own collection which triggers a deployment of your contract.
  Deploying your own contract allows you to define the number of NFTs you’ll have in your collection.
  Kalao charges absolutely no service fees but network fees remain.`;

const done = (msg: string) => {
  const dispatch = useDispatch();
  const onContinue = () => {
    dispatch(openModal.create({}));
  };
  
  return (
    <>
      <Typography variant="body2" color="text.primary">
        {msg}
      </Typography>
      <Stack sx={{ width: '100%' }}>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button ctx="primary" onClick={() => onContinue()} sx={{ marginLeft: '-3.84rem' }}>
            <Typography variant="button">Continue</Typography>
          </Button>
        </Box>
      </Stack>
    </>
  );
};

const CollectionCreator = () => {
  const dispatch = useDispatch();
  const [royalties, setRoyalties] = useState<NumberOrString>('');
  const [file, setFile] = useState<FileUpload>();
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [certify, setCertify] = useState(false);
  const nftKind = useSelector((global: GlobalState) => global.state.deployNFTKind);
  const [uploadAsset] = useUploadAssetMutation();
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState<string>();
  const [doneMsg, setDoneMsg] = useState('');
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);

  const { account, hooks } = useWeb3React();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();

  useEffect(() => {
    return () => {
      file && URL.revokeObjectURL(file.preview);
    };
  }, []);

  const create = async () => {
    if (nftKind) {
      setStatus(StepStatus.IN_PROGRESS);
      setCreating(true);
      try {
        nftKind === TokenKind.erc721 ? await deployERC721() : await deployERC1155();
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('Creating collection failed!');
        }
        setStatus(StepStatus.ERROR);
      }
    }
  };

  const deployERC721 = async () => {
    if (!account || !provider) {
      return;
    }

    // set royalties
    const royaltiesRate = Math.floor(Number(royalties) * 100);

    // deploy contract
    const contractAddress = await createERC721(provider, {
      name,
      symbol,
      royalties: royaltiesRate,
      royaltyRecipient: account
    });

    // upload collection avatar
    await uploadAsset({
      kind: AssetKind.CollectionAvatar,
      contractAddress,
      file: file?.fileToUpload
    })
      .unwrap()
      .then(() => {
        setDoneMsg(`${name} contract successfully deployed`);
        setStatus(StepStatus.DONE);
        dispatch(setCollectionAddress.create({ address: contractAddress }));
      })
      .catch(() => {
        setError(`${name} contract deployment failed`);
        setStatus(StepStatus.ERROR);
      });
  };

  const deployERC1155 = async () => {
    if (!account || !provider) {
      return;
    }
    // set royalties
    const royaltiesRate = Math.floor(Number(royalties) * 100);

    // deploy contract
    const contractAddress = await createERC1155(provider, {
      name,
      symbol,
      royalties: royaltiesRate,
      royaltyRecipient: account
    });

    // upload collection avatar
    await uploadAsset({
      kind: AssetKind.CollectionAvatar,
      contractAddress,
      file: file?.fileToUpload
    })
      .unwrap()
      .then(() => {
        setDoneMsg(`${name} contract successfully deployed`);
        setStatus(StepStatus.DONE);
        dispatch(setCollectionAddress.create({ address: contractAddress }));
      })
      .catch(() => {
        setError(`${name} contract deployment failed`);
        setStatus(StepStatus.ERROR);
      });
  };

  const disabled = !name || !symbol || !file?.preview;

  return (
    <Container>
      <Typography variant="h4" color="text.primary" textAlign="center" sx={{ mb: 2 }}>
        Create collection
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {preambule}
      </Typography>

      <Stack direction="row" spacing={2} sx={{ mt: 4, mb: 2 }}>
        <Stack spacing={2} sx={{ flex: 1 }}>
          <InputField
            flex={1}
            sx={sx}
            label={'Name'}
            value={name}
            set={(v) => setName(v as string)}
            placeholder={'e.g. «Bubbles mania»'}
          />
          <InputField
            flex={1}
            sx={sx}
            label={'Symbol'}
            value={symbol}
            set={(v) => setSymbol(v as string)}
            placeholder={'e.g. «BUBLM»'}
          />
        </Stack>
        <CollectionAvatar file={file} setFile={setFile} />
      </Stack>
      <Royalties royalties={royalties} setRoyalties={setRoyalties} sx={sx} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
        <StyledSwitch checked={certify} onChange={() => setCertify(!certify)} />
        <Typography variant="body2" color="text.secondary">
          I certify that my collection is non copyrighted.
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 2,
          mt: 1
        }}
      >
        {creating ? (
          <>
            <Divider sx={{ my: 3 }} variant="middle" />
            <Step
              title={status == StepStatus.DONE ? done(doneMsg) : 'Deploying collection contract'}
              step={1}
              currentStep={1}
              error={error}
              status={status}
            />
          </>
        ) : (
          <Button
            disabled={disabled || !certify}
            ctx="primary"
            sx={{ minWidth: '6rem', display: 'flex', gap: 1, alignItems: 'center', mx: 'auto' }}
            onClick={create}
          >
            <Typography variant="button">Create</Typography>
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default CollectionCreator;
