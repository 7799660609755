import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { Address, SocialNetworks, SquareIconButton } from 'components';
import { UserProfile } from 'types/generated';
import { compact } from 'lib/format';
import { formatAddress } from 'helpers/formatAddress';

type Props = {
  user: UserProfile;
  // true when displaying profile page
  isPublic: boolean;
  // Browse to public profile
  goTo?: () => void;
};

const Container = styled(Box)({
  margin: '1% 2%'
});

const sx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary
};

const render = (value: string, key: string) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="h4" color="text.primary" fontWeight={500}>
        {value}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {key}
      </Typography>
    </Box>
  );
};

const renderTopSection = (user: UserProfile, isPublic: boolean, goTo: () => void) => {
  const { name, address, instagram, twitter, facebook, discord, website, telegram } = user;
  return (
    <Box sx={{ mb: 1 }}>
      <Stack spacing={1} alignItems="center" sx={{ mb: 1 }}>
        <Typography variant="h2" color="text.primary" fontWeight="600">
          {(name && name != 'anonymous') ? name : formatAddress(address)}
        </Typography>
        <Address address={address} copy={true} length={4} />
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={1}>
        {!isPublic && (
          <SquareIconButton sx={sx} size="small" onClick={() => goTo()}>
            <Typography variant="body2" color="text.secondary">
              See public profile
            </Typography>
          </SquareIconButton>
        )}
        <SocialNetworks
          instagram={instagram}
          twitter={twitter}
          facebook={facebook}
          discord={discord}
          website={website}
          telegram={telegram}
        />
      </Stack>
    </Box>
  );
};

const renderDescription = (description: string) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
      <Typography
        variant="subtitle1"
        color="text.secondary"
        textAlign="center"
        maxWidth={700}
        sx={{ fontSize: { xs: 13, md: 15 } }}
      >
        {description}
      </Typography>
    </Box>
  );
};

//Disable Metadata User TMP
const disableMetadata = true;

const UserInfo = ({ user, isPublic = true, goTo }: Props) => {
  const { metadata, description } = user;
  return (
    <Container sx={{ mt: 6 }}>
      {renderTopSection(user, isPublic, () => goTo && goTo())}
      {renderDescription(description)}
      {!disableMetadata && (
        <Stack direction="row" spacing={4} justifyContent="center" sx={{ mt: 2 }}>
          {render(compact.format(metadata.followers), 'followers')}
          {render(compact.format(metadata.following), 'following')}
        </Stack>
      )}
    </Container>
  );
};

export default UserInfo;
