import React, { useEffect, useState } from 'react';
import { Tabs } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { VolumeStatistics } from 'types/generated';
import { useVolumeQuery } from 'api';
import { compact } from 'lib/format';

const tabs: { label: string; value: string }[] = [
  { label: 'Collections', value: 'collections' },
  { label: 'NFTs', value: 'nfts' },
  { label: 'Artists', value: 'artists' },
  { label: 'Galleries', value: 'galleries' }
];

const pairs: Record<string, keyof Omit<VolumeStatistics, '__typename'>> = {
  collections: 'collections',
  nfts: 'nfts_on_sale',
  artists: 'artists'/*,
  galleries: 'galleries'*/
};

export const ExploreTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);

  // Add stats if any
  /*
  const { data } = useVolumeQuery();
  const tabsWithStats = data
    ? tabs.map((tab) => ({
        ...tab,
        label: `${tab.label} ${compact.format(data.volume[pairs[tab.value]])}`
      }))
    : tabs;
  */

  useEffect(() => {
    const index = tabs.findIndex((tab) => location.pathname.includes(tab.value));
    setCurrentTab(index);
  }, [location]);

  return <Tabs tabs={tabs} currentTab={currentTab} onTabChange={(tab) => navigate(tab)} />;
};
