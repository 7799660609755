import React, { CSSProperties } from 'react';
import { Box, Chip, styled, Typography, useTheme } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Button, ImageContainer } from 'components';
import { Metaverse } from 'types';

type Props = {
  metaverse: Metaverse;
};

// Styling
const Card = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  borderTopLeftRadius: '1rem',
  borderTopRightRadius: '1rem'
});

const CardInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  border: theme.border,
  borderTop: '0.0625rem solid transparent',
  borderBottomLeftRadius: '1rem',
  borderBottomRightRadius: '1rem'
}));

const infoRenderer = (meta: Metaverse) => {
  const { name, owner, description } = meta;
  return (
    <CardInfoContainer>
      <Typography variant="body1" color="text.primary" sx={{ textAlign: 'center' }}>
        {name}
      </Typography>
      <Typography
        variant="caption"
        fontWeight={700}
        sx={{
          textAlign: 'center',
          mb: 2,
          color: (theme) => {
            const mode = theme.palette.mode;
            return mode == 'dark' ? '#FFDB3D' : '#989BA0';
          }
        }}
      >
        {owner}
      </Typography>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{
          mb: 2,
          textAlign: 'center',
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3
        }}
      >
        {description}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Chip
          sx={{
            background: (theme) =>
              theme.palette.mode == 'dark' ? theme.palette.background.paper : theme.input.background
          }}
          label="Copy address Code"
        />
      </Box>
    </CardInfoContainer>
  );
};

const likesRenderer = (likes: number) => {
  const theme = useTheme();
  const color = theme.palette.mode == 'dark' ? '#596067' : theme.colors.red;
  return (
    <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
      <Chip
        size="small"
        icon={<FavoriteIcon sx={{ '&&': { color } }} />}
        label={likes}
        sx={{ background: '#FFFFFF', color }}
      />
    </Box>
  );
};

const MetaverseCard = ({ metaverse }: Props): JSX.Element => {
  const { thumbnail, likes } = metaverse;
  return (
    <Card>
      <ImageContainer src={thumbnail} ratio={60} />
      {infoRenderer(metaverse)}
      {likesRenderer(likes)}
    </Card>
  );
};

export default MetaverseCard;
