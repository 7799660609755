import React, { useMemo, useState } from 'react';
import {
  Box,
  MenuItem,
  Popper,
  Stack,
  useMediaQuery,
  useTheme,
  styled,
  ClickAwayListener,
  MenuList,
  Paper,
  Typography
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as Certified } from '../../../assets/certified.svg';

// Types
import { EventType, NFTSale } from 'types';

// Components
import { Activity, DropDownSelect, Price, SquareIconButton } from 'components';

// Helpers
import Avatar from 'components/Avatar';
import { EventIcon } from 'helpers/events';
import { activity_listings_options } from 'constants/options';
import { useDispatch } from 'react-redux';
import { openModal, setSale, batch } from 'store/actions';
import { useSalesQuery } from 'api';
import { useWeb3React } from '@web3-react/core';
import { SaleKind, SaleStatus } from 'types/generated';
import { useNavigate } from 'react-router-dom';
import { goToNft } from 'helpers/nfts';
import { goToCollection } from 'helpers/collections';

const StyledRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '4.5rem',
  padding: theme.spacing(1),
  borderRadius: '0.5rem',
  ':hover': { background: theme.palette.action.hover }
}));

const StyledMobileRow = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  borderRadius: '0.5rem',
  ':hover': { background: theme.palette.action.hover }
}));

const StyledCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0.5),
  height: '100%'
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: '0.5rem',
  fontSize: 14,
  color: theme.palette.text.secondary,
  ':hover': {
    color: theme.palette.text.primary
  }
}));

const InnerPopperContainer = styled(Paper)(({ theme }) => ({
  background: theme.input.background,
  borderRadius: '0.9375rem',
  padding: theme.spacing(1)
}));

const PopperContainer = styled(Box)({
  background: 'transparent',
  paddingTop: '0.5rem'
});

const toStatus: { [key in SaleStatus]: string } = {
  all: 'null',
  cancelled: 'Cancelled',
  ended: 'Ended',
  live: 'Live',
  sold_out: 'Sold out'
};

const Key = (caption: string) => {
  return (
    <Typography variant="caption" color="text.secondary">
      {caption}
    </Typography>
  );
};

const avatar = (
  name: string,
  thumbnail: string,
  collection: string,
  certified: boolean,
  goTo: () => void,
  goToCollection: () => void
) => {
  return (
    <>
      <Avatar
        onClick={goTo}
        src={thumbnail}
        sx={{ borderRadius: '0.5rem', width: '3.125rem', height: '3.125rem', cursor: 'pointer' }}
        top={20}
      />
      <Stack sx={{ flex: 1, minWidth: 0 }}>
        <Typography
          variant="body2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {name}
        </Typography>
        <Stack direction="row" spacing={0.5} alignItems="center" sx={{ cursor: 'pointer' }}>
          <Typography variant="caption" color="text.secondary" onClick={goToCollection}>
            {collection}
          </Typography>
          {certified && <Certified style={{ width: 16, height: 16 }} />}
        </Stack>
      </Stack>
    </>
  );
};

const listingRenderer = (
  nftSale: NFTSale,
  colors: Record<string, string>,
  handleClick: (event: React.MouseEvent<HTMLButtonElement>, nftSale: NFTSale) => void,
  goTo: () => void,
  goToCollection: () => void
) => {
  const { name, collection, sale, asset_id, thumbnail, token_id } = nftSale;
  const { name: title, certified } = collection;
  const { status, sale_id, quantity, unitary_price_float, floor_diff } = sale;
  const key = `${sale_id}-${asset_id}`;

  const color =
    status == SaleStatus.Cancelled || status == SaleStatus.Ended
      ? colors.red
      : status == SaleStatus.SoldOut
      ? colors.green
      : 'text.primary';

  return (
    <StyledRow key={key}>
      <StyledCell sx={{ flex: 1 }}>
        {Key('Type')}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <EventIcon event={EventType.Sale} />
          <Typography variant="caption">{EventType.Sale}</Typography>
        </Box>
      </StyledCell>
      <StyledCell sx={{ flexDirection: 'row', alignItems: 'center', gap: 1, minWidth: 0, flex: 2 }}>
        {avatar(name || token_id, thumbnail, title, certified, goTo, goToCollection)}
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Price')}
        <Price value={unitary_price_float} size={12} />
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Qty')}
        <Typography variant="body2">{quantity}</Typography>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Floor diff')}
        <Typography variant="body2">{floor_diff}</Typography>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Status')}
        <Typography variant="body2" sx={{ color }}>
          {toStatus[status]}
        </Typography>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'flex-end', flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            '& > *': { color: (theme) => theme.palette.text.secondary }
          }}
        >
          <SquareIconButton size="small" onClick={(action) => handleClick(action, nftSale)}>
            <MoreHorizIcon fontSize="small" />
          </SquareIconButton>
        </Box>
      </StyledCell>
    </StyledRow>
  );
};

const mobileRenderer = (
  nftSale: NFTSale,
  colors: Record<string, string>,
  handleClick: (event: React.MouseEvent<HTMLButtonElement>, nftSale: NFTSale) => void,
  goTo: () => void,
  goToCollection: () => void
) => {
  const { name, collection, sale, asset_id, thumbnail, token_id } = nftSale;
  const { name: title, certified } = collection;
  const { status, sale_id, unitary_price_float, floor_diff } = sale;
  const key = `${sale_id}-${asset_id}`;
  const color =
    status == SaleStatus.Cancelled || status == SaleStatus.Ended
      ? colors.red
      : status == SaleStatus.SoldOut
      ? colors.green
      : 'text.primary';

  return (
    <StyledMobileRow key={key}>
      <StyledCell sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        {avatar(name || token_id, thumbnail, title, certified, goTo, goToCollection)}
      </StyledCell>
      <Stack direction="row" justifyContent="space-between">
        <StyledCell sx={{ flex: 1 }}>
          {Key('Price')}
          <Price value={unitary_price_float} size={12} />
        </StyledCell>
        <StyledCell sx={{ flex: 1, alignItems: 'flex-end' }}>
          {Key('Floor diff')}
          <Typography variant="body2">{floor_diff}</Typography>
        </StyledCell>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
        <StyledCell sx={{ flex: 1 }}>
          {Key('Status')}
          <Typography variant="body2" sx={{ color }}>
            {toStatus[status]}
          </Typography>
        </StyledCell>
        <StyledCell sx={{ flex: 1, alignItems: 'flex-end' }}>
          <SquareIconButton size="small" onClick={(action) => handleClick(action, nftSale)}>
            <MoreHorizIcon fontSize="small" />
          </SquareIconButton>
        </StyledCell>
      </Stack>
    </StyledMobileRow>
  );
};

export const Listings = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { account } = useWeb3React();
  const [status, setStatus] = useState<SaleStatus>(SaleStatus.Live);
  const [kind, setKind] = useState<SaleKind>(SaleKind.All);
  const { data } = useSalesQuery({ address: account, kind }, { skip: !account });
  const [selectedSale, setSelectedSale] = useState<NFTSale | undefined>();

  const sales = useMemo(() => (data?.sales || []) as NFTSale[], [data]);
  // filter status
  const filtered = useMemo(() => {
    if (status == SaleStatus.All) return sales;
    return sales.filter((l) => l.sale.status == status);
  }, [status, data]);

  /* Popper */
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, sale: NFTSale) => {
    const openPopper = event.currentTarget !== anchorEl || !open;
    setAnchorEl(event.currentTarget);
    setOpen(openPopper);
    setSelectedSale(sale);
  };

  const resetPopper = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const cancelSale = () => {
    resetPopper();
    if (selectedSale)
      dispatch(
        batch.create({
          actions: [
            setSale.create({ nftSale: selectedSale }),
            openModal.create({ modal: 'CANCEL SALE' })
          ]
        })
      );
  };

  const changePrice = () => {
    resetPopper();
    if (selectedSale)
      dispatch(
        batch.create({
          actions: [
            setSale.create({ nftSale: selectedSale }),
            openModal.create({ modal: 'UPDATE SALE' })
          ]
        })
      );
  };

  const goTo = (address: string) => navigate(goToNft(address));
  const gotoCollection = (address: string) => navigate(goToCollection(address));
  const current = kind === SaleKind.All ? '' : kind === SaleKind.Auction ? 'Auction' : 'Direct';

  return (
    <div>
      <Popper open={open} anchorEl={anchorEl} placement={'bottom-end'}>
        <PopperContainer>
          <ClickAwayListener onClickAway={handleClose}>
            <InnerPopperContainer>
              <MenuList>
                <StyledMenuItem onClick={() => cancelSale()}>Cancel Sale</StyledMenuItem>
                <StyledMenuItem onClick={() => changePrice()}>Edit Price</StyledMenuItem>
              </MenuList>
            </InnerPopperContainer>
          </ClickAwayListener>
        </PopperContainer>
      </Popper>
      <Activity
        title="Listings"
        el={
          <DropDownSelect
            defaultKey={status}
            pairs={activity_listings_options}
            onSelectChange={(v) => setStatus(v as SaleStatus)}
            disablePortal={false}
            sx={{ width: '10rem' }}
          />
        }
        data={filtered}
        renderer={(el) =>
          matches
            ? listingRenderer(
                el,
                theme.colors,
                (action) => handleClick(action, el),
                () => goTo(el.asset_id),
                () => gotoCollection(el.collection.address)
              )
            : mobileRenderer(
                el,
                theme.colors,
                (action) => handleClick(action, el),
                () => goTo(el.asset_id),
                () => gotoCollection(el.collection.address)
              )
        }
        height={500}
        empty={
          <Typography variant="body1" color="text.primary">
            No listings
          </Typography>
        }
      />
    </div>
  );
};
