import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { Asset } from 'types';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';

type Props = {
  nft: Asset | null | undefined;
};

export const Done = ({ nft }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goTo = (path: string) => {
    dispatch(openModal.create({}));
    navigate(path);
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Typography variant="h6" fontWeight="600" sx={{ color: (theme) => theme.colors.green, mr: 1 }}>
        Congrats! Your NFT has been successfully created
      </Typography>
      <Stack sx={{ width: '100%' }}>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          {nft && (
            <Button ctx="primary" onClick={() => goTo(`${AppRoutes.dashboard}/owned`)} sx={{ marginLeft: '-3.84rem' }}>
              <Typography variant="button">My Collectibles</Typography>
            </Button>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
