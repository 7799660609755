import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useActivities } from 'hooks';
import { ActivityTarget, ActivityType } from 'types/generated';
import { ActivityTable, Container, Banner, Filters, MobileFilters } from 'components';
import { FiltersProvider, useFilters } from 'providers';
import { theme } from 'themes';

const SIZE = 100;

const ActivityPageRenderer = () => {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { collection, activityTypes } = useFilters();
  const { activities, isFetching, loadMore } = useActivities({
    target: ActivityTarget.Global,
    collection_address: collection?.address,
    types: activityTypes.length == 0 ? [ActivityType.All] : activityTypes,
    skip: false,
    size: SIZE
  });

  const filters = matches ? (
    <Filters
      showActivityTypes={true}
      showCollections={true}
      width="15rem"
      sx={{ justifyContent: 'center' }}
    />
  ) : (
    <Box sx={{px:2, mb: 1}}>
      <MobileFilters activeFilters={['collections', 'activityTypes']} />
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Banner title="Activity" subTitle="" />
      {filters}
      <Container>
        <ActivityTable
          activities={activities}
          loading={isFetching}
          loadMore={loadMore}
          useWindowScroll={true}
        />
      </Container>
    </Box>
  );
};

const ActivityPage = (): JSX.Element => {
  return (
    <FiltersProvider>
      <ActivityPageRenderer />
    </FiltersProvider>
  );
};

export default ActivityPage;
