// 3rd party libs
import React from 'react';
import {
  TypographyProps,
  Box,
  Grid,
  Stack,
  Typography,
  styled,
  Skeleton,
  Avatar as MuiAvatar
} from '@mui/material';
import { priceFormatter } from 'lib/format';
import { Avatar, Price } from 'components';

/**
 * Types
 */

type NameRendererProps = {
  name: string | undefined;
  loading: boolean;
} & TypographyProps;

type RankedAvatarProps = {
  rank: number;
  avatar: string;
  certified: boolean;
  loading: boolean;
};

type PriceRendererProps = {
  caption: string;
  value: number;
  loading: boolean;
};

/**
 * Styling
 */

export const StyledGrid = styled(Grid)({
  padding: '1rem'
});

export const StyledBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0.5rem',
  background: 'transparent',
  boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 4px;',
  border: theme.border,
  transition: 'all 0.2s',
  padding: '0.5rem',
  ':hover': {
    border: theme.borderHover,
    background:
      theme.palette.mode == 'dark'
        ? theme.colors.backgroundSecondaryDark
        : theme.colors.backgroundPrimaryLight
  }
}));

export const PriceRenderer = ({ caption, value, loading }: PriceRendererProps) => {
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={{ xs: 0, sm: 1 }}>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      >
        {loading ? <Skeleton width={60} /> : caption}
      </Typography>
      {loading ? <Skeleton width={60} /> : <Price value={priceFormatter.format(value)} size={12} />}
    </Stack>
  );
};

export const RankedAvatar = ({ rank, avatar, certified, loading }: RankedAvatarProps) => {
  return (
    <>
      <Typography variant="subtitle1" sx={{ p: '1rem', fontWeight: 700 }}>
        {loading ? <Skeleton width={10} /> : rank + '.'}
      </Typography>
      {loading ? (
        <Skeleton variant="circular">
          <MuiAvatar sx={{ width: '3rem', height: '3rem', borderRadius: '50%' }} />
        </Skeleton>
      ) : (
        <Avatar
          src={avatar}
          top={20}
          approved={certified}
          sx={{ width: '3rem', height: '3rem', borderRadius: '50%' }}
        />
      )}
    </>
  );
};

export const NameRenderer = ({ name, loading, ...rest }: NameRendererProps) => {
  return (
    <Typography variant="body1" color="text.primary" {...rest}>
      {loading ? <Skeleton width={100} /> : name}
    </Typography>
  );
};
