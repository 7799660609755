import createPalette from '@mui/material/styles/createPalette';
import createTheme from '@mui/material/styles/createTheme';
import createTypography from '@mui/material/styles/createTypography';

const theme = createTheme();
export const typography = createTypography(createPalette({}), {
  fontFamily: ['Averta CY', 'Open Sans', 'Roboto'].join(','),
  fontSize: 14,
  h1: {
    fontSize: '2.75rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem'
    }
  },
  h2: {
    fontSize: '2.0rem',
    fontWeight: 700
  },
  h3: {
    fontSize: '1.75rem',
    fontWeight: 700
  },
  h4: {
    fontSize: '1.375rem',
    fontWeight: 700
  },
  h5: {
    fontSize: '1.2rem',
    fontWeight: 600
  },
  h6: {
    fontSize: '1.0rem',
    fontWeight: 600
  },
  button: {
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 600
  },
  subtitle1: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    fontWeight: 400
  },
  subtitle2: {
    fontSize: '1rem',
    lineHeight: '1.2rem',
    fontWeight: 400
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.2rem',
    fontWeight: 400
  },
  body2: {
    fontSize: '0.96rem',
    lineHeight: '1.1rem',
    fontWeight: 400
  },
  caption: {
    fontSize: '0.8rem',
    lineHeight: '0.9rem',
    fontWeight: 400
  }
});
