// 3rd party libs
import React from 'react';
import { Box, Link, Skeleton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { formatAddress } from 'helpers/formatAddress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Explore from '@mui/icons-material/NorthEast';
import { useNetwork, useNotistack } from 'hooks';
import { HtmlTooltip } from 'components';

type SharecodesProps = {
  sharecode: string;
  color?: string;
  variant?: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "overline" | "subtitle1" | "subtitle2" | "body1" | "body2" | undefined;
};



const Sharecode = ({
  sharecode,
  color,
  variant
}: SharecodesProps) => {
  const { success } = useNotistack();

  const sx: SxProps<Theme> = {
    cursor: 'pointer',
    color: color ? color : (theme) => theme.palette.text.secondary,
    ':hover *': { color: (theme) => theme.palette.text.primary }
  };

  // Copy to clipboard
  const copyText = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(sharecode.toUpperCase() || '').then(() => success('Sharecode copied to clipboard'));
  };
  return (
    <Box
      onClick={copyText}
      sx={sx}
    >
      <HtmlTooltip
        placement="top"
        title={
            <Typography
              variant={variant}
              sx={sx}
            >
              {'Copy Text : ' + sharecode}
            </Typography>
        }
        sx={{
          '& .MuiTooltip-tooltip': {
            backgroundColor: (theme) => theme.palette.background.paper,
            borderRadius: '0.75rem',
            padding: '0.5rem 1rem'
          },
          '& .MuiTooltip-arrow': {
            color: (theme) => theme.input.background
          }
        }}
      >
        <Typography variant={variant} sx={sx}>
          <Stack 
            direction="row"
            alignItems="center"
          >
            <Box>{sharecode.toUpperCase()}</Box>
            <ContentCopyIcon fontSize={'small'} sx={{ml: 1}} />
          </Stack>
        </Typography>
      </HtmlTooltip>
    </Box>
  );
};

export default Sharecode;
