import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { OwnerShipStep, Button, Unlisting } from 'components';
import { Divider } from '@mui/material';
import { openModal } from 'store/actions';
import { Renderer } from '../Renderer';

type ConfirmProps = {
  close: () => void;
  confirm: () => void;
};

const Confirm = ({ confirm, close }: ConfirmProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1" color="text.primary" textAlign="center">
        Do you confirm the cancellation of your sale ?
      </Typography>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button ctx="primary" sx={{ width: '8rem' }} onClick={() => confirm()}>
          <Typography variant="button">Confirm</Typography>
        </Button>
        <Button ctx="secondary" sx={{ width: '8rem' }} onClick={() => close()}>
          <Typography variant="button">Cancel</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

const CancelSale = () => {
  const dispatch = useDispatch();
  const nftSale = useSelector((global: GlobalState) => global.state.nftSale);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    setCurrentStep(1);
  }, []);

  return (
    //todo pass listing to renderer
    <Renderer title="Cancel Sale" nftSale={nftSale}>
      <Divider sx={{ my: 3 }} variant="middle" />
      {!confirm && nftSale && (
        <Confirm confirm={() => setConfirm(true)} close={() => dispatch(openModal.create({}))} />
      )}
      {/** Steps */}
      {confirm && nftSale && (
        <Stack spacing={2}>
          <OwnerShipStep step={1} currentStep={currentStep} complete={() => setCurrentStep(2)} />
          <Unlisting
            step={2}
            currentStep={currentStep}
            nftSale={nftSale}
            complete={() => undefined}
          />
        </Stack>
      )}
    </Renderer>
  );
};

export default CancelSale;
