/** This component formats and renders the prices
 * For the moment we only use avax,
 * but it should be Flexible enough to support more currencies/cryptos in the future
 */
import * as React from 'react';
import { Skeleton, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { ReactComponent as AvaxDark } from '../../assets/cards/avax-dark.svg';
import { ReactComponent as AvaxLight } from '../../assets/cards/avax-light.svg';
import { ReactComponent as Wavax } from '../../assets/cards/wavax.svg';
import { PriceSymbol } from 'types';
import { priceFormatter } from 'lib/format';

type Props = {
  value: number | string;
  // currency/crypto identifier
  symbol?: PriceSymbol;
  variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline'
    | undefined;
  size?: number;
  isLoading?: boolean;
} & StackProps;

const getSymbol = (mode: 'dark' | 'light', symbol: PriceSymbol, size = 15) => {
  let defaultSymbol = <AvaxDark width={15} height={15} />;

  if (mode == 'dark') {
    if (symbol == PriceSymbol.AVAX) defaultSymbol = <AvaxDark width={size} height={size} />;
    else if (symbol == PriceSymbol.WAVAX) defaultSymbol = <Wavax width={size} height={size} />;
  } else if (mode == 'light') {
    if (symbol == PriceSymbol.AVAX) defaultSymbol = <AvaxLight width={size} height={size} />;
    else if (symbol == PriceSymbol.WAVAX) defaultSymbol = <Wavax width={size} height={size} />;
  }

  return defaultSymbol;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Price = ({
  value,
  symbol,
  variant = 'h6',
  fontWeight,
  fontSize,
  size,
  alignItems = 'center',
  isLoading,
  ...rest
}: Props) => {
  const mode = useTheme().palette.mode;
  return (
    <Stack {...rest} direction="row" alignItems={alignItems} spacing={1}>
      {!isLoading && getSymbol(mode, symbol || PriceSymbol.AVAX, size)}
      <Typography
        variant={variant}
        color="text.primary"
        fontWeight={fontWeight}
        fontSize={fontSize}
      >
        {isLoading ? (
          <Skeleton width={30} />
        ) : typeof value == 'string' ? (
          value
        ) : (
          priceFormatter.format(Number(value))
        )}
      </Typography>
    </Stack>
  );
};

export default Price;
