import React, { useState } from 'react';
import { Stack, Typography, Link, useTheme, SxProps, Theme, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SquareIconButton from 'components/SquareIconButton';

type Props = {
  text: string;
  link?: { url: string, target: string, label: string  };
};

const sxClose: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  right: 0,
  transform : 'scale(0.5)',
  transforOrigin: 'center right',
  cursor: 'pointer',
  minWidth: 'auto',
  height: '100%'
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BannerTopAnnouncement = ({ text, link }: Props) => {
  const theme = useTheme();
  const [isShown, setIsShown] = useState(true);

  const handleClick = () => {
    setIsShown(current => !current);
  };

  return (
    <Stack sx={{ position: 'relative', padding: '0.5rem', backgroundColor: theme.colors.buttonBlue, display: isShown ? 'block' : 'none' }}>
      <SquareIconButton onClick={handleClick} sx={sxClose} title="Close">
        <CloseIcon style={{ color: theme.colors.white }} />
      </SquareIconButton>
      <Typography variant="body2" sx={{ color: theme.colors.white, textAlign: 'center', px: '2rem', fontSize: '0.96rem' }}>
        {text}
        {link && (
        <Link href={link.url} variant="body2" sx={{ fontWeight: 600, ml: 1, color: theme.colors.white, display: 'block', fontSize: '0.96rem'  }} target={link.target} >
          {link.label}
        </Link>
      )}
      </Typography>
    </Stack>
  );
};

export default BannerTopAnnouncement;
