import * as React from 'react';
import { KeyValuePair, NumberOrString } from 'types';
import { Typography, BoxProps, MenuList, Popper, Grow, ClickAwayListener } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Radio } from 'components';
import { StyledDropDown, PopperContainer, InnerPopperContainer, StyledMenuItem } from '../utils';

type Props = {
  empty?: string;
  pairs: KeyValuePair<NumberOrString, NumberOrString>[];
  defaultKey?: NumberOrString;
  onSelectChange?: (key: NumberOrString) => void;
  disablePortal?: boolean;
} & BoxProps;

const menuItem = (
  key: NumberOrString,
  value: NumberOrString,
  checked: boolean,
  click: (key: NumberOrString) => void
) => {
  return (
    <StyledMenuItem key={`${key}-${value}`} onClick={() => click(key)}>
      <Radio checked={checked} />
      {value}
    </StyledMenuItem>
  );
};

const DropDownSelect = ({
  pairs,
  onSelectChange,
  empty,
  defaultKey,
  disablePortal = true,
  ...rest
}: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [selected, setSelected] = React.useState<NumberOrString>(defaultKey || '');

  React.useEffect(() => {
    setSelected(defaultKey || '');
  }, [defaultKey]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleClick = (key: NumberOrString) => {
    setSelected(key);
    onSelectChange?.(key);
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  return (
    <>
      <StyledDropDown
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        {...rest}
      >
        <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
          {pairs.find((pair) => pair.key == selected)?.value || empty}
        </Typography>
        <KeyboardArrowDownIcon />
      </StyledDropDown>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal={disablePortal}
        style={{ width: anchorRef.current?.clientWidth, zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <PopperContainer>
              <ClickAwayListener onClickAway={handleClose}>
                <InnerPopperContainer id="composition-menu">
                  <MenuList onKeyDown={handleListKeyDown}>
                    {empty && menuItem('', empty, !selected, handleClick)}
                    {pairs.map(({ key, value }) =>
                      menuItem(key, value, selected == key, handleClick)
                    )}
                  </MenuList>
                </InnerPopperContainer>
              </ClickAwayListener>
            </PopperContainer>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropDownSelect;
