import React from 'react';
import { Grid, styled, Box, Typography } from '@mui/material';
import { Activity, Price, SquareIconButton } from 'components';
import { useListingsAndOffersQuery } from 'api';
import { LAndOResult, NftBase, NftMetadatas, Collection } from 'types/generated';
import { priceFormatter } from 'lib/format';
import { PriceSymbol } from 'types';
import { formatAddress } from 'helpers/formatAddress';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch } from 'react-redux';
import { batch, openModal, setOffer, setSale } from 'store/actions';
import { toNftOffer, toNftSale, toNFTSalePreview } from '../utils';
import { useWeb3React } from '@web3-react/core';
import CloseIcon from '@mui/icons-material/Close';
import { formatDistance, fromUnixTime } from 'date-fns';
import { equalsIgnoringCase } from 'helpers/common';
import { goToArtist } from 'helpers/artists';
import { useNavigate } from 'react-router-dom';

type Props = {
  assetId: string;
  base: NftBase;
  metadata: NftMetadatas;
  collection: Collection;
  owner: string;
};

const StyledRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '4.5rem',
  padding: theme.spacing(1),
  borderRadius: '0.5rem',
  ':hover': { background: theme.palette.action.hover }
}));

const StyledCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0.5),
  height: '100%'
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  borderRadius: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  height: '4rem',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  gap: '1rem',
  ':hover': {
    background: theme.palette.action.hover,
    transition: 'all 0.2s'
  }
}));

const LinkedTypography = styled(Typography)({
  cursor: 'pointer'
});

const Key = (caption: string) => {
  return (
    <Typography variant="caption" color="text.secondary">
      {caption}
    </Typography>
  );
};

const offerRenderer = (offer: LAndOResult, isMine: boolean, accept: () => void) => {
  const { id, price, quantity, expiration, floor_diff } = offer;
  return (
    <StyledRow key={id}>
      <StyledCell sx={{ flex: 1, alignItems: 'flex-start' }}>
        <StyledCell sx={{ flex: 1, alignItems: 'center' }}>
          {Key('Price')}
          <Price value={priceFormatter.format(price)} size={12} symbol={PriceSymbol.WAVAX} />
        </StyledCell>
      </StyledCell>
      <StyledCell sx={{ flex: 1, alignItems: 'center' }}>
        {Key('Qty')}
        <Typography variant="body2">{quantity}</Typography>
      </StyledCell>
      <StyledCell sx={{ flex: 1, alignItems: 'center' }}>
        {Key('Expiration')}
        <Typography variant="body2">
          {formatDistance(fromUnixTime(expiration), new Date())}
        </Typography>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Floor Diff')}
        <Typography variant="body2" textAlign="center">
          {floor_diff}
        </Typography>
      </StyledCell>
      {isMine && (
        <StyledCell sx={{ alignItems: 'flex-end', flex: 1 }}>
          <SquareIconButton size="small" onClick={accept}>
            <CheckIcon fontSize="small" />
          </SquareIconButton>
        </StyledCell>
      )}
    </StyledRow>
  );
};

const listingRenderer = (
  listing: LAndOResult,
  isMine: boolean,
  buy: () => void,
  cancel: () => void
) => {
  const { id, price, quantity, expiration, from_address } = listing;
  const navigate = useNavigate();
  return (
    <StyledRow key={id}>
      <StyledCell sx={{ flex: 1, alignItems: 'flex-start' }}>
        <StyledCell sx={{ flex: 1, alignItems: 'center' }}>
          {Key('Price')}
          <Price value={priceFormatter.format(price)} size={12} symbol={PriceSymbol.AVAX} />
        </StyledCell>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Qty')}
        <Typography variant="body2">{quantity}</Typography>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('Expiration')}
        <Typography variant="body2">
          {formatDistance(fromUnixTime(expiration), new Date())}
        </Typography>
      </StyledCell>
      <StyledCell sx={{ alignItems: 'center', flex: 1 }}>
        {Key('From')}
        <LinkedTypography
            variant="body2"
            textAlign="center"
            onClick={() => navigate(goToArtist(from_address))}
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
          {formatAddress(from_address)}
          </LinkedTypography>
      </StyledCell>
      {isMine ? (
        <StyledCell sx={{ alignItems: 'flex-end', flex: 1 }}>
          <SquareIconButton size="small" onClick={cancel}>
            <CloseIcon fontSize="small" />
          </SquareIconButton>
        </StyledCell>
      ) : (
        <StyledCell sx={{ alignItems: 'flex-end', flex: 1 }}>
          <SquareIconButton size="small" onClick={buy}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ px: 1, py: 0.5, borderRadius: '0.5rem' }}
            >
              Buy
            </Typography>
          </SquareIconButton>
        </StyledCell>
      )}
    </StyledRow>
  );
};

export const ListingsAndOffers = ({
  assetId,
  base,
  metadata,
  collection,
  owner
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const { data } = useListingsAndOffersQuery({ assetId }, { skip: !assetId });
  const listings = data?.listingsAndOffers.listings || [];
  const offers = data?.listingsAndOffers.offers || [];

  const acceptOffer = (offer: LAndOResult) => {
    dispatch(
      batch.create({
        actions: [
          setOffer.create({ nftOffer: toNftOffer(base, metadata, collection, offer) }),
          openModal.create({ modal: 'ACCEPT OFFER' })
        ]
      })
    );
  };

  const buy = (offer: LAndOResult) => {
    dispatch(
      batch.create({
        actions: [
          setSale.create({
            nftSale: toNftSale(base, metadata, collection, toNFTSalePreview(offer))
          }),
          openModal.create({ modal: 'BUY ITEM' })
        ]
      })
    );
  };

  const cancel = (offer: LAndOResult) => {
    dispatch(
      batch.create({
        actions: [
          setSale.create({
            nftSale: toNftSale(base, metadata, collection, toNFTSalePreview(offer))
          }),
          openModal.create({ modal: 'CANCEL SALE' })
        ]
      })
    );
  };

  return (
    <Grid container columnSpacing={{ xs: 4 }}>
      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mb: { xs: 4 } }}>
        <Activity
          title="Listings"
          data={listings}
          renderer={(el) =>
            listingRenderer(
              el,
              equalsIgnoringCase(account, el.from_address),
              () => buy(el),
              () => cancel(el)
            )
          }
          height={400}
          empty={
            <Typography variant="body1" color="text.primary">
              No listings
            </Typography>
          }
          sx={{ py: '2rem', px: '1rem' }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Activity
          title="Offers"
          data={offers}
          renderer={(el) =>
            offerRenderer(el, equalsIgnoringCase(account, owner), () => acceptOffer(el))
          }
          height={400}
          empty={
            <Typography variant="body1" color="text.primary">
              No offers
            </Typography>
          }
          sx={{ py: '2rem', px: '1rem' }}
        />
      </Grid>
    </Grid>
  );
};
