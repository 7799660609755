import React from 'react';
import { Typography } from '@mui/material';
import { PreviewNftCard, Swiper } from 'components';
import { useSale } from '../context/saleContext';
import { PriceSymbol, SaleType } from 'types';
import { Sale } from '../type';
import { useNavigate } from 'react-router-dom';
import { goToNft } from 'helpers/nfts';

const emptyPreview = (
  <div style={{ padding: '0.5rem' }}>
    <PreviewNftCard />
  </div>
);

const SalesPreview = () => {
  const navigate = useNavigate();
  const { sales, auction, type } = useSale();
  const direct: Sale[] = [];
  if (type == SaleType.auction && auction) direct.push(auction);
  else sales.forEach((sale, _) => direct.push(sale));

  const goTo = (asset_id: string) => navigate(goToNft(asset_id));

  const getContent = () => {
    const content: JSX.Element[] = [];
    if (direct.length == 0) return [emptyPreview];
    direct.forEach((nft, key) => {
      const price = nft.price;
      content.push(
        <div
          key={key}
          style={{ padding: '0.5rem', cursor: 'pointer' }}
          onClick={() => goTo(nft.asset_id)}
        >
          <PreviewNftCard
            previewUri={nft.thumbnail}
            name={nft.name}
            avatar={nft.collection.avatar}
            displayTheme={nft.collection.display_theme}
            price={price}
            type={type}
            symbol={PriceSymbol.AVAX}
          />
        </div>
      );
    });
    return content;
  };

  return (
    <Swiper
      el={
        <Typography variant="h4" color="text.primary" fontWeight={500}>
          Preview
        </Typography>
      }
      navigation={true}
      items={getContent()}
      options={{ spaceBetween: 20 }}
    />
  );
};

export default SalesPreview;
