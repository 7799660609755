import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Property, Sizes } from 'types';

/**
 * Provide gird resize context for children of the same page
 */

type GridSize = 'comfy' | 'dense';
type ContextType = {
  size: GridSize;
  config: Sizes;
  resize: (size: GridSize) => void;
};

const defaultConfig = {
  comfy: {
    xs: '100%',
    sm: '50%',
    md: '33.33%',
    lg: '25%',
    xl: '20%'
  },
  dense: {
    xs: '100%',
    sm: '50%',
    md: '25%',
    lg: '20%',
    xl: '16.66%'
  }
};

const defaultValue = {
  size: 'comfy' as GridSize,
  config: defaultConfig.comfy,
  resize: () => undefined
};

const GridSizeContext = createContext<ContextType>(defaultValue);

export const useResize = (): ContextType => {
  return useContext(GridSizeContext);
};

type Props = {
  size?: GridSize;
  config?: Record<GridSize, Sizes>;
  children: ReactNode;
};

export const GridSizeProvider = (props: Props): JSX.Element => {
  const [size, setSize] = useState<GridSize>(props.size || 'comfy');
  const [gridConfig, setGidConfig] = useState<Record<GridSize, Sizes>>({
    ...defaultConfig,
    ...props.config
  });

  const resize = (s: GridSize) => {
    if (s !== size) setSize(s);
  };

  const value = {
    size,
    resize,
    config: gridConfig[size]
  };
  return <GridSizeContext.Provider value={value}>{props.children}</GridSizeContext.Provider>;
};
