import * as React from 'react';

import { FormControlLabel, Stack, StackProps, Typography } from '@mui/material';
import { Checkbox } from 'components';

type Props = {
  current: string;
  options: [string, string];
  click: (size: string) => void;
} & StackProps;

const buttunRenderer = (active: boolean, option: string, click: () => void) => {
  return (
    <FormControlLabel
      onChange={click}
      control={<Checkbox checked={active} />}
      label={
        <Typography
          variant="body2"
          sx={{
            color: (theme) => (active ? theme.palette.text.primary : theme.palette.text.secondary)
          }}
        >
          {option}
        </Typography>
      }
    />
  );
};

export const Switcher = ({ current, options, click }: Props) => {
  return (
    <Stack direction="row">
      {buttunRenderer(current === options[0], options[0], () => click(options[0]))}
      {buttunRenderer(current === options[1], options[1], () => click(options[1]))}
    </Stack>
  );
};
