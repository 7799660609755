import React, { useState } from 'react';
import { Typography, Stack, styled } from '@mui/material';
import { Button, InputField } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { useUpdateNotificationSettingsMutation, useUpdateProfileMutation } from 'api';
import { isValidEmail } from 'helpers/validators';
import { useNotistack } from 'hooks';

const Container = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  background: 'transparent'
}));

const EmailNotifs = () => {
  const dispatch = useDispatch();
  const { success, error } = useNotistack();
  // Global State
  const userEmail = useSelector((global: GlobalState) => global.state.userEmail);

  // API Calls
  const [updateUserEmail] = useUpdateProfileMutation();
  const [updateEmailNotif] = useUpdateNotificationSettingsMutation();

  // Local State
  const [mail, setMail] = useState<string | undefined>(userEmail);
  const [err, setErr] = useState('');

  const connect = () => {
    if (!mail) setErr('Please enter your email address');
    else if (!isValidEmail(mail)) setErr('Invalid email address');
    else
      updateEmailNotif({ input: { by_email: true } })
        .unwrap()
        .then(() => {
          success('Notifications successfully updated');
        })
        .catch(() => error('Notifications Update Failed!'));
  };

  const update = () => {
    if (!mail) setErr('Please enter your email address');
    else if (!isValidEmail(mail)) setErr('Invalid email address');
    updateUserEmail({ input: { mail: mail } })
      .unwrap()
      .then(() => {
        success('Email successfully updated');
      })
      .catch(() => error('Email Update Failed!'));
  };

  return (
    <Container spacing={2}>
      <Typography variant="body1" sx={{ fontSize: 20 }}>
        Connect email
      </Typography>

      <InputField
        sx={{
          height: '3.5rem',
          fontSize: 14,
          px: 2,
          borderRadius: '0.9375rem'
        }}
        label={'Email'}
        value={mail}
        err={err}
        set={(v) => setMail(v.toString())}
        placeholder={'Enter your email'}
      />
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          ctx="secondary"
          color="success"
          disabled={mail === userEmail}
          onClick={update}
          sx={{ width: '8rem' }}
        >
          <Typography variant="button">Update</Typography>
        </Button>
        <Button ctx="primary" onClick={connect} sx={{ width: '8rem' }}>
          <Typography variant="button">Connect</Typography>
        </Button>
      </Stack>
    </Container>
  );
};

export default EmailNotifs;
