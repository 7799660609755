/*eslint-disable*/

import React from "react";
import PropTypes from "prop-types";
import pannellum from "./libs/pannellum.js";
import { myPromise } from "./utils/utils";
import { configs } from "./utils/constants";
import "./css/pannellum.css";

let myPannellum:any = null;

interface Props {
  id: string;
  sceneId: string;
  children?: any;
  type?: string;
  imageSource?: string;
  equirectangularOptions?: {};
  cubeMap?: [];
  multiRes?: {
    basePath?: string;
    path?: string;
    fallbackPath?: string;
    extension?: string;
    tileResolution?: number;
    maxLevel?: number;
    cubeResolution?: number;
  };
  config?: {};
  className?: string;
  style?: {};
  onPanoramaLoaded?: () => void;
}

class ReactPannellum extends React.Component<Props> {

  static defaultProps = {
    type: "equirectangular",
    imageSource: "",
    equirectangularOptions: {},
    cubeMap: [],
    multiRes: {},
    className: "",
    style: configs.styles,
    config: {},
  };

  state = {
    imageSource: "",
    equirectangularOptions: {},
    cubeMap: [],
    multiRes: {},
  };

  init = () => {
    const {
      imageSource,
      equirectangularOptions,
      cubeMap,
      multiRes,
    } = this.state;
    const { sceneId, config, type, id, onPanoramaLoaded } = this.props;
    myPannellum = pannellum.viewer(id, {
      default: {
        firstScene: sceneId,
      },
      scenes: {
        [sceneId]: {
          ...configs.panoramaConfigs,
          ...configs.equirectangularOptions,
          ...configs.uiText,
          ...config,
          type,
          imageSource,
          ...equirectangularOptions,
          cubeMap,
          multiRes,
        },
      },
    });
    onPanoramaLoaded &&
      myPannellum.on("load", () => onPanoramaLoaded());
  };

  initPanalleum() {
    const {
      imageSource,
      type,
      cubeMap,
      multiRes,
      equirectangularOptions,
    } = this.props;
    switch (type) {
      case "equirectangular":
        this.setState(
          {
            imageSource,
            equirectangularOptions,
            cubeMap: [],
          },
          () => this.init()
        );
        break;
      case "cubemap":
        this.setState(
          {
            cubeMap,
            imageSource: "",
          },
          () => this.init()
        );
        break;
      case "multires":
        this.setState(
          {
            cubeMap: [],
            imageSource: "",
            multiRes,
          },
          () => this.init()
        );
        break;
      default:
        break;
    }
  }

  componentDidMount() {
    this.initPanalleum();
  }

  componentWillUnmount() {
    myPannellum &&
      this.props.onPanoramaLoaded &&
      myPannellum.off("load", this.props.onPanoramaLoaded);
  }

  static isLoaded() {
    return myPannellum && myPannellum.isLoaded();
  }

  static getPitch() {
    return myPannellum && myPannellum.getPitch();
  }

  static setPitch(pitch: any, animated = 1000, callback: any, callbackArgs: any) {
    if (myPannellum) {
      myPannellum.setPitch(pitch, animated, callback, callbackArgs);
    }
  }

  static getPitchBounds() {
    return myPannellum && myPannellum.getPitchBounds();
  }

  static setPitchBounds(bounds: any) {
    if (myPannellum) {
      myPannellum.setPitchBounds(bounds);
    }
  }

  static getYaw() {
    return myPannellum && myPannellum.getYaw();
  }

  static setYaw(yaw: any, animated = 1000, callback: any, callbackArgs: any) {
    if (myPannellum) {
      myPannellum.setYaw(yaw, animated, callback, callbackArgs);
    }
  }

  static getYawBounds() {
    return myPannellum && myPannellum.getYawBounds();
  }

  static setYawBounds(bounds: any) {
    myPromise(myPannellum, { bounds })
      .then(({ bounds }) => {
        myPannellum.setYawBounds(bounds);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static getHfov() {
    return myPannellum && myPannellum.getHfov();
  }

  static setHfov(hfov: any, animated = 1000, callback: any, callbackArgs: any) {
    if (myPannellum) {
      myPannellum.setHfov(hfov, animated, callback, callbackArgs);
    }
  }

  static getHfovBounds() {
    return myPannellum && myPannellum.getHfovBounds();
  }

  static setHfovBounds(bounds: any) {
    myPromise(myPannellum, { bounds })
      .then(({ bounds }) => {
        myPannellum.setHfovBounds(bounds);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static lookAt(pitch: any, yaw: any, hfov: any, animated = 1000, callback: any, callbackArgs: any) {
    if (myPannellum) {
      myPannellum.lookAt(pitch, yaw, hfov, animated, callback, callbackArgs);
    }
  }

  static getNorthOffset() {
    return myPannellum && myPannellum.getNorthOffset();
  }

  static setNorthOffset(heading: any) {
    myPromise(myPannellum, { heading })
      .then(({ heading }) => {
        myPannellum.setNorthOffset(heading);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static getHorizonRoll() {
    return myPannellum && myPannellum.getHorizonRoll();
  }

  static setHorizonRoll(roll: any) {
    myPromise(myPannellum, { roll })
      .then(({ roll }) => {
        myPannellum.setHorizonRoll(roll);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static getHorizonPitch() {
    return myPannellum && myPannellum.getHorizonPitch();
  }

  static setHorizonPitch(pitch: any) {
    myPromise(myPannellum, { pitch })
      .then(({ pitch }) => {
        myPannellum.setHorizonPitch(pitch);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static startAutoRotate(speed: any, pitch: any) {
    myPromise(myPannellum, { pitch })
      .then(({ pitch }) => {
        myPannellum.startAutoRotate(speed, pitch);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static stopAutoRotate() {
    if (myPannellum) {
      myPannellum.stopAutoRotate();
    }
  }

  static mouseEventToCoords(event: any) {
    return myPannellum && myPannellum.mouseEventToCoords(event);
  }

  static addScene(sceneId: string, config: any, callback: any) {
    if (sceneId && sceneId !== "" && config) {
      myPromise(myPannellum, { sceneId, config, callback })
        .then(({ sceneId, config, callback }) => {
          myPannellum.addScene(sceneId, config);
          callback && callback();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(
        "sceneId cannot be empty and config.imageSource cannot be empty!!"
      );
    }
  }

  static getCurrentScene() {
    return myPannellum && myPannellum.getScene();
  }

  static getAllScenes() {
    return myPannellum && myPannellum.getAllScenes();
  }

  static removeScene(sceneId: string, callback: () => any) {
    if (sceneId && sceneId !== "") {
      myPromise(myPannellum, { sceneId })
        .then(({ sceneId }) => {
          myPannellum.removeScene(sceneId);
          callback && callback();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("sceneId cannot be empty");
    }
  }

  static loadScene(sceneId: string, targetPitch: any, targetYaw: any, targetHfov: any, fadeDone: any) {
    if (myPannellum && sceneId && sceneId !== "") {
      myPannellum.loadScene(
        sceneId,
        targetPitch,
        targetYaw,
        targetHfov,
        fadeDone
      );
    }
  }

  static toggleFullscreen() {
    return myPannellum && myPannellum.toggleFullscreen();
  }

  static getConfig() {
    return myPannellum && myPannellum.getConfig();
  }

  static getContainer() {
    return myPannellum && myPannellum.getContainer();
  }

  static addHotSpot(hotspot: any, sceneId: any) {
    if (hotspot) {
      myPromise(myPannellum, { hotspot, sceneId })
        .then(({ hotspot, sceneId }) => {
          myPannellum.addHotSpot(hotspot, sceneId);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(
        "hotspot cannot be empty, please check hotspot elements needed in document: config props `hotSpots`."
      );
    }
  }

  static removeHotSpot(hotSpotId: string, sceneId: any) {
    if (hotSpotId !== "") {
      myPromise(myPannellum, { hotSpotId, sceneId })
        .then(({ hotSpotId, sceneId }) => {
          myPannellum.removeHotSpot(hotSpotId, sceneId);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("hotspotId cannot be empty!!");
    }
  }

  static destroy() {
    return myPannellum && myPannellum.destroy();
  }

  static stopMovement() {
    return myPannellum && myPannellum.stopMovement();
  }

  static resize() {
    return myPannellum && myPannellum.resize();
  }

  static isOrientationSupported() {
    return myPannellum && myPannellum.isOrientationSupported();
  }

  static stopOrientation() {
    return myPannellum && myPannellum.stopOrientation();
  }

  static startOrientation() {
    return myPannellum && myPannellum.startOrientation();
  }

  static isOrientationActive() {
    return myPannellum && myPannellum.isOrientationActive();
  }

  static getViewer() {
    return myPannellum;
  }

  render() {
    const { style, className, id, children } = this.props;
    return (
      <div id={id} style={style} className={className} children={children} />
    );
  }
}

export default ReactPannellum;