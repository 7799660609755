import React from 'react';
import { Stack } from '@mui/material';
import { ActivityTable, Filters } from 'components';
import { EnhancedActivity, SortState } from 'types';
import { ActivityTarget, ActivityType, ActivityResult } from 'types/generated';
import { useNftActivityQuery } from 'api';
import { FiltersProvider, useFilters } from 'providers';

type Props = {
  assetId: string;
};

export const initSortState = (): Record<string, SortState> => {
  return {
    type: SortState.unsorted,
    price: SortState.unsorted,
    timestamp: SortState.unsorted,
    quantity: SortState.unsorted
  };
};

export const toActivity = (activities?: ActivityResult[]): EnhancedActivity[] => {
  if (!activities) return [];
  return activities?.map((a) => {
    const name = a.nft ? a.nft?.name : '';
    return { ...a, name };
  });
};

export const ActivityRenderer = ({ assetId }: Props): JSX.Element => {
  const { activityTypes } = useFilters();
  const { data, isLoading } = useNftActivityQuery(
    {
      input: {
        target: ActivityTarget.Nft,
        asset_id: assetId,
        types: activityTypes.length == 0 ? [ActivityType.All] : activityTypes
      }
    },
    { skip: !assetId }
  );

  const activities = (data?.activity.activities as ActivityResult[]) || [];
  const height = activities.length * 72 < 400 ? activities.length * 72 : 400;

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Filters showActivityTypes={true} width="15rem" />
      <ActivityTable
        activities={toActivity(activities)}
        loading={isLoading}
        useWindowScroll={false}
        isNftPage={true}
        height={height}
        minHeight={250}
      />
    </Stack>
  );
};

const NFTActivity = ({ assetId }: Props): JSX.Element => {
  return (
    <FiltersProvider>
      <ActivityRenderer assetId={assetId} />
    </FiltersProvider>
  );
};

export default NFTActivity;
