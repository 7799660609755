// 3rd party libs
import React from 'react';
import { Link, Skeleton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { formatAddress } from 'helpers/formatAddress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Explore from '@mui/icons-material/NorthEast';
import { useNetwork, useNotistack } from 'hooks';
import { HtmlTooltip } from 'components';

type AddressProps = {
  address?: string;
  copy?: boolean;
  exploreAddress?: boolean;
  exploreToken?: boolean;
  tokenId?: string;
  length?: number;
  loading?: boolean;
};

const sx: SxProps<Theme> = {
  cursor: 'pointer',
  color: (theme) => theme.palette.text.secondary,
  ':hover': { color: (theme) => theme.palette.text.primary }
};

const Address = ({
  address,
  tokenId,
  copy,
  exploreAddress,
  exploreToken,
  length,
  loading
}: AddressProps) => {
  const { network } = useNetwork();
  const { success } = useNotistack();
  // Copy to clipboard
  const copyAddress = () =>
    navigator.clipboard.writeText(address || '').then(() => success('Address copied to clipboard'));

  return (
    <Stack direction="row" spacing={1}>
      <HtmlTooltip
        placement="top"
        title={
          !exploreToken && (
            <Typography
              variant="body2"
              color="#FFF"
              sx={{
                background: '#000',
                borderRadius: '0.5rem',
                width: 'fit-content',
                fontSize: 14,
                p: 1
              }}
            >
              {address}
            </Typography>
          )
        }
        sx={{
          '& .MuiTooltip-arrow': {
            color: (theme) => theme.input.background
          }
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {loading ? (
            <Skeleton width={100} />
          ) : exploreToken ? (
            tokenId
          ) : (
            formatAddress(address || '', length)
          )}
        </Typography>
      </HtmlTooltip>

      {copy && <ContentCopyIcon onClick={copyAddress} fontSize={'small'} sx={sx} />}
      {exploreAddress && (
        <Link target="_blank" href={`${network.blockExplorerUrls[1]}${address}`} underline="none">
          <Explore fontSize={'small'} sx={sx} />
        </Link>
      )}
      {exploreToken && (
        <Link
          target="_blank"
          href={`${network.blockExplorerUrls[2]}${address}?a=${tokenId}`}
          underline="none"
        >
          <Explore fontSize={'small'} sx={sx} />
        </Link>
      )}
    </Stack>
  );
};

export default Address;
