import React, { useState } from 'react';
import { Box, Link, Paper, Stack, styled, Typography } from '@mui/material';
import { Button, DropDownInput, Price, SquareIconButton, Step } from 'components';
import { Modal, NumberOrString, PriceSymbol, StepStatus } from 'types';
import useAssets from 'hooks/useAssets';
import { swapAvax, swapWavax } from 'services/exchange';
import { useWeb3React } from '@web3-react/core';
import { useNetwork } from 'hooks';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import { batch, openModal, setPrevious } from 'store/actions';

type SwapState = {
  token: 'avax' | 'wavax';
  price: NumberOrString;
};

const Container = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  background: 'transparent'
}));

export const options = [
  {
    key: 'avax',
    value: <Price value="AVAX" />
  },
  {
    key: 'wavax',
    value: <Price value="WAVAX" symbol={PriceSymbol.WAVAX} />
  }
];

const notStarted = () => {
  return (
    <Typography variant="caption" color="text.secondary">
      You will receive same amount WAVAX after the swap. No fee is charged and the only cost is
      transaction gas.
    </Typography>
  );
};

const done = (explorer: string, hash: string) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Typography variant="body2" sx={{ color: (theme) => theme.colors.green, mr: 1 }}>
        Done!
      </Typography>
      <Link
        target="_blank"
        sx={{ display: 'flex', alignItems: 'center' }}
        href={`${explorer}/${hash}`}
        variant="body2"
      >
        View on block explorer
      </Link>
    </Box>
  );
};

const Swap = (): JSX.Element => {
  const dispatch = useDispatch();
  const previous = useSelector((global: GlobalState) => global.state.previous);
  const { hooks } = useWeb3React();
  const { usePriorityProvider } = hooks;
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { avax, wavax, getAssets } = useAssets();
  const [from, setFrom] = useState<SwapState>({ token: 'avax', price: 0 });
  const [to, setTo] = useState<SwapState>({ token: 'wavax', price: '0.0' });
  const provider = usePriorityProvider();
  const { network } = useNetwork();
  const [txHash, setTxHash] = useState('');

  const onDropDwonChanege = (key: NumberOrString) => {
    if (key == 'avax') {
      setFrom({ token: 'avax', price: '0.0' });
      setTo({ token: 'wavax', price: '0.0' });
    } else {
      setFrom({ token: 'wavax', price: '0.0' });
      setTo({ token: 'avax', price: '0.0' });
    }
  };

  const onSwap = async () => {
    try {
      if (provider) {
        setCurrentStep(1);
        setStatus(StepStatus.IN_PROGRESS);
        if (from.token == 'avax') {
          const response = await swapAvax(provider, from.price as string);
          setTxHash(response?.transactionHash);
          setStatus(StepStatus.DONE);
          getAssets();
        } else {
          const response = await swapWavax(provider, from.price as string);
          setTxHash(response?.transactionHash);
          setStatus(StepStatus.DONE);
          getAssets();
        }
      }
    } catch (err) {
      setStatus(StepStatus.ERROR);
      setError('Swap failed !');
    }
  };

  // Back to previous modal
  const back = (modal: Modal) => {
    dispatch(
      batch.create({
        actions: [setPrevious.create({}), openModal.create({ modal })]
      })
    );
  };

  return (
    <Container spacing={3}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {previous && (
          <SquareIconButton size="small" onClick={() => back(previous)}>
            <ChevronLeftIcon />
          </SquareIconButton>
        )}
        <Typography
          variant="body1"
          color="text.primary"
          textAlign={'center'}
          sx={{ flex: 1, fontSize: 20 }}
        >
          Swap
        </Typography>
      </Box>
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary">
          From
        </Typography>
        <DropDownInput
          defaultKey={from.token}
          value={from.price}
          numeric={true}
          onValueChange={(price) => {
            setFrom({ ...from, price });
            setTo({ ...to, price });
          }}
          onOptionChange={onDropDwonChanege}
          options={options}
        />
        <Stack direction="row" spacing={1}>
          <Typography variant="caption" color="text.secondary">
            Balance:
          </Typography>
          {from.token == 'avax' ? (
            <Price fontWeight={500} value={avax} symbol={PriceSymbol.AVAX} size={10} />
          ) : (
            <Price fontWeight={500} value={wavax} symbol={PriceSymbol.WAVAX} size={10} />
          )}
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary">
          To
        </Typography>
        <Paper
          elevation={0}
          sx={{
            background: (theme) => theme.input.background,
            display: 'flex',
            alignItems: 'center',
            px: 2,
            height: '3.5rem',
            borderRadius: '0.5rem'
          }}
        >
          <Typography variant="body1" color="text.primary" sx={{ flex: 1 }}>
            {to.price}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {from.token == 'avax' ? 'WAVAX' : 'AVAX'}
          </Typography>
        </Paper>
        <Stack direction="row" spacing={1}>
          <Typography variant="caption" color="text.secondary">
            Balance:
          </Typography>
          {to.token == 'avax' ? (
            <Price fontWeight={500} value={avax} symbol={PriceSymbol.AVAX} size={10} />
          ) : (
            <Price fontWeight={500} value={wavax} symbol={PriceSymbol.WAVAX} size={10} />
          )}
        </Stack>
      </Stack>
      <Step
        title={
          status == StepStatus.NOT_STARTED ? (
            notStarted()
          ) : status == StepStatus.IN_PROGRESS ? (
            'Swapping...'
          ) : (
            <></>
          )
        }
        step={1}
        currentStep={currentStep}
        status={status}
        error={error}
        el={status == StepStatus.DONE ? done(network.blockExplorerUrls[0], txHash) : undefined}
      />
      <Stack alignItems={'center'}>
        <Button ctx="primary" sx={{ width: '8rem' }} onClick={onSwap}>
          <Typography variant="button">Swap</Typography>
        </Button>
      </Stack>
    </Container>
  );
};

export default Swap;
