import React, { useEffect, useState } from 'react';
import { Tabs } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

type Props = {
  isCreator?: boolean;
};

const getTabs = (isCreator?: boolean): { label: string; value: string; disabled?: boolean }[] => {
  return [
    { label: 'Items', value: 'items' },
    { label: 'Activity', value: 'activity' },
    { label: 'Stats', value: 'stats' },
    { label: 'Settings', value: 'settings', disabled: !isCreator }
  ];
};

export const CollectionTabs = ({ isCreator }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const index = getTabs(isCreator).findIndex((tab) => location.pathname.includes(tab.value));
    setCurrentTab(index > -1 ? index : 0);
  }, [location]);

  return (
    <Box sx={{ borderBottom: (theme) => theme.border, display: 'flex', justifyContent: 'center' }}>
      <Tabs
        tabs={getTabs(isCreator)}
        currentTab={currentTab}
        onTabChange={(tab) => navigate(tab)}
      />
    </Box>
  );
};
