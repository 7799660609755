import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { SquareIconButton } from 'components';

type SearchInputProps = {
  placeholder: string;
  clearInput: () => void;
  inputChange: (value: string) => void;
  mobile?: boolean;
  mobileCloseClick?: () => void;
  modal?: boolean;
} & BoxProps;

const Container = styled(Box)<{ modal?: boolean }>(({ theme, modal }) => ({
  borderRadius: '0.9375rem',
  backgroundColor: modal ? theme.palette.background.default : theme.input.background,
  marginRight: theme.spacing(2),
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.pxToRem(15),
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0)
  }
}));

const Search = ({
  clearInput,
  inputChange,
  placeholder,
  mobile,
  mobileCloseClick,
  modal,
  ...props
}: SearchInputProps) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (props.defaultValue && typeof props.defaultValue === 'string')
      setValue(props.defaultValue as string);
  }, [props.defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value;
    setValue(inputValue);
    inputChange(inputValue);
    if (!inputValue) clearInput();
  };

  const empty = !value;

  return (
    <Container {...props} modal={modal}>
      <StyledInputBase
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        inputProps={{ style: { fontWeight: 500 } }}
        onKeyDown={(e) => e.stopPropagation()}
        startAdornment={
          mobile ? (
            <SquareIconButton
              sx={{ border: 0 }}
              onClick={() => mobileCloseClick && mobileCloseClick()}
            >
              <KeyboardBackspaceIcon />
            </SquareIconButton>
          ) : (
            <SearchIcon sx={{ ml: 2, mr: 1, color: (theme) => theme.colors.grey100 }} />
          )
        }
        endAdornment={
          <CloseIcon
            sx={{
              mr: 1,
              cursor: empty ? 'default' : 'pointer',
              color: (theme) => (empty ? 'transparent' : theme.colors.grey100),
              '&:hover': { color: (theme) => (!empty ? theme.palette.text.primary : 'transparent') }
            }}
            onClick={() => {
              setValue('');
              clearInput();
            }}
          />
        }
      />
    </Container>
  );
};

export default Search;
