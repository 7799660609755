import React, { createContext, ReactNode, useContext, useState } from 'react';
import { NumberOrString, SaleType } from 'types';
import { UserBalance } from 'types/generated';
import { Sale } from '../type';

type Reserved = {
  isReserved: boolean;
  buyer?: NumberOrString;
};

type ContextType = {
  type: SaleType;
  sales: Map<string, Sale>;
  auction?: Sale;
  startDate: Date;
  expirationDate: Date;
  price?: NumberOrString;
  reservePrice?: NumberOrString;
  reserved: Reserved;
  setAuction: (sale: Sale) => void;
  setStartDate: (date: Date) => void;
  setExpirationDate: (date: Date) => void;
  setType: (type: SaleType) => void;
  setPriceUnit: (price: NumberOrString) => void;
  setReservePrice: (price: NumberOrString) => void;
  setReserved: (resserved: Reserved) => void;
  select: (nft: UserBalance) => void;
  unSelect: (id: string) => void;
  update: (sale: Sale) => void;
  updateSales: (sales: Sale[]) => void;
  reset: () => void;
};

const defaultValue = {
  type: SaleType.direct,
  sales: new Map(),
  auction: undefined,
  startDate: new Date(),
  expirationDate: new Date(),
  price: undefined,
  reserved: { isReserved: false },
  setAuction: () => undefined,
  setStartDate: () => undefined,
  setExpirationDate: () => undefined,
  setType: () => undefined,
  setPriceUnit: () => undefined,
  setReservePrice: () => undefined,
  setReserved: () => undefined,
  select: () => undefined,
  unSelect: () => undefined,
  update: () => undefined,
  updateSales: () => undefined,
  reset: () => undefined
};

const SaleContext = createContext<ContextType>(defaultValue);

export const useSale = (): ContextType => {
  return useContext(SaleContext);
};

type Props = {
  children: ReactNode;
};

export const SaleProvider = (props: Props): JSX.Element => {
  const [saleType, setSaleType] = useState(SaleType.direct);
  const [sales, setDirectSales] = useState<Map<string, Sale>>(new Map());
  const [auction, setAuction] = useState<Sale | undefined>(undefined);
  const [startDate, setStartDate] = useState(new Date());
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [price, setPrice] = useState<NumberOrString>();
  const [reservePrice, setReservePrice] = useState<NumberOrString>();
  const [reserved, setReserved] = useState<Reserved>({ isReserved: false });

  const setApplyPriceForAll = (price: NumberOrString) => {
    if (price !== undefined) {
      const record = new Map(sales);
      for (const [key, value] of record.entries()) {
        value.price = price;
        value.error = {};
        record.set(key, { ...value });
      }
      setDirectSales(record);
    }
  };

  const select = (userBalance: UserBalance) => {
    if (saleType == SaleType.direct) {
      const record = new Map(sales);
      const { nft, available_amount } = userBalance;
      if (record.has(nft.asset_id)) record.delete(nft.asset_id);
      else
        record.set(nft.asset_id, {
          ...nft,
          price: price || '',
          quantity: 1,
          qty: Number(available_amount),
          error: {}
        });
      setDirectSales(record);
    }
  };

  const unSelect = (asset_id: string) => {
    if (saleType == SaleType.direct) {
      const record = new Map(sales);
      record.delete(asset_id);
      setDirectSales(record);
    }
  };

  const update = (sale: Sale) => {
    if (saleType == SaleType.direct) {
      const record = new Map(sales);
      record.set(sale.asset_id, { ...sale });
      setDirectSales(record);
    }
  };

  const setType = (saleType: SaleType) => {
    if (saleType == SaleType.direct) {
      setAuction(undefined);
    } else {
      const record = new Map(sales);
      record.clear();
      setDirectSales(record);
    }
    setPrice(undefined);
    setSaleType(saleType);
  };

  const setPriceUnit = (price: NumberOrString) => {
    setPrice(price);
    setApplyPriceForAll(price);
  };

  const reset = () => {
    setPrice('');
    setDirectSales(new Map());
    setAuction(undefined);
  };

  const updateSales = (sales: Sale[]) => {
    const record = new Map<string, Sale>();
    sales.forEach((sale) => record.set(sale.asset_id, sale));
    setDirectSales(record);
  };

  const value = {
    sales,
    auction,
    type: saleType,
    startDate,
    price,
    reserved,
    reservePrice,
    expirationDate,
    setExpirationDate,
    setReservePrice,
    setAuction,
    setStartDate,
    setType,
    setPriceUnit,
    setReserved,
    select,
    unSelect,
    update,
    updateSales,
    reset
  };
  return <SaleContext.Provider value={value}>{props.children}</SaleContext.Provider>;
};
