import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { PriceSymbol } from 'types';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import { Button, Price } from 'components';

type Props = {
  value: number;
};

export const Done = ({ value }: Props) => {
  const dispatch = useDispatch();

  const onContinue = () => {
    dispatch(openModal.create({}));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
      <Typography variant="h6" fontWeight="600" sx={{ color: (theme) => theme.colors.green, mr: 1 }}>
        Offer successfully accepted
      </Typography>
      <Stack sx={{ width: '100%' }}>
        <Box>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
              Price
            </Typography>
            <Price value={value} symbol={PriceSymbol.WAVAX} />
            <Typography variant="body2" color="text.primary">
              WAVAX
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button ctx="primary" onClick={() => onContinue()} sx={{ marginLeft: '-3.84rem' }}>
            <Typography variant="button">Continue</Typography>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};