import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import {
  DropDownMultiSelect,
  DropDownSelect,
  SearchInput,
  CollectionAutoComplete,
  CollectionFilter
} from 'components';
import { DropDownPriceRange } from './DropDownPriceRange';
import { activity_options, days_filter_options, sales_options } from 'constants/options';
import { useFilters } from 'providers';
import { useCategoriesQuery } from 'api';
import { KeyValuePair } from 'types';
import { ActivityType, DaysFilter } from 'types/generated';

type Props = {
  showCategories?: boolean;
  showSales?: boolean;
  showCollections?: boolean;
  showOwnCollections?: boolean;
  showPrice?: boolean;
  showKeywords?: boolean;
  showActivityTypes?: boolean;
  showPeriod?: boolean;
  // override default width
  // TODO pass sx instead to override the whole styling
  width?: string;
} & BoxProps;

// Styling
export const Container = styled(Box)({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center'
});

const Filters = ({
  showCategories,
  showSales,
  showCollections,
  showPrice,
  showKeywords,
  showOwnCollections,
  showActivityTypes,
  showPeriod,
  width,
  ...rest
}: Props) => {
  const {
    setCategories,
    categories,
    setSalesType,
    salesType,
    setPrice,
    price,
    keywords,
    setKeywords,
    collection,
    setCollection,
    collections,
    activityTypes,
    setActivityTypes,
    period,
    setPeriod
  } = useFilters();
  const categoriesPairs: KeyValuePair<string, string>[] = [];

  // call api to get all categories
  const { data, error } = useCategoriesQuery();

  if (data) {
    data.categories.forEach((cat) => {
      categoriesPairs.push({ key: cat?.tag, value: cat?.name });
    });
  }

  return (
    <Container {...rest}>
      {showCollections && (
        <CollectionAutoComplete
          sx={{ width: width || '10rem' }}
          collection={collection}
          setCollection={setCollection}
        />
      )}
      {showOwnCollections && (
        <CollectionFilter
          collection={collections.find((c) => c.address == collection?.address)}
          collections={collections}
          setCollection={setCollection}
          sx={{ width: width || '10rem' }}
        />
      )}
      {showCategories && (
        <DropDownMultiSelect
          pairs={categoriesPairs}
          keys={categories}
          empty="All categories"
          onSelectChange={(v) => setCategories(v)}
          sx={{ width: width || '10rem' }}
        />
      )}
      {showActivityTypes && (
        <DropDownMultiSelect
          pairs={activity_options}
          keys={activityTypes}
          empty="All types"
          onSelectChange={(v) => setActivityTypes(v as ActivityType[])}
          sx={{ width: width || '10rem' }}
        />
      )}
      {showSales && (
        <DropDownSelect
          pairs={sales_options}
          defaultKey={salesType}
          empty="All sales types"
          placeholder="All sales types"
          onSelectChange={(v) => setSalesType(v)}
          sx={{ width: width || '10rem' }}
        />
      )}
      {showPeriod && (
        <DropDownSelect
          defaultKey={period}
          empty="All time"
          pairs={days_filter_options}
          onSelectChange={(key) => setPeriod(key as DaysFilter)}
          sx={{ width: width || '10rem' }}
        />
      )}
      {showPrice && (
        <DropDownPriceRange
          range={price || {}}
          setPriceRange={(price) => setPrice(price)}
          sx={{ width: width || '10rem' }}
        />
      )}
      {showKeywords && <SearchInput keyword={keywords} set={setKeywords} />}
    </Container>
  );
};

export default Filters;
