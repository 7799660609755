/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Typography, MenuItem, MenuList } from '@mui/material';
import { Attribute, Property } from 'types';
import { Checkbox } from 'components';

type Props = {
  property: Property;
  keyword?: string;
  setProperty: (properties: Property) => void;
};

type AttributeProps = {
  attribue: Attribute;
  keyword?: string;
  onClick: () => void;
};

const highlight = (value: string, i: number) => (
  <Typography key={i} variant="body2" fontSize={13} sx={{ background: '#3D3AE9', color: '#fff' }}>
    {value}
  </Typography>
);

export const AttributeRenderer = ({ attribue, keyword, onClick }: AttributeProps) => {
  const { name, count, selected } = attribue;
  return (
    <MenuItem
      disableRipple={true}
      key={name}
      sx={{ pl: 0, borderRadius: '0.5rem', display: 'flex', height: '3rem', minWidth: 0 }}
      onClick={onClick}
    >
      <Checkbox sx={{ ':hover': { background: 'transparent' } }} checked={selected} size="small" />
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
        component="span"
      >
        {keyword
          ? name
              .split(new RegExp(`(${keyword})`, 'g'))
              .map((s, i) => (s.includes(keyword) ? highlight(s, i) : s))
          : name}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mx: 1 }}>
        {count}
      </Typography>
    </MenuItem>
  );
};

export const PropertyRenderer = ({ property, keyword, setProperty }: Props) => {
  const onAttrClick = (index: number) => {
    const updatedProperty = { ...property };
    updatedProperty.attrs[index].selected = !updatedProperty.attrs[index].selected;
    setProperty(updatedProperty);
  };

  return (
    <MenuList>
      {property.attrs.map((attribute, index) => {
        const key = `${property.name}-${attribute.name}`;
        return (
          <AttributeRenderer
            key={key}
            attribue={attribute}
            keyword={keyword}
            onClick={() => onAttrClick(index)}
          />
        );
      })}
    </MenuList>
  );
};
