// 3rd party libs
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Chart, Container, DropDownSelect } from 'components';
import { ChartContainer } from './chart';
import { StatsRanges } from 'types/generated';
import { useParams } from 'react-router-dom';
import { AddressRouteParams } from 'types';
import { useSeriesQuery } from 'api';
import { toDataset, toStats, periods } from './utils';
import { TopSectionContainer } from 'helpers/topSection';
import { stats_range_options } from 'constants/options';
import { compact } from 'lib/format';

const Stats = () => {
  const { address } = useParams<AddressRouteParams>();
  const [period, setPeriod] = useState<StatsRanges>(StatsRanges.Weekly);
  const { data, isFetching } = useSeriesQuery({ address, duration: period });
  // Stats
  const { market_cap, volume, floor_price, owners, sales, offers } = toStats(data?.graph);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TopSectionContainer>
        <DropDownSelect
          pairs={stats_range_options}
          defaultKey={period}
          onSelectChange={(v) => setPeriod(v as StatsRanges)}
          sx={{ width: '15rem' }}
        />
      </TopSectionContainer>
      <Container sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <Box>
          <ChartContainer title="Sales history" period={periods[period]} loading={isFetching}>
            <Chart
              data={{
                datasets: [
                  toDataset('line', 'Floor price', floor_price, false, '#1f78c1', '#1f78c1'),
                  toDataset(
                    'bar',
                    'Volume',
                    volume,
                    false,
                    'rgb(201, 203, 207)',
                    'rgba(201, 203, 207, 0.8)',
                    'y1'
                  )
                ]
              }}
              displayYaxis={true}
            />
          </ChartContainer>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
          <ChartContainer
            title="NFTs on sale"
            value={compact.format(sales[sales.length - 1]?.y)}
            period={periods[period]}
            loading={isFetching}
          >
            <Chart data={{ datasets: [toDataset('line', 'Sales', sales)] }} />
          </ChartContainer>
          <ChartContainer
            title="Market cap"
            value={compact.format(market_cap[market_cap.length - 1]?.y)}
            period={periods[period]}
            loading={isFetching}
          >
            <Chart data={{ datasets: [toDataset('line', 'Market cap', market_cap)] }} />
          </ChartContainer>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
          <ChartContainer
            title="Offers"
            value={compact.format(offers[offers.length - 1]?.y)}
            period={periods[period]}
            loading={isFetching}
          >
            <Chart data={{ datasets: [toDataset('line', 'Offers', offers)] }} />
          </ChartContainer>
          <ChartContainer
            title="Owners"
            value={compact.format(owners[owners.length - 1]?.y)}
            period={periods[period]}
            loading={isFetching}
          >
            <Chart data={{ datasets: [toDataset('line', 'Owners', owners)] }} />
          </ChartContainer>
        </Box>
      </Container>
    </Box>
  );
};

export default Stats;
