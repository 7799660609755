import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Stack,
  styled,
  Typography,
  useTheme,
  Link,
  PaletteMode,
  Skeleton
} from '@mui/material';
import Avatar from 'components/Avatar';
import { CountDown, ImageContainer } from 'components';
import { KalaoGo } from 'types';
import { priceFormatter } from 'lib/format';
import { ReactComponent as AvaxDark } from '../../assets/cards/avax-dark.svg';
import { ReactComponent as AvaxLight } from '../../assets/cards/avax-light.svg';

type Props = {
  go: KalaoGo;
  isLoading?: boolean;
};

// Styling
const Card = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '1rem',
  background: 'transparent',
  boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 4px;',
  border: theme.border,
  transition: 'all 0.2s',
  ':hover': {
    border: theme.borderHover,
    background:
      theme.palette.mode == 'dark'
        ? theme.colors.backgroundSecondaryDark
        : theme.colors.backgroundPrimaryLight
  }
}));

const GoContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem'
});

const AvatarContainer = styled(Box)({
  position: 'absolute',
  top: 'calc(-2.3rem)',
  left: 'calc(50% - 1.71875rem)'
});

const avatarRenderer = (collectionAvatarSrc: string, isLoading?: boolean) => {
  return (
    <AvatarContainer>
      {isLoading ? (
        <Skeleton variant="circular" sx={{ width: 55, height: 55 }} />
      ) : (
        <Avatar 
          top={25}
          src={collectionAvatarSrc}
          sx={{
            width: 55,
            height: 55,
            border: (theme) => `0.2rem solid ${theme.input.background}`
          }}
        />
      )}
    </AvatarContainer>
  );
};
const renderer = (
  description: string,
  items: number,
  price: number,
  reserve_price: number,
  last_price: number,
  type_tag: string,
  status_tag: string,
  mode: PaletteMode,
  isLoading?: boolean
) => {
  return (
    <Stack direction="column" sx={{ flex: 1 }}>
      <Box sx={{ height: '4rem', mb: 2 }}>
        {isLoading ? (
          <>
            <Skeleton width="90%" sx={{ mx: 'auto' }} />
            <Skeleton width="90%" sx={{ mx: 'auto' }} />
          </>
        ) : (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mb: 2,
              textAlign: 'center',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Chip
          sx={{
            flex: 1,
            borderRadius: '5rem',
            height: '3rem',
            backgroundColor: (theme) => theme.palette.background.default
          }}
          label={
            !isLoading && (
              <Typography variant="h6" fontWeight={600}>
                {items} items
              </Typography>
            )
          }
        />
        <Chip
          sx={{ flex: 1, borderRadius: '5rem', height: '3rem' }}
          label={
            !isLoading && (
              <Stack direction="row" spacing={1} alignItems="center">
                {mode == 'dark' && price > 0 ? <AvaxDark width={15} height={15} /> : ''}
                {mode == 'light' && price > 0 ? <AvaxLight width={16} height={15} /> : ''}
                <Typography variant="h6">
                  {price > 0 && priceFormatter.format(price)}
                  {price == 0 && 'Free'}
                  {status_tag == 'upcoming' &&
                    type_tag == 'dutch_auction' &&
                    ' > ' + (reserve_price == 0 ? 'Free' : priceFormatter.format(reserve_price))}
                  {status_tag == 'soldout' &&
                    type_tag == 'dutch_auction' &&
                    ' > ' + (last_price == 0 ? 'Free' : priceFormatter.format(last_price))}
                </Typography>
              </Stack>
            )
          }
          variant="outlined"
        />
      </Box>
    </Stack>
  );
};

const renderStatus = (
  tag: 'soldout' | 'live' | 'upcoming',
  status: string,
  countdown?: Date,
  isLoading?: boolean
) => {
  return countdown ? (
    <CountDown
      targetDate={countdown}
      sx={{
        textAlign: 'center',
        mb: 4,
        mx: 'auto',
        fontSize: '1rem',
        color: (theme) => theme.colors.upcoming
      }}
      isLoading={isLoading}
    />
  ) : (
    <Typography
      variant="body2"
      fontWeight={600}
      sx={{
        textAlign: 'center',
        color: (theme) =>
          tag == 'soldout'
            ? theme.colors.soldOut
            : tag == 'live'
            ? theme.colors.live
            : theme.colors.upcoming,
        mt: 1,
        mb: 4,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '1rem'
      }}
    >
      {isLoading ? <Skeleton width={100} /> : status}
    </Typography>
  );
};

const info = (go: KalaoGo, countdown?: Date, isLoading?: boolean) => {
  const mode = useTheme().palette.mode;
  const {
    name,
    url_icon,
    description,
    type_tag,
    type_name,
    status_tag,
    status_name,
    total_items,
    starting_price,
    reserve_price,
    last_price
  } = go;
  return (
    <GoContainer>
      {avatarRenderer(url_icon, isLoading)}
      <Box style={{ position: 'relative' }}>
        <Typography
          variant="caption"
          fontWeight={600}
          sx={{
            color: (theme) => theme.colors.buttonBlue,
            textTransform: 'uppercase',
            position: 'absolute',
            top: -10,
            right: 0
          }}
        >
          {isLoading ? <Skeleton width={60} /> : type_name}
        </Typography>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ mt: 3, justifyContent: 'center', alignItems: 'center' }}
        >
          <Typography
            variant="h4"
            color="text.primary"
            fontWeight={600}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {isLoading ? <Skeleton width={100} /> : name}
          </Typography>
        </Stack>
      </Box>
      <Stack
        direction="row"
        spacing={0.5}
        style={{ position: 'relative', justifyContent: 'center', alignItems: 'center' }}
      >
        {renderStatus(status_tag, status_name, countdown, isLoading)}
      </Stack>
      {renderer(
        description,
        total_items,
        Number(starting_price),
        Number(reserve_price),
        Number(last_price),
        type_tag,
        status_tag,
        mode,
        isLoading
      )}
    </GoContainer>
  );
};

const getLink = (go: KalaoGo) => {
  const { status_tag, start_date, url_twitter, url_go } = go;
  if (status_tag == 'upcoming')
    return {
      target: start_date == '0000-00-00' || !url_go ? '_blank' : '_self',
      href: start_date == '0000-00-00' || !url_go ? url_twitter : url_go
    };
  return {
    target: '_self',
    href: url_go
  };
};

const KalaoGoCard = ({ go, isLoading }: Props): JSX.Element => {
  const { url_cover } = go;
  const [countdown, setCountDown] = useState<Date>();

  useEffect(() => {
    const { status_tag, start_date, starting_date, start_time } = go;
    if (status_tag === 'upcoming' && start_date != '0000-00-00' && start_time) {
      setCountDown(new Date(starting_date));
    }
  }, []);

  const { href, target } = getLink(go);

  return (
    <Card href={href} target={target}>
      <ImageContainer src={url_cover} ratio={50} preventEvent={false} isLoading={isLoading} displayTheme="covered" />
      {info(go, countdown, isLoading)}
    </Card>
  );
};

export default KalaoGoCard;
