import React from 'react';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { NumberOrString } from 'types';
import { NumberInput } from 'components';

type Props = {
  sx: SxProps<Theme>;
  royalties: NumberOrString;
  setRoyalties: (royalties: NumberOrString) => void;
};

const Royalties = ({ royalties, sx, setRoyalties }: Props) => {
  return (
    <Stack>
      <Typography variant="body2" color="text.secondary">
        Royalties
      </Typography>
      <NumberInput
        sx={{
          mt: 1,
          height: { xs: '3rem' },
          fontSize: { xs: 14, sm: 16 },
          px: 2,
          borderRadius: '0.5rem'
        }}
        max={10}
        step={1}
        value={royalties}
        onChange={(v) => setRoyalties(v)}
        placeholder={'e.g. «2,5»'}
      />
      <Typography variant="caption" color="text.secondary" textAlign="end">
        Creator royalties cannot be greater than 10%
      </Typography>
    </Stack>
  );
};

export default Royalties;
