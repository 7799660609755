import React, { useState } from 'react';
import { CollectionSearchRenderer, DropDownElement } from 'components';
import { BoxProps, Typography } from '@mui/material';
import { Collection } from 'types/generated';

type Props = {
  collections: Collection[];
  collection?: Collection;
  setCollection: (collction?: Collection) => void;
  mobile?: boolean;
} & BoxProps;

const CollectionFilter = ({ collection, collections, setCollection, mobile, ...props }: Props) => {
  const [search, setSearch] = useState('');

  const handleSearchChange = (keyword: string) => setSearch(keyword);

  const filtered = search
    ? collections.filter((c) => c.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    : undefined;

  return mobile ? (
    <CollectionSearchRenderer
      collection={collection}
      filtered={filtered}
      collections={collections}
      onInputChange={handleSearchChange}
      clear={() => setSearch('')}
      setCollection={(c) => {
        setCollection(c);
      }}
      {...props}
    />
  ) : (
    <DropDownElement
      element={
        <CollectionSearchRenderer
          collection={collection}
          filtered={filtered}
          collections={collections}
          clear={() => setSearch('')}
          onInputChange={handleSearchChange}
          setCollection={(c) => {
            setCollection(c);
          }}
        />
      }
      valueRenderer={
        <Typography
          variant="body2"
          sx={{
            ml: 1,
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {collection ? collection.name : 'All collections'}
        </Typography>
      }
      {...props}
    />
  );
};

export default CollectionFilter;
