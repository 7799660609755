import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Badge,
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';
import { ModeSwitcher, SquareIconButton } from 'components';
import { useWeb3React } from '@web3-react/core';
import { useUserQuery } from 'api';
import config from 'config';

type Props = {
  close: () => void;
};

const Pages = ({ close }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { account } = useWeb3React();
  const { data } = useUserQuery({ address: account || '' }, { skip: !account });
  const avatar = data?.user.avatar || config.default_avatar;
  const name = data?.user.name || '';

  const goTo = (path: string) => {
    navigate(path);
    close();
  };

  const goToExternal = (path: string) => {
    window.location.href = path;
    close();
  };

  return (
    <>
      <MenuList>
        <MenuItem onClick={() => goTo(AppRoutes.explore)}>
          <ListItemText>Explore</ListItemText>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => goTo(AppRoutes.activity)}>
          <ListItemText>Activity</ListItemText>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => goTo(AppRoutes.ranking)}>
          <ListItemText>Ranking</ListItemText>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => goTo(AppRoutes.sell)}>
          <ListItemText>Sell</ListItemText>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => goToExternal('https://go.kalao.io')}>
          <ListItemText>Launchpad</ListItemText>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => goTo(AppRoutes.rewards)}>
          <ListItemText>Rewards</ListItemText>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        </MenuItem>
      </MenuList>
      <Divider variant="middle" />
      <MenuList>
        <MenuItem onClick={() => setOpen(!open)}>
          <Avatar src={avatar} sx={{ mr: 1 }} />
          <ListItemText primary={name} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <MenuItem onClick={() => goTo(AppRoutes.dashboard)}>
              <ListItemText>My Dashboard</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => goTo(AppRoutes.my_collectbles)}>
              <ListItemText>My Collectibles</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => goTo(AppRoutes.create)}>
              <ListItemText>Create</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => goTo(AppRoutes.transfer)}>
              <ListItemText>Transfer</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => goTo(AppRoutes.settings)}>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
          </List>
        </Collapse>
      </MenuList>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <ModeSwitcher />
      </Box>
    </>
  );
};

const Header = (onClick: () => void) => {
  return (
    <MenuList sx={{ py: 0, my: '1rem' }}>
      <MenuItem onClick={onClick} sx={{ py: 0 }}>
        <ListItemText>Kalao</ListItemText>
        <ListItemIcon>
          <SquareIconButton>
            <CloseIcon />
          </SquareIconButton>
        </ListItemIcon>
      </MenuItem>
    </MenuList>
  );
};

export const MobileBarLinks = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <SquareIconButton onClick={() => setOpen(true)} sx={{ ml: 1 }}>
        <MenuIcon />
      </SquareIconButton>
      <Drawer
        anchor={'left'}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: '85%', background: (theme) => theme.palette.background.default }
        }}
      >
        {Header(() => setOpen(false))}
        <Divider />
        <Pages close={() => setOpen(false)} />
      </Drawer>
    </>
  );
};
