// 3rd party libs
import React, { useEffect, useLayoutEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, IconButton, styled } from '@mui/material';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { AppBar, Dialog, BasketChip, PrivateRoute, BannerTopAnnouncement } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';
import CloseIcon from '@mui/icons-material/Close';

// Constants
import { AppRoutes } from 'constants/routes';

// Pages
import {
  ExplorePage,
  HomePage,
  KalaoVision,
  Rewards,
  Ranking,
  ActivityPage,
  CollectionPage,
  GalleryPage,
  Dashboard,
  Profile,
  NFT,
  Create,
  Sell,
  Transfer
} from 'pages';

// theme
import { createMuiTheme } from 'themes';
import './App.css';
import { GlobalState } from 'store';
import { openModal, setMode } from 'store/actions';
import connectors from 'connectors';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { AuthProvider } from 'providers/authProvider';
import { useLocalStorage } from 'hooks';
import { Mode } from 'types';

const Frame = styled('div')`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.background.default};
`;

const AppRouting = () => {
  const dispatch = useDispatch();
  return (
    <BrowserRouter>
      <CssBaseline />
      <Frame>
        <AppBar />
        <BannerTopAnnouncement
          text="Have some NFTs listed on Kalao V1? Please click on the following link to cancel listings on V1 so you can list them on Kalao Marketplace V2 and enjoy 0% service fees."
          link={{
            url: 'https://marketplace.v1.kalao.io/my-account/dashboard',
            target: '_blank',
            label: 'Cancel Listings on Marketplace V1'
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Routes>
            {/* Home */}
            <Route path={AppRoutes.home} element={<HomePage />} />
            {/* Explore */}
            <Route path={`${AppRoutes.explore}/*`} element={<ExplorePage />} />
            {/* Kalao vision */}
            <Route path={AppRoutes.vision} element={<KalaoVision />} />
            {/* Rewards */}
            <Route path={AppRoutes.rewards} element={<Rewards />} />
            {/* Ranking */}
            <Route path={`${AppRoutes.ranking}/*`} element={<Ranking />} />
            {/* Activity */}
            <Route path={`${AppRoutes.activity}`} element={<ActivityPage />} />
            {/* Collection page */}
            <Route path={`${AppRoutes.collection_page}/*`} element={<CollectionPage />} />
            {/* Gallery page */}
            <Route path={`${AppRoutes.gallery_page}/*`} element={<GalleryPage />} />
            {/** Dashboard */}
            <Route
              path={`${AppRoutes.dashboard}/*`}
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            {/** Profile */}
            <Route path={`${AppRoutes.profile}/*`} element={<Profile />} />
            {/* NFT Detail */}
            <Route path={AppRoutes.nft} element={<NFT />} />
            {/* Create NFT single/multiple */}
            <Route
              path={AppRoutes.create}
              element={
                <PrivateRoute>
                  <Create />
                </PrivateRoute>
              }
            />
            {/** Sell NFTs */}
            <Route
              path={AppRoutes.sell}
              element={
                <PrivateRoute>
                  <Sell />
                </PrivateRoute>
              }
            />
            {/** Transfer NFTs */}
            <Route
              path={AppRoutes.transfer}
              element={
                <PrivateRoute>
                  <Transfer />
                </PrivateRoute>
              }
            />
          </Routes>
        </Box>
        <BasketChip maxChips={3} onClick={() => dispatch(openModal.create({ modal: 'BASKET' }))} />
        {/* Handle modals */}
        <Dialog />
        {/*<Footer />*/}
      </Frame>
    </BrowserRouter>
  );
};

const Dismiss = ({ id }: { id: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      sx={{ color: (theme) => theme.colors.darkGrey }}
      onClick={() => {
        closeSnackbar(id);
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const [themeMode, _] = useLocalStorage<Mode | undefined>('mode', undefined);
  const mode = useSelector((global: GlobalState) => global.state.mode);
  const theme = createMuiTheme(mode);

  useLayoutEffect(() => {
    if (themeMode && themeMode !== mode) dispatch(setMode.create({ mode: themeMode }));
  }, []);

  useEffect(() => {
    window.process = {
      ...window.process
    };
  }, []);

  return (
    <Web3ReactProvider connectors={connectors}>
      <AuthProvider>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3} action={(key) => <Dismiss id={key} />}>
            <AppRouting />
          </SnackbarProvider>
        </MuiThemeProvider>
      </AuthProvider>
    </Web3ReactProvider>
  );
};

export default App;
