import * as Redux from 'redux';
import * as Actions from './actions';

// Types
import { NFTSale, State } from 'types';

export const initial: State = {
  mode: 'dark',
  // Selected NFT (appear in the basket)
  basket: new Map(),
  // <Footer /> shown on most of the pages
  hideFooter: false,
  // Open collection filters
  openFilters: false,
  // Wallet status
  isWalletValid: false
};

export function apply<TS extends State>(s: TS, a: Redux.Action): TS {
  if (Actions.batch.test(a)) {
    for (const a2 of a.actions) s = apply(s, a2);
    return s;
  }

  if (Actions.setMode.test(a)) {
    return { ...s, mode: a.mode };
  }

  if (Actions.addToBasket.test(a)) {
    const { nftSale } = a;
    // Remove if already exists, otherwise add it to the record
    const record = new Map(s.basket);
    if (record.has(nftSale.asset_id)) record.delete(nftSale.asset_id);
    else record.set(nftSale.asset_id, nftSale);

    return { ...s, basket: record };
  }

  if (Actions.openModal.test(a)) {
    return { ...s, modal: a.modal };
  }

  if (Actions.hideFooter.test(a)) {
    return { ...s, hideFooter: a.hideFooter };
  }

  if (Actions.openFilters.test(a)) {
    return { ...s, openFilters: a.open };
  }

  if (Actions.setSale.test(a)) {
    return { ...s, nftSale: a.nftSale };
  }

  if (Actions.setOffer.test(a)) {
    return { ...s, nftOffer: a.nftOffer };
  }

  if (Actions.setNFT.test(a)) {
    return { ...s, nft: a.nft };
  }

  if (Actions.listForSale.test(a)) {
    return { ...s, items: a.items };
  }

  if (Actions.createNFT.test(a)) {
    return { ...s, createNFT: a.createNFT };
  }

  if (Actions.setDeployNFTKind.test(a)) {
    return { ...s, deployNFTKind: a.deployNFTKind };
  }

  if (Actions.setWalletStatus.test(a)) {
    if (s.isWalletValid !== a.valid) return { ...s, isWalletValid: a.valid };
  }

  if (Actions.userEmail.test(a)) {
    return { ...s, userEmail: a.email };
  }

  if (Actions.userTelegramToken.test(a)) {
    return { ...s, telegramToken: a.token };
  }

  if (Actions.updateBasket.test(a)) {
    const record = new Map<string, NFTSale>();
    a.nftsSales.forEach((sale) => {
      record.set(sale.asset_id, sale);
    });
    return { ...s, basket: record };
  }

  if (Actions.setCreateDealId.test(a)) {
    return { ...s, createDealId: a.dealId };
  }

  if (Actions.setCollectionAddress.test(a)) {
    return { ...s, collectionAddress: a.address };
  }

  if (Actions.setPrevious.test(a)) {
    return { ...s, previous: a.previous };
  }

  if (Actions.transfer.test(a)) {
    return { ...s, transferAssets: a.transferAssets };
  }

  if (Actions.transferTransaction.test(a)) {
    return { ...s, transferTx: a.transferTx };
  }

  if (Actions.listNFTForSale.test(a)) {
    return { ...s, listNFTForSale: a.nft };
  }

  if (Actions.transferNFT.test(a)) {
    return { ...s, transferNFT: a.nft };
  }

  if (Actions.setAdvancdOffer.test(a)) {
    return { ...s, advancedOffer: a.advancedOffer };
  }

  if (Actions.clearBasket.test(a)) {
    const record = new Map(s.basket);
    record.clear();
    return { ...s, basket: record };
  }

  return s;
}

export const reducer = (state: State = initial, action: Redux.Action) => apply(state, action);
