// 3rd party libs
import React from 'react';
import { Typography, Stack, Divider } from '@mui/material';
import { useSettings } from '../context/settingsContext';
import { useWeb3React } from '@web3-react/core';
import { useSetTelegramNotificationsMutation, useUpdateNotificationSettingsMutation } from 'api';
import { useDispatch } from 'react-redux';
import { batch, openModal, userEmail, userTelegramToken } from 'store/actions';
import { Notify } from './Notify';
import { UserNotificationSettings, UserNotificationsSettingsInput } from 'types/generated';
import { useNotistack } from 'hooks';

const Notifications = () => {
  const { success, error } = useNotistack();
  const dispatch = useDispatch();

  // Call to api
  const [update] = useUpdateNotificationSettingsMutation();
  const [updateTelegramNotif] = useSetTelegramNotificationsMutation();

  const { account } = useWeb3React();

  const { notifications, setNotifications, profile } = useSettings();

  const set = (key: keyof UserNotificationSettings, value: unknown) => {
    const input: UserNotificationsSettingsInput = { [key]: value };
    update({ input })
      .unwrap()
      .then(() => {
        success(`Notifications successfully updated`);
        notifications && setNotifications({ ...notifications, [key]: value });
      })
      .catch(() => {
        error(`Notifications Update Failed!`);
      });
  };

  const updateTelegram = (activate: boolean) => {
    updateTelegramNotif({ activate })
      .unwrap()
      .then((res) => {
        if (activate) {
          const telegramToken = res.setTelegramNotifications?.telegram_registration_token || '';
          dispatch(
            batch.create({
              actions: [
                userTelegramToken.create({ token: telegramToken }),
                openModal.create({ modal: 'TELEGRAM NOTIFICATIONS' })
              ]
            })
          );
        }
        notifications && setNotifications({ ...notifications, ['by_telegram']: activate });
        success('Telegram successfully updated');
      })
      .catch(() => {
        error('Telegram Update Failed!');
      });
  };

  const updateEmail = (by_email: boolean) => {
    if (by_email) {
      dispatch(
        batch.create({
          actions: [
            userEmail.create({ email: profile?.mail }),
            openModal.create({ modal: 'EMAIL NOTIFICATIONS' })
          ]
        })
      );
    } else {
      update({ input: { by_email } })
        .then(() => {
          success('Email successfully updated');
          notifications && setNotifications({ ...notifications, ['by_email']: by_email });
        })
        .catch(() => error('Email Update Failed!'));
    }
  };
  
  //Disable TMP Launch Screen
  const disableEventType = true;
  const disableNewsletter = true;

  return (
    <Stack spacing={3}>
      <Typography variant="h3" color="text.primary">
        Notifications
      </Typography>
      <Typography variant="body2" color="text.secondary" fontWeight={400}>
        Set the notifications you would like to receive for 0x... {account}
      </Typography>
      <Typography variant="h4" color="text.primary" fontWeight={500}>
        Preferred communication channels
      </Typography>
      <Stack spacing={2}>
        <Notify
          topic="Telegram"
          variant="h5"
          checked={notifications?.by_telegram || false}
          onChange={updateTelegram}
          connectBtn
        />
        <Notify
          topic="Kalao (Coming soon)"
          variant="h5"
          checked={notifications?.on_website || false}
          disabled={true}
          onChange={(checked) => {
            set('on_website', checked);
          }}
        />
        <Notify
          topic="Email (Coming soon)"
          variant="h5"
          checked={notifications?.by_email || false}
          disabled={true}
          onChange={updateEmail}
          connectBtn
        />
      </Stack>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h4" color="text.primary" fontWeight={500}>
        Event types
      </Typography>
      <Stack spacing={3}>
        <Notify
          topic="Item Sold (Coming soon)"
          description="When someone purchases one of your items"
          checked={notifications?.events_kinds.item_sold || notifications?.by_telegram || false}
          disabled={disableEventType}
          onChange={(checked) => {
            set('events_kinds', { ...notifications?.events_kinds, item_sold: checked });
          }}
        />
        <Notify
          topic="Bid Activity (Coming soon)"
          description="When someone bids on one of your items"
          checked={notifications?.events_kinds.bid_received || notifications?.by_telegram || false}
          disabled={disableEventType}
          onChange={(checked) => {
            set('events_kinds', { ...notifications?.events_kinds, bid_received: checked });
          }}
        />
        <Notify
          topic="Outbid (Coming soon)"
          description="When someone outbid your current offer"
          checked={notifications?.events_kinds.outbid_received || notifications?.by_telegram || false}
          disabled={disableEventType}
          onChange={(checked) => {
            set('events_kinds', { ...notifications?.events_kinds, outbid_received: checked });
          }}
        />
        <Notify
          topic="Offer Received (Coming soon)"
          description="When someone makes an offer on one of your items"
          checked={notifications?.events_kinds.offer_received || notifications?.by_telegram || false}
          disabled={disableEventType}
          onChange={(checked) => {
            set('events_kinds', { ...notifications?.events_kinds, offer_received: checked });
          }}
        />
        <Notify
          topic="Offer Accepted (Coming soon)"
          description="When someone accepts your offer"
          checked={notifications?.events_kinds.offer_accepted || notifications?.by_telegram || false}
          disabled={disableEventType}
          onChange={(checked) => {
            set('events_kinds', { ...notifications?.events_kinds, offer_accepted: checked });
          }}
        />
      </Stack>
      {!disableNewsletter && (
        <>
        <Divider sx={{ my: 4 }} />
        <Typography variant="h4" color="text.primary" fontWeight={500}>
          Newsletter
        </Typography>
        <Notify
          topic="Occasional Updates From The Kalao Team"
          variant="body2"
          checked={notifications?.events_kinds.newsletter || false}
          onChange={(checked) => {
            set('events_kinds', { ...notifications?.events_kinds, newsletter: checked });
          }}
        />
        </>
      )}
    </Stack>
  );
};

export default Notifications;
