import React from 'react';
import { Box, Typography, styled, Stack, Divider } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SquareIconButton, IconFactory } from 'components';
import Overview from '../Overview';
import { KeyValuePair } from 'types';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});

const data: KeyValuePair<string, string>[] = [
  { key: 'KLO Price', value: '$ 0.0710' },
  { key: 'Base APR', value: '29.09 %' },
  { key: 'Total Staked', value: '24.85 %' },
  { key: 'Circulating Supply', value: '500000k' }
];

const Kalao = () => {
  return (
    <Stack>
      <Container>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconFactory icon="klo" size={20} />
          <Typography variant="h4" color="text.primary" fontWeight={500} sx={{ flex: 1 }}>
            Protocol Rewards (KLO)
          </Typography>
        </Box>
        <SquareIconButton size="medium">
          <RefreshIcon fontSize="medium" />
        </SquareIconButton>
      </Container>
      <Divider sx={{ my: 2, background: (theme) => theme.colors.lightGrey3 }} />
      <Overview data={data} sm={3} />
    </Stack>
  );
};

export default Kalao;
