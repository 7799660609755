import { AppRoutes } from 'constants/routes';
import { CollectionDetails } from 'types/generated';

// Go to collection page
export const goToCollection = (address: string) =>
  `/${AppRoutes.collection_page.replace(':address', address)}`;

export const initCollection: CollectionDetails = {
  collection: { address: '', avatar: '', certified: false, name: '', thumbnails: [] },
  stats: {
    average_price: 0,
    average_royalties_percent: 0,
    floor_price: 0,
    highest_price: 0,
    in_sale: 0,
    sold: 0,
    total_minted: '0',
    total_owners: 0,
    volume: 0,
    weekly_volume: 0
  }
};
