/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useSort } from 'hooks';
import { CollectionRanking, SortClickHandler, SortState } from 'types';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { RenderMobileRow, RenderRow, Headers, skeletonRow } from './rows';
import { useNavigate } from 'react-router-dom';
import { goToCollection } from 'helpers/collections';

type Props = {
  rankings: CollectionRanking[];
  loading: boolean;
  footer?: JSX.Element;
  homePage?: boolean;
  // Number of skeletons to display the first time we render the component
  skeletonCount: number;
};

export const initSortState = (): Record<string, SortState> => {
  return {
    name: SortState.unsorted,
    volume: SortState.unsorted,
    floor_price: SortState.unsorted,
    average_price: SortState.unsorted,
    total_owners: SortState.unsorted,
    supply: SortState.unsorted
  };
};

const ListContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

const CollectionsRanking = ({ rankings, footer, loading, homePage, skeletonCount }: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [sortState, setSortState] = useState<Record<string, SortState>>(initSortState());
  const sortedData = useSort<CollectionRanking>(sortState, rankings);
  const navigate = useNavigate();

  const sortHandler: SortClickHandler = (key, currentSortState) => {
    const newState = {
      ...sortState,
      [key]:
        currentSortState === SortState.ascending
          ? SortState.descending
          : currentSortState === SortState.descending
          ? SortState.unsorted
          : SortState.ascending
    };
    setSortState(newState);
  };

  const renderer = (index: number) => {
    if (loading) return skeletonRow(!matches);
    const rank = sortedData[index];
    const goTo = () => navigate(goToCollection(rank.address));
    return matches ? (
      <RenderRow ranking={rank} homePage={homePage} goTo={goTo} />
    ) : (
      <RenderMobileRow ranking={rank} goTo={goTo} />
    );
  };

  return (
    <>
      <Headers sort={sortState} click={sortHandler} hide={!matches || homePage == true} />
      <Virtuoso
        useWindowScroll={true}
        totalCount={loading ? skeletonCount : sortedData.length}
        overscan={200}
        components={{
          List: React.forwardRef((props, ref) => <ListContainer {...props} ref={ref} />)
        }}
        itemContent={(index) => renderer(index)}
      />
    </>
  );
};

export default CollectionsRanking;
