// 3rd
import { useEffect, useState, useCallback, useRef } from 'react';
// Api
import { useLazyLikesQuery } from 'api';
// Types
import { NftPreview } from 'types/generated';

type Props = {
  // page size (paging offset)
  size: number;
  // Skip if true
  skip: boolean;
  user: string;
};

type Response = {
  nfts: NftPreview[];
  error?: string | unknown;
  isLoading: boolean;
  loadMore: (reset?: boolean) => void;
};

const useLikes = ({ size, skip, user }: Props): Response => {
  const [likes, result] = useLazyLikesQuery();
  const [nfts, setNfts] = useState<NftPreview[]>([]);
  const shouldReset = useRef(true);
  const page = useRef(1);

  const trigger = () =>
    likes({
      pagination: {
        page: page.current++,
        size
      },
      address: user
    });

  useEffect(() => {
    if (!skip) trigger();
  }, []);

  useEffect(() => {
    shouldReset.current = true;
    page.current = 1;
    trigger();
  }, [user]);

  useEffect(() => {
    if (!result.isSuccess) return;
    if (shouldReset.current) {
      shouldReset.current = false;
      setNfts(result.data.likes as NftPreview[]);
    } else {
      setNfts([...nfts, ...(result.data.likes as NftPreview[])]);
    }
  }, [result.data]);

  const loadMore = () => {
    trigger();
  };

  return {
    nfts,
    error: result.error && 'Failed to load data',
    isLoading: result.isLoading,
    loadMore
  };
};

export default useLikes;
