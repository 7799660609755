import React, { CSSProperties } from 'react';
import { Box, Divider, Stack, styled, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as Certified } from '../../assets/certified.svg';
import { ImageContainer, Price } from 'components';
import { compact, priceFormatter } from 'lib/format';
import { CollectionRanking } from 'types';

type Props = {
  collection: CollectionRanking;
  onClick: () => void;
};

type ImageListItemProps = {
  src: string;
  rows?: number;
  cols?: number;
  style?: CSSProperties;
  caption?: string;
};

export const formatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  notation: 'compact',
  compactDisplay: 'short'
});

// for testing purpose
const getItems = (collection: CollectionRanking): ImageListItemProps[] => {
  const { thumbnails, volume } = collection;
  return [
    {
      src: thumbnails[0],
      rows: 2,
      cols: 1,
      style: { borderTopLeftRadius: '1rem', borderBottomLeftRadius: '1rem', overflow: 'hidden' }
    },
    {
      src: thumbnails[1],
      style: { borderTopRightRadius: '1rem', overflow: 'hidden' }
    },
    {
      src: thumbnails[2],
      style: { borderBottomRightRadius: '1rem', overflow: 'hidden' }
    }
  ];
};

const Images = styled('div')(({ theme }) => ({
  borderRadius: '1.25rem',
  overflow: 'hidden',
  display: 'flex',
  gap: '0.3rem',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;',
  border: theme.border,
  ' > div:nth-child(1n)': {
    flex: 2
  },
  ' > div:nth-child(2n)': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3rem',
    '> div': { flex: 1, position: 'relative' }
  }
}));

const Info = styled('div')({
  height: '100%',
  width: '100%',
  position: 'absolute',
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const renderer = (key: string, value: string | JSX.Element) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '1rem', minWidth: 0 }}>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ mr: '0.5rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      >
        {key}
      </Typography>
      {typeof value === 'string' ? (
        <Typography variant="body2" color="text.primary">
          {value}
        </Typography>
      ) : (
        value
      )}
    </Box>
  );
};

const CollectionInfo = (name: string, floor: number, volume: number, certified?: boolean) => {
  return (
    <div>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography variant="body1" color="text.primary" fontWeight={500}>
          {name}
        </Typography>
        {certified && (
          <SvgIcon sx={{ width: '1rem' }}>
            <Certified />
          </SvgIcon>
        )}
      </Stack>
      <Box sx={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}>
        {renderer('Floor price', <Price value={priceFormatter.format(floor)} size={10} />)}
        <Divider sx={{ color: (theme) => theme.palette.text.secondary, fontSize: 12 }}>
          &#8226;
        </Divider>
        {renderer('Volume', compact.format(volume))}
      </Box>
    </div>
  );
};

const GroupCollectionCard = ({ collection, onClick }: Props) => {
  const items = getItems(collection);
  const { name, certified, volume, floor_price } = collection;
  return (
    <Stack spacing={1}>
      <Images>
        <div>
          <ImageContainer src={items[0].src} />
        </div>
        <div>
          <div>
            <ImageContainer src={items[1].src} />
          </div>
          <div className="info">
            <ImageContainer src={items[2].src} />
            <Info>
              <Typography variant="body1" fontSize={25} color="whitesmoke">
                {formatter.format(volume)}
              </Typography>
            </Info>
          </div>
        </div>
      </Images>
      {CollectionInfo(name, floor_price, volume, certified)}
    </Stack>
  );
};

export default GroupCollectionCard;
