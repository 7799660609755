import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { NFTSale, StepStatus } from 'types';
import Step from '../Step';
import { useCancelDealForEditMutation, useCreateDealMutation, useSaleNonceMutation } from 'api';
import { useWeb3React } from '@web3-react/core';
import { DealKind } from 'types/generated';
import { saleSignature } from 'services/exchange';
import { useNetwork } from 'hooks';
import { toBackendSale } from '../utils';
import { Done } from './Done';

type Props = {
  step: number;
  currentStep: number;
  nftSale: NFTSale;
  // callback, called once the step has been completed successfully
  complete: () => void;
};

const Progress = () => {
  return (
    <Typography variant="body2" color="text.secondary">
      Please sign the transaction in your wallet to confirm the price change
    </Typography>
  );
};

const Updating = () => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Typography variant="body2" sx={{ color: (theme) => theme.colors.green, mr: 1 }}>
        Done!
      </Typography>
      <Typography variant="body2" color="text.primary">
        Sale successfully updated
      </Typography>
    </Box>
  );
};

const Updateting = ({ step, currentStep, complete, nftSale }: Props) => {
  const [status, setStatus] = useState<StepStatus>(StepStatus.NOT_STARTED);
  const [error, setError] = useState<string>();
  const [cancelListing] = useCancelDealForEditMutation();
  const [getNonce] = useSaleNonceMutation();
  const [createDeal] = useCreateDealMutation();

  const { account, hooks } = useWeb3React();
  const { supportedChainId } = useNetwork();
  const { usePriorityProvider } = hooks;
  const provider = usePriorityProvider();

  useEffect(() => {
    if (step == currentStep) {
      setStatus(StepStatus.STARTED);
      confirmTransation();
    }
  }, [currentStep]);

  const confirmTransation = async () => {
    if (!account || !provider) {
      setStatus(StepStatus.ERROR);
      setError('Unauthenticated');
      return;
    }

    try {
      setStatus(StepStatus.IN_PROGRESS);

      const { saleNonce } = await getNonce().unwrap();

      const sale = toBackendSale(nftSale, account, saleNonce, supportedChainId);

      const signature = await saleSignature(sale, provider);

      const response = await cancelListing({
        input: { kind: DealKind.Sale, uuid: nftSale.sale.sale_id },
        address: account
      }).unwrap();

      //todo: add contract cancel
      if (!response) throw new Error('Validation failed !');

      await createDeal({
        input: {
          kind: DealKind.Sale,
          sale: { ...sale, signature }
        }
      }).unwrap();

      setStatus(StepStatus.DONE);
      complete();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Update failed !');
      }
      setStatus(StepStatus.ERROR);
    }
  };

  return (
    <Step
      title={
        status == StepStatus.DONE ? (
          <Done sale={nftSale.sale} asset_id={nftSale.asset_id} />
        ) : (
          'Updating'
        )
      }
      step={step}
      currentStep={currentStep}
      status={status}
      error={error}
      el={status == StepStatus.IN_PROGRESS ? <Progress /> : undefined}
    />
  );
};

export default Updateting;
