import { ScriptableContext } from 'chart.js';
import { theme } from 'themes';

type Options = {
  title?: string;
  displayYaxis?: boolean;
  displayXaxis?: boolean;
  enableTooltip?: boolean;
};

export type DataSet = {
  type: any;
  label?: string;
  data: { x: number; y: number }[];
  borderColor: string;
  backgroundColor?: string;
  fill: boolean;
  tension: number;
  pointRadius?: number;
  pointHoverRadius?: number;
  pointHoverBackgroundColor?: string;
  pointHoverBorderColor?: string;
  yAxisID?: string;
};

export type Data = {
  datasets: DataSet[];
};

export const getOptions = ({
  displayYaxis = false,
  displayXaxis = true,
  enableTooltip = true
}: Options) => {
  return {
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0
      }
    },
    layout: {
      padding: 10
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: enableTooltip
      }
    },
    interaction: {
      mode: 'index',
      intersect: false
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'M/d'
          }
        },
        display: displayXaxis,
        grid: {
          display: false,
          drawBorder: true
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          fontSize: 50
        }
      },
      y: {
        display: displayYaxis,
        grid: {
          display: true,
          drawBorder: true
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5
        }
      },
      y1: {
        display: false,
        grid: {
          display: false,
          drawBorder: true
        },
        position: 'right'
      }
    },
    clip: false
  };
};

function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
}

export const processData = (data: Data) => {
  const datasets = data.datasets.map((ds) => {
    return {
      ...ds,
      backgroundColor: (context: ScriptableContext<'line'>) => {
        if (ds.backgroundColor) return ds.backgroundColor;
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradient.addColorStop(0, hexToRGB(ds.borderColor, 0.5));
        gradient.addColorStop(1, hexToRGB(ds.borderColor, 0));
        return gradient;
      }
    };
  });

  return { ...data, datasets };
};
