import { styled, Box } from '@mui/material';

export const Scrollable = styled(Box)<{
  scrollbarGutter?: 'auto' | 'stable' | 'both-edges';
}>(({ theme, scrollbarGutter }) => ({
  scrollbarGutter: scrollbarGutter,
  '&::-webkit-scrollbar': {
    width: '0.3rem'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '1rem',
    backgroundColor: theme.palette.mode == 'dark' ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)'
  }
}));
