// 3rd party libs
import React from 'react';
import { Box, styled, useMediaQuery } from '@mui/material';

// Componenets
import { FilterChips, ResizeToggle, Filters, MobileFilters, Sorting } from 'components';
import { TopSectionContainer } from 'helpers/topSection';
import { FiltersProvider, GridSizeProvider, useResize } from 'providers';
import { Explorer } from './explorer';

import { theme } from 'themes';
import { RightContainer } from 'helpers/rightContainer';
import { SearchNftSort } from 'types/generated';

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default
}));

// Styling
const Container = styled(Box)({
  margin: '1% 2%'
});

const config = {
  comfy: {
    xs: '100%',
    sm: '50%',
    md: '25%',
    lg: '20%',
    xl: '16.66%'
  },
  dense: {
    xs: '100%',
    sm: '50%',
    md: '20%',
    lg: '16.66%',
    xl: '12.5%'
  }
};

const NFTsRenderer = (): JSX.Element => {
  const { size, resize } = useResize();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const filters = matches ? (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
      <Filters showCollections={true} showCategories={true} showSales={true} showPrice={true} />
      <FilterChips />
    </Box>
  ) : (
    <MobileFilters activeFilters={['collections', 'categories', 'sales', 'price']} sorting={<Sorting sortType={'sortExploreNfts'} defaultKey={'recently_listed'} />} />
  );
  return (
    <Root>
      <TopSectionContainer>
        {filters}
        {matches && (
          <RightContainer>
            <Sorting sortType={'sortExploreNfts'} defaultKey={'recently_listed'} />
            <ResizeToggle size={size} resize={(size) => resize(size)} />
          </RightContainer>
        )}
      </TopSectionContainer>
      <Container>
        <Explorer />
      </Container>
    </Root>
  );
};

export const NFTs = () => (
  <GridSizeProvider size="comfy" config={config}>
    <FiltersProvider sortBy={SearchNftSort.RecentlyListed}>
      <NFTsRenderer />
    </FiltersProvider>
  </GridSizeProvider>
);
