import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Button, Box, styled } from '@mui/material';
import { HtmlTooltip } from 'components';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';
import { BarLinkItem, BarLinkProps } from 'types';

import { ReactComponent as Collections } from '../../assets/header-top/collections-menu.svg';
import { ReactComponent as Nfts } from '../../assets/header-top/nfts-menu.svg';
import { ReactComponent as Creators } from '../../assets/header-top/creators-menu.svg';
import { ReactComponent as Galleries } from '../../assets/header-top/galleries-menu.svg';

import { ReactComponent as ActivityMenu } from '../../assets/header-top/activity-menu.svg';
import { ReactComponent as RankingMenu } from '../../assets/header-top/ranking-menu.svg';
import { useState } from 'react';

const barlinks: BarLinkProps[] = [
  {
    page: 'Explore',
    link: AppRoutes.explore,
    items: [
      {
        icon: <Collections />,
        title: 'Collections',
        caption: 'Discover all collection',
        link: AppRoutes.explore_collections
      },
      {
        icon: <Nfts />,
        title: 'All NFTs',
        caption: 'Discover all NFTs',
        link: AppRoutes.explore_nfts
      },
      {
        icon: <Creators />,
        title: 'Creators',
        caption: 'Discover all creators',
        link: AppRoutes.explore_artists
      },
      {
        icon: <Galleries />,
        title: 'Galleries',
        caption: 'Discover all Vision Gallery',
        link: AppRoutes.explore_galleries
      }
    ]
  },
  //{ page: 'Vision', link: AppRoutes.vision, items: [] },
  {
    page: 'Stats',
    link: '',
    items: [
      {
        icon: <ActivityMenu />,
        title: 'Activity',
        caption: 'Collections activities',
        link: AppRoutes.activity
      },
      {
        icon: <RankingMenu />,
        title: 'Ranking',
        caption: 'Top collections and users',
        link: AppRoutes.ranking
      }
    ]
  },
  { page: 'Sell', link: AppRoutes.sell, items: [] },
  { page: 'Launchpad', link: 'https://go.kalao.io', items: [] },
  { page: 'Rewards', link: AppRoutes.rewards, items: [] }
];

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.1rem',
  borderRadius: '0.5rem',
  background:
    theme.palette.mode == 'dark' ? theme.input.background : theme.palette.background.default,
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;'
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  '&:hover': {
    background: theme.palette.action.hover,
    transitionDuration: '0.5s'
  }
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: '3rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0.9375rem'
}));

// Renders the list of items
const renderer = (item: BarLinkItem) => {
  const navigate = useNavigate();
  return (
    <BoxWrapper
      key={item.title}
      sx={{
        display: 'flex',
        paddingX: '0.5rem',
        paddingY: '0.3rem',
        cursor: 'pointer',
        gap: '0.5rem',
        alignItems: 'center',
        borderRadius: '0.9375rem'
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        item.link && navigate(item.link);
      }}
    >
      <IconWrapper>{item.icon}</IconWrapper>
      <Box>
        <Typography variant="subtitle1" color="text.primary" fontWeight={500}>
          {item.title}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {item.caption}
        </Typography>
      </Box>
    </BoxWrapper>
  );
};

const navigateExternal = (link: string) => {
  window.location.href = link;
};

const Link = ({ link, page, items }: BarLinkProps) => {
  const navigate = useNavigate();
  const hasItems = items.length > 0;
  const hasLinkExternal = link.indexOf('https://');
  const [open, setOpen] = useState(false);
  const openTooltip = () => !open && setOpen(true);

  return hasItems ? (
    <Button
      key={link}
      onClick={() => link && navigate(link)}
      sx={{ mx: 1 }}
      onMouseLeave={() => setOpen(false)}
      onMouseOver={openTooltip}
    >
      <HtmlTooltip
        arrow={true}
        key={link}
        open={open}
        placement="bottom-start"
        title={<Container sx={{ p: '1rem' }}>{items.map(renderer)}</Container>}
        sx={{
          '& .MuiTooltip-arrow': {
            color: (theme) => theme.input.background
          }
        }}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          textAlign="center"
          fontWeight={500}
          textTransform={'none'}
        >
          {page}
        </Typography>
      </HtmlTooltip>
    </Button>
  ) : hasLinkExternal != -1 ? (
    <Button key={link} onClick={() => link && navigateExternal(link)} sx={{ mx: 1 }}>
      <Typography
        variant="body1"
        color="text.secondary"
        textAlign="center"
        fontWeight={500}
        textTransform={'none'}
      >
        {page}
      </Typography>
    </Button>
  ) : (
    <Button key={link} onClick={() => link && navigate(link)} sx={{ mx: 1 }}>
      <Typography
        variant="body1"
        color="text.secondary"
        textAlign="center"
        fontWeight={500}
        textTransform={'none'}
      >
        {page}
      </Typography>
    </Button>
  );
};

export const BarLinks = (): JSX.Element => {
  return <Box sx={{ display: 'flex' }}>{barlinks.map(Link)}</Box>;
};
