import React, { useCallback, useRef, CSSProperties } from 'react';
// Import Swiper React components
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react/swiper-react';
import Box from '@mui/material/Box';
// Import Swiper styles
import 'swiper/swiper.min.css';
// Import Override Swiper styles
import './index.css';
import IconButton from '@mui/material/IconButton';
// Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigatePrevIcon from '@mui/icons-material/NavigateBefore';
import { Swiper, SwiperOptions } from 'swiper/types';
import { Container } from 'components';
import { Autoplay, Pagination, Navigation } from 'swiper';

type Props = {
  items: JSX.Element[];
  // With navigation buttons (top-right)
  navigation?: boolean;
  // Any element to display at the top-left (title, links, buttons...)
  el?: JSX.Element;
  // Swiper options
  options?: SwiperOptions;
  // Swiper options
  overflow?: boolean;
  // Apply styling on the navigation arrows & title container
  style?: CSSProperties;
};

const MySwiper = ({ items, el, navigation, options, overflow, style }: Props) => {
  // TODO - Define CoreSwiper & use it instead of any
  const swiperRef = useRef<Swiper>();

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.slideNext();
  }, []);

  return (
    <Box>
      <Container
        style={style}
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {el && <Box>{el}</Box>}
        {navigation && (
          <Box sx={{ marginLeft: 'auto' }}>
            <IconButton onClick={handlePrev}>
              <NavigatePrevIcon />
            </IconButton>
            <IconButton onClick={handleNext}>
              <NavigateNextIcon />
            </IconButton>
          </Box>
        )}
      </Container>
      <ReactSwiper
        onInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        navigation={true}
        spaceBetween={10}
        {...options}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        style={{
          overflow: `${overflow ? 'visible' : 'hidden'}`
        }}
      >
        {items.map((item, i) => (
          <SwiperSlide key={`slide_${i}`}>{item}</SwiperSlide>
        ))}
      </ReactSwiper>
    </Box>
  );
};

export default MySwiper;
