import * as React from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { Scrollable } from 'helpers/scrollable';

type Props<T> = {
  title?: string;
  // Element to be placed top-right corner (could be a earch input, etc...)
  el?: JSX.Element;
  // Data to be renderer
  // Headers
  headers?: JSX.Element;
  data: T[];
  // Data renderer
  renderer: (el: T) => React.ReactNode;
  // Max height before showing scroll
  height?: number;
  // No data placeholder
  empty?: JSX.Element;
} & BoxProps;

// Styling
const ActivityContainer = styled(Box)(({ theme }) => ({
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  border: theme.border,
  padding: theme.spacing(1, 0)
}));

const ActivityTopSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1.5rem'
});

const Empty = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '6rem'
});

const Activity = <T extends unknown>({
  title,
  el,
  data,
  renderer,
  height,
  headers,
  empty,
  ...rest
}: Props<T>) => {
  return (
    <ActivityContainer {...rest}>
      {title && (
        <ActivityTopSection sx={{ p: '1rem' }}>
          <Typography variant="h4" color="text.primary">
            {title}
          </Typography>
          {el}
        </ActivityTopSection>
      )}
      {headers}
      {data.length == 0 ? (
        <Empty>{empty}</Empty>
      ) : (
        <Scrollable sx={{ maxHeight: height, overflow: 'auto', p: 1 }}>
          {data.map(renderer)}
        </Scrollable>
      )}
    </ActivityContainer>
  );
};

export default Activity;
