import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from 'components';
import { useFilters } from 'providers';
import { useDispatch } from 'react-redux';
import { batch, openModal, setAdvancdOffer } from 'store/actions';
import { useParams } from 'react-router-dom';
import { AddressRouteParams, Property } from 'types';
import { useCollectionQuery } from 'api';
import { processCollectionQueryResponse } from 'helpers/processQueryResponse';

export const PropertiesOfferButton = () => {
  const dispatch = useDispatch();
  const { properties } = useFilters();
  const { address } = useParams<AddressRouteParams>();
  const { data } = useCollectionQuery({ address }, { skip: !address });
  const { collection, stats } = processCollectionQueryResponse(data);

  const onClick = () => {
    const selectedProperties: Property[] = [];

    properties.forEach((property) => {
      const p: Property = { name: property.name, attrs: [] };
      let selected = false;
      property.attrs.forEach((attr) => {
        if (attr.selected) {
          selected = true;
          p.attrs.push({ ...attr });
        }
      });
      if (selected) selectedProperties.push(p);
    });

    dispatch(
      batch.create({
        actions: [
          setAdvancdOffer.create({
            advancedOffer: { collection, stats, properties: selectedProperties }
          }),
          openModal.create({ modal: 'ADVANCED OFFER' })
        ]
      })
    );
  };

  const display = properties.filter((p) => p.attrs.some((attr) => attr.selected)).length !== 0;

  return display ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
      <Button ctx="primary" onClick={onClick}>
        <Typography variant="button">Make Properties Offer</Typography>
      </Button>
    </Box>
  ) : null;
};
