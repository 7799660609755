import { SnackbarOrigin, useSnackbar } from 'notistack';

const useNotistack = () => {
  const { enqueueSnackbar } = useSnackbar();

  const success = (message: string, anchorOrigin?: SnackbarOrigin) => {
    enqueueSnackbar(message, {
      variant: 'success',
      anchorOrigin: {
        vertical: anchorOrigin?.vertical || 'top',
        horizontal: anchorOrigin?.horizontal || 'right'
      }
    });
  };

  const error = (message: string, anchorOrigin?: SnackbarOrigin) =>
    enqueueSnackbar(message, {
      variant: 'error',
      anchorOrigin: {
        vertical: anchorOrigin?.vertical || 'top',
        horizontal: anchorOrigin?.horizontal || 'right'
      }
    });

  const info = (message: string, anchorOrigin?: SnackbarOrigin, autoHideDuration?: number) =>
    enqueueSnackbar(message, {
      autoHideDuration,
      anchorOrigin: {
        vertical: anchorOrigin?.vertical || 'top',
        horizontal: anchorOrigin?.horizontal || 'right'
      }
    });

  return { success, error, info };
};

export default useNotistack;
