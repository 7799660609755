/* eslint-disable react/display-name */
import React, { Children, CSSProperties, useMemo } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Sizes } from 'types';

interface ListProps {
  children?: React.ReactNode;
  style?: CSSProperties;
}

const ListContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap'
});

const NoItems = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  height: '5rem'
});

export const VList = React.forwardRef<HTMLDivElement, ListProps>(({ style, children }, listRef) => {
  const empty = Children.count(children) == 0;
  return (
    <ListContainer style={{ padding: 0, ...style, margin: 0 }} ref={listRef}>
      {empty ? (
        <NoItems>
          <Typography variant="subtitle1" color="text.secondary">
            No items found
          </Typography>
        </NoItems>
      ) : (
        children
      )}
    </ListContainer>
  );
});

export const VContainer = (config: Sizes) =>
  useMemo(
    () =>
      styled(Box)(({ theme }) => ({
        padding: '0.5rem',

        [theme.breakpoints.up('xs')]: {
          width: config.xs
        },
        [theme.breakpoints.up('sm')]: {
          width: config.sm
        },
        [theme.breakpoints.up('md')]: {
          width: config.md
        },
        [theme.breakpoints.up('lg')]: {
          width: config.lg
        },
        [theme.breakpoints.up('xl')]: {
          width: config.xl
        }
      })),
    [config]
  );
