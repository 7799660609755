import React from 'react';
import { Stack, Box, styled, Typography, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as LoaderDark } from '../../../assets/events/loader-dark.svg';
import { ReactComponent as LoaderLight } from '../../../assets/events/loader-light.svg';
import { StepProps, StepStatus } from 'types';

type Props = StepProps & {
  // init Message (not started & started)
  title: string | JSX.Element;
  // Element to display while the processing is ongoing
  // ...Changes depending on the status
  el?: JSX.Element;
  // Error message
  error?: string;
};

const Container = styled(Box)({
  display: 'flex',
  gap: '1rem',
  '.step': {
    width: '3rem',
    height: '3rem',
    background: '#757980',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FCFCFD',
    '&.in-progress': {
      background: 'transparent',
      animation: 'rotate 1.5s linear infinite'
    },
    '@keyframes rotate': {
      to: {
        transform: 'rotate(360deg)'
      }
    },
    '&.done': {
      background: '#3D3AE9'
    }
  }
});

const Error = ({ error }: { error?: string }) => {
  return (
    <Stack justifyContent="center" sx={{ flex: 1, minWidth: 0 }}>
      <Typography variant="body1" sx={{ color: (theme) => theme.colors.red }}>
        Error!
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      >
        {error}
      </Typography>
    </Stack>
  );
};

const Step = ({ step, currentStep, status, title, el, error }: Props) => {
  const mode = useTheme().palette.mode;
  const loader = mode == 'dark' ? <LoaderDark /> : <LoaderLight />;
  const opacity =
    step > currentStep || (step == currentStep && status == StepStatus.NOT_STARTED) ? 0.6 : 1;
  const cls =
    step == currentStep && status == StepStatus.IN_PROGRESS
      ? 'in-progress'
      : step < currentStep || (step == currentStep && status == StepStatus.DONE)
      ? 'done'
      : null;

  const showLoader = step == currentStep && status == StepStatus.IN_PROGRESS;
  return (
    <Container sx={{ opacity }}>
      <div>
        <div className={`step ${cls}`}>{showLoader ? loader : <CheckIcon fontSize="small" />}</div>
      </div>
      {status == StepStatus.ERROR ? (
        <Error error={error} />
      ) : (
        <Stack sx={{ width: '100%' }} justifyContent="center">
          {typeof title == 'string' ? (
            <Typography  variant="h6" fontWeight="600">
              {title}
            </Typography>
          ) : (
            title
          )}
          <div>{el}</div>
        </Stack>
      )}
    </Container>
  );
};

export default Step;
