import React from 'react';
import { styled, Box } from '@mui/material';
import { PartnerCard, Swiper } from 'components';
import { Partner } from 'types';

type Props = {
  partners: Partner[];
  isLoading?: boolean;
};

const Root = styled(Box)(({ theme }) => ({
  background: theme.input.background,
  paddingBottom: theme.spacing(10)
}));

const Container = styled(Box)({
  '.swiper': {
    padding: '0 2rem 0 2rem !important',
    '.swiper-slide': {
      flexShrink: 1,
      height: '350px',
      width: 'auto'
    }
  }
});

const Partners = ({ partners, isLoading }: Props) => {
  return (
    <Root>
      <Container>
        <Swiper
          style={{ marginTop: 0 }}
          navigation={true}
          items={partners.map((p, i) => {
            return <PartnerCard key={`${p.title}-${i}`} partner={p} isLoading={isLoading} />;
          })}
          options={{
            slidesPerView: 'auto',
            spaceBetween: 30,
            autoplay: {
              delay: 4000,
              pauseOnMouseEnter: true
            }
          }}
        />
      </Container>
    </Root>
  );
};

export default Partners;
