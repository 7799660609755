import {
  ActivityResult,
  Collection,
  CollectionStats,
  NftOfferPreview,
  NftPreview,
  NftSalePreview,
  UserBalance
} from './generated';

export type Mode = 'dark' | 'light';

export enum BREAKPOINT {
  xs = 0,
  sm,
  md,
  lg,
  xl
}

export type Modal =
  | 'BASKET'
  | 'BUY ITEM'
  | 'MAKE OFFER'
  | 'ACCEPT OFFER'
  | 'CANCEL OFFER'
  | 'CANCEL SALE'
  | 'UPDATE SALE'
  | 'CHECKOUT_BASKET'
  | 'LIST FOR SALE'
  | 'CREATE NFT'
  | 'CREATE COLLECTION'
  | 'WALLET'
  | 'SWAP'
  | 'AUTH'
  | 'TELEGRAM NOTIFICATIONS'
  | 'EMAIL NOTIFICATIONS'
  | 'TRANSFER'
  | 'LIST NFT FOR SALE'
  | 'TRANSFER NFT'
  | 'ADVANCED OFFER';

export enum PriceSymbol {
  AVAX = 'AVAX',
  WAVAX = 'WAVAX'
}

export type FilterOption =
  | 'collections'
  | 'ownedCollections'
  | 'categories'
  | 'sales'
  | 'price'
  | 'properties'
  | 'keywords'
  | 'activityTypes'
  | 'period';

// Make sale mandatory
export type NFTSale = Omit<NftPreview, 'sale' | 'offer'> & { sale: NftSalePreview };
// Make Offer mandatory
export type NFTOffer = Omit<NftPreview, 'sale' | 'offer'> & { offer: NftOfferPreview };

export type AssetProperty = {
  trait_type: NumberOrString;
  value: NumberOrString;
};

export type Form = {
  name: string;
  description: string;
  external_url: string;
  properties: AssetProperty[];
  copies: NumberOrString;
};

export type Asset = {
  fileUrl: string;
  form: Form;
  tokenKind: TokenKind;
  collection: Collection;
  fileCoverUrl?: string | undefined;
};

export interface TransferedBalance extends UserBalance {
  amountToTransfer: number;
}

export type TransferAssets = {
  erc721: TransferedBalance[];
  erc1155: TransferedBalance[];
  // send to
  to: string;
};

export type AdvancedOffer = {
  collection: Collection;
  stats: CollectionStats;
  properties?: Property[];
};

/**
 * Global state
 */
export type State = {
  // Theme mode
  mode: Mode;
  // basket (cart)
  basket: Map<string, NFTSale>;
  // Set the modal to be opened
  modal?: Modal;
  // Flag to hide <Footer />
  hideFooter: boolean;
  // Flag to open collection filters
  openFilters: boolean;
  // Sale on wich an action will be applied (make offer, buy, etc..)
  nftSale?: NFTSale;
  // Offer on which an action will be applied (accept, decline, cancel)
  nftOffer?: NFTOffer;
  // NFT on which an action will be applied (Create offer )
  nft?: NftPreview & { editions: string };
  // Item(s) that will be listed for sale
  items?: NftPreview[];
  // Created NFT (could ne listed for sale Immediately)
  item?: { nft?: NftPreview; file: FileUpload };
  // Wallet status, true => valid
  isWalletValid: boolean;
  // Bid (Nft | basic (on collection) | advanced (on attributes))
  bid?: Bid;
  // user email
  userEmail?: string;
  // User Telegram Token for telegram notifications
  telegramToken?: string;
  // Nft kind to deploy
  deployNFTKind?: TokenKind;
  // Deal id,  used whithin the 'sell page' to get notified that the listing has been successful
  // ... in which case the user's balances is refreshed and the selected items are cleared
  createDealId?: string;
  // Create NFT
  createNFT?: Asset;
  // contract address deployed collection
  collectionAddress?: string;
  // Save lthe last modal name in order to browse back from a modal to another one e.g (make offer -> swap, swap -> make offer)
  previous?: Modal;
  // Holds tokens to be transfered
  transferAssets?: TransferAssets;
  // Set transferTx to notify transfer page that the transfer has processed
  transferTx?: string;
  // Set nft to be listed for sale directly from nft details page
  listNFTForSale?: NftPreview;
  // Set nft to be transfered from nft details page
  transferNFT?: NftPreview;
  // Make offer con Collection/Properties
  advancedOffer?: {
    collection: Collection;
    stats: CollectionStats;
    properties?: Property[];
  };
};

/**
 * Used to type partners displayed in the home page
 */
export type Partner = {
  title: string;
  subtitle: string;
  description: string;
  link_url: string;
  link_target: string;
  image_url: string;
  format: string;
  date_start: string;
  date_end: string;
};

export type HomePartners = {
  primary: Partner[];
  secondary: Partner[];
};

// Used when placing a bid on a collection, nft or attributes
export type Bid =
  | {
      type: 'nft';
      price: number;
      quantity: number;
      expirationDate: Date;
      token_id: string;
      collection: {
        address: string;
        avatar: string;
        name: string;
      };
    }
  | {
      type: 'collection';
      price: number;
      quantity: number;
      collection: {
        address: string;
        avatar: string;
        name: string;
      };
    }
  | {
      type: 'attributes';
      properties: Property[];
      price: number;
      quantity: number;
      collection: {
        address: string;
        avatar: string;
        name: string;
      };
    };

export enum SortState {
  none,
  unsorted,
  ascending,
  descending
}

export type BaseType = Record<string, unknown>;

export type SortClickHandler = (key: string, currentSortState: SortState) => void;

/**
 * Used by Step compoenent
 */

export enum StepStatus {
  NOT_STARTED,
  STARTED,
  IN_PROGRESS,
  DONE,
  ERROR
}

/**
 * Used by any modal that Orchestrates steps
 */
export type StepState = {
  currentStep: number;
  status: StepStatus;
};

export type StepProps = {
  // Own step (order in the sequence)
  step: number;
  // Current active step
  currentStep: number;
  // Status
  status: StepStatus;
  //
};

/****
 *
 * App types and interfaces
 *
 */

export type NumberOrString = number | string;

export type PriceRangeType = {
  min?: NumberOrString;
  max?: NumberOrString;
};

export type KeyValuePair<T, U> = {
  key: T;
  value: U;
};

/**
 * Sale Form type
 */

export type SaleFormType = {
  type: SaleType;
  price?: NumberOrString;
  startPrice?: NumberOrString;
  reservePrice?: NumberOrString;
  startDate?: Date;
  expirationDate?: Date;
  reserved?: boolean;
  // Buyer address when reserved is TRUE
  buyer?: NumberOrString;
};

/**
 * Navigation bar
 */

export type BarLinkItem = {
  icon: JSX.Element;
  title: string;
  caption: string;
  link?: string;
};

export interface BaseLink {
  page: string;
  link: string;
}

export interface BarLinkProps extends BaseLink {
  items: BarLinkItem[];
}

export interface Base {
  avatar: string;
  address: string;
  name: string;
  owner?: string;
  certified: boolean;
}

export type Sizes = {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
};

/**
 *  NFT (temp type)
 */

export enum SaleType {
  auction = 'Auction',
  direct = 'Direct'
}

/**
 * Artist (temp type)
 */

export interface Artist extends Base {
  description: string;
  banner: string;
}

/**
 * Gallery
 */

 export interface Gallery {
  sharecode: string;
  title: string;
  description: string;
  total_items: number;
  total_likes: number;
  image_cover: string;
  image_360: string;
  creator: {
    address: string;
    name: string;
    avatar: string;
    certified: boolean;
  };
  items?: NftPreview[];
}

/**
 * KalaoGo
 */

export interface KalaoGo {
  address: string;
  name: string;
  url_icon: string;
  url_cover: string;
  description: string;
  total_items: number;
  url_go: string;
  status_tag: 'upcoming' | 'live' | 'soldout';
  type_tag: 'free_mint' | 'standard_mint' | 'dutch_auction';
  url_twitter: string;
  start_date: string;
  start_time: string;
  starting_date: string;
  status_name: string;
  type_name: string;
  starting_price: string;
  reserve_price: string;
  last_price: string;
}

// NFT Property

export type Attribute = {
  name: string;
  count: number;
  selected: boolean;
};

export type Property = {
  name: string;
  attrs: Attribute[];
};

export enum EventType {
  Sale = 'Sale',
  Listing = 'Listing',
  Offer = 'Offer made',
  Transfer = 'Transfer',
  Auction = 'Auction',
  OfferReceived = 'Offer received',
  CounterOffer = 'Counter offer'
}

export enum EventStatus {
  active = 'Active',
  canceled = 'Canceled',
  ended = 'Ended'
}

export type EnhancedActivity = ActivityResult & { name: string };

/**
 * Dashboard & profile pages
 */

export type Social = {
  instagram: string;
  twitter: string;
  followers: number;
  following: number;
};

export type UserInfo = {
  backgroundImage: string;
  name: string;
  contractAddress: string;
  description: string;
};

/**
 * Dshboard - listings
 */

export type Listing = {
  address?: string;
  assetId?: string;
  tokenId?: string;
  id: string;
  type: EventType.Auction | EventType.Sale;
  title: string;
  name: string;
  avatar: string;
  certified: boolean;
  price: number;
  bid?: number;
  quantity: number;
  floorDiff: string;
  status: 'Live' | 'Canceled' | 'Ended' | 'Sold out' | Date;
};

/**
 * Ranking
 */
export type CollectionRanking = {
  rank: number;
  avatar: string;
  address: string;
  certified: boolean;
  name: string;
  volume: number;
  floor_price: number;
  average_price: number;
  total_owners: number;
  supply: number;
  thumbnails: string[];
  // Graph data
  series: { x: number; y: number }[];
};

export type UserRanking = {
  rank: number;
  name: string;
  avatar: string;
  certified: boolean;
  description: string;
  address: string;
  banner: string;
  volume: number;
  sold: number;
  bought: number;
};

export type GenericCardInfo = {
  avatar: string;
  certified: boolean;
  name: string;
  count: number;
};
export type GenericCardProps = {
  address: string;
  text: string;
  image: string;
  info?: GenericCardInfo;
};

export type HotBid = {
  address: string;
  thumbnail: string;
  avatar: string;
  certified: boolean;
  name: string;
  // Last bid ?
  price: number;
  // Bidding end time
  endTime: Date;
};

/** Kalao vision */
export interface Metaverse extends Base {
  thumbnail: string;
  description: string;
  likes: number;
}

export interface FileUpload {
  name: string;
  size: number;
  lastModified: number;
  preview: string;
  type: string;
  fileToUpload?: File;
}

// web3 types
export type Ticket = {
  seller: string;
  token_address: string;
  token_id: string;
  in_sale_amount: string;
  unitary_price: string;
  expiration_ts: number;
  starting_ts: number;
  nonce: number;
  chain_id: number;
  seller_signature: string;
  buyer: string;
  nft_recipient: string;
  amount_bought: string;
  sale_id: number;
  kalao_fees: string;
  community_fees: string;
  extern_fees: string;
  extern_recipient: string;
  validator_signature_expiration_ts: number;
  activate_royalties: boolean;
  validator_signature: string;
};

export type OfferTicket = {
  buyer: string;
  token_address: string;
  merkle_root: string;
  payment_token: string;
  amount_to_buy: string;
  unitary_price: string;
  expiration_ts: number;
  starting_ts: number;
  nonce: number;
  chain_id: number;
  buyer_signature: string;
  seller?: string;
  token_id: string;
  amount_sold: string;
  offer_id: number;
  fees_kalao: string;
  fees_community: string;
  fees_extern: string;
  extern_recipient: string;
  validator_signature_expiration_ts: number;
  activate_royalties: boolean;
  validator_signature: string;
  merkle_proof: string[];
};

export type AddressRouteParams = {
  address?: string;
};

export type SharecodeRouteParams = {
  sharecode?: string;
};

// different kinds of tokens
// compatible with backend
export enum TokenKind {
  erc721 = 'erc721',
  erc1155 = 'erc1155',
  erc20 = 'erc20'
}
