// 3rd party libs
import React from 'react';
import { Box } from '@mui/material';

// Compononent
import HomeBanner from './HomeBanner';
import Partners from './Partners';
import { Ranking } from './Ranking';
import Go from './Go';
import { Footer } from 'components';
import { usePartnersQuery } from 'api';
import { HomePartners, Partner } from 'types';

const initPartner: Partner = {
  title: '',
  subtitle: '',
  description: '',
  link_url: '',
  link_target: '',
  image_url: '',
  format: '',
  date_start: '',
  date_end: ''
};

const getPartners = (response?: string): HomePartners => {
  let primary: Partner[] = [initPartner];
  let secondary: Partner[] = [...Array(8).keys()].map(() => initPartner);

  if (response) {
    const partners = JSON.parse(response);
    const parse = partners as HomePartners;
    primary = parse.primary;
    secondary = parse.secondary;
  }

  return {
    primary,
    secondary
  };
};

const Home = () => {
  const { data, isLoading } = usePartnersQuery({});
  const { primary, secondary } = getPartners(data?.params);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <HomeBanner partner={primary[0]} isLoading={isLoading} />
      <Partners partners={secondary} isLoading={isLoading} />
      <Ranking />
      <Go />
      <Footer />
    </Box>
  );
};

export default Home;
