export const colors = {
  // purple shades
  lightPurple: '#8b73ed',
  purple: '#6F51E9',
  darkPurple: '#4d38a3',
  // grey shades
  lightGrey0: '#F8F8F7',
  lightGrey1: '#8F9092',
  lightGrey2: '#B3B3B3',
  lightGrey3: '#757980',
  lightGrey: '#a4a4a4',
  grey: '#8E8E8E',
  grey100: '#757980',
  darkGrey: '#636363',
  darkGrey100: '#7579801A',
  // blue
  lightBlue: '#5f8eff',
  blue: '#3D3AE9',
  buttonBlue: '#3D3AE9',
  buttonDark: '#0F1012',
  darkblue: '#264fb2',
  //
  white: '#FFFFFF',
  //
  lightBalck: '#1F2125',
  //
  lightBlack100: '#2C353C',
  //
  backgroundPrimaryDark: '#1F2125',
  backgroundSecondaryDark: '#0F1012',
  backgroundPrimaryLight: '#F8F8F7',
  backgroundSecondaryLight: '#ECECEC',
  //
  darkBlack: '#0F1012',
  // yellow
  homeCardYellow: '#ffd136',
  // likes
  red: '#FF434D',
  // Dashboard
  green: '#26B45D',

  soldOut: '#3D3AE9',
  live: '#26B45D',
  upcoming: '#FFDB3D',
  yellow: '#FFDB3D',
};
