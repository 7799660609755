import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { DatePicker, DropDownInput, Price } from 'components';
import { NumberOrString, PriceSymbol } from 'types';

type Props = {
  startPrice: NumberOrString | undefined;
  reservePrice: NumberOrString | undefined;
  startDate: Date | undefined;
  expirationDate: Date | undefined;
  onChange: (
    startPrice: NumberOrString | undefined,
    reservePrice: NumberOrString | undefined,
    startDate: Date | undefined,
    expirationDate: Date | undefined
  ) => void;
};

export const options = [
  {
    key: 'wavax',
    value: <Price value="WAVAX" symbol={PriceSymbol.WAVAX} />
  }
];

export const Auction = ({
  startPrice,
  reservePrice,
  startDate,
  expirationDate,
  onChange
}: Props) => {
  const [priceOption, setPriceOption] = useState<NumberOrString>('wavax');

  return (
    <>
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary">
          Starting price
        </Typography>
        <DropDownInput
          defaultKey={'wavax'}
          value={startPrice}
          numeric={true}
          onValueChange={(v) => onChange(v, reservePrice, startDate, expirationDate)}
          onOptionChange={(v) => setPriceOption(v)}
          options={options}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary">
          Reserve price
        </Typography>
        <DropDownInput
          defaultKey={'wavax'}
          value={reservePrice}
          numeric={true}
          onValueChange={(v) => onChange(startPrice, v, startDate, expirationDate)}
          onOptionChange={(v) => setPriceOption(v)}
          options={options}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary">
          Starting date
        </Typography>
        <DatePicker
          date={startDate || new Date()}
          onChange={(d) => onChange(startPrice, reservePrice, d, expirationDate)}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary">
          Expiration date
        </Typography>
        <DatePicker
          date={expirationDate || new Date()}
          onChange={(d) => onChange(startPrice, reservePrice, startDate, d)}
        />
      </Stack>
    </>
  );
};
