import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Stack, Typography, styled, Box, Divider } from '@mui/material';
import { formatBytes } from '../utils';
import { format } from 'date-fns';
import { FileUpload } from 'types';
import { allMedia, ImageIconLabel, onlyImages, UploaderIconLabel } from './utils';

type Props = {
  type: 'all' | 'images';
  file: FileUpload | undefined;
  setFile: (file: FileUpload) => void;
};

const FileDropZone = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  height: '20rem',
  background: theme.input.background,
  borderRadius: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.5rem'
}));

const renderProperty = (key: string, value: string) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        borderColor: 'divider'
      }}
    >
      <Typography variant="caption" color="text.secondary">
        {key}
      </Typography>
      <Typography variant="caption" color="text.primary">
        {value}
      </Typography>
    </Box>
  );
};

const renderFile = (file: FileUpload) => {
  return (
    <Stack spacing={1} sx={{ mt: 1, width: '100%' }}>
      <Divider />
      {renderProperty('Filename', file.name)}
      {renderProperty('Size', formatBytes(file.size))}
      {renderProperty('Modified', format(new Date(file.lastModified), 'PPpp'))}
      {renderProperty('Type', file.type)}
    </Stack>
  );
};

const FileUploader = ({ file, setFile, type }: Props) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: type == 'all' ? allMedia : onlyImages,
    onDrop: (files) => {
      const { lastModified, type, name, size } = files[0];
      setFile({
        lastModified,
        type,
        name,
        size,
        fileToUpload: files[0],
        preview: URL.createObjectURL(files[0])
        // We can load the actual data this way
        // const stream = fetch(file.preview).then(...)
      });
    }
  });

  return (
    <>
      <FileDropZone {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {type == 'all' ? <UploaderIconLabel /> : <ImageIconLabel title="Card preview" />}
        {file && renderFile(file)}
      </FileDropZone>
    </>
  );
};

export default FileUploader;
