import React from 'react';
import { InputBase, Paper } from '@mui/material';
import { KeyValuePair, NumberOrString } from 'types';
import DropDownBasic from '../DropDownBasic';

type Props = {
  numeric?: boolean;
  value?: NumberOrString;
  defaultKey: NumberOrString;
  onValueChange: (v: NumberOrString) => void;
  onOptionChange: (key: NumberOrString) => void;
  placeholder?: string;
  options: KeyValuePair<NumberOrString, JSX.Element>[];
  error?: boolean;
};

const DropDownInput = ({
  value,
  onValueChange,
  onOptionChange,
  options,
  numeric,
  placeholder,
  defaultKey,
  error
}: Props): JSX.Element => {
  return (
    <Paper
      elevation={0}
      sx={{
        background: (theme) => theme.input.background,
        display: 'flex',
        alignItems: 'center',
        py: 0.5,
        height: '3.5rem',
        borderRadius: '0.5rem',
        border: error ? '1px solid #EF5454' : null
      }}
    >
      <InputBase
        sx={{ flex: 1, px: 2 }}
        value={value == undefined ? '' : value}
        inputProps={{ style: { fontWeight: 500 } }}
        onChange={(e) => {
          if (numeric)
            onValueChange(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'));
          else onValueChange(e.target.value);
        }}
        placeholder={placeholder}
      />
      <DropDownBasic defaultKey={defaultKey} options={options} onOptionChange={onOptionChange} />
    </Paper>
  );
};

export default DropDownInput;
