import { NumberOrString } from 'types';
import { Collection, CollectionNotificationSettingInput } from 'types/generated';

export type OwnedNftsCollection = Pick<Collection, 'address' | 'name' | 'certified' | 'avatar'>;

export type OwnedNftsCollectionThreshold = OwnedNftsCollection & {
  offer_threshold: NumberOrString;
};

export const toCollectionThresholds = (
  collections: OwnedNftsCollection[],
  offer_thresholds?: CollectionNotificationSettingInput[]
): OwnedNftsCollectionThreshold[] => {
  const ownedNftsCollections: OwnedNftsCollectionThreshold[] = [...collections].map((c) => {
    return { ...c, offer_threshold: '' };
  });
  offer_thresholds?.forEach((threshold) => {
    const index = ownedNftsCollections.findIndex((c) => c.address == threshold.address);
    if (index > -1) {
      ownedNftsCollections[index].offer_threshold = threshold.offer_threshold;
    }
  });
  return ownedNftsCollections;
};
