import React from 'react';
import { Swiper, ExploreNFTCard } from 'components';
import { NftPreview } from 'types/generated';
import { useNavigate } from 'react-router-dom';
import { goToNft } from 'helpers/nfts';
import { Typography } from '@mui/material';

type Props = {
  nfts: NftPreview[];
};

const options = {
  breakpoints: {
    340: {
      slidesPerView: 1.5,
      spaceBetween: 30
    },
    576: {
      slidesPerView: 2.5,
      spaceBetween: 30
    },
    768: {
      slidesPerView: 3.5,
      spaceBetween: 30
    },
    1200: {
      slidesPerView: 4.5,
      spaceBetween: 30
    },
    1400: {
      slidesPerView: 5.5,
      spaceBetween: 30
    }
  }
};

const NFTsSwiper = ({ nfts }: Props) => {
  const navigate = useNavigate();
  return (
    <Swiper
      el={
        <Typography variant="h4" color="text.primary">
          More from this collection
        </Typography>
      }
      style={{ marginLeft: 0, marginRight: 0 }}
      navigation={true}
      items={nfts.map((nft) => (
        <ExploreNFTCard
          key={nft.asset_id}
          nft={nft}
          goTo={() => {
            navigate(goToNft(nft.asset_id));
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }}
        />
      ))}
      options={{ ...options }}
    />
  );
};

export default NFTsSwiper;
