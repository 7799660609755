import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { UserNotificationSettings } from 'types/generated';
import { Vision, Profile } from '../types';
import { shallowEqulityObjects } from '../utils';

type ContextType = {
  // profile
  profile?: Profile;
  setProfile: (profile: Profile) => void;
  hasProfileChanged: () => boolean;
  // Notifications (+offers)
  notifications?: UserNotificationSettings;
  setNotifications: (notifications?: UserNotificationSettings) => void;
  // Vision
  vision?: Vision;
  setVision: (vision: Vision) => void;
  visionUsernameChanged: () => boolean;
};

const defaultValue: ContextType = {
  // profile
  profile: undefined,
  setProfile: () => undefined,
  hasProfileChanged: () => false,
  // Notifications
  notifications: undefined,
  setNotifications: () => undefined,
  // Vision
  vision: undefined,
  setVision: () => undefined,
  visionUsernameChanged: () => false
};

const SettingsContext = createContext<ContextType>(defaultValue);

export const useSettings = (): ContextType => {
  return useContext(SettingsContext);
};

type Props = {
  children: ReactNode;
  defaultValues: {
    initProfile?: Profile;
    initNotifications?: UserNotificationSettings;
    initVision?: Vision;
  };
};

export const SettingsProvider = (props: Props): JSX.Element => {
  const { defaultValues, children } = props;
  const { initProfile, initNotifications, initVision } = defaultValues;
  const [profile, setProfile] = useState<Profile | undefined>(initProfile);
  const [notifications, setNotifications] = useState<UserNotificationSettings | undefined>(
    initNotifications
  );
  const [vision, setVision] = useState<Vision | undefined>(initVision);

  useEffect(() => {
    initProfile && setProfile(initProfile);
    initNotifications && setNotifications(initNotifications);
    initVision && setVision(initVision);
  }, [props.defaultValues]);

  const hasProfileChanged = () =>
    shallowEqulityObjects(defaultValues?.initProfile || {}, profile || {});

  const visionUsernameChanged = () => initVision?.username !== vision?.username;

  const value = {
    profile,
    setProfile,
    hasProfileChanged,
    notifications,
    setNotifications,
    vision,
    setVision,
    visionUsernameChanged
  };

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
