import * as React from 'react';
import { Chip, Stack, Typography } from '@mui/material';

type Props = {
  select: (value: string) => void;
};

export const Chips = ({ select }: Props) => {
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        I'm looking for...
      </Typography>
      <Stack direction={'row'} spacing={1}>
        <Chip label="NFTs" onClick={() => select('NFTs')} />
        <Chip label="Collections" onClick={() => select('Collections')} />
        <Chip label="Users" onClick={() => select('Users')} />
      </Stack>
    </Stack>
  );
};
