import { factory } from 'lib/action';
import { Action } from 'redux';
import {
  Modal,
  Mode,
  Bid,
  NFTSale,
  NFTOffer,
  TokenKind,
  Asset,
  TransferAssets,
  AdvancedOffer
} from 'types';
import { NftPreview } from 'types/generated';

// Set theme mode
export const setMode = factory<{ mode: Mode }>('SET_MODE');

// Add an NFT that listed for sale to basket (remove if already added)
export const addToBasket = factory<{ nftSale: NFTSale }>('ADD_TO_BASKET');

// Update basket (new quantities)
export const updateBasket = factory<{ nftsSales: NFTSale[] }>('UPDATE_BASKET');

// Hide <Footer /> when collection/:id/items page is mounted
export const hideFooter = factory<{ hideFooter: boolean }>('HIDE_FOOTER');

// Open collection filters
export const openFilters = factory<{ open: boolean }>('OPEN_FILTERS');

// Clear Basket (Delete all NFTs from the basket)
export const clearBasket = factory('CLEAR_BASKET');

// Set the modal to be opened
export const openModal = factory<{ modal?: Modal }>('OPEN_MODAL');

// Set the Sale on which an action will be applied (buy, make offer, change price, cancel ...)
export const setSale = factory<{ nftSale: NFTSale }>('SET_SALE');

// Set the NFT on which an action will be applied (create offer ...)
export const setNFT = factory<{ nft?: NftPreview & { editions: string } }>('SET_NFT');

// Set the offer on which an action will be applied (accept, decline, cancel and counter-offer offers )
export const setOffer = factory<{ nftOffer?: NFTOffer }>('SET_OFFER');

// batch, batch, batch, mushroom, mushroom, snake
export const batch = factory<{ actions: Action[] }>('BATCH');

// List items for sale
export const listForSale = factory<{ items: NFTSale[] }>('LIST_FOR_SALE');

// Create an NFT (Eventually put it on market)
export const createNFT = factory<{ createNFT: Asset }>('CREATE_NFT');

// Update wallet status
export const setWalletStatus = factory<{ valid: boolean }>('WALLET_STATUS');

// Set user email
export const userEmail = factory<{ email?: string }>('USER_EMAIL');

// Set user telegram token
export const userTelegramToken = factory<{ token: string }>('USER_TELEGRAM_TOKEN');

// Set the created nft kind
export const setDeployNFTKind = factory<{ deployNFTKind: TokenKind }>('DEPLOY_NFT_KIND');

// Set the deal id
export const setCreateDealId = factory<{ dealId?: string }>('DEAL_ID');

// Set newly created collection address
export const setCollectionAddress = factory<{ address?: string }>('CREATED_CLLECTION_ADDRESS');

// Save modal, so we can browse back to it later on.
export const setPrevious = factory<{ previous?: Modal }>('SET_PREVIOUS');

// Set assets to be transfered
export const transfer = factory<{ transferAssets: TransferAssets }>('SET_TRANSFER');

// Set transfer transaction hash
export const transferTransaction = factory<{ transferTx?: string }>('SET_TRANSFER_TX');

// List nft for sale NFT details page
export const listNFTForSale = factory<{ nft: NftPreview }>('LIST_NFT_FOR_SALE');

// Transfer nft for from NFT details page
export const transferNFT = factory<{ nft: NftPreview }>('TRANSFER_NFT');

// Make offer on Collection/Properties
export const setAdvancdOffer = factory<{ advancedOffer: AdvancedOffer }>('SET_ADVANCED_OFFER');
