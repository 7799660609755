import { createTheme, darken, lighten, PaletteMode } from '@mui/material';
import { colors } from '../colors';
import AvertaRegular from '../fonts/AvertaCY-Regular.woff2';
import AvertaSemibold from '../fonts/AvertaCY-Semibold.woff2';
import AvertaBold from '../fonts/AvertaCY-Bold.woff2';
import { typography } from './typography';

export const theme = createTheme();

const light = createTheme({
  palette: {
    mode: 'light',
    background: { default: '#FFFFFF', paper: '#FFF' }
  },
  button: {
    primary: {
      color: colors.white,
      background: colors.blue
    },
    secondary: {
      color: colors.lightBlack100,
      background: darken(colors.lightGrey0, 0.04)
    },
    secondaryV2: {
      color: colors.grey,
      background: darken(colors.lightGrey0, 0.04)
    }
  },
  input: {
    background: '#F8F8F7',
    color: '#8E8E8E'
  },
  colors,
  typography,
  border: '0.0625rem solid #75798033',
  borderHover: '0.0625rem solid #75798070',
  backgroundBorder: '#75798010',
  textPrimary: '#3D3AE9',
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Averta CY';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${AvertaRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Averta CY';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: url(${AvertaSemibold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Averta CY';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url(${AvertaBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#8E8E8E',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          backgroundColor: '#FFFFFF80'
        }
      },
    }
  }
});

const dark = createTheme({
  palette: {
    mode: 'dark',
    background: { default: '#18191D', paper: '#0F1012' },
    text: { secondary: '#757980' }
  },
  button: {
    primary: {
      color: colors.white,
      background: colors.buttonBlue
    },
    secondary: {
      color: colors.white,
      background: colors.backgroundPrimaryDark
    },
    secondaryV2: {
      color: colors.white,
      background: lighten(colors.lightBalck, 0.05)
    }
  },
  input: {
    background: '#1F2125',
    color: colors.white
  },
  colors,
  typography,
  border: '0.0625rem solid #75798050',
  borderHover: '0.0625rem solid #75798080',
  backgroundBorder: '#75798033',
  textPrimary: '#3D3AE9',
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Averta CY';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${AvertaRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Averta CY';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: url(${AvertaSemibold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Averta CY';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url(${AvertaBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          backgroundColor: '#00000080'
        }
      },
    }
  }
});

export const createMuiTheme = (mode: PaletteMode) => {
  return createTheme(mode == 'dark' ? dark : light);
};
