import { NFTSale, Ticket } from 'types';
import { BasketItem, BasketRequest, DealKind } from 'types/generated';

export const calulateBasketPrice = (backendTickets: Ticket[] | undefined) => {
  let total = 0;

  backendTickets?.forEach((elem) => {
    total += Number(elem.unitary_price) * Number(elem.amount_bought);
  });

  return total.toString();
};

// TODO Why amount: 1 ?
// TODO handle amount for erc1155 nfts
export const buildBasketFromSale = (nft: NFTSale) => {
  return [{ id: nft.sale.sale_id, amount: nft.sale.quantity }];
};

export const toBackendBasket = (basket: Map<string, NFTSale> | undefined) => {
  const backend_basket: BasketItem[] = [];

  basket?.forEach((nft) => {
    const { sale } = nft;
    backend_basket.push({ id: sale.sale_id, amount: sale.quantity });
  });

  return backend_basket;
};

export const checkBasketRequest = (basket: BasketItem[], account: string): BasketRequest => {
  return {
    kind: DealKind.Sale,
    basket: basket,
    buyer: account
  };
};
