import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

type Props = {
  children: ReactNode;
};

export const RightContainer = ({ children }: Props) => {
  return (
    <Box
      sx={{
        ml: 'auto',
        display: 'flex',
        flexWrap: 'wrap-reverse',
        gap: 1,
        pr: 1,
        justifyContent: 'center'
      }}
    >
      {children}
    </Box>
  );
};
