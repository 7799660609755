import React from 'react';
import { BoxProps } from '@mui/material';
import { DropDownSelect } from 'components';
import {
  sort_explore_nfts,
  sort_explore_collection,
  sort_collection,
  sort_owned_nfts,
  sort_created_nfts,
  sort_sell_nfts
} from 'constants/options';
import { useFilters } from 'providers';
import { SearchNftSort } from 'types/generated';

type SortType =
  | 'sortExploreNfts'
  | 'sortExploreCollections'
  | 'sortCollection'
  | 'sortOwnedNfts'
  | 'sortCreatedNfts'
  | 'sortSellNfts';

type Props = {
  sortType: SortType;
  defaultKey: string;
} & BoxProps;

const Sorting = ({ defaultKey, sortType, ...props }: Props) => {
  const { setSortBy } = useFilters();

  const content: { [key in SortType]: JSX.Element } = {
    sortExploreNfts: (
      <DropDownSelect
        defaultKey={defaultKey}
        pairs={sort_explore_nfts}
        sx={{ minWidth: {xs: 'none', sm: '12rem'} }}
        onSelectChange={(v) => setSortBy(v as SearchNftSort)}
        {...props}
      />
    ),
    sortExploreCollections: (
      <DropDownSelect
        defaultKey={defaultKey}
        pairs={sort_explore_collection}
        sx={{ minWidth: {xs: 'none', sm: '12rem'} }}
        onSelectChange={(v) => setSortBy(v as SearchNftSort)}
        {...props}
      />
    ),
    sortCollection: (
      <DropDownSelect
        defaultKey={defaultKey}
        pairs={sort_collection}
        sx={{ minWidth: {xs: 'none', sm: '12rem'} }}
        onSelectChange={(v) => setSortBy(v as SearchNftSort)}
        {...props}
      />
    ),
    sortOwnedNfts: (
      <DropDownSelect
        defaultKey={defaultKey}
        pairs={sort_owned_nfts}
        sx={{ minWidth: {xs: 'none', sm: '12rem'} }}
        onSelectChange={(v) => setSortBy(v as SearchNftSort)}
        {...props}
      />
    ),
    sortCreatedNfts: (
      <DropDownSelect
        defaultKey={defaultKey}
        pairs={sort_created_nfts}
        sx={{ minWidth: '12rem' }}
        onSelectChange={(v) => setSortBy(v as SearchNftSort)}
        {...props}
      />
    ),
    sortSellNfts: (
      <DropDownSelect
        defaultKey={defaultKey}
        pairs={sort_sell_nfts}
        sx={{ minWidth: '12rem' }}
        onSelectChange={(v) => setSortBy(v as SearchNftSort)}
        {...props}
      />
    )
  };
  return content[sortType];
};

export default Sorting;
