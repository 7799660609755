import React from 'react';
import { Typography, Stack, styled } from '@mui/material';
import config from 'config';

export type ConnectorProps = {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  error?: string;
  connect: () => void;
};

// Styling
export const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '0.5rem',
  '.connector': {
    cursor: 'pointer',
    padding: theme.spacing(1),
    border: theme.border,
    borderRadius: '0.5rem',
    ':hover': {
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
    }
  }
}));

// Helpers

export const ConnectorRenderer = ({ title, subtitle, icon, error, connect }: ConnectorProps) => {
  return (
    <div className="connector" onClick={connect}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Stack sx={{ flex: 1 }}>
          <Typography variant="body1" color="text.primary">
            {title}
          </Typography>
          <Typography variant="caption" color={'text.secondary'}>
            {subtitle}
          </Typography>
        </Stack>
        {icon}
      </Stack>
      {error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </div>
  );
};

export const Help = () => {
  return (
    <Stack sx={{ mt: 2 }} spacing={2}>
      <Typography variant="caption" color="text.secondary" textAlign="center">
        Need help connecting a wallet?{' '}
        <Typography variant="body2" color="text.primary">
          Read our FAQ
        </Typography>
      </Typography>
      <Typography variant="caption" color="text.secondary" textAlign="center">
        Wallets are provided by External Providers and by selecting you agree to Terms of those
        Providers. Your access to the wallet might be reliant on the External Provider being
        operational.
      </Typography>
    </Stack>
  );
};

export const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const openMetamaskDeepLink = () => {
  window.open(config.env == 'dev' ? config.dev_deeplink : config.prod_deeplink);
};
