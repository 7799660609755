import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Logo, OwnerShipStep } from 'components';
import { Divider } from '@mui/material';
import { Renderer } from '../Renderer';

const Header = () => {
  return (
    <Stack spacing={1} justifyContent="center" alignItems="center" direction="row">
      <Logo clickable={false} />
      <Typography variant="body1" color="text.primary" textAlign="center">
        Authentication
      </Typography>
    </Stack>
  );
};

const Authentication = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    setCurrentStep(1);
  }, []);

  return (
    <Renderer header={<Header />}>
      <Divider sx={{ my: 3 }} variant="middle" />
      <Stack spacing={2}>
        <OwnerShipStep step={1} currentStep={currentStep} complete={() => undefined} />
      </Stack>
    </Renderer>
  );
};

export default Authentication;
