import React from 'react';
import { Box, Skeleton, styled, Typography, useTheme } from '@mui/material';
import { Avatar } from 'components';
import { ImageContainer, Price } from 'components';
import { NumberOrString, PriceSymbol, SaleType } from 'types';
import { formatDistance } from 'date-fns';
import { ReactComponent as Timer } from '../../assets/cards/timer-dark.svg';

type Props = {
  previewUri?: string;
  previewMime?: string;
  previewCoverUri?: string;
  name?: string;
  avatar?: string;
  displayTheme?: string | null;
  certified?: boolean;
  price?: NumberOrString;
  symbol?: PriceSymbol;
  expirationDate?: Date;
  type?: SaleType;
};

// Styling
const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '1rem',
  background: theme.input.background,
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;'
}));

const AvatarContainer = styled(Box)({
  position: 'absolute',
  top: 'calc(-1.71875rem)',
  left: 'calc(1rem)'
});

export const CardInfoContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem'
});

const avatarRenderer = (collectionAvatarSrc: string, isApproved?: boolean) => {
  const theme = useTheme();
  return (
    <AvatarContainer>
      <Avatar
        top={25}
        approved={isApproved}
        src={collectionAvatarSrc}
        style={{ border: `0.2rem solid ${theme.input.background}` }}
        sx={{ width: 55, height: 55 }}
        iconStyle={{ fontSize: 20 }}
      />
    </AvatarContainer>
  );
};

const NFTInfo = ({ name, avatar, price, certified, expirationDate, symbol, type }: Props) => {
  const label = type == SaleType.direct ? 'Price' : 'Starting price';
  return (
    <CardInfoContainer>
      {avatarRenderer(avatar || '', certified)}
      <Typography
        variant="h3"
        color="text.primary"
        sx={{
          pt: 3,
          pb: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {!name ? <Skeleton /> : name}
      </Typography>
      <Box
        sx={{
          height: '4rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end'
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {price !== undefined ? label : <Skeleton width="30%" />}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {price && price > 0 ? <Price value={price} symbol={symbol} variant="h4" /> : <Skeleton width="40%" />}
          {type == SaleType.auction ? (
            expirationDate ? (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 0.5 }}
              >
                {formatDistance(expirationDate, new Date(), { addSuffix: true })}
                <Timer />
              </Typography>
            ) : (
              <Skeleton width="40%" />
            )
          ) : null}
        </Box>
      </Box>
    </CardInfoContainer>
  );
};

const PreviewNftCard = ({ previewUri, previewMime, previewCoverUri, displayTheme, ...rest }: Props): JSX.Element => {
  let is_animation = false;
  let previewCover = '';
  if (previewMime?.includes('video') || previewMime?.includes('audio')) {
    is_animation = true;
    if (previewMime?.includes('video')) {
      previewCover = previewCoverUri ? previewCoverUri : 'https://storage.kalao.io/assets-v2/preview-video.png';
    } else if (previewMime?.includes('audio')) {
      previewCover = previewCoverUri ? previewCoverUri : 'https://storage.kalao.io/assets-v2/preview-music.png';
    }
  }
  return (
    <Card>
      <ImageContainer 
        animation_mime={previewMime || ''}
        animation={is_animation ? previewUri : ''}
        src={!is_animation ? previewUri || '' : previewCover}
        displayTheme={displayTheme} />
      <NFTInfo {...rest} />
    </Card>
  );
};

export default PreviewNftCard;
