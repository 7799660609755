import React, { useEffect, useRef, useState } from 'react';
import { Skeleton, styled, Typography } from '@mui/material';
import { Partner } from 'types';
import { goToCollection } from 'helpers/collections';
import { useNavigate } from 'react-router-dom';

type Props = {
  partner: Partner;
  isLoading?: boolean;
  imgWidth?: '100%' | 'auto';
  isPrimary?: boolean;
};

const StyledCard = styled('div')({
  height: '100%',
  borderRadius: '1.25rem',
  overflow: 'hidden',
  position: 'relative',
  cursor: 'pointer',
  img: {
    height: '100%',
    objectFit: 'cover'
  },
  ':hover': {
    '.info': {
      opacity: 1,
      transition: ' all 0.2s ease-in-out'
    }
  }
});

const Info = styled('div')({
  width: '100%',
  height: '4rem',
  position: 'absolute',
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)',
  opacity: 0,
  div: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const LinkCard = styled('a')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  background: 'transparent'
});

const PartnerCard = ({ partner, imgWidth = 'auto', isLoading, isPrimary }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { image_url, title, subtitle, link_url, link_target } = partner;
  const [loaded, setLoaded] = useState(false);
  const image = useRef<HTMLImageElement>(null);
  const [collection, setCollection] = useState('');

  const handleLoad = () => setLoaded(true);

  useEffect(() => {
    if (image.current && image.current.complete) setLoaded(true);
  }, []);

  useEffect(() => {
    if (link_url) {
      try {
        const url = new URL(link_url);
        if (url.hostname.includes('kalao.io') && url.pathname.includes('/collection')) {
          const address = url.pathname.split('/collection/')[1];
          setCollection(address);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [link_url]);

  const goTo = () => {
    if (collection) navigate(goToCollection(collection));
  };

  return (
    <StyledCard onClick={goTo}>
      {link_url && !collection && <LinkCard href={link_url} target={link_target} />}
      {(isLoading || !loaded) && (
        <Skeleton
          variant="rectangular"
          width={isPrimary ? '100%' : '25rem'}
          height={'30rem'}
          sx={{ borderRadius: '1rem' }}
        />
      )}
      <img
        ref={image}
        src={image_url}
        onLoad={handleLoad}
        style={{
          opacity: isLoading || !loaded ? 0 : 1,
          width: imgWidth
        }}
      />
      {(title || subtitle) && (
        <Info className="info">
          <div>
            <Typography variant="body1" color="white">
              {title}
            </Typography>
            <Typography variant="body2" color="white">
              {subtitle}
            </Typography>
          </div>
        </Info>
      )}
    </StyledCard>
  );
};

export default PartnerCard;
